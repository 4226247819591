export default {
  GETLEADDISTRO: "GETLEADDISTRO",
  SETLEADDISTRO: "SETLEADDISTRO",
  POSTLEADDISTRO: "POSTLEADDISTRO",
  PUTLEADDISTRO: "PUTLEADDISTRO",
  GET_AGENT_TIERS: "GET_AGENT_TIERS",
  POST_AGENT_TIERS: "POST_AGENT_TIERS",
  GET_AUTO_DIAL: "GET_AUTO_DIAL",
  SET_AUTO_DIAL: "SET_AUTO_DIAL",
};
