<template>
  <div
    class="table-responsive gridHeight newPanelWrapper"
    style="box-shadow: 0 0 1px 1px #d1d1d1 !important; padding: 1px !important"
  >
    <form v-on:keydown.enter.prevent="() => {}">
      <table
        class="datagrid-table mb-0"
        id="btn-editable-cpg"
        style="width: 100%; border: none !important; background-color: #ffffff"
      >
        <thead>
          <tr>
            <th>Phone</th>
            <th>Department</th>
            <th></th>
          </tr>

          <tr v-if="isNewRowActive" v-click-outside="outsideOfNewRow">
            <th>
              <input
                class="tabledit-input form-control generalTextBox"
                id="newPhoneInp"
                type="text"
                name="col1"
                v-model="newRow.Phone"
                @change="newPhoneChange"
                v-myMask="{ mask: ['(999) 999-9999'] }"
                :class="v$.newRow.Phone.$error ? 'has-error' : ''"
                @blur="v$.newRow.Phone.$touch"
                autocomplete="off"
              />
            </th>
            <th>
              <input
                class="tabledit-input form-control generalTextBox"
                id="newPhoneDepartment"
                type="text"
                name="col2"
                v-model="newRow.Department"
                autocomplete="off"
              />
            </th>
            <th>
              <button
                :disabled="v$.$invalid || myInvalid"
                type="button"
                class="btn"
                style="background-color: #24d15f"
                v-on:click.prevent="handleNewRowSave"
              >
                <i class="fas fa-save"></i>
              </button>
            </th>
          </tr>
        </thead>

        <tbody v-if="value">
          <tr v-for="item in value" :key="item.Id">
            <td>
              <div v-show="!item.Edit">
                <button
                  type="button"
                  class="btn"
                  style="background-color: #24d15f"
                  v-on:click.prevent="makeCall(item.Phone)"
                >
                  <i class="fe-phone"></i>
                </button>

                <span class="tabledit-span tabledit-identifier">
                  {{ formatPhoneNumber(item.Phone) }}
                </span>
              </div>

              <input
                class="tabledit-input generalTextBox form-control"
                v-on:keydown.enter.prevent="resetEnter"
                v-on:keydown.esc.prevent="resetEnter"
                type="text"
                name="col1"
                v-model="item.Phone"
                style="display: none"
                :key="'ph' + item.Id"
                :id="'phid' + item.Id"
                @input="myPhoneValidation"
                v-show="item.Edit"
                @change="editPhoneChange($event, item)"
                v-myMask="{ mask: ['(999) 999-9999'] }"
              />
            </td>
            <td class="tabledit-view-mode">
              <span v-show="!item.Edit" class="tabledit-span">
                {{ item.Department }}
              </span>

              <input
                class="tabledit-input form-control generalTextBox"
                id="newPhoneDepartment"
                type="text"
                name="col2"
                v-model="item.Department"
                v-show="item.Edit"
              />
            </td>
            <td style="white-space: nowrap; width: 1%">
              <button
                type="button"
                title="Edit"
                :disabled="myInvalid"
                class="btn"
                style="background-color: #24d171"
                v-on:click.prevent.stop="
                  () => {
                    resetEdit(item);
                    item.Edit = !item.Edit;
                    isNewRowActive = false;
                  }
                "
              >
                <i v-show="!item.Edit" class="ri-edit-line"></i>
                <i v-show="item.Edit" class="fe-check"></i>
              </button>
              <button
                type="button"
                title="Delete"
                class="btn"
                style="background-color: #ed3030"
                :disabled="myInvalid"
                v-on:click.prevent.stop="() => handleDelete(item.Id)"
              >
                <i class="fe-trash-2"></i>
              </button>
            </td>
          </tr>
          <tr
            v-if="!isNewRowActive"
            style="background-color: #ffffff !important"
          >
            <td colspan="4">
              <button
                type="button"
                title="Delete"
                class="addNewButton"
                :disabled="myInvalid"
                v-on:click.prevent.stop="() => startNewRow()"
              >
                <i style="font-size: 18px" class="fe-plus-circle"></i>
                Add New Phone
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import formatMixin from "@/mixins/formatMixin.js";

export default {
  name: "CreditorPhoneGrid",

  props: {
    ContactId: {
      type: Number,
      default: () => 0,
    },
    ContactName: {
      type: String,
      default: () => "",
    },
    Phones: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [formatMixin],
  data() {
    return {
      initRow: Object.assign({}, this.initRowFnc()),
      value: Object.assign([], this.Phones),
      v$: useValidate(),
      isNewRowActive: false,
      newRow: Object.assign({}, this.initRowFnc()),
      myInvalid: false,
    };
  },
  mounted() {},
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  methods: {
    initRowFnc() {
      return {
        Id: 0,
        Phone: "",
        Department: "",
        Edit: false,
      };
    },
    emptyTheObjectsOnUnmount() {
      this.value = null;
      this.isNewRowActive = null;
      this.newRow = null;
      this.myInvalid = null;
    },
    newPhoneChange(event) {
      if (event.target.value && event.target.value != "") {
        this.newRow.Phone = event.target.value;
      }
    },
    editPhoneChange(event, item) {
      if (event.target.value && event.target.value != "") {
        item.Phone = event.target.value;
      }
    },
    makeCall(phone) {
      if (phone.length >= 10) {
        if (phone.length == 11 && phone.startsWith("1")) {
          phone = phone.slice(1, phone.length);
        }

        this.$Emitter.emit("callCreditor", {
          PhoneNumber: phone,
          Id: this.ContactId,
          Name: this.ContactName,
        });
        this.$emit("closeParentForm");
      }
    },
    resetEdit(obj) {
      if (this.myInvalid) return;

      for (let item of this.value) {
        if (item !== obj) item.Edit = false;
      }
    },
    resetEnter({ target }) {
      this.phoneValidation(target);
      this.resetEdit(null);
    },

    formatPhoneNumber(phoneNumberString) {
      let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return "";
    },
    startNewRow() {
      if (this.myInvalid) return;
      this.resetEdit(null);
      this.isNewRowActive = true;
    },
    handleNewRowSave() {
      this.resetEdit(null);
      this.isNewRowActive = false;
      let newId = -1;
      if (this.value.length > 0) {
        let minId = Math.min(...this.value.map((x) => x.Id));
        if (minId < 0) {
          newId = minId - 1;
        }
      }
      this.newRow.Id = newId;
      this.value.push(this.newRow);
      this.newRow = Object.assign({}, this.initRow);

      this.$emit("updatePhones", this.value);
    },
    outsideOfNewRow() {
      this.phoneValidation(document.getElementById("newPhoneInp"));

      if (this.myInvalid) {
        this.newRow = Object.assign({}, this.initRow);
        this.isNewRowActive = false;
        this.myInvalid = false;
        return;
      }
      this.resetEdit(null);
      this.isNewRowActive = false;
      let newId = -1;
      if (this.value.length > 0) {
        let minId = Math.min(...this.value.map((x) => x.Id));
        if (minId < 0) {
          newId = minId - 1;
        }
      }
      this.newRow.Id = newId;
      this.value.push(this.newRow);
      this.newRow = Object.assign({}, this.initRow);
      this.$emit("updatePhones", this.value);
    },
    myPhoneValidation({ target }) {
      this.phoneValidation(target);
    },
    phoneValidation(target) {
      this.myInvalid = false;
      if (target.value != null) {
        if (target.value.length != 10) {
          this.myInvalid = true;
        }
      }
    },
    async handleDelete(itemId) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.value.findIndex((x) => x.Id == itemId);
            if (rowIndex > -1) {
              this.value.splice(rowIndex, 1);
            }
            this.isNewRowActive = false;
            this.$emit("updatePhones", this.value);
            this.$swal("Deleted!", "Phone deleted", "success");
          }
        });
    },
  },
  validations() {
    return {
      newRow: {
        Phone: {
          isPhone(phone) {
            phone = this.cleanFormatPhone(phone);
            const regex = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
            return regex.test(phone);
          },
        },
      },
    };
  },
};
</script>
