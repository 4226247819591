import { createWebHistory, createRouter } from "vue-router";
import Login from "@/Login";
import Home from "@/Home";
import Dashboard from "@/views/agent/Dashboard/Dashboard";
import Customers from "@/views/agent/ClientGrid/Clients";
import Unsubscribe from "@/components/Unsubscribe/Unsubscribe";
import OfficeManagement from "@/views/admin/OfficeManagement/OfficeManagement";
import EmployeeManagement from "@/views/admin/EmployeeManagement/EmployeeManagement";
import QuickEmailManagement from "@/views/admin/QuickEmailManagement/QuickEmailManagement";
import QuickNoteManagement from "@/views/admin/QuickNoteManagement/QuickNoteManagement";
import CSRTaskActionManagement from "@/views/admin/CSRTaskActionManagement/CSRTaskActionManagement";
import CSRTaskTypeManagement from "@/views/admin/CSRTaskTypeManagement/CSRTaskTypeManagement";
import RolesManagement from "@/views/admin/RolesManagement/RolesManagement";
import DispositionManagement from "@/views/admin/DispositionManagement/DispositionManagement";
import StatusManagement from "@/views/admin/StatusManagement/StatusManagement";
import QuickSmsManagement from "@/views/admin/QuickSmsManagement/QuickSmsManagement";
import ScriptManagement from "@/views/admin/ScriptManagement/ScriptManagement";
import VMDropManagement from "@/views/admin/VMDropManagement/VMDropManagement";
import PauseReasonManagement from "@/views/admin/PauseReasonManagement/PauseReasonManagement";
import VMRecorderManagement from "@/views/admin/VMRecorderManagement/VMRecorderManagement";
import CampaignManagement from "@/views/admin/CampaignManagement/CampaignManagement";
import LeadDistributionManagement from "@/views/admin/LeadDistributionManagement/LeadDistributionManagement";
import CompanyDidManagement from "@/views/admin/CompanyDidManagement/CompanyDidManagement";
import CallSettingsManagement from "@/views/admin/CallSettingsManagement/CallSettingsManagement";
import CsrTaskDistro from "@/views/agent/CsrTaskDistro/CsrTaskDistro";
import DripEmailManagement from "@/views/admin/DripEmailManagement/DripEmailManagement";
import CallLogsReport from "@/views/reports/CallLogsReport";
import CSRTaskPerformanceReport from "@/views/reports/CSRTaskPerformanceReport";
import CSRRealtimeTasksReport from "@/views/reports/CSRRealtimeTasksReport.vue";
import CSRTaskReport from "@/views/reports/CSRTaskReport";
import CustomerStatusReport from "@/views/reports/CustomerStatusReport";
import InboundStatisticsReport from "@/views/reports/InboundStatisticsReport";
import LeadAssignReport from "@/views/reports/LeadAssignReport";
import LiveCallsReport from "@/views/reports/LiveCallsReport";
import MissedCallReport from "@/views/reports/MissedCallReport";
import RemindersReport from "@/views/reports/RemindersReport";
import SmsHistoryReport from "@/views/reports/SmsHistoryReport";
import IncomingVmReport from "@/views/reports/IncomingVmReport";
import PendingDispositionReport from "@/views/reports/PendingDispositionReport";
import TagManagement from "@/views/admin/TagManagement/TagManagement";
import CustomerTagReport from "@/views/reports/CustomerTagReport";
import InboundCallSettingsManagement from "@/views/admin/InboundCallSettingsManagement/InboundCallSettingsManagement";
import InboundCallRulesManagement from "@/views/admin/InboundCallRulesManagement/InboundCallRulesManagement";
import OutboundCallSettingsManagement from "@/views/admin/OutboundCallSettingsManagement/OutboundCallSettingsManagement";
import DidManagement from "@/views/admin/DidManagement/DidManagement";
import DidPurchaseManagement from "@/views/admin/DidPurchaseManagement/DidPurchaseManagement";
import UISettingsManagement from "@/views/admin/UISettingsManagement/UISettingsManagement";
import CsrTickets from "@/views/agent/CsrTickets/CsrTickets";
import DebtBlueSalesCompensationReport from "@/views/reports/DebtBlueSalesCompensationReport";
import AgentPhoneStatusReport from "@/views/reports/AgentPhoneStatusReport";
import SMSBrandsManagement from "@/views/admin/SMSBrandsManagement/SMSBrandsManagement";
import AgentPhoneReport from "@/views/reports/AgentPhoneReport";
import CsrTaskSummaryReport from "@/views/reports/CsrTaskSummaryReport";
import CustomerDispositionSettingsReport from "@/views/reports/CustomerDispositionSettingsReport";
import CSRTaskContactScheduleReport from "@/views/reports/CSRTaskContactScheduleReport";
import CSRTaskDistroQueueReport from "@/views/reports/CSRTaskDistroQueueReport";
import CallMetricsReport from "@/views/reports/CallMetricsReport";
import CustomerCancelationReasonsManagement from "@/views/admin/CustomerCancelationReasonsManagement/CustomerCancelationReasonsManagement";
import ResetPassword from "@/ResetPassword";
import BrPopup from "@/BrPopup";
import CPAAgentReport from "@/views/reports/CPAAgentReport";
import CPAOfficeReport from "@/views/reports/CPAOfficeReport";
import CPAStateReport from "@/views/reports/CPAStateReport";
import CPAPubReport from "@/views/reports/CPAPubReport";
import ApiLinkGeneratorManagement from "@/views/admin/ApiLinkGeneratorManagement/ApiLinkGeneratorManagement";
import BlockedNumberManagement from "@/views/admin/BlockedNumberManagement/BlockedNumberManagement";
import UserTimeLogReport from "@/views/reports/UserTimeLogReport";
const routes = [
  {
    path: "/login",
    component: Login,
    beforeEnter(to, from, next) {
      if (location.href) {
        if (
          location.href.toLowerCase().includes("unsubscribe") ||
          location.href.toLowerCase().includes("resetpassword") ||
          location.href.toLowerCase().includes("bluerockscreen")
        ) {
          next(location.href);
        } else {
          next();
        }
      } else next();
    },
    name: "login",
    meta: {
      title: "Login",
    },
  },
  {
    path: "/unsubscribe",
    component: Unsubscribe,
    name: "Unsubscribe",
    meta: {
      title: "Unsubscribe",
    },
  },
  {
    path: "/ResetPassword",
    component: ResetPassword,
    name: "Reset Password",
    meta: {
      title: "Reset Password",
    },
  },
  {
    path: "/BlueRockScreen",
    component: BrPopup,
    name: "Br Popup",
    meta: {
      title: "BlueRockScreen",
    },
  },
  {
    path: "/",
    component: Home,
    beforeEnter(to, from, next) {
      document.title = getTitle();
      isAuthenticated() ? next() : next("/login");
      history.pushState(null, null, location.href);
      window.onpopstate = () => {
        history.go(1);
      };
    },

    name: "home",
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        component: Dashboard,
        name: "dashboard",
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "/customers",
        beforeEnter(to, from, next) {
          next();
          // isAuthorized() ? next() : next('/')
        },
        component: Customers,
        name: "customers",
        meta: {
          title: "Customers",
        },
      },

      {
        path: "/office-management",
        beforeEnter(to, from, next) {
          isAuthorized(1453) ? next() : next("/");
        },
        component: OfficeManagement,
        name: "office",
        meta: {
          title: "Offices",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(921085) ? next() : next("/");
        },
        path: "/ui-settings-management",
        component: UISettingsManagement,
        meta: {
          title: "UI Settings",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(9542) ? next() : next("/");
        },
        path: "/employee-management",
        component: EmployeeManagement,
        meta: {
          title: "Employees",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(1096) ? next() : next("/");
        },
        path: "/disposition-management",
        component: DispositionManagement,
        meta: {
          title: "Dispositions",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(5146) ? next() : next("/");
        },
        path: "/quick-email-management",
        component: QuickEmailManagement,
        meta: {
          title: "Quick Emails",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3432) ? next() : next("/");
        },
        path: "/quick-note-management",
        component: QuickNoteManagement,
        meta: {
          title: "Quick Notes",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(1580) ? next() : next("/");
        },
        path: "/quick-sms-management",
        component: QuickSmsManagement,
        meta: {
          title: "Quick SMS",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(6580) || isAuthorized(6581) ? next() : next("/");
        },
        path: "/roles-management",
        component: RolesManagement,
        meta: {
          title: "Roles",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(952) ? next() : next("/");
        },
        path: "/status-management",
        component: StatusManagement,
        meta: {
          title: "Statuses",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(64326) ? next() : next("/");
        },
        path: "/tag-management",
        component: TagManagement,
        meta: {
          title: "Customer Tags",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(97696) ? next() : next("/");
        },
        path: "/agent-phone-status-report",
        component: AgentPhoneStatusReport,
        meta: {
          title: "Agent Phone Status Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(8913) || isAuthorized(8914) ? next() : next("/");
        },
        path: "/csr-task-action-management",
        component: CSRTaskActionManagement,
        meta: {
          title: "Task Actions",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(8913) || isAuthorized(8914) ? next() : next("/");
        },
        path: "/csr-task-type-management",
        component: CSRTaskTypeManagement,
        meta: {
          title: "Task Types",
        },
      },
      {
        path: "/script-management",
        beforeEnter(to, from, next) {
          isAuthorized(1496) ? next() : next("/");
        },
        component: ScriptManagement,
        name: "script",
        meta: {
          title: "Scripts",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3841) ? next() : next("/");
        },
        path: "/vm-drop-management",
        component: VMDropManagement,
        meta: {
          title: "VM Templates",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(1662) ? next() : next("/");
        },
        path: "/pause-reason-management",
        component: PauseReasonManagement,
        meta: {
          title: "Pause Reasons",
        },
      },
      {
        beforeEnter(to, from, next) {
          next();
        },
        path: "/vm-recorder-management",
        component: VMRecorderManagement,
        meta: {
          title: "VM Recorder",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(677991) ? next() : next("/");
        },
        path: "/api-link-generator-management",
        component: ApiLinkGeneratorManagement,
        meta: {
          title: "Api Link Generator",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(2774) ? next() : next("/");
        },
        path: "/campaign-management",
        component: CampaignManagement,
        meta: {
          title: "Campaigns",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(1588) ? next() : next("/");
        },
        path: "/drip-email-management",
        component: DripEmailManagement,
        meta: {
          title: "Drip Email",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(8361) ? next() : next("/");
        },
        path: "/lead-distro-management",
        component: LeadDistributionManagement,
        meta: {
          title: "Lead Distribution",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(10022) ? next() : next("/");
        },
        path: "/csr-tasks",
        component: CsrTaskDistro,
        meta: {
          title: "",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(8113) ? next() : next("/");
        },
        path: "/call-logs-reports",
        component: CallLogsReport,
        meta: {
          title: "Call Logs Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(8263) ? next() : next("/");
        },
        path: "/csr-task-performance-report",
        component: CSRTaskPerformanceReport,
        meta: {
          title: "CSR Task Performance Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(7261) ? next() : next("/");
        },
        path: "/csr-realtime-tasks-report",
        component: CSRRealtimeTasksReport,
        meta: {
          title: "CSR Realtime Tasks Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3715) ? next() : next("/");
        },
        path: "/csr-task-report",
        component: CSRTaskReport,
        meta: {
          title: "CSR Task Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(5366) ? next() : next("/");
        },
        path: "/customer-status-reports",
        component: CustomerStatusReport,
        meta: {
          title: "Customer Status Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(61346) ? next() : next("/");
        },
        path: "/customer-tag-report",
        component: CustomerTagReport,
        meta: {
          title: "Customer Tag Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(5578) ? next() : next("/");
        },
        path: "/inbound-statistics",
        component: InboundStatisticsReport,
        meta: {
          title: "Inbound Statistics Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(9677) ? next() : next("/");
        },
        path: "/incoming-vm-report",
        component: IncomingVmReport,
        meta: {
          title: "Incoming Vm Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(8162) ? next() : next("/");
        },
        path: "/lead-assign-report",
        component: LeadAssignReport,
        meta: {
          title: "Lead Assign Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(3821) ? next() : next("/");
        },
        path: "/live-calls-reports",
        component: LiveCallsReport,
        meta: {
          title: "Live Calls Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(6180) ? next() : next("/");
        },
        path: "/missed-call-reports",
        component: MissedCallReport,
        meta: {
          title: "Missed Call Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(4721) ? next() : next("/");
        },
        path: "/pending-disposition-report",
        component: PendingDispositionReport,
        meta: {
          title: "Pending Disposition Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(6582) ? next() : next("/");
        },
        path: "/reminders-reports",
        component: RemindersReport,
        meta: {
          title: "Reminders Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(7567) ? next() : next("/");
        },
        path: "/sms-history-reports",
        component: SmsHistoryReport,
        meta: {
          title: "SMS History Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(81547) ? next() : next("/");
        },
        path: "/db-sales-compensation-report",
        component: DebtBlueSalesCompensationReport,
        meta: {
          title: "DB Sales Compensation Report",
        },
      },

      //  Phone Settings

      {
        beforeEnter(to, from, next) {
          isAuthorized(3908) ? next() : next("/");
        },
        path: "/inbound-call-settings-management",
        component: InboundCallSettingsManagement,
        meta: {
          title: "Inbound Call Settings",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3922) ? next() : next("/");
        },
        path: "/inbound-call-rules-management",
        component: InboundCallRulesManagement,
        meta: {
          title: "Inbound Call Rules",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3917) ? next() : next("/");
        },
        path: "/outbound-call-settings-management",
        component: OutboundCallSettingsManagement,
        meta: {
          title: "Outbound Call Settings",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54984) ? next() : next("/");
        },
        path: "/blocked-number-management",
        component: BlockedNumberManagement,
        meta: {
          title: "Blocked Numbers",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(240) ? next() : next("/");
        },
        path: "/did-management",
        component: DidManagement,
        meta: {
          title: "DIDs",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(273) ? next() : next("/");
        },
        path: "/did-purchase-management",
        component: DidPurchaseManagement,
        meta: {
          title: "DID Purchase",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(200010) ? next() : next("/");
        },
        path: "/csr-tickets",
        component: CsrTickets,
        meta: {
          title: "Tickets Dashboard",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3923) ? next() : next("/");
        },
        path: "/sms-brands-management",
        component: SMSBrandsManagement,
        meta: {
          title: "SMS Brands",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(55417) ? next() : next("/");
        },
        path: "/agent-phone-report",
        component: AgentPhoneReport,
        meta: {
          title: "Agent Phone Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(8234) ? next() : next("/");
        },
        path: "/csr-task-summary-report",
        component: CsrTaskSummaryReport,
        meta: {
          title: "Csr Task Summary Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(5377) ? next() : next("/");
        },
        path: "/customer-disposition-settings-report",
        component: CustomerDispositionSettingsReport,
        meta: {
          title: "Customer Disposition Settings Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3716) ? next() : next("/");
        },
        path: "/csr-task-distro-queue-report",
        component: CSRTaskDistroQueueReport,
        meta: {
          title: "CSR Task Distro Queue Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(48214) ? next() : next("/");
        },
        path: "/call-metrics-report",
        component: CallMetricsReport,
        meta: {
          title: "Call Metrics Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(55836) ? next() : next("/");
        },
        path: "/csr-task-contact-schedule-report",
        component: CSRTaskContactScheduleReport,
        meta: {
          title: "CSR Task Contact Trigger Schedule Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(55840) ? next() : next("/");
        },
        path: "/customer-cancelation-reasons-management",
        component: CustomerCancelationReasonsManagement,
        meta: {
          title: "Customer Cancelation Reasons",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(65837) ? next() : next("/");
        },
        path: "/cpa-agent-report",
        component: CPAAgentReport,
        meta: {
          title: "CPA Agent Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(65838) ? next() : next("/");
        },
        path: "/cpa-office-report",
        component: CPAOfficeReport,
        meta: {
          title: "CPA Office Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(65839) ? next() : next("/");
        },
        path: "/cpa-state-report",
        component: CPAStateReport,
        meta: {
          title: "CPA State Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(65840) ? next() : next("/");
        },
        path: "/cpa-pub-report",
        component: CPAPubReport,
        meta: {
          title: "CPA Pub Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(55850) ? next() : next("/");
        },
        path: "/user-time-log-report",
        component: UserTimeLogReport,
        meta: {
          title: "User Time Log Report",
        },
      },
    ],
  },
  { path: "/:catchAll(.*)", component: Login },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function isAuthorized(permissionId) {
  let rtn = false;

  let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

  let arrPermission = userInfo.moduleFunctions.split(",").map(function (item) {
    return parseInt(item, 10);
  });

  if (arrPermission.includes(permissionId)) {
    rtn = true;
  }
  return rtn;
}

function isAuthenticated() {
  let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
  if (!userInfo) return false;
  let expireUTC = sessionStorage.getItem("expireUTC");
  if (expireUTC) {
    let expireLocalDate = new Date(expireUTC);
    let dtNow = new Date();
    if (dtNow > expireLocalDate) return false;
  }

  return true;
}

function getTitle() {
  let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
  if (!userInfo) return "CRM";
  return userInfo.companyName;
}

export default router;
