<template>
  <div class="row px-2">
    <div
      style="
        width: 100%;
        box-shadow: 0px 0px 19px -3px rgba(187, 239, 253, 0.5);
        background-color: rgba(104, 193, 227, 0.1);
        margin: 10px;
        border-radius: 10px;
      "
    >
      <div class="col-md-12">
        <label
          v-if="!widgetData.newSnf && !widgetData.newSnfBottomBoxes"
          style="font-size: 23px"
          class="generalLabel"
        >
          {{
            widgetData.valueFormat == "money"
              ? formatMoney(current)
              : widgetData.valueFormat == "percantage"
              ? parseFloat(current).toFixed(2) + "%"
              : current
          }}
        </label>
        <div
          v-if="widgetData.newSnf"
          style="display: flex; flex-direction: column"
        >
          <div>
            <label style="font-size: 19px" class="generalLabel">
              {{
                "SNF: " +
                widgetData.salesSnf +
                " (" +
                widgetData.debtAmountSnf +
                ")"
              }}
            </label>
          </div>
          <div>
            <label style="font-size: 19px" class="generalLabel">
              {{
                "FLG: " +
                widgetData.salesFlg +
                " (" +
                widgetData.debtAmountFlg +
                ")"
              }}
            </label>
          </div>
        </div>

        <div v-if="widgetData.newSnfBottomBoxes">
          <div
            v-if="!widgetData.amount"
            style="display: flex; flex-direction: column"
          >
            <label style="font-size: 19px" class="generalLabel">{{
              "SNF: " + widgetData.salesSnf
            }}</label>
            <label style="font-size: 19px" class="generalLabel">{{
              "FLG: " + widgetData.salesFlg
            }}</label>
          </div>

          <div
            v-if="widgetData.amount"
            style="display: flex; flex-direction: column"
          >
            <label style="font-size: 19px" class="generalLabel"
              >{{ "SNF: " + formatMoney(widgetData.salesSnf) }}
            </label>
            <label style="font-size: 19px" class="generalLabel"
              >{{ "FLG: " + formatMoney(widgetData.salesFlg) }}
            </label>
          </div>
        </div>
      </div>

      <div
        v-if="!isNaN(changeRate)"
        style="display: flex; align-items: center"
        class="col-md-12"
      >
        <label
          :style="
            changeRate == 0
              ? 'color:#f7b84b;'
              : changeRate > 0
              ? 'color:#24d15f;'
              : 'color:#ea2027;'
          "
          class="generalLabel"
        >
          <i v-if="changeRate == 0" class="ri-checkbox-blank-circle-fill"></i>
          <i v-if="changeRate > 0" class="ri-arrow-up-line"></i>
          <i v-if="changeRate < 0" class="ri-arrow-down-line"></i>
          {{ changeRate.toFixed(2) }}%
        </label>

        <label class="ml-2"> {{ previous }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";

export default {
  mixins: [formatMixin],
  props: {
    widgetData: Object,
  },
  mounted() {},
  computed: {
    changeRate() {
      return parseFloat(this.widgetData.rate) * -1;
    },
    previous() {
      let str =
        this.widgetData.type == "daily"
          ? "Yesterday"
          : this.widgetData.type == "weekly"
          ? "Last Week"
          : this.widgetData.type == "monthly"
          ? "Last Month"
          : this.widgetData.type == "yearly"
          ? "Last Year"
          : "";

      return ` From ${str} (${
        this.widgetData.amount == true
          ? this.formatMoney(this.widgetData.prev)
          : this.widgetData.prev
      })`;
    },

    current() {
      return this.widgetData.current;
    },
  },
};
</script>

<style></style>
