<template>
  <div class="row admin-page officePage">
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 350px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12">
      <div class="panelContent">
        <div class="col-md-12 noMargin">
          <label class="form-label">System</label>
        </div>
        <div class="col-md-12">
          <select class="form-control" v-model="selectedRow.SystemId" @change="generateLink()">
            <option v-for="item in systemArr" :key="item.Id" :value="item.Id">
              {{ item.Name }}
            </option>
          </select>
        </div>
        <div class="col-md-12 noMargin">
          <label class="form-label">Office</label>
        </div>
        <div class="col-md-12">
          <select class="form-control" v-model="selectedRow.Office" @change="generateLink()">
            <option :value="null">Select Office</option>
            <option v-for="item in officeList" :key="item.Id" :value="item.Id">
              {{ item.Name }}
            </option>
          </select>
        </div>
        <div class="mt-2">
          <div class="col-md-12 noMargin">
            <label class="form-label">Campaign</label>
          </div>
          <div class="col-md-12 noMargin">
            <select class="form-control" v-model="selectedRow.Campaign"  @change="generateLink()">
              <option :value="null">Select Campaign</option>
              <option
                v-for="item in campaignList"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>
        </div>

        <div class="mt-2">
          <div class="col-md-12 noMargin">
            <label class="form-label">Key</label>
          </div>
          <div class="col-md-12">
            <input
              v-model="selectedRow.Key"
              class="form-control"
              type="text"
              placeholder="Key"
              @change="generateLink()"
            />
          </div>
        </div>

        <div class="mt-2">
          <div class="col-md-12 noMargin">
            <label class="form-label">Link</label>
          </div>
          <div class="col-md-12" style="display: flex">
            <textarea
              class="form-control readonlyInput"
              type="text"
              placeholder="Link"
              rows="4"
              v-model="link"
              :disabled="true"
              style="resize: none;"
            />
            <button @click="copyText" class="btn btn-primary ml-1">
              {{ buttonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import types from "./types";
import deepCopy from "@/helpers/deepCopy";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import _orderby from "lodash";

const init = {
  Office: null,
  Campaign: null,
  Key: "uig_avail",
  SystemId:1,
};
const systemModel= [{ Name: "Retreaver", Id: 1 },{ Name: "Ringba", Id: 2 },{ Name: "Track Drive", Id: 3 }];
export default {
  name: "ApiLinkGeneratorManagement",
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      selectedRow: deepCopy(init),
      systemArr: deepCopy(systemModel),
      userRoles: null,
      saving: false,
      buttonText: "Copy",
      link: "",
    };
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_OFFICE_NAMES),
      this.$store.dispatch(globalTypes.GET_CAMPAIGNLIST, {
        Roles: this.userRoles,
      }),
    ]);

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null) {
      if (userInfo.moduleFunctions != null) this.userRoles = userInfo.roles;
    }

    this.generateLink();
  },

  computed: mapState({
    officeList: (state) => state.globals.offices,
    campaignList: (state) => state.globals.campaignList,
  }),

  methods: {
    copyText() {

      let text = this.link;
      console.log(text);
      navigator.clipboard.writeText(text).then(() => {
        this.buttonText = "Copied";
        setTimeout(() => {
          this.buttonText = "Copy";
        }, 2000);
      });
    },
    getCampaignDid(campaignId){
      let rtn = 'NoDid';
      let myCampaign =  this.campaignList.filter(x=>x.Id == this.selectedRow.Campaign);
      if(myCampaign.length > 0) {
        if (myCampaign[0].Did) {
          rtn = myCampaign[0].Did;
        }
      }
      return rtn;
    },
    generateLink(){
      let officeId = 0;
      let CampaignDid="NoDid";
      if(this.selectedRow.Office)
        officeId = this.selectedRow.Office;
      if(this.selectedRow.Campaign){
        CampaignDid = this.getCampaignDid(this.selectedRow.Campaign);
      }
      let lnk = "https://api.zcrm.zenithsaas.com/api/AvailableAgent/Get/"+CampaignDid+"?officeId="+officeId.toString()+"&key="
              +this.selectedRow.Key+"&system="+this.selectedRow.SystemId.toString()+"&publisher_id=[publisher_id]&state=[caller_state]&caller_number=[caller_number]";
      if(this.selectedRow.SystemId == 2)
      {
        lnk = lnk+"&pubcallid=[call_id]";
      }
      this.link=lnk;
    }
  },
};
</script>

<style></style>
