import types from "./types";
import api from "@/services/admin/api";
import customerApi from "@/services/client/customerApi";
import to from "@/helpers/to";
export default {
  state: {
    agentPerformanceList: [],
    callLogList: [],
    customerStatusList: [],
    missedCallList: [],
    smsHistoryList: [],
    remindersList: [],
    csrRealtimeTasksList: [],
    csrTasksList: [],
    csrTaskPerformanceList: [],
    inboundStatisticsList: [],
    agentList: [],
    inboundCallList: [],
    leadAssignReport: null,
    listenWhisperList: [],
    incomingVmList: [],
    campaignPerformanceList: [],
    clearDraftList: [],
    firstClearDraftList: [],
    pendingDispositionList: [],
    liveCallRoleList: [],
    customerTagList: [],
    agentDropRateList: [],
    agentStatustList: null,
    agentPerformanceNewList: [],
    agentPhoneList: [],
    csTaskSummaryList: [],
    userActivityList: [],
    dispositionSettingsList: [],
    distributionQueueList: [],
    callMetricsList: [],
    respondersList: [],
    csrTaskContactScheduleList: [],
    cpaAgentList: [],
    cpaOfficeList: [],
    cpaStateList: [],
    cpaPubList: [],
    userLogTimeList: [],
  },
  actions: {
    GET_USER_TIME_LOG_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getUserTimeLogReport(payload));
      if (result) {
        commit("SET_USER_TIME_LOG_REPORT", result.Data);
      }
    },
    GET_CPA_AGENT_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCPAAgentReport(payload));
      if (result) {
        commit("SET_CPA_AGENT_REPORT", result.Data);
      }
    },

    GET_CPA_OFFICE_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCPAOfficeReport(payload));
      if (result) {
        commit("SET_CPA_OFFICE_REPORT", result.Data);
      }
    },

    GET_CPA_STATE_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCPAStateReport(payload));
      if (result) {
        commit("SET_CPA_STATE_REPORT", result.Data);
      }
    },

    GET_CPA_PUB_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCPAPubReport(payload));
      if (result) {
        commit("SET_CPA_PUB_REPORT", result.Data);
      }
    },

    GET_CSR_TASK_CONTACT_SCHEDULE_REPORT: async (
      { commit, state },
      payload
    ) => {
      let err, result;

      [err, result] = await to(api.getCsrTaskContactScheduleReport(payload));
      if (result) {
        commit("SET_CSR_TASK_CONTACT_SCHEDULE_REPORT", result.Data);
      }
    },

    GET_CALL_METRICS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCallMetricsReport(payload));
      if (result) {
        commit("SET_CALL_METRICS_REPORT", result.Data);
      }
    },

    GET_CSR_TASK_DISTRIBUTION_QUEUE_REPORT: async (
      { commit, state },
      payload
    ) => {
      let err, result;

      [err, result] = await to(api.getCsrTaskDistributionQueueReport(payload));
      if (result) {
        commit("SET_CSR_TASK_DISTRIBUTION_QUEUE_REPORT", result.Data);
      }
    },

    GET_DISPOSITION_SETTINGS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(
        api.getCustomerDispositionSettingsReport(payload)
      );
      if (result) {
        commit("SET_DISPOSITION_SETTINGS_REPORT", result.Data);
      }
    },

    GET_USER_ACTIVITY_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getUserActivityReport(payload));
      if (result) {
        commit("SET_USER_ACTIVITY_REPORT", result.Data);
      }
    },

    GET_CSR_TASK_SUMMARY_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCsrTaskSummaryReport(payload));
      if (result) {
        commit("SET_CSR_TASK_SUMMARY_REPORT", result.Data);
      }
    },

    GET_AGENT_PHONE_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getAgentPerformanceNewReport(payload));
      if (result) {
        commit("SET_AGENT_PHONE_REPORT", result.Data);
      }
    },

    GET_AGENT_STATUS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getAgentStatusReport(payload));
      if (result) {
        commit("SET_AGENT_STATUS_REPORT", result.Data);
      }
    },

    GET_CALL_LOG_DETAIL: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getCallLogDetail(payload));
      return [err, result];
    },

    GET_DB_SALES_COMPENSATION_REPORT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getDbSalesCompensationReport(payload));
      return [err, result];
    },

    GET_CUSTOMER_TAG_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCustomerTagReport(payload));
      if (result) {
        commit("SET_CUSTOMER_TAG_REPORT", result.Data);
      }
    },

    GET_AGENT_PERFORMANCE_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getAgentPerformanceReport(payload));
      if (result) {
        commit("SET_AGENT_PERFORMANCE_REPORT", result.Data);
      }
    },
    GET_CALL_LOG_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCallLogReport(payload));
      if (result) {
        let updatedData = result.Data.map((item) => {
          let LogText =
            item.MissedCallReason === ""
              ? item.CallType
              : `${item.CallType} (${item.MissedCallReason})`;

          if (item.TriggerLogId !== null) {
            LogText += " (Auto Call Trigger)";
          }

          return { ...item, LogText };
        });

        commit("SET_CALL_LOG_REPORT", updatedData);
      }
    },
    GET_CUSTOMER_STATUS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCustomerStatusReport(payload));
      if (result) {
        commit("SET_CUSTOMER_STATUS_REPORT", result.Data);
      }
    },
    GET_MISSED_CALL_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getMissedCallReport(payload));
      if (result) {
        commit("SET_MISSED_CALL_REPORT", result.Data);
      }
    },
    GET_SMS_HISTORY_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getSmsHistoryReport(payload));
      if (result) {
        commit("SET_SMS_HISTORY_REPORT", result.Data);
      }
    },
    GET_REMINDERS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getRemindersReport(payload));
      if (result) {
        commit("SET_REMINDERS_REPORT", result.Data);
      }
    },
    GET_CSR_REALTIME_TASK_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCsrRealtimeTasksReport(payload));
      if (result) {
        commit("SET_CSR_REALTIME_TASK_REPORT", result.Data);
      }
    },
    GET_CSR_TASK_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCsrTaskReport(payload));
      if (result) {
        commit("SET_CSR_TASK_REPORT", result.Data);
      }
    },

    GET_LEAD_ASSIGN_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getLeadAssignReport(payload));
      if (result) {
        commit("SET_LEAD_ASSIGN_REPORT", result.Data);
      }
    },
    GET_CSR_TASK_PERFORMANCE_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCsrTaskPerformanceReport(payload));
      if (result) {
        commit("SET_CSR_TASK_PERFORMANCE_REPORT", result.Data);
      }
    },
    GET_INBOUND_STATISTICS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getInboundStatisticsReport(payload));

      if (result) {
        commit("SET_INBOUND_STATISTICS_REPORT", result.Data);
      }
    },

    GET_LIVE_CALL_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getLiveCallReport(payload));
      if (result) {
        commit(types.SET_LIVE_CALL_REPORT, result.Data);
      }
    },
    GET_INCOMING_VM_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getIncomingVmReport(payload));

      if (result) {
        commit("SET_INCOMING_VM_REPORT", result.Data);
      }
    },

    GET_PENDING_DISPOSITION_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getPendingDispositionReport(payload));
      if (result) {
        commit("SET_PENDING_DISPOSITION_REPORT", result.Data);
      }
    },

    RESET_REPORTS: async ({ commit, state }, payload) => {
      commit("RESET_REPORTS_DATA");
    },
  },
  mutations: {
    SET_USER_TIME_LOG_REPORT: (state, payload) => {
      state.userLogTimeList = payload;
    },
    SET_CSR_TASK_CONTACT_SCHEDULE_REPORT: (state, payload) => {
      state.csrTaskContactScheduleList = payload;
    },

    SET_CALL_METRICS_REPORT: (state, payload) => {
      state.callMetricsList = payload;
    },
    SET_CSR_TASK_DISTRIBUTION_QUEUE_REPORT: (state, payload) => {
      state.distributionQueueList = payload;
    },
    SET_DISPOSITION_SETTINGS_REPORT: (state, payload) => {
      state.dispositionSettingsList = payload;
    },
    SET_USER_ACTIVITY_REPORT: (state, payload) => {
      state.userActivityList = payload;
    },
    SET_CSR_TASK_SUMMARY_REPORT: (state, payload) => {
      state.csTaskSummaryList = payload;
    },
    SET_AGENT_PHONE_REPORT: (state, payload) => {
      state.agentPhoneList = payload;
    },
    SET_AGENT_STATUS_REPORT: (state, payload) => {
      state.agentStatustList = payload;
    },

    SET_LIVE_CALL_ROLE_LIST: (state, payload) => {
      state.liveCallRoleList = payload;
    },

    SET_PENDING_DISPOSITION_REPORT: (state, payload) => {
      state.pendingDispositionList = payload;
    },
    SET_AGENT_PERFORMANCE_REPORT: (state, payload) => {
      state.agentPerformanceList = payload;
    },
    SET_LIVE_CALL_REPORT: (state, payload) => {
      if (payload && payload.InboundCalls && payload.InboundCalls.length > 0) {
        state.inboundCallList = payload.InboundCalls;
      } else {
        state.inboundCallList = [];
      }

      if (payload && payload.Agents && payload.Agents.length > 0) {
        state.agentList = payload.Agents;
      } else {
        state.agentList = [];
      }
    },
    SET_INBOUND_STATISTICS_REPORT: (state, payload) => {
      state.inboundStatisticsList = payload;
    },
    SET_CSR_TASK_PERFORMANCE_REPORT: (state, payload) => {
      state.csrTaskPerformanceList = payload;
    },
    SET_CALL_LOG_REPORT: (state, payload) => {
      state.callLogList = payload;
    },
    SET_CUSTOMER_STATUS_REPORT: (state, payload) => {
      state.customerStatusList = payload;
    },
    SET_MISSED_CALL_REPORT: (state, payload) => {
      state.missedCallList = payload;
    },
    SET_SMS_HISTORY_REPORT: (state, payload) => {
      state.smsHistoryList = payload;
    },
    SET_REMINDERS_REPORT: (state, payload) => {
      state.remindersList = payload;
    },
    SET_CSR_REALTIME_TASK_REPORT: (state, payload) => {
      state.csrRealtimeTasksList = payload;
    },
    SET_CSR_TASK_REPORT: (state, payload) => {
      state.csrTasksList = payload;
    },

    SET_LEAD_ASSIGN_REPORT: (state, payload) => {
      state.leadAssignReport = payload;
    },
    SET_INCOMING_VM_REPORT: (state, payload) => {
      state.incomingVmList = payload;
    },

    SET_CUSTOMER_TAG_REPORT: (state, payload) => {
      state.customerTagList = payload;
    },
    SET_CPA_AGENT_REPORT: (state, payload) => {
      state.cpaAgentList = payload;
    },
    SET_CPA_OFFICE_REPORT: (state, payload) => {
      state.cpaOfficeList = payload;
    },
    SET_CPA_STATE_REPORT: (state, payload) => {
      state.cpaStateList = payload;
    },
    SET_CPA_PUB_REPORT: (state, payload) => {
      state.cpaPubList = payload;
    },
    RESET_REPORTS_DATA: (state) => {
      state.agentPerformanceList = [];
      state.callLogList = [];
      state.customerStatusList = [];
      state.missedCallList = [];
      state.smsHistoryList = [];
      state.remindersList = [];
      state.csrRealtimeTasksList = [];
      state.csrTasksList = [];
      state.csrTaskPerformanceList = [];
      state.inboundStatisticsList = [];
      state.agentList = [];
      state.inboundCallList = [];
      state.leadAssignReport = null;
      state.incomingVmList = [];
      state.clearDraftList = [];
      state.firstClearDraftList = [];
      state.pendingDispositionList = [];
      state.customerTagList = [];
      state.agentDropRateList = [];
      state.agentStatustList = null;
      state.agentPerformanceNewList = [];
      state.agentPhoneList = [];
      state.csTaskSummaryList = [];
      state.userActivityList = [];
      state.dispositionSettingsList = [];
      state.distributionQueueList = [];
      state.callMetricsList = [];
      state.respondersList = [];
      state.csrTaskContactScheduleList = [];
      state.cpaAgentList = [];
      state.cpaOfficeList = [];
      state.cpaStateList = [];
      state.cpaPubList = [];
      state.userLogTimeList = [];
    },
  },
};
