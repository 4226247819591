<template>
  <div class="row admin-page">
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 panelWrapper">
      <button
        type="button"
        class="col-md-12 btn btn-primary waves-effect waves-light"
        @click="handleAddNew"
      >
        Add New Campaign
      </button>
      <div class="col-md-12 mt-2 noPadding">
        <input
          autocomplete="off"
          id="txtShowInactive"
          type="checkbox"
          class="checkbox-input generalCheckBox"
          style="
            border-radius: 4px !important;
            border: solid 1px #d1d1d1 !important;
            vertical-align: text-top;
          "
          v-model="showInactiveItems"
        />

        <label class="generalLabel" style="font-weight: normal"
          >Show Inactive Campaigns</label
        >
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          v-model="typeFilter"
          placeholder="Filter Type"
        />
      </div>
      <div class="list col-md-12">
        <div
          class="role item row"
          v-for="(campaign, index) in filteredCampaign"
          :key="campaign.Id"
          :class="{ active: activeItem === index }"
          :style="[
            index % 2 === 0
              ? { 'background-color': '#F1EFEF' }
              : { 'background-color': '#FFFFFF' },
          ]"
          :disabled="saving"
          @click="() => handleCampaignSelection(campaign, index)"
        >
          <span :class="{ adminActiveMenuItem: activeItem === index }">
            <i class="ri-price-tag-3-fill"></i>
            <span style="margin-left: 8px">{{ campaign.Name }}</span>
          </span>
        </div>
      </div>
      <div
        v-if="isMobileDevice()"
        style="
          border-top: 1px solid rgb(0, 0, 0, 0);
          margin: 20px 0 0 9px;
          padding-bottom: 20px;
        "
        class="col-md-12 scrollToSelectedItem"
      ></div>
    </div>
    <div
      class="col-xl-9 col-lg-8 col-md-12 col-sm-12 noMargin"
      v-if="selectedRow != null"
    >
      <form
        v-on:keydown.enter.prevent="() => {}"
        class="form-horizontal col-md-12 float-right panelWrapper"
        role="form"
      >
        <div class="panelContent">
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-form-label"
              >Campaign Name *</label
            >
            <div class="col-xl-8 col-lg-9 col-md-8 col-sm-12">
              <input
                autocomplete="off"
                type="text"
                id="CMcampaignName"
                v-model="selectedRow.Name"
                class="form-control"
                :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Name.$touch"
                placeholder="Enter campaign name"
              />
            </div>
          </div>

          <div
            class="form-group row"
            style="display: flex; align-items: center"
          >
            <label class="col-xl-2 col-lg-3 col-md-4 col-sm-12"
              >Lead Distro Priority</label
            >
            <div class="col-xl-8 col-lg-9 col-md-8 col-sm-12">
              <input
                autocomplete="off"
                type="text"
                id="CMcampaignName"
                v-model="selectedRow.Priority"
                class="form-control"
                style="width: 100px"
                v-myMask="{
                  alias: 'numeric',
                  min: 1,
                  rightAlign: false,
                  rightAlign: false,
                }"
              />
            </div>
          </div>

          <div class="form-group row mt-1">
            <label
              class="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-form-label"
              for="CampaignColor"
              >Campaign Color</label
            >
            <div class="col-xl-2 col-lg-9 col-md-8 col-sm-12">
              <label class="form-label" for="ColorPicker">Status Color *</label>
              <div
                id="taskColor-cm"
                class="ColorPickerBox rounded"
                @click="OpenNewColorPicker()"
                :style="'background-color: ' + selectedRow.Color"
              ></div>

              <NewColorPicker
                v-if="isNewColorPickerOpen"
                @updateColor="updateColor"
                :color="Colors"
                id="CMcolorPicker"
              />
            </div>
          </div>
          <div class="form-group row mt-1">
            <label
              class="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-form-label"
              for="CampaignDid"
              >Campaign Did</label
            >
            <div class="col-xl-8 col-lg-9 col-md-8 col-sm-12 noPadding">
              <div v-if="selectedRow.DidId == null || selectedRow.DidId == 0">
                <select
                  id="CMcampaignDid"
                  v-model="selectedRow.DidId"
                  class="form-control float-left ml-2 mr-2"
                  style="width: 200px !important"
                >
                  <option :value="convertNull('null')">Unassigned</option>
                  <option
                    v-for="item in companyDids"
                    :key="item.Id"
                    :value="item.Id"
                  >
                    {{ item.Did }}
                  </option>
                </select>
              </div>
              <div class="ml-2" v-if="selectedRow.DidId > 0">
                <span style="margin-right: 10px">{{
                  getDidById(selectedRow.DidId)
                }}</span>
                <button
                  type="button"
                  class="btn btn-danger btn-md waves-effect waves-light"
                  @click="handleUnassign"
                >
                  Unassign
                </button>
              </div>
            </div>
          </div>
          <div class="form-group row mt-2">
            <label class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-form-label"
              >Incoming Call Timeout</label
            >
            <div class="col-xl-4 col-lg-5 col-md-8 col-sm-6 noPadding">
              <div style="width: 20px; float: left; margin: 0 12px">
                <input
                  autocomplete="off"
                  type="checkbox"
                  class="form-control"
                  :checked="selectedRow.DefaultInboundCallTimeout == null"
                  @change="setCallTimeout($event)"
                />
              </div>

              <label class="col-form-label">Default</label>
              <label class="col-form-label ml-2">or</label>
              <input
                autocomplete="off"
                type="number"
                min="0"
                max="1000"
                v-model="selectedRow.DefaultInboundCallTimeout"
                @change="
                  () => {
                    if (selectedRow.DefaultInboundCallTimeout < 5) {
                      selectedRow.DefaultInboundCallTimeout = 5;
                    } else if (selectedRow.DefaultInboundCallTimeout > 3600) {
                      selectedRow.DefaultInboundCallTimeout = 3600;
                    }
                  }
                "
                pattern="\d+"
                id="campaignNameInput"
                style="width: 80px !important"
                class="generalTextBox ml-1"
              />
              <label class="col-form-label ml-1">seconds</label>
            </div>
          </div>
          <div
            class="form-group row"
            v-if="
              selectedRow.DefaultInboundCallTimeout &&
              selectedRow.DefaultInboundCallTimeout < 30
            "
          >
            <label class="col-md-12 col-form-label text-danger"
              >Short duration calls may cause additional carrier charges and
              account suspension.</label
            >
          </div>
          <div class="form-group row mt-2">
            <label class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-form-label"
              >Campaign Token</label
            >
            <label class="col-xl-4 col-lg-5 col-md-5 col-sm-4 col-form-label">{{
              selectedRow.Token
            }}</label>
            <button
              type="button"
              id="campaignToken"
              class="col-xl-3 col-lg-3 col-md-3 col-sm-12 btn btn-primary btn-md waves-effect waves-light"
              @click="genereateToken()"
            >
              Generate new Token
            </button>
          </div>
          <div class="form-group row mt-2">
            <label class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-form-label"
              >Auto DTMF Value</label
            >
            <div class="col-xl-8 col-lg-9 col-md-8 col-sm-12">
              <input
                autocomplete="off"
                type="number"
                id="autoDtmfValue"
                v-model="selectedRow.AutoDtmfValue"
                style="width: 80px !important"
                class="form-control"
                @keypress="keyChange($event)"
                v-on:click.prevent.stop="() => {}"
                placeholder=""
              />
            </div>
          </div>

          <div class="form-group row mt-2">
            <label class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-form-label"
              >Buffer Time</label
            >
            <div class="col-xl-4 col-lg-5 col-md-8 col-sm-6">
              <div style="display: flex">
                <input
                  type="number"
                  style="width: 80px !important"
                  class="form-control"
                  v-model="selectedRow.BufferTime"
                />
                <label class="col-form-label ml-1">seconds</label>
              </div>
            </div>
          </div>

          <div class="form-group row mt-2">
            <label class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-form-label"
              >Cost Per Call</label
            >
            <div class="col-xl-8 col-lg-9 col-md-8 col-sm-12">
              <input
                type="text"
                v-model="selectedRow.CostPerCall"
                 class="form-control"
                    style="width: 110px !important"
                v-myMask="{
                  alias: 'currency',
                  prefix: '$',
                  rightAlign: false,
                }"
              />
            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 noPadding">
              <div style="width: 20px; float: left; margin: 0 12px">
                <input
                  autocomplete="off"
                  type="checkbox"
                  class="form-control"
                  v-model="selectedRow.IsActive"
                />
              </div>

              <label class="col-form-label">Active</label>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 noPadding">
              <div style="width: 20px; float: left; margin: 0 12px">
                <input
                  autocomplete="off"
                  type="checkbox"
                  class="form-control"
                  v-model="selectedRow.IsDoNotDistLead"
                />
              </div>
              <div class="roleLabel">
                <label class="col-form-label">Mailer Campaign</label>
              </div>
            </div>
            <div
              v-if="
                selectedRow &&
                selectedRow.Id > 0 &&
                selectedRow.IsDoNotDistLead &&
                checkAuth(921090)
              "
              class="col-xl-4 col-lg-5 col-md-8 col-sm-12 noPadding"
            >
              <button
                type="button"
                class="btn btn-primary btn-md waves-effect waves-light"
                @click="openDataUploadPopup"
              >
                Data Upload
              </button>
            </div>
            <!-- <div class="col-xl-4 col-lg-3 col-md-4 col-sm-6 noPadding">
              <div style="width: 20px; float: left; margin: 0 12px">
                <input
                  autocomplete="off"
                  type="checkbox"
                  class="form-control"
                  v-model="selectedRow.SpanishCampaign"
                />
              </div>
              <div class="roleLabel">
                <label class="col-form-label">Spanish</label>
              </div>
            </div> -->
          </div>

          <div v-if="checkAuth(81556)">
            <div class="mt-3">
              <label class="genralLabel">Marketing Information:</label>
            </div>
            <div style="display: flex">
              <div class="mr-2">
                <label>Source: </label>
                <input
                  v-model="selectedRow.MarketingSource"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  placeholder="Source"
                />
              </div>
              <div class="mr-2">
                <label>Leads Purchased:</label>
                <input
                  v-model="selectedRow.MarketingLeadsPurchased"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  placeholder="Leads Purchased"
                  v-myMask="{
                    alias: 'decimal',
                    rightAlign: false,
                  }"
                />
              </div>
              <div>
                <label>Cost: </label>
                <input
                  v-model="selectedRow.MarketingCost"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  placeholder="Marketing Cost"
                  v-myMask="{
                    alias: 'currency',
                    prefix: '$',
                    rightAlign: false,
                    min: 0,
                  }"
                />
              </div>
            </div>
          </div>

          <div
            class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 legend mt-4 form-group"
            style="border: 1px solid #b4b5b7"
            v-if="selectedRow.DidId != null"
          >
            <div class="col-sm-12" style="margin-bottom: 40px">
              <div>
                <div style="display: flex; align-items: baseline">
                  <label>Voicemail User</label>
                  <select
                    style="width: 200px !important"
                    class="form-control ml-2"
                    v-model="selectedRow.PhoneVmUserId"
                  >
                    <option :value="convertNull('null')">
                      Select Voicemail User
                    </option>
                    <option
                      v-for="item in filteredAgents"
                      :key="item.Id"
                      :value="item.Id"
                    >
                      {{ item.Name }}
                    </option>
                  </select>
                </div>

                <div style="display: flex; align-items: flex-end" class="pt-2">
                  <label>Create Missed Call Lead</label>
                  <input
                    v-model="selectedRow.PhoneCreateMissedCallLead"
                    autocomplete="off"
                    type="checkbox"
                    class="form-control"
                    style="width: 20px; float: left; margin: 0 12px"
                  />
                </div>

                <div class="pt-2" style="display: flex; align-items: flex-end">
                  <label>Transfer Did</label>

                  <input
                    @change="changeTransferDid()"
                    v-model="selectedRow.PhoneTransferDidEnabled"
                    autocomplete="off"
                    type="checkbox"
                    class="form-control"
                    style="width: 20px; float: left; margin: 0 12px"
                  />
                </div>
              </div>
              <div v-if="selectedRow.PhoneTransferDidEnabled == true">
                <div style="display: flex" class="mt-3">
                  <div
                    style="display: flex; align-items: baseline"
                    class="mr-3"
                  >
                    <label style="margin-right: 80px">Did</label>
                    <input
                      v-model="selectedRow.PhoneTransferDid"
                      autocomplete="off"
                      type="text"
                      class="form-control ml-2"
                      placeholder="Did"
                      style="width: 200px"
                      v-myMask="{ mask: ['(999) 999-9999'] }"
                    />
                  </div>

                  <div style="display: flex; align-items: baseline">
                    <label>Timezone</label>
                    <select
                      v-model="selectedRow.PhoneTransferDidTimezoneId"
                      style="width: 200px !important"
                      class="form-control ml-2"
                    >
                      <option :value="convertNull('null')">
                        Select Timezone
                      </option>
                      <option
                        v-for="item in timezones"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div style="display: flex" class="mt-2">
                  <div
                    style="display: flex; align-items: baseline"
                    class="mr-3"
                  >
                    <label style="margin-right: 44px">Start Time</label>

                    <DatePicker
                      mode="time"
                      :locale="'en-US'"
                      v-model="selectedRow.PhoneTransferDidStartTime"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          placeholder="Start Time"
                          autocomplete="off"
                          class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </DatePicker>
                  </div>
                  <div style="display: flex; align-items: baseline">
                    <label style="margin-right: 14px">End Time</label>

                    <DatePicker
                      mode="time"
                      :locale="'en-US'"
                      v-model="selectedRow.PhoneTransderDidEndTime"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          placeholder="End Time"
                          autocomplete="off"
                          class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row mt-3">
            <label class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-form-label"
              >Tier Timeout</label
            >
            <div class="col-xl-4 col-lg-5 col-md-8 col-sm-6 noPadding">
              <input
                type="number"
                style="width: 80px !important"
                class="generalTextBox ml-1"
                v-model="selectedRow.TierTimeout"
              />
              <label class="col-form-label ml-1">seconds</label>
            </div>
          </div>

          <div class="form-group row mt-3">
            <label class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-form-label"
              >Roles Distribution:</label
            >
            <!-- <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 noPadding">
              <div style="width: 20px; float: left; margin: 0 12px">
                <input
                  autocomplete="off"
                  type="checkbox"
                  :id="selectedRow.Id"
                  v-model="selectedRow.AllRolesSelected"
                  @change="allRolesChange()"
                  class="form-control"
                />
              </div>
              <label class="col-form-label">All Roles</label>
            </div> -->
          </div>

          <div class="form-group row mt-2">
            <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12">
              <perfect-scrollbar
                class="datagrid-table creditorTable"
                style="min-height: 350px; max-height: 350px"
              >
                <div v-if="isLoading" class="loading" style="min-height: 350px">
                  <div class="text-center" style="margin-top: 150px">
                    <i class="spinner-grow text-info m-2"></i>
                  </div>
                </div>
                <section v-if="!isLoading">
                  <o-table
                    :data="isEmpty ? [] : formattedData"
                    :default-sort-direction="defaultSortDirection"
                    :sort-icon="sortIcon"
                    :sort-icon-size="sortIconSize"
                    :striped="isStriped"
                    :hoverable="isHoverable"
                    :loading="isLoading"
                  >
                    <template>
                      <o-table-column
                        v-slot="props"
                        field="Name"
                        label="Role"
                        width="30%"
                        sortable
                        searchable
                        >{{ props.row.Name }}</o-table-column
                      >
                      <o-table-column
                        v-slot="props"
                        field="OfficeId"
                        label="Office"
                        width="40%"
                        sortable
                        >{{ getOfficeName(props.row.OfficeId) }}</o-table-column
                      >

                      <o-table-column
                        field="IsSelected"
                        label="Selected"
                        width="20%"
                        sortable
                        v-slot="props"
                      >
                        <input
                          type="checkbox"
                          :id="props.row.Id"
                          style="width: 20px; padding: 0 !important"
                          class="form-control"
                          v-model="props.row.IsSelected"
                          @change="isSelectedChange"
                        />
                      </o-table-column>

                      <o-table-column
                        field="TierNumber"
                        label="Tier Number"
                        width="50%"
                        sortable
                        v-slot="props"
                      >
                        <input
                          v-if="props.row.IsSelected"
                          type="number"
                          style="width: 60px !important; margin-right: 50px"
                          class="generalTextBox ml-1"
                          v-model="props.row.TierNumber"
                          @change="
                            props.row.TierNumber = minMaxTierNumber(
                              props.row.TierNumber
                            )
                          "
                          @keypress="keyChange($event)"
                        />
                      </o-table-column>
                    </template>

                    <template>
                      <section class="section">
                        <div class="content has-text-grey has-text-centered">
                          <p>
                            <i
                              style="font-size: 32px; color: #939393"
                              class="ri-question-line"
                            ></i>
                          </p>
                          <p>Nothing here.</p>
                        </div>
                      </section>
                    </template>
                  </o-table>
                </section>
              </perfect-scrollbar>
            </div>
          </div>

          <div class="form-group row mt-2">
            <div
              class="offset-xl-6 col-xl-2 offset-lg-6 col-lg-3 col-md-6 col-sm-12"
            >
              <button
                type="button"
                style="width: 100%"
                class="float-right btn btn-success btn-md waves-effect waves-light"
                @click="saveCampaign()"
                :disabled="v$.$invalid"
              >
                <i v-if="saving" class="fas fa-spinner mr-1"></i>Save
              </button>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <button
                type="button"
                style="width: 100%"
                class="float-right btn btn-danger btn-md waves-effect waves-light"
                @click="cancel()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div
      class="backgroundLock"
      v-if="isDataUploadPopupOpen"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div
        style="position: relative; margin: 10% auto"
        class="offset-xl-4 col-xl-4 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
      >
        <CampaignDataUploadPopup
          :campaignId="selectedRow.Id"
          :campaignName="selectedRow.Name"
          @closeDataUploadPopup="closeDataUploadPopup"
        >
        </CampaignDataUploadPopup>
      </div>
    </div>
  </div>
</template>
<script>
import types from "./types";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import NewColorPicker from "@/components/NewColorPicker.vue";
import employeeTypes from "@/views/admin/EmployeeManagement/types";
import deepCopy from "@/helpers/deepCopy";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import _orderby from "lodash";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import CampaignDataUploadPopup from "./CampaignDataUploadPopup";
import { DatePicker } from "v-calendar";
const init = {
  Id: 0,
  IsActive: true,
  Name: "",
  Token: "",
  Color: "#d61831",
  DidId: null,
  // AllRolesSelected: true,
  RoleList: [],
  IsDoNotDistLead: false,
  DefaultInboundCallTimeout: null,
  AutoDtmfValue: "",
  SpanishCampaign: false,
  Priority: null,
  PhoneVmUserId: null,
  PhoneCreateMissedCallLead: null,
  PhoneTransferDidEnabled: null,
  PhoneTransferDid: null,
  PhoneTransferDidStartTime: null,
  PhoneTransderDidEndTime: null,
  PhoneTransferDidTimezoneId: null,
  TierTimeout: null,
  MarketingCost: null,
  MarketingLeadsPurchased: null,
  MarketingSource: "",
  BufferTime: null,
  CostPerCall: null,
};
export default {
  name: "CampaignManagement",

  components: {
    NewColorPicker,
    CampaignDataUploadPopup,
    DatePicker,
  },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      allowedKeyCodes: [35, 42, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57], // *#0-9
      v$: useValidate(),
      selectedRow: null,
      oldValue: null,
      activeItem: null,
      saving: false,
      guid: "",
      allDids: [],
      isCancelledBeforeSave: false,
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      isStriped: true,
      isHoverable: true,
      isLoading: false,
      isEmpty: false,
      showGrid: false,
      isNewColorPickerOpen: false,
      Colors: "#d61831",
      initModified: deepCopy(init),
      showInactiveItems: false,
      typeFilter: "",
      hasAccessOtherOffices: false,
      hasAccessSubOffices: false,
      userOfficeId: 0,
      isDataUploadPopupOpen: false,
    };
  },
  computed: {
    ...mapState({
      campaignList: (state) => state.campaignManagement.campaignList,
      companyDids: (state) => state.employeeManagement.companyDids,
      allRoleList: (state) => state.globals.roles,
      offices: (state) => state.globals.offices,
      timezones: (state) => state.globals.timezones,
      salesAgents: (state) => state.globals.salesAgents,
      filteredAgents() {
        return this.filterAgentsByAccessRole(this.salesAgents);
      },

      formattedData() {
        this.selectedRow.RoleList.sort((a, b) => {
          return b.IsSelected - a.IsSelected;
        });

        return this.selectedRow.RoleList;
      },

      filteredRoles() {
        return _orderby.orderBy(this.filterRolesByAccessRole(this.allRoleList));
      },

      filteredCampaign() {
        let camps = this.hasAccessOtherOffices
          ? this.campaignList
          : this.campaignList
              .filter((x) =>
                x.RoleList.some((y) =>
                  this.filteredRoles.map((y) => y.Id).includes(y.RoleId)
                )
              )
              .filter(
                (x) =>
                  !x.RoleList.some(
                    (y) =>
                      !this.filteredRoles.map((y) => y.Id).includes(y.RoleId)
                  )
              );
        return _orderby.orderBy(
          camps.filter((item) => {
            let rtn =
              item.Name.toLowerCase().indexOf(this.typeFilter.toLowerCase()) >
                -1 &&
              ((!this.showInactiveItems && item.IsActive) ||
                this.showInactiveItems);
            return rtn;
          }),
          "Name"
        );
      },
    }),
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch(types.GETCAMPAIGNS),
      this.$store.dispatch(globalTypes.GET_SALES_AGENTS),
      this.$store.dispatch(employeeTypes.GET_COMPANY_DIDS, 1),
      this.$store.dispatch(globalTypes.GET_OFFICE_NAMES),
      this.$store.dispatch(globalTypes.GET_ROLES),
      this.$store.dispatch(globalTypes.GET_TIMEZONES),
    ]);
    let result = await this.$store.dispatch(types.CAMPAIGN_All_COMPANY_DIDS);
    if (result && result.Data) {
      this.allDids = result.Data;
    }
    for (let item of this.campaignList) {
      if (item.Color == null) item.Color = "#d61831";
    }

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
    }

    this.hasAccessOtherOffices = this.canSeeAllOffices();
    this.hasAccessSubOffices = this.canSeeSubOffices();
    let roles = [];
    roles = this.filterRolesByAccessRole(this.allRoleList);

    this.initModified.RoleList = roles;

    this.genereateTokenInit();
  },

  methods: {
    filterAgentsByAccessRole(agents) {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let subOfficeIds = [];

      if (userInfo != null) {
        subOfficeIds = userInfo.subOffices;
      }

      if (this.hasAccessOtherOffices) {
        return agents;
      } else if (
        this.hasAccessSubOffices &&
        subOfficeIds &&
        subOfficeIds.length > 0
      ) {
        subOfficeIds.push(userInfo.officeId);
        return agents.filter((x) => subOfficeIds.includes(x.OfficeId));
      } else {
        return agents.filter((x) => x.OfficeId == this.userOfficeId);
      }
    },
    filterRolesByAccessRole(roleList) {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let subOfficeIds = [];

      if (userInfo != null) {
        subOfficeIds = userInfo.subOffices;
      }

      if (this.hasAccessOtherOffices) {
        return roleList;
      } else if (
        this.hasAccessSubOffices &&
        subOfficeIds &&
        subOfficeIds.length > 0
      ) {
        subOfficeIds.push(userInfo.officeId);
        return roleList.filter((x) => subOfficeIds.includes(x.OfficeId));
      } else {
        return roleList.filter((x) => x.OfficeId == this.userOfficeId);
      }
    },
    keyChange(event) {
      if (!this.allowedKeyCodes.includes(event.keyCode)) {
        event.preventDefault();
      }
    },
    minMaxTierNumber(val) {
      let isNum = /^\d+$/.test(val);
      if (!isNum) {
        return 1;
      } else if (val == 0) {
        return 1;
      } else if (val > 100) {
        return 100;
      } else return val;
    },
    async isSelectedChange() {
      this.isLoading = true;
      await this.sleep(100);
      this.isLoading = false;
    },

    changeTransferDid() {
      if (this.selectedRow.PhoneTransferDidEnabled == true) {
        this.selectedRow.PhoneTransferDidStartTime = "1/1/2020 00:00";
        this.selectedRow.PhoneTransderDidEndTime = "1/1/2020 00:00";
      } else {
        this.selectedRow.PhoneTransferDid = null;
        this.selectedRow.PhoneTransferDidStartTime = null;
        this.selectedRow.PhoneTransderDidEndTime = null;
        this.selectedRow.PhoneTransferDidTimezoneId = null;
      }
    },

    openDataUploadPopup() {
      if (JSON.stringify(this.selectedRow) != JSON.stringify(this.oldValue)) {
        this.$swal.fire(
          "Warning!",
          "Please save your changes before uploading data.",
          "warning"
        );
        return;
      } else {
        this.isDataUploadPopupOpen = true;
      }
    },
    closeDataUploadPopup() {
      this.isDataUploadPopupOpen = false;
    },
    getDidById(id) {
      let rtn = "";
      let indx = this.allDids.findIndex((x) => x.Id == id);
      if (indx > -1) {
        rtn = this.formatPhone(this.allDids[indx].Did);
      }

      return rtn;
    },
    getOfficeName(officeId) {
      let rtn = "";
      if (officeId == null) {
        rtn = "All Offices";
      } else if (this.offices.length > 0) {
        let indx = this.offices.findIndex((x) => x.Id == officeId);
        if (indx > -1) {
          rtn = this.offices[indx].Name;
        }
      }
      return rtn;
    },
    OpenNewColorPicker() {
      this.isNewColorPickerOpen = true;
    },
    updateColor(value) {
      this.selectedRow.Color = value;
      this.isNewColorPickerOpen = false;
    },
    setCallTimeout(event) {
      let checked = event.target.checked;
      if (this.selectedRow.DefaultInboundCallTimeout > 0) {
        this.selectedRow.DefaultInboundCallTimeout = null;
      }

      if (!checked) {
        this.selectedRow.DefaultInboundCallTimeout = 30;
      }
    },

    // allRolesChange() {
    //   if (this.selectedRow) {
    //     if (this.selectedRow.AllRolesSelected) {
    //       this.showGrid = false;
    //       for (let item of this.selectedRow.RoleList) {
    //         item.IsSelected = false;
    //       }
    //     } else this.showGrid = true;
    //   } else this.showGrid = false;
    // },

    handleUnassign() {
      if (this.selectedRow.DidId != null) {
        this.selectedRow.DidId = null;
        if (this.oldValue.DidId != this.selectedRow.DidId) {
          this.saveCampaign();
        }
      }
    },
    async handleAddNew() {
      let changes = false;
      if (
        JSON.stringify(this.selectedRow) != JSON.stringify(this.oldValue) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init) &&
        this.selectedRow != null
      ) {
        {
          changes = true;
          await this.$swal
            .fire({
              title: "You have unsaved changes",
              text: "You will lose it if you don't save",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, continue without saving!",
            })
            .then(async (dialog) => {
              if (dialog.value) {
                this.isCancelledBeforeSave = true;
              } else {
                this.isCancelledBeforeSave = false;
                return;
              }
            });
        }
      }
      if (!changes || this.isCancelledBeforeSave) {
        this.showGrid = false;
        this.selectedRow = deepCopy(this.initModified);
        this.oldValue = deepCopy(this.selectedRow);
        this.activeItem = null;
      }
      changes = false;
    },
    async handleCampaignSelection(row, index) {
      let changes = false;

      if (
        JSON.stringify(this.selectedRow) != JSON.stringify(this.oldValue) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(this.initModified) &&
        this.selectedRow != null
      ) {
        changes = true;
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = true;
            } else {
              this.isCancelledBeforeSave = false;
              return;
            }
          });
      }
      if (!changes || this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(row);

        let tempList = deepCopy(this.filteredRoles);

        let campaignRoles = this.selectedRow.RoleList.map((x) => x.RoleId);
        for (let roleItem of tempList) {
          if (campaignRoles.includes(roleItem.Id)) {
            let cRoles = this.selectedRow.RoleList.filter(
              (x) => x.RoleId == roleItem.Id
            );
            if (cRoles.length > 0) {
              roleItem.TierNumber = cRoles[0].TierNumber;
            }
            roleItem.IsSelected = true;
          } else {
            roleItem.IsSelected = false;
          }
        }

        this.selectedRow.RoleList = _orderby.orderBy(
          tempList,
          "TierNumber",
          "asc"
        );

        // if (this.selectedRow.RoleList.filter((x) => x.IsSelected).length == 0) {
        //   //this.selectedRow.AllRolesSelected = true;
        //   this.showGrid = false;
        // } else {
        //   //this.selectedRow.AllRolesSelected = false;
        //   this.showGrid = true;
        // }
        if (this.selectedRow.PhoneTransferDidStartTime) {
          this.selectedRow.PhoneTransferDidStartTime =
            "1/1/2020 " + this.selectedRow.PhoneTransferDidStartTime;
        } else {
          if (this.selectedRow.PhoneTransferDidEnabled == true) {
            this.selectedRow.PhoneTransferDidStartTime = "1/1/2020 00:00";
          }
        }
        if (this.selectedRow.PhoneTransderDidEndTime) {
          this.selectedRow.PhoneTransderDidEndTime =
            "1/1/2020 " + this.selectedRow.PhoneTransderDidEndTime;
        } else {
          if (this.selectedRow.PhoneTransferDidEnabled == true) {
            this.selectedRow.PhoneTransderDidEndTime = "1/1/2020 00:00";
          }
        }

        this.oldValue = deepCopy(this.selectedRow);

        this.activeItem = index;
      }

      changes = false;

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },

    formatPhone(number) {
      if (number.startsWith("1") && number.length > 6) {
        number = number.slice(1);
      }
      //Filter only numbers from the input
      let cleaned = ("" + number).replace(/\D/g, "");

      //Check if the input is of correct
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        //let intlCode = match[1] ? "+1 " : "";
        return ["(", match[2], ") ", match[3], "-", match[4]].join("");
      }
    },
    genereateToken() {
      if (this.selectedRow) {
        let dt = new Date().getTime();
        let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
          /[xy]/g,
          function (c) {
            let r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
          }
        );

        this.selectedRow.Token = uuid.toUpperCase();
      }
    },
    genereateTokenInit() {
      if (this.initModified) {
        let dt = new Date().getTime();
        let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
          /[xy]/g,
          function (c) {
            let r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
          }
        );

        this.initModified.Token = uuid.toUpperCase();
      }
    },
    async saveCampaign() {
      if (JSON.stringify(this.oldValue) == JSON.stringify(this.selectedRow)) {
        this.$swal.fire("Warning!", "No changes detected!", "warning");
      } else {
        if (
          this.selectedRow.TierTimeout == null ||
          this.selectedRow.TierTimeout == 0 ||
          this.selectedRow.TierTimeout == ""
        ) {
          this.selectedRow.TierTimeout = 5;
        }
        if (
          this.selectedRow.TierTimeout < 5 ||
          this.selectedRow.TierTimeout > 15
        ) {
          this.$swal.fire(
            "Warning!",
            "Tier Timeout should be between 5 and 15.",
            "warning"
          );
          return;
        }

        let err, result, type;

        type = this.selectedRow.Id > 0 ? types.PUTCAMPAIGN : types.POSTCAMPAIGN;

        if (this.selectedRow.DidId == null || this.selectedRow.DidId == 0) {
          this.selectedRow.DidId = null;
          this.selectedRow.PhoneCreateMissedCallLead = null;
          this.selectedRow.PhoneVmUserId = null;
          this.selectedRow.PhoneTransferDidEnabled = null;
          this.selectedRow.PhoneTransferDid = null;
          this.selectedRow.PhoneTransferDidStartTime = null;
          this.selectedRow.PhoneTransderDidEndTime = null;
          this.selectedRow.PhoneTransferDidTimezoneId = null;
        }

        let rowToSave = deepCopy(this.selectedRow);

        let selectedRoles = rowToSave.RoleList.filter(
          (role) => role.IsSelected
        );

        if (rowToSave.RoleList.length == 0) {
          this.$swal.fire(
            "Warning!",
            "Please select at least one role.",
            "warning"
          );
          return;
        }

        let newRoleList = selectedRoles.map((x) => {
          return {
            RoleId: x.Id,
            TierNumber: x.TierNumber == null ? 1 : x.TierNumber,
          };
        });

        rowToSave.RoleList = newRoleList;

        if (rowToSave.DefaultInboundCallTimeout == "")
          rowToSave.DefaultInboundCallTimeout = 0;

        if (this.selectedRow.PhoneTransferDidEnabled == true) {
          if (
            this.selectedRow.PhoneTransferDid == null ||
            this.selectedRow.PhoneTransferDidTimezoneId == null ||
            this.selectedRow.PhoneTransferDidStartTime == null ||
            this.selectedRow.PhoneTransderDidEndTime == null
          ) {
            this.$swal.fire(
              "Warning!",
              "<b>Did, Timezone, Start time, End time</b> fields cannot be empty.",
              "warning"
            );
            return;
          }
        }

        rowToSave.PhoneTransferDidStartTime = new Date(
          rowToSave.PhoneTransferDidStartTime
        ).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        });
        rowToSave.PhoneTransderDidEndTime = new Date(
          rowToSave.PhoneTransderDidEndTime
        ).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        });

        rowToSave.PhoneTransferDidStartTime = this.convert12HoursTo24(
          rowToSave.PhoneTransferDidStartTime
        );
        rowToSave.PhoneTransderDidEndTime = this.convert12HoursTo24(
          rowToSave.PhoneTransderDidEndTime
        );

        /*if (
          this.isLeadDistroTimeInValid(
            rowToSave.PhoneTransferDidStartTime,
            rowToSave.PhoneTransderDidEndTime
          )
        ) {
          this.$swal.fire(
            "Warning!",
            "There is invalid time in schedule. Please check your time values",
            "warning"
          );
          return;
        }*/

        this.saving = true;
        [err, result] = await this.$store.dispatch(type, rowToSave);
        this.saving = false;

        if (result) {
          this.$store.dispatch(employeeTypes.GET_COMPANY_DIDS, 1);
          this.genereateTokenInit();
          this.$swal.fire("Success!", result.Message, "success");

          this.selectedRow = deepCopy(result.Data);

          let tempList = deepCopy(this.filteredRoles);
          let campaignRoles = this.selectedRow.RoleList.map((x) => x.RoleId);
          for (let roleItem of tempList) {
            if (campaignRoles.includes(roleItem.Id)) {
              let cRoles = this.selectedRow.RoleList.filter(
                (x) => x.RoleId == roleItem.Id
              );
              if (cRoles.length > 0) {
                roleItem.TierNumber = cRoles[0].TierNumber;
              }
              roleItem.IsSelected = true;
            } else {
              roleItem.IsSelected = false;
            }
          }

          this.selectedRow.RoleList = _orderby.orderBy(
            tempList,
            "TierNumber",
            "asc"
          );

          this.oldValue = deepCopy(this.selectedRow);
          this.$store.dispatch(types.GETCAMPAIGNS);
          this.saving = false;
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
    },
    cancel() {
      this.selectedRow = null;
    },
  },
  validations() {
    return {
      selectedRow: {
        Name: { required },
      },
    };
  },
};
</script>
