<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div style="display: flex; justify-content: space-between">
      <div><label>Trigger Schedule</label></div>
      <div class="closeButton mt-1" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>

    <perfect-scrollbar
      style="width: 100%; max-height: 500px; overflow-x: hidden !important"
    >
      <div class="col-md-12 mb-2">
        <div class="col-md-12" style="display: flex; align-items: baseline">
          <div class="mr-1">
            <label class="generalLabel">Trigger Type:</label>
          </div>
          <div class="mr-1">
            <select
              v-model="triggerSchedule.TriggerTypeId"
              class="form-control"
            >
              <option :value="null">Please Select</option>
              <option
                v-for="item in triggerTypeList"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>
          <div class="mr-1">
            <label class="generalLabel">Trigger Time:</label>
          </div>
          <div class="mr-1" style="width: 10%">
            <input
              type="text"
              v-model="triggerDays"
              placeholder="0"
              class="form-control"
              v-myMask="{
                alias: 'numeric',
                rightAlign: false,
                min: 0,
              }"
            />
          </div>
          <div class="mr-1">
            <label class="generalLabel">Days and</label>
          </div>
          <div class="mr-1" style="width: 10%">
            <input
              type="text"
              v-model="triggerHours"
              placeholder="0"
              class="form-control"
              v-myMask="{
                alias: 'numeric',
                rightAlign: false,
                min: 0,
              }"
            />
          </div>
          <div>
            <label class="generalLabel ml-1">Hours</label>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div
          v-if="
            triggerSchedule.TriggerTypeId != null &&
            (triggerSchedule.TriggerTypeId == 1 ||
              triggerSchedule.TriggerTypeId == 2 ||
              triggerSchedule.TriggerTypeId == 5)
          "
          class="col-md-12"
        >
          <label class="generalLabel">{{
            triggerSchedule.TriggerTypeId == 5 ? "Task Type" : "Template"
          }}</label>
          <select
            v-if="triggerSchedule.TriggerTypeId == 1"
            v-model="triggerSchedule.TemplateId"
            class="form-control"
            id="negotiators"
          >
            <option :value="null">Please Select</option>
            <option v-for="item in quickEmails" :key="item.Id" :value="item.Id">
              {{ item.Title }}
            </option>
          </select>

          <select
            v-if="triggerSchedule.TriggerTypeId == 2"
            v-model="triggerSchedule.TemplateId"
            class="form-control"
            id="negotiators"
          >
            <option :value="null">Please Select</option>
            <option
              v-for="item in quickSmsTemplates"
              :key="item.Id"
              :value="item.Id"
            >
              {{ item.Title }}
            </option>
          </select>

          <select
            v-if="triggerSchedule.TriggerTypeId == 5"
            v-model="triggerSchedule.TemplateId"
            class="form-control"
            id="negotiators"
          >
            <option :value="null">Please Select</option>
            <option
              v-for="item in taskTypeNames"
              :key="item.Id"
              :value="item.Id"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="col-md-12 row">
      <div class="col-md-6">
        <audio v-if="soundUrl != ''" autoplay="autoplay" controls preload>
          <source :src="soundUrl" />
        </audio>
      </div>
      <div class="col-md-6 text-right">
        <button @click="addNewTrigger" class="btn btn-success">
          Add New Trigger
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import globalTypes from "@/store/types";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
export default {
  name: "TriggerPopUp",
  mixins: [utilitiesMixin],
  components: {},
  props: {
    allTriggers: { Type: Array, default: () => [] },
  },
  data() {
    return {
      soundUrl: "",
      triggerSchedule: {
        TriggerId: 0,
        TriggerTypeId: null,
        TriggerTime: null,
        TemplateId: null,
        Script: "",
        VmScript: "",
        IsActive: true,
      },
      triggerDays: 0,
      triggerHours: 0,
      oldSpeechText: "",
      oldSpeechTextVM: "",
      userId: 0,
      scriptSound: "",
      vmScriptSound: "",
    };
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_TASK_TYPE_NAMES, {
        ForceRefresh: true,
      }),
      this.$store.dispatch(globalTypes.GET_SMS_TEMPLATES, {
        ForceRefresh: true,
      }),
      this.$store.dispatch(globalTypes.GET_QUICK_EMAIL_TEMPLATES, {
        ForceRefresh: true,
      }),
      this.$store.dispatch(types.GET_CSR_TASK_TRIGGER_TYPE),
    ]);

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (userInfo) {
      this.userId = userInfo.userId;
    }
  },
  computed: mapState({
    quickEmails: (state) => state.globals.quickEmailTemplates,
    triggerTypeList: (state) => state.csrTaskTypeManagement.triggerTypeList,
    taskTypeNames: (state) => state.globals.taskTypeNames,
    quickSmsTemplates: (state) => state.globals.quickSmsTemplates,
  }),
  methods: {
    addNewTrigger() {
      if (this.triggerSchedule.TriggerTypeId == null) {
        this.$swal("Warning!", "Please select trigger type.", "warning");
        return;
      }
      if (
        (this.triggerSchedule.TriggerTypeId == 1 ||
          this.triggerSchedule.TriggerTypeId == 2 ||
          this.triggerSchedule.TriggerTypeId == 5) &&
        this.triggerSchedule.TemplateId == null
      ) {
        this.$swal("Warning!", "Please select template.", "warning");
        return;
      }

      if (this.triggerSchedule.TriggerTypeId == 3) {
        this.triggerSchedule.TemplateId = null;
        if (this.triggerSchedule.Script == "") {
          this.$swal("Warning!", "The script is empty.", "warning");
          return;
        }
      }

      if (this.triggerSchedule.TriggerTypeId != 3) {
        this.triggerSchedule.Script = "";
        this.triggerSchedule.VmScript = "";
      }

      this.triggerSchedule.TriggerTime =
        parseInt(this.triggerDays) * 24 + parseInt(this.triggerHours);

      if (
        this.allTriggers &&
        this.allTriggers.length > 0 &&
        (this.triggerSchedule.TriggerTypeId == 4 ||
          this.triggerSchedule.TriggerTypeId == 5)
      ) {
        let cancelTriggers = this.allTriggers.filter(
          (x) => !x.IsDeleted && (x.TriggerTypeId == 4 || x.TriggerTypeId == 5)
        );
        let warningMessage =
          'The trigger types "Cancel" or "Cancel & Create" should be placed as the final options in the schedule, and only one of them is allowed in the schedule.';
        if (cancelTriggers.length > 0) {
          this.$swal("Warning!", warningMessage, "warning");
          return;
        } else if (cancelTriggers.length > 0) {
          let oldTriggers = this.allTriggers.filter(
            (x) =>
              !x.IsDeleted && x.TriggerTime > this.triggerSchedule.TriggerTime
          );
          if (oldTriggers.length > 0) {
            this.$swal("Warning!", warningMessage, "warning");
            return;
          }
        }
      }

      this.$emit("triggerSchedule", this.triggerSchedule);

      this.closePanel();
    },
    async playRecord(text) {
      if (this.oldSpeechText != text) {
        this.oldSpeechText = text;
        let err, result;
        [err, result] = await this.$store.dispatch(types.GET_SPEECH_TEXT, {
          UserId: this.userId,
          SpeechText: text,
          Type: "script",
        });
        if (result.Data && result.Data.SpeechUrl) {
          this.scriptSound =
            result.Data.SpeechUrl + "?v=" + new Date().getTime();
          this.soundUrl = "";
          await this.sleep(500);
          this.soundUrl = this.scriptSound;
        }
      } else {
        if (this.scriptSound != "") {
          this.soundUrl = "";
          await this.sleep(500);
          this.soundUrl = this.scriptSound;
        }
      }
    },

    async playRecordVmScript(text) {
      if (this.oldSpeechTextVM != text) {
        this.oldSpeechTextVM = text;
        let err, result;
        [err, result] = await this.$store.dispatch(types.GET_SPEECH_TEXT, {
          UserId: this.userId,
          SpeechText: text,
          Type: "script",
        });

        if (result.Data && result.Data.SpeechUrl) {
          this.vmScriptSound =
            result.Data.SpeechUrl + "?v=" + new Date().getTime();
          this.soundUrl = "";
          await this.sleep(500);
          this.soundUrl = this.vmScriptSound;
        }
      } else {
        if (this.vmScriptSound != "") {
          this.soundUrl = "";
          await this.sleep(500);
          this.soundUrl = this.vmScriptSound;
        }
      }
    },

    closePanel() {
      this.$emit("closeTriggerPopUp");
    },
  },
};
</script>

<style>
.validSetting {
  box-shadow: 0 0 2px 1px #24d15f;
}
.invalidSetting {
  box-shadow: 0 0 2px 1px #ff3860;
}
</style>
