<template>
  <div id="app">
    <div class="textDiv" style="background-color: #fff">
      <span class="loader" v-if="loading"></span>
      <p v-html="responseMessage"></p>
      <p v-if="staticMessageVisible">You can now close this window.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      responseMessage: "",
      staticMessageVisible: false,
    };
  },
  mounted() {
    this.handlePop();
  },
  methods: {
    getQueryString(name) {
      name = name.replace(/\[/, "\\[").replace(/\]/, "\\]");
      let regex = new RegExp("[?&]" + name + "=([^&#]*)");
      let results = regex.exec(location.search);
      return results == null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    handlePop() {
      let screenName = this.getQueryString("agent");
      let from = this.getQueryString("callerId");
      let token = this.getQueryString("token");

      if (screenName !== "" && from !== "" && token !== "") {
        let url = `${process.env.VUE_APP_API}/api/BlueRockScreenPop`;
        let payload = {
          ScreenName: screenName,
          From: from,
          Token: token,
        };

        this.loading = true;

        fetch(url, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            this.loading = false;
            let message = data.Message || "Response message not found.";
            this.responseMessage = message.replaceAll("\n", "<br>");
            this.staticMessageVisible = true;
          })
          .catch(() => {
            this.loading = false;
            this.responseMessage = "An error occurred.";
            this.staticMessageVisible = true;
          });
      } else {
        this.responseMessage = "Parameters are missing.";
        this.staticMessageVisible = true;
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
#app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: #f0f8ff;
  padding-bottom: 15vh;
}

p {
  text-align: center;
  font-size: 25px;
  font-family: system-ui;
}

.textDiv {
  border: 1px solid rgb(230, 230, 230);
  width: 503px;
  padding: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  text-align: center;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  background-color: #ddd;
  position: relative;
  border: 10px solid;
  border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25)
    rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
