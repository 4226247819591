<template>
  <div class="row admin-page officePage">
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 panelWrapper">
      <button
        type="button"
        class="col-md-12 btn btn-primary waves-effect waves-light"
        @click="handleAddVMDrop"
      >
        Add New VM Drop
      </button>
      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="vmDropFilter"
            placeholder="Filter by name"
          />
        </div>
      </div>
      <div class="list col-md-12">
        <div
          class="office item row"
          v-for="(drop, index) in filteredVMDrops"
          :key="drop.Id"
          :class="{ active: activeVMDrop === drop.Id }"
          @click="() => handleVMDropSelection(drop, index)"
          :disabled="saving || deleting"
          :style="[
            index % 2 === 0
              ? { 'background-color': '#F1EFEF' }
              : { 'background-color': '#FFFFFF' },
          ]"
        >
          <span :class="{ adminActiveMenuItem: activeVMDrop === drop.Id }">
            <i class="ri-record-mail-line"></i>
            <span style="margin-left: 8px">{{ drop.Name }}</span>
          </span>
        </div>
      </div>
      <div
        v-if="isMobileDevice()"
        style="
          border-top: 1px solid rgb(0, 0, 0, 0);
          margin: 20px 0 0 9px;
          padding-bottom: 20px;
        "
        class="col-md-12 scrollToSelectedItem"
      ></div>
    </div>
    <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12">
      <form
        v-if="isEditing"
        class="form-horizontal formWrapper panelWrapper float-left"
        role="form"
      >
        <div class="panelContent">
          <div class="form-group row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="nameinput">VM Drop Name *</label>
            </div>
            <div class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                autocomplete="off"
                type="text"
                id="nameInput-vdm"
                class="form-control"
                v-model="selectedRow.Name"
                placeholder="Enter your name"
                :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Name.$touch"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="displayorder">Display Order</label>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <input
                autocomplete="off"
                type="number"
                id="displayorder-vdm"
                class="form-control"
                v-model="selectedRow.Priority"
              />
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="content">Content *</label>
            </div>
            <div class="col-xl-6 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <textarea
                type="text"
                id="content"
                class="form-control"
                rows="5"
                v-model="selectedRow.TemplateContent"
                placeholder="Enter Content"
              ></textarea>
            </div>
          </div>
          <!-- <div class="form-group row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="spanishContent"
                >Spanish Content</label
              >
            </div>
            <div class="col-xl-6 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <textarea
                type="text"
                id="spanishContent"
                class="form-control"
                rows="5"
                v-model="selectedRow.SpanishTemplate"
                placeholder="Enter Spanish Content"
              ></textarea>
            </div>
          </div> -->
          <div class="form-group row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label"
                >Statuses * (leave empty for all statuses)</label
              >
            </div>
            <div class="col-xl-6 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <VueMultiselect
                track-by="Id"
                label="Name"
                placeholder="Please Select"
                v-model="selectedRow.Statuses"
                :options="statuses"
                :close-on-select="false"
                :multiple="true"
                selectLabel
                deselectLabel
              />
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label"
                >Roles * (leave empty for all roles)</label
              >
            </div>
            <div class="col-xl-6 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <VueMultiselect
                track-by="Id"
                label="Name"
                placeholder="Please Select"
                v-model="selectedRow.Roles"
                :options="sortedRoles"
                :close-on-select="false"
                :multiple="true"
                selectLabel
                deselectLabel
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
              <label class="form-label" for="autodisposition"
                >Auto Disposition</label
              >
            </div>
            <div class="col-xl-6 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <select
                v-model="selectedRow.CustomerDispositionId"
                id="autodisposition"
                class="form-control"
              >
                <option value="0">None</option>
                <option
                  v-for="item in dispositionTypes"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <button
                type="button"
                id="SaveButton"
                @click="handleSave"
                :disabled="v$.$invalid || saving || deleting"
                class="animated-btn btn btn-success btn-bordered-success waves-effect width-md waves-light submit-button float-right"
              >
                <i v-if="saving" class="fas fa-spinner mr-1"></i>
                Save
              </button>
              <button
                type="button"
                v-if="false"
                :disabled="saving || deleting"
                @click="() => handleDelete(selectedRow.Id)"
                class="animated-btn btn btn-danger btn-bordered-danger waves-effect width-md waves-light submit-button float-right"
              >
                <i v-if="deleting" class="fas fa-spinner mr-1"></i>
                Delete
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import api from "@/services/admin/api";
import types from "./types";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
import VueMultiselect from "vue-multiselect";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";

const init = {
  Id: 0,
  Name: "",
  Priority: 1,
  TemplateContent: "",
  SpanishTemplate: "",
  CustomerDispositionId: 0,
  Roles: [],
  Statuses: [],
};

export default {
  name: "VMDropManagement",
  components: { VueMultiselect },
  data() {
    return {
      v$: useValidate(),
      selectedRow: deepCopy(init),
      isEditing: false,
      saving: false,
      deleting: false,
      activeVMDrop: null,
      vmDropFilter: "",
      oldValue: "",
      valueToRevert: deepCopy(init),
      isCancelledBeforeSave: false,
    };
  },
  mixins: [formatMixin, utilitiesMixin],
  computed: {
    ...mapState({
      list: (state) => state.vmDropManagement.list,
      roles: (state) => state.globals.roles,
      sortedRoles() {
        return _orderby.orderBy(this.roles, "Name", "asc");
      },
      statuses: (state) => state.globals.customerStatuses,
      dispoList: (state) => state.globals.dispositionOptionList,
    }),
    filteredVMDrops() {
      return _orderby.orderBy(
        this.list.filter(
          (item) =>
            item.Name.toLowerCase().indexOf(this.vmDropFilter.toLowerCase()) >
            -1
        ),
        [(item) => item.Name.toLowerCase()]
      );
    },
    dispositionTypes() {
      return _orderby.orderBy(
        this.dispoList,
        [(item) => item.Name.toLowerCase()],
        "asc"
      );
    },
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch(types.GET_VMDropManagement),
      this.$store.dispatch(globalTypes.GET_ROLES),
      this.$store.dispatch(globalTypes.GET_STATUS_NAMES),
      this.$store.dispatch(globalTypes.GET_DISPOSITION_OPTIONS_FOR_ADMIN),
    ]);

    for (let item of this.list) {
      if (!item.CustomerDispositionId) {
        item.CustomerDispositionId = 0;
      }
      let tempRoles = [],
        tempStatuses = [];
      for (let role of item.Roles) {
        tempRoles.push({
          Id: role,
          Name: this.roles.filter((x) => x.Id == role)[0].Name,
        });
      }
      item.Roles = tempRoles;
      for (let status of item.Statuses) {
        tempStatuses.push({
          Id: status,
          Name: this.statuses.filter((x) => x.Id == status)[0].Name,
        });
      }

      item.Statuses = tempStatuses;
    }

    if (!this.selectedRow.CustomerDispositionId) {
      this.selectedRow.CustomerDispositionId = 0;
    }
  },

  methods: {
    async handleAddVMDrop() {
      if (
        this.oldValue != JSON.stringify(this.selectedRow) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = true;
            } else {
              this.isCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.selectedRow = { ...init };
        this.activeVMDrop = null;
        this.isEditing = true;
        this.isCancelledBeforeSave = false;
      }

      if (this.isCancelledBeforeSave) {
        this.selectedRow = { ...init };
        this.activeVMDrop = null;
        this.isEditing = true;
        this.isCancelledBeforeSave = false;
      }
    },
    async handleSave() {
      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal.fire("Warning!", "No Changes Detected!", "warning");

        return;
      }
      this.saving = true;

      let err, result, type;

      let isVMDropExists =
        this.list.filter(
          (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
        ).length > 0;

      if (this.selectedRow.Id) {
        type = types.PUT_VMDROP;
        isVMDropExists =
          this.list.filter(
            (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
          ).length > 1;
      } else {
        type = types.POST_VMDROP;
      }
      if (isVMDropExists) {
        this.saving = false;
        this.$swal.fire(
          "Warning!",
          "This VM drop name is already exists in list!",
          "warning"
        );

        return;
      }
      let objToSave = deepCopy(this.selectedRow);
      if (objToSave.CustomerDispositionId == 0) {
        objToSave.CustomerDispositionId = null;
      }
      if (objToSave.Roles && objToSave.Roles.length > 0) {
        objToSave.Roles = objToSave.Roles.map((x) => x.Id);
      }
      if (objToSave.Statuses && objToSave.Statuses.length > 0) {
        objToSave.Statuses = objToSave.Statuses.map((x) => x.Id);
      }
      if (type == types.POST_VMDROP) {
        objToSave = {
          Name: objToSave.Name,
          Priority: objToSave.Priority,
          TemplateContent: objToSave.TemplateContent,
          CustomerDispositionId: objToSave.CustomerDispositionId,
          Roles: objToSave.Roles,
          Statuses: objToSave.Statuses,
        };
      }
      [err, result] = await this.$store.dispatch(type, objToSave);

      if (result) {
        this.$swal.fire("Success!", result.Message, "success");
        if (type == types.POST_VMDROP) {
          this.selectedRow.Id = result.Data.Id;
          this.list.push(this.selectedRow);
          this.oldValue = JSON.stringify(this.selectedRow);
        } else {
          this.oldValue = JSON.stringify(this.selectedRow);
        }

        //this.reset();
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
    async handleDelete(Id) {
      this.deleting = true;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.list.indexOf(this.selectedRow);
            let err, result;
            [err, result] = await this.$store.dispatch(types.DELETE, Id);
            if (!err) {
              this.reset();
            }
            if (result) {
              this.list.splice(rowIndex, 1);
              this.reset();

              this.$swal.fire("Deleted!", result.Message, "success");
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
      this.deleting = false;
    },
    handleCancel() {
      this.isEditing = false;
    },
    async handleVMDropSelection(row, index) {
      if (this.saving) return;
      if (
        this.oldValue != JSON.stringify(this.selectedRow) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = true;
            } else {
              this.isCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.valueToRevert = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
        this.activeVMDrop = row.Id;
      }
      this.isEditing = true;
      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        let indx = this.list.findIndex((x) => x.Id == this.valueToRevert.Id);
        this.list[indx] = this.valueToRevert;
        this.list.push();
        //this.list.set(indx,this.valueToRevert);

        this.valueToRevert = deepCopy(row);

        this.oldValue = JSON.stringify(row);
        this.activeVMDrop = row.Id;

        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
      }
      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    reset() {
      this.activeVMDrop = null;
      //this.$store.dispatch(types.GET);
      this.selectedRow = { ...init };
      this.isEditing = false;
    },
  },
  validations() {
    return {
      selectedRow: {
        Name: { required },
      },
    };
  },
};
</script>
