export default {
  GET_OFFICE_PERFORMANCE: "GET_OFFICE_PERFORMANCE",
  GET_OFFICE_PERFORMANCE_STATE:"GET_OFFICE_PERFORMANCE_STATE",
  SET_OFFICE_PERFORMANCE_STATE:"SET_OFFICE_PERFORMANCE_STATE",
  GET_SUMMARY_TILES: "GET_SUMMARY_TILES",
  SET_SUMMARY_TILES: "SET_SUMMARY_TILES",
  GET_CSR_DASHBOARD_REPORT: "GET_CSR_DASHBOARD_REPORT",
  SET_CSR_DASHBOARD_REPORT: "SET_CSR_DASHBOARD_REPORT",
  REFRESH_DASHBOARD_DATA: "REFRESH_DASHBOARD_DATA",
  RESET: "RESET",
};
