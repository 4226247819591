<template>
  <Popover
    class="&.internal-call { width: 246px; bottom: 60px; left: -200px; .arrow { left: 204px!important; } }"
    :height="240"
  >
    <template v-slot:header>
      <div class="container">Campaign Leads</div>
    </template>
    <template v-slot:body>
      <div v-if="!isLoading" class="contact-list">
        <section
          v-if="!isMatchWithPhone"
          class="section mt-1"
          style="text-align: center"
        >
          <div style="width: 100%; float: left">
            <label
              class="generalLabel"
              style="width: 100px; float: left; margin: 8px 0"
              >Search by:</label
            >
            <select
              v-model="searchType"
              class="generalSelectBox"
              style="width: 150px; float: left"
            >
              <option value="Name">First & Last Name</option>
              <option value="campaignCode">Reference Code</option>
              <option value="Phone">Phone Number</option>
            </select>
          </div>
          <div style="width: 100%; float: left; position: relative">
            <input
              class="generalTextBox"
              placeholder="Please type and search"
              style="
                width: 240px;
                margin: 10px 10px;
                float: left;
                padding-right: 45px;
              "
              type="text"
              v-model="searchParam"
              @keypress="searchLead"
            />
            <button
              type="button"
              class="btn btn-success btn-sm"
              @click="searchLead('button')"
              style="
                width: 40px;
                height: 36px;
                position: absolute;
                top: 10px;
                right: 8px;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
              "
            >
              <i
                style="
                  color: #ffffff;
                  font-size: 16px;
                  top: 3px;
                  left: -2px;
                  position: relative;
                "
                class="fe-search"
              ></i>
            </button>
          </div>
        </section>
      </div>
      <div class="text-center" style="font-size: 24px; color: #3bafda">
        <i v-if="isLoading" class="fas fa-spinner mr-1"></i>
      </div>
      <div
        v-if="!isLoading && campaignLeads && campaignLeads.length > 0"
        class="contact-list"
      >
        <div
          class="contact"
          :style="
            'cursor:' + item.SalesUserId > 0
              ? 'default !important;'
              : 'pointer !important;'
          "
          :key="item.CustomerId"
          v-for="item in campaignLeads"
        >
          {{ maxNchars(item.Name, 32) }}
          <span
            style="padding: 5px 3px"
            class="badge badge-success"
            v-if="item.SalesUserId > 0"
          >
            (Assigned to {{ getAgentName(item.SalesUserId) }})
          </span>
          <button
            v-if="item.SalesUserId == null"
            @click="assignLead(item.CustomerId, item.MailerDumpId, item.Name)"
            type="button"
            class="btn btn-success btn-success-bordered btn-sm"
            style="font-size: 10px; padding: 1px 5px 2px 5px !important"
          >
            Assign
          </button>
        </div>
      </div>
      <section
        v-if="campaignLeads.length == 0"
        class="section mt-1"
        style="text-align: center"
      >
        <div>
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
      <div class="full-width p-12">
        <button
          v-show-if-authorized="'1871'"
          type="button"
          @click="createNewCustomer()"
          class="btn btn-primary btn-primary-bordered full-width mt-2"
        >
          New Customer
        </button>
      </div>
    </template>
  </Popover>
</template>
<script>
import Popover from "../../Popover";
import types from "../types";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import deepCopy from "../../../helpers/deepCopy";
import utilitiesMixin from "@/mixins/utilitiesMixin";
export default {
  name: "CampaignLeadPanel",
  mixins: [utilitiesMixin],
  components: {
    Popover,
  },
  props: {
    campaignId: {
      type: Number,
      default: () => 0,
    },
    customerPhone: {
      type: String,
      default: () => "",
    },
  },
  emits: ["updateLeadPanelVisible"],
  data() {
    return {
      isLoading: true,
      searchType: "campaignCode",
      searchParam: "",
      isMatchWithPhone: false,
      campaignLeads: [],
      userInfo: null,
      userRoles: "",
    };
  },
  computed: mapState({
    allEmployees: (state) => state.globals.allEmployees,
  }),
  unmounted() {
    this.campaignLeads = [];
  },
  async mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (this.userInfo != null) {
      if (this.userInfo.roles != null) {
        this.userRoles = this.userInfo.roles.join(",");
      }
    }

    this.isLoading = true;
    if (this.campaignId > 0 && this.customerPhone.length == 10) {
      let err, result;

      [err, result] = await this.$store.dispatch(types.GET_CAMPAIGN_LEADS, {
        Id: this.campaignId,
        Phone: this.customerPhone,
        Roles: this.userRoles,
      });

      if (result) {
        this.campaignLeads = deepCopy(result.Data);
      }

      this.isMatchWithPhone = this.campaignLeads.length > 0;
    }
    this.isLoading = false;
    await this.$store.dispatch(globalTypes.GET_STATUS_NAMES);
    await this.$store.dispatch(globalTypes.GET_ALL_ACTIVE_EMPLOYEES);
  },

  methods: {
    getAgentName(agentId) {
      let rtn = "";
      if (this.allEmployees.length > 0 && agentId > 0) {
        let indx = this.allEmployees.findIndex((x) => x.Id == agentId);
        if (indx > -1) {
          rtn = this.allEmployees[indx].Name;
        }
      }
      return rtn;
    },
    async searchLead(event) {
      this.isLoading = true;
      if (event.keyCode == 13 || event == "button") {
        let payload = { Id: this.campaignId, Roles: this.userRoles };
        payload[this.searchType] = this.searchParam;

        let err, result;

        [err, result] = await this.$store.dispatch(
          types.GET_CAMPAIGN_LEADS,
          payload
        );

        if (result) {
          this.campaignLeads = deepCopy(result.Data);
        }
      }
      this.isLoading = false;
    },
    async createNewCustomer() {
      this.$Emitter.emit("showCustomerGrid");
      this.$emit("updateLeadPanelVisible", false);
      let languageId = 1;
      // let indx = this.campaignTypes.findIndex(x=> x.Id==  this.campaignId);
      // if(indx > -1)
      // {
      //   languageId = this.campaignTypes[indx].SpanishCampaign == true ? 2 :1;
      // }

      await this.$store.dispatch(globalTypes.UPDATE_INCOMING_CUSTOMER_INFO, {
        CampaignId: this.campaignId,
        PhoneNumber: this.customerPhone,
        LanguageId: languageId,
      });
    },
    async assignLead(customerId, mailerDumpId, customerName) {
      this.isLoading = true;
      if (customerId > 0) {
        this.$Emitter.emit("acceptLeadFromCampaignCall", {
          Id: customerId,
          Name: customerName,
          CampaignId: this.campaignId,
          PhoneNumber: this.customerPhone,
        });
      } else {
        let err, result;

        [err, result] = await this.$store.dispatch(
          types.CONVERT_MAILER_DUMP_TO_CUSTOMER,
          { MailerDumpId: mailerDumpId }
        );

        if (result) {
          let showError = false;
          if (result.Data) {
            let customerId = result.Data.CustomerId;
            if (customerId > 0) {
              this.$Emitter.emit("acceptLeadFromCampaignCall", {
                Id: customerId,
                Name: customerName,
                CampaignId: this.campaignId,
                PhoneNumber: this.customerPhone,
              });
            } else {
              showError = true;
            }
          } else {
            showError = true;
          }
          if (showError) {
            this.$swal("Customer couldn't be assigned.", PopUpText, "error");
          }
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Campaign Lead Error!", errMsg, "error");
        }
      }
      this.isLoading = true;

      this.$emit("updateLeadPanelVisible", false);
    },
    maxNchars(val, n) {
      let rtn = val;
      if (val.length > n) rtn = val.slice(0, n - 3) + "...";
      return rtn;
    },
  },
};
</script>
