<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'IncomingVmReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />
    <div
      class="row"
      v-if="soundUrl != '' && filteredIncomingVMReport.length > 0"
    >
      <div class="col-md-12">
        <audio
          style="outline: none; float: right"
          v-if="soundUrl != '' && filteredIncomingVMReport.length > 0"
          autoplay="autoplay"
          controls
        >
          <source :src="soundUrl" />
        </audio>
      </div>
    </div>
    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredIncomingVMReport"
        :paginated="isPaginated && filteredIncomingVMReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
      >
        <o-table-column field="Agent" label="Agent" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="agentFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">{{ props.row.Agent }} </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.Agent }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="OfficeName"
          label="Office Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="officeNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.OfficeName }}
          </template>
        </o-table-column>
        <o-table-column
          field="PhoneNumber"
          label="Phone Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="phoneNumberFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ tryFormatPhone(props.row.PhoneNumber) }}
          </template>
        </o-table-column>
        <o-table-column
          field="RecordingDuration"
          label="Recording Duration"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="recordingDurationFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.RecordingDuration }}
          </template>
        </o-table-column>
        <o-table-column field="LogDate" label="Log Date" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="logDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatGridDate(props.row.LogDate) }}
          </template>
        </o-table-column>
        <o-table-column field="RecordUrl" label="Record">
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <i
              v-if="!props.row.IsSummary"
              class="fas fa-play"
              style="color: green; cursor: pointer"
              @click="playRecord(props.row.RecordUrl)"
            ></i>
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredIncomingVMReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "IncomingVmReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],
      soundUrl: "",

      incomingVmReportInitSummary: {
        Agent: "",
        OfficeName: "",
        PhoneNumber: "",
        RecordingDuration: "",
        LogDate: "",
        RecordUrl: "",
      },

      agentFilter: "",
      officeNameFilter: "",
      phoneNumberFilter: "",
      recordingDurationFilter: "",
      logDateFilter: "",
    };
  },
  computed: mapState({
    documentsIncomingVmList: (state) => state.reports.incomingVmList,

    filteredIncomingVMReport() {
      let rtn = [];
      let tmpList =
        this.documentsIncomingVmList.length > 0
          ? this.documentsIncomingVmList.filter((item) => {
              return (
                this.filterString(item.Agent, this.agentFilter) &&
                this.filterString(item.OfficeName, this.officeNameFilter) &&
                this.filterString(item.PhoneNumber, this.phoneNumberFilter) &&
                this.filterNumeric(
                  item.RecordingDuration,
                  this.recordingDurationFilter
                ) &&
                new Date(item.LogDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.logDateFilter) > -1
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalAgent = tmpList.length.toString();

      this.incomingVmReportInitSummary.Agent = "Total: " + totalAgent;
      this.recordCount = tmpList.length;
      this.incomingVmReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.incomingVmReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
  },
  methods: {
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_INCOMING_VM_REPORT, value);
      this.loadingSpinner = false;
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async playRecord(url) {
      this.soundUrl = "";
      await this.sleep(500);
      this.soundUrl = url;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style scoped></style>
