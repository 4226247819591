<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'PendingDispositionReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      :loadingSpinner="loadingSpinner"
      @refreshReport="refreshReport"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table :data="filteredPendingDispositionReport">
        <o-table-column field="Agent" label="Agent" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="agentFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">{{ props.row.Agent }} </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.Agent }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="CustomerName"
          label="Customer Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="customerNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props"> {{ props.row.CustomerName }} </template>
        </o-table-column>
        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <div v-if="loadingSpinner" class="text-center" style="margin: 6em 0">
        <i class="spinner-border avatar-lg text-primary m-2"></i>
      </div>
      <section
        v-if="filteredPendingDispositionReport.length == 0 && !loadingSpinner"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "PendingDispositionReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      pendingDispositionReportInitSummary: {
        Agent: "",
        CustomerName: "",
      },

      agentFilter: "",
      customerNameFilter: "",
    };
  },
  computed: mapState({
    pendingDispositionList: (state) => state.reports.pendingDispositionList,

    filteredPendingDispositionReport() {
      let rtn = [];

      let tmpList =
        this.pendingDispositionList.length > 0
          ? this.pendingDispositionList.filter((item) => {
              return (
                this.filterString(item.Agent, this.agentFilter) &&
                this.filterString(item.CustomerName, this.customerNameFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalAgent = tmpList.length.toString();

      this.pendingDispositionReportInitSummary.Agent = "Total: " + totalAgent;
      this.recordCount = tmpList.length;
      this.pendingDispositionReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.pendingDispositionReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    this.setPayload();
  },
  methods: {
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_PENDING_DISPOSITION_REPORT, value);
      this.loadingSpinner = false;
    },
    async refreshReport() {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_PENDING_DISPOSITION_REPORT);
      this.loadingSpinner = false;
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
