<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'LeadAssignReport'"
      @setPayload="setPayload"
      @clearReport="clearReport"
      :recordCount="recordCount"
      :loadingSpinner="loadingSpinner"
    />
    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <table
        v-if="reportData.length > 0"
        class="datagrid-table creditorTable"
        id="btn-editable-lar"
        style="
          width: 100%;
          border: none !important;
          background-color: rgb(255, 255, 255);
        "
      >
        <thead>
          <tr>
            <th :key="indexC" v-for="(itemC, indexC) in reportColumns">
              {{ itemC }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr :key="index" v-for="(item, index) in reportData">
            <td :key="itemC" v-for="itemC in reportColumns">
              {{ matchReportColumnValue(itemC, item) }}
            </td>
          </tr>
        </tbody>
      </table>

      <section
        v-if="reportData.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import ReportSummary from "./ReportSummary.vue";

export default {
  name: "LeadAssignReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      recordCount: 0,
      reportData: [],
      reportColumns: [],
      columns: [],
    };
  },
  computed: mapState({
    leadAssignReportData: (state) => state.reports.leadAssignReport,
  }),
  mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
  },
  methods: {
    matchReportColumnValue(columnName, valueList) {
      let rtn = "";
      if (columnName == "Sales User") {
        rtn = valueList.SalesUser;
      } else if (columnName == "Total") {
        rtn = valueList.TotalLeads;
      } else {
        let indx = valueList.Statuses.findIndex(
          (x) => x.StatusName == columnName
        );

        rtn = valueList.Statuses[indx].StatusLeads;
      }
      return rtn;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_LEAD_ASSIGN_REPORT, value);
      if (this.leadAssignReportData) {
        this.reportColumns = this.leadAssignReportData.Columns;
        this.reportData = this.leadAssignReportData.Report;
        this.recordCount = this.reportData.length;
      }
      this.loadingSpinner = false;
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped></style>
