<template>
  <div
    class="notifyItemWrapper"
    :class="index % 2 == 0 ? 'backgroundOdd' : 'backgroundEven'"
  >
    <div v-if="!value.IsAutoCallReminder" class="notifyRow">
      <div class="notifyIcon">
        <img
          v-if="value != null && value.NotificationType.LogoUrl != ''"
          :src="value.NotificationType.LogoUrl"
          class="notifyImage"
        />
        <i
          v-if="value != null && value.NotificationType.IconName != ''"
          :class="value.NotificationType.IconName"
          :style="'color:' + value.NotificationType.IconColor + ';'"
        ></i>
      </div>
      <div class="notifyDetails">
        <div class="notifyHeader">
          <span class="notifyName">{{ value.NotificationType.Name }}</span>
          <span class="notifyDate">{{ formatDate(value.TriggerDate) }}</span>
        </div>

        <span class="notifyText">{{ value.NotificationText }} </span>
      </div>
    </div>
    <div v-if="value.IsAutoCallReminder" class="notifyRow">
      <div class="notifySecondsWrapper">
        <div class="base-timer">
          <svg
            class="base-timer__svg"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g class="base-timer__circle">
              <circle
                class="base-timer__path-elapsed"
                cx="50"
                cy="50"
                r="45"
              ></circle>
              <path
                id="base-timer-path-remaining"
                :stroke-dasharray="circleDasharray"
                :class="timerBaseColor"
                class="base-timer__path-remaining"
                d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
              ></path>
            </g>
          </svg>
          <span id="base-timer-label" class="base-timer__label"
            >{{ timerText }}
          </span>
        </div>
      </div>
      <div class="notifyAutoCallHeader">
        <div class="notifyRow" style="font-size: 20px; font-weight: bold">
          {{ value.CustomerName }}
        </div>
        <div class="notifyRow" v-if="canCallCustomer">
          <button
            type="button"
            class="btn btn-sm btn-success"
            v-on:click.prevent="callCustomer()"
          >
            Call Now
          </button>
        </div>
      </div>
    </div>
    <div v-if="value.IsAutoCallReminder" class="autoCallReminderText">
      <span style="padding: 0 16px; display: block">
        {{ value.NotificationText }}
      </span>
    </div>
    <div v-if="!value.IsAutoCallReminder" class="notfiyButtonWrapper">
      <button
        type="button"
        :key="item.Id"
        v-for="item in value.NotificationType.NotificationActions.filter(
          (item) =>
            item.ActionMethod != 'OpenSMS' ||
            (item.ActionMethod == 'OpenSMS' && checkAuth(6231))
        )
          .filter(
            (x) =>
              !(x.ActionMethod == 'ViewCustomer' && value.ForeignId == null)
          )
          .filter(
            (x) =>
              x.ActionMethod != 'CallNow' ||
              (x.ActionMethod == 'CallNow' && canCallCustomer)
          )"
        class="notifyButton btn waves-effect waves-light btn-sm"
        :class="item.IconColor"
        v-on:click.prevent="processAction(item.ActionMethod, value.ForeignId)"
      >
        <i :class="item.IconName"></i>
        {{ item.ActionName }}
      </button>
    </div>
    <div v-if="value.IsAutoCallReminder" class="notifyRow">
      <label class="generalLabel" style="font-size: 12px; padding: 0 16px"
        >Postpone options:
      </label>
    </div>
    <div
      v-if="value.IsAutoCallReminder"
      class="notfiyButtonWrapper text-left noMargin"
    >
      <button
        type="button"
        style="margin-left: 16px; color: #333"
        class="btn btn-sm btn-warning mr-2 text-black-50"
        v-on:click.prevent="postpone(5)"
      >
        5 min
      </button>
      <button
        type="button"
        class="btn btn-sm btn-warning ml-1 mr-2 text-black-50"
        v-on:click.prevent="postpone(10)"
      >
        10 min
      </button>
      <button
        type="button"
        class="btn btn-sm btn-warning ml-1 mr-2 text-black-50"
        v-on:click.prevent="postpone(15)"
      >
        15 min
      </button>
    </div>
    <div
      v-if="
        value.NotificationType.Id == 5 &&
        value.NotificationPayload != null &&
        soundUrl != ''
      "
      class="notfiySoundWrapper"
    >
      <audio
        v-if="soundUrl != ''"
        autoplay="autoplay"
        controls
        style="outline: none; float: right"
      >
        <source :src="soundUrl" />
      </audio>
    </div>
  </div>
</template>
<script>
import api from "@/services/client/customerApi";
import to from "@/helpers/to";
import globalTypes from "@/store/types";
import notificationTypes from "./types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import { mapState } from "vuex";
export default {
  name: "NotificationItem",
  mixins: [formatMixin, utilitiesMixin],
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    index: {
      type: Number,
      default: () => 0,
    },
    canCallCustomer: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      timerInterval: null,
      timeLimit: 30,
      timeLeft: 30,
      timePassed: 0,
      timerText: "30",
      timerBaseColor: "green",
      circleDasharray: "283",
      musicAutoPlay: true,
      soundUrl: "",
    };
  },
  computed: mapState({
    notificationList: (state) => state.globals.pendingNotifications,
  }),
  unmounted() {
    this.timerInterval = null;
    clearInterval(this.timerInterval);
    delete this.timerInterval;
  },
  mounted() {
    this.startTimer();
  },

  methods: {
    async processAction(action, foreignId) {
      switch (action) {
        case "ViewCustomer":
          this.$Emitter.emit("viewCustomer", {
            customerId: foreignId,
            customerName: this.value.CustomerName,
          });
          this.closeNotification(this.value);
          break;
        case "CallNow":
          if (this.value.NotificationPayload) {
            this.$Emitter.emit("makeCall", {
              phone: this.value.NotificationPayload,
              customerId: foreignId,
            });
          } else {
            this.$swal("Error!", "Invalid phone number!", "error");
          }

          this.closeNotification(this.value);
          break;
        case "Dismiss":
          await this.dismiss();

          break;
        case "Postpone5":
          await this.postpone(5);
          break;
        case "Postpone15":
          await this.postpone(15);
          break;
        case "OpenSMS":
          if (
            this.value.NotificationPayload &&
            this.value.NotificationPayload.length == 10
          ) {
            this.$Emitter.emit("openSms", {
              Phones: this.value.NotificationPayload,
              CustomerId: this.value.ForeignId,
            });
          } else {
            this.$swal("Error!", "Invalid phone number!", "error");
          }
          break;
        case "Listen":
          if (this.value.NotificationPayload) {
            let recordUrl = JSON.parse(
              this.value.NotificationPayload
            ).RecordUrl;
            if (recordUrl && recordUrl.length > 0) {
              this.soundUrl = "";
              await this.sleep(500);
              this.soundUrl = recordUrl;
            }
          }
          break;
      }
    },
    async dismiss() {
      let err, result;
      [err, result] = await to(api.dismissNotification(this.value.Id));
      if (result) {
        //this.closeNotification(this.value);
        let itemIndex = this.notificationList
          .map((x) => x.Id)
          .indexOf(this.value.Id);
        this.notificationList.splice(itemIndex, 1);

        if (this.value.ForeignId) {
          this.$Emitter.emit("updateReminderLogs", {
            CustomerId: this.value.ForeignId,
          });
        }
        this.$store
          .dispatch(globalTypes.MODIFY_NOTIFICATIONS, this.notificationList)
          .catch();
        this.$store
          .dispatch(notificationTypes.OUT_DISMISS, this.value.Id)
          .catch();
        if (document.getElementById("reminderCalendar") != null) {
          let dateNow = new Date();
          this.$store.dispatch(notificationTypes.GET_CALENDAREVENTS, {
            year: dateNow.getFullYear(),
            month: dateNow.getMonth() + 1,
          });
        }
      } else {
        this.$swal("Error!", "An error occured!", "error");
      }
      this.soundUrl = "";
    },
    async postpone(minute) {
      let err, result;

      [err, result] = await to(api.postponeNotification(this.value.Id, minute));
      if (result) {
        this.closeNotification(this.value);
        let itemIndex = this.notificationList
          .map((x) => x.Id)
          .indexOf(this.value.Id);
        this.notificationList.splice(itemIndex, 1);

        if (this.value.ForeignId) {
          this.$Emitter.emit("updateReminderLogs", {
            CustomerId: this.value.ForeignId,
          });
        }

        this.$store
          .dispatch(globalTypes.MODIFY_NOTIFICATIONS, this.notificationList)
          .catch();

        this.$swal("Success!", "Postponed " + minute + " minutes", "success");
      } else {
        this.$swal("Error!", "An error occured!", "error");
      }
    },
    async callCustomer() {
      window.clearInterval(this.timerInterval);
      this.timerInterval = null;
      if (this.value.NotificationPayload) {
        this.$Emitter.emit("makeCall", {
          phone: this.value.NotificationPayload,
          customerId: this.value.ForeignId,
          skipDispoCheck: true,
        });
        await this.dismiss();
      } else {
        this.$swal("Error!", "Invalid phone number!", "error");
      }

      this.closeNotification(this.value);
    },
    closeNotification() {
      this.$emit("closeNotification", this.value);
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    onTimesUp() {
      if (this.timerInterval != null) {
        if (this.value.IsAutoCallReminder) {
          this.callCustomer();
        }
      }
      window.clearInterval(this.timerInterval);
      this.timerInterval = null;
    },

    startTimer() {
      if (this.value.IsAutoCallReminder) {
        this.timerInterval = setInterval(() => {
          this.timePassed = this.timePassed += 1;
          this.timeLeft = this.timeLimit - this.timePassed;
          this.timerText = this.formatTime(this.timeLeft);
          this.setCircleDasharray();
          this.setRemainingPathColor(this.timeLeft);

          if (this.timeLeft == 0) {
            this.onTimesUp();
          }
        }, 1000);
      }
    },
    formatTime(time) {
      let seconds = time;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return seconds;
    },
    setRemainingPathColor(timeLeft) {
      if (timeLeft <= 10) {
        this.timerBaseColor = "red";
      } else if (timeLeft <= 20) {
        this.timerBaseColor = "orange";
      }
    },
    calculateTimeFraction() {
      let rawTimeFraction = this.timeLeft / this.timeLimit;
      return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
    },
    setCircleDasharray() {
      this.circleDasharray = `${(this.calculateTimeFraction() * 283).toFixed(
        0
      )} 283`;
    },
  },
};
</script>
<style scoped>
.backgroundOdd {
  background-color: #e9e9e9;
}
.backgroundEven {
  background-color: #f9f9f9;
}
.notifyItemWrapper {
  width: 100%;
  float: left;
  padding: 16px;
}
.notifyIcon {
  width: 48px;
  height: 48px;
  font-size: 36px;
  float: left;
  margin: 0 0;
}
.notifySecondsWrapper {
  width: 100px;
  float: left;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}
.notifyAutoCallHeader {
  width: 254px;
  float: right;
  text-align: center;
}
.notifyRow {
  width: 100%;
  float: left;
  margin: 5px 0;
}

.notifyImage {
  width: 48px;
  float: left;
}
.notifyDetails {
  width: 300px;
  float: right;
}
.notifyHeader {
  width: 100%;
  float: left;
}
.notifyName {
  width: 60%;
  float: left;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #000;
  text-overflow: ellipsis;
  overflow: hidden;
}
.notifyDate {
  width: 40%;
  float: right;
  font-size: 0.75rem;
  line-height: 1.2rem;
  color: #666;
  text-overflow: ellipsis;
  overflow: hidden;
}
.notifyText {
  width: 100%;
  display: block;
  float: left;
  max-height: 75px;
  margin: 10px 0;
  color: #6c757d;
  text-overflow: ellipsis;
  overflow: hidden;
}
.notfiyButtonWrapper {
  width: 100%;
  height: 32px;
  float: left;
  margin: 10px 0 0 0;
  text-align: right;
}
.autoCallReminderText {
  width: 100%;
  max-height: 50px;
  float: left;
  margin: 10px 0 0 0;
  text-align: left;
}
.notifyButton {
  display: inline-block;
  margin: 0 5px;
}
.notfiySoundWrapper {
  width: 100%;
  height: 50px;
  float: left;
  margin: 10px 0 0 0;
  position: relative;
}
.base-timer {
  position: relative;
  width: 100px;
  height: 100px;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.gray {
  color: gray;
}
.green {
  color: rgb(65, 184, 131);
}

.orange {
  color: orange;
}

.red {
  color: red;
}

.base-timer__label {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
</style>
