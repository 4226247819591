import types from "./types";
import customerApi from "@/services/client/customerApi";
import to from "@/helpers/to";
export default {
  state: {
    popupNotifications: [],
    calendarEvents: [],
    customerReminders: [],
  },
  actions: {
    GET_POPUPNOTIFICATIONS: async ({ commit, state }) => {
      let err, result;

      [err, result] = await to(customerApi.getNotifications());

      if (result) {
        commit(types.SET_POPUPNOTIFICATIONS, result.Data);
      }
    },
    MODIFY_POPUPNOTIFICATIONS: ({ commit }, payload) => {
      commit(types.SET_POPUPNOTIFICATIONS, payload);
    },
    GET_CALENDAREVENTS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(
        customerApi.getNotificationsByDate(payload.year, payload.month)
      );
      if (result) {
        let data = [];
        if (payload && payload.removeAutoCallReminders) {
          if (result.Data) {
            data = result.Data.filter((x) => x.NotificationType.Id != 1);

            commit(types.SET_CALENDAREVENTS, data);
          }
        } else {
          commit(types.SET_CALENDAREVENTS, result.Data);
        }
      }
    },
    GET_CUSTOMERREMINDERS: async ({ commit }, customerId) => {
      let err, result;

      [err, result] = await to(customerApi.getCustomerRemindes(customerId));

      if (result) {
        commit(types.SET_CUSTOMERREMINDERS, result.Data);
      }
    },
    GET_CUSTOMER_REMINDERS_LOCAL: async ({ commit }, customerId) => {
      let err, result;

      [err, result] = await to(customerApi.getCustomerRemindes(customerId));

      return [err, result];
    },
    MODIFY_CUSTOMERREMINDERS: ({ commit }, payload) => {
      commit(types.SET_CUSTOMERREMINDERS, payload);
    },
    OUT_DISMISS: ({ commit, state }, payload) => {
      let newArr = state.popupNotifications;
      if (state.popupNotifications && state.popupNotifications.length > 0) {
        newArr = [];

        newArr = state.popupNotifications.filter((x) => x.Id != payload);
      }

      commit(types.SET_POPUPNOTIFICATIONS, newArr);
    },
  },
  mutations: {
    SET_POPUPNOTIFICATIONS: (state, payload) => {
      state.popupNotifications = payload;
    },
    SET_CALENDAREVENTS: (state, payload) => {
      state.calendarEvents = payload;
    },
    SET_CUSTOMERREMINDERS: (state, payload) => {
      state.customerReminders = payload;
    },
  },
};
