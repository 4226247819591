<template>
  <div class="row admin-page">
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group row" v-if="false">
        <label
          class="col-xl-1 col-lg-3 col-md-4 col-sm-4 col-form-label"
          for="nameinput"
          >Method</label
        >
        <select
          class="col-xl-3 col-lg-6 col-md-6 col-sm-8 form-control"
          v-model="distroSettings.MethodId"
        >
          <option v-for="item in methodsList" :key="item.Key" :value="item.Key">
            {{ item.Value }}
          </option>
        </select>
      </div>
      <!-- <div class="form-group row mt-3">
        <div
          class="col-xl-6 col-lg-8 col-md-12 col-sm-12 panelWrapper noMargin"
        >
          <ul class="nav nav-tabs">
            <li v-for="tab in tabs" :key="tab.id" class="nav-item tab-item">
              <a
                data-toggle="tab"
                :aria-expanded="tab.active"
                v-on:click.prevent="() => handleTabChange(tab.name)"
                class="tab-link"
                :class="{ active: tab.active }"
              >
                <span class="d-sm-inline-block tab-item-label">
                  {{ tab.label }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div> -->
      <div v-show="tabs[0].active" class="form-group row">
        <div
          class="col-xl-6 col-lg-8 col-md-12 col-sm-12 panelWrapper noMargin"
        >
          <div style="display: flex; gap: 20px; align-items: baseline">
            <div><label>Distribution Method</label></div>
            <div class="col-md-4">
              <select class="form-control" v-model="isAutoDial">
                <option :value="false">Hot Lead</option>
                <option :value="true">Auto Dial</option>
              </select>
            </div>
          </div>
          <div class="panelContent">
            <div
              class="form-group row font-weight-semibold"
              style="background-color: #ddd; border-bottom: 2px solid #ddd"
            >
              <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">Day</div>
              <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">Enabled</div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                Local Start Time
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                Local End Time
              </div>
            </div>
            <div v-if="distroSettings != null">
              <div
                class="form-group row"
                style="border-bottom: 2px solid #ddd; padding: 5px 0"
                v-for="item in distroSettings.Schedule"
                :key="item.Day"
              >
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 mt-2">
                  {{ item.DayName }}
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                  <div style="width: 20px; float: left; margin: 0 12px">
                    <input
                      autocomplete="off"
                      type="checkbox"
                      class="form-control"
                      v-model="item.Enabled"
                    />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <DatePicker
                    mode="time"
                    :locale="'en-US'"
                    v-model="item.StartTime"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        autocomplete="off"
                        class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </DatePicker>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <DatePicker
                    mode="time"
                    :locale="'en-US'"
                    v-model="item.EndTime"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        autocomplete="off"
                        class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </DatePicker>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div
                class="offset-xl-9 col-xl-3 offset-lg-9 col-lg-3 offset-md-6 col-md-6 col-sm-12"
              >
                <button
                  type="button"
                  style="width: 100%"
                  class="float-right btn btn-success btn-md waves-effect waves-light float-right mt-2 mr-2"
                  @click="saveSettings()"
                  :disabled="saving"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div  v-show="tabs[1].active" class="form-group row">
        <div
          class="col-xl-6 col-lg-8 col-md-12 col-sm-12 panelWrapper noMargin"
        >
          <div class="panelContent">
            <div class="form-group row">
              <div class="col-md-12">
                <label
                  class="col-form-label"
                  for="tiersTimeout"
                  style="font-weight: bold"
                  >Tier Timeout (seconds)</label
                >
                <input
                  autocomplete="off"
                  type="number"
                  min="0"
                  style="width: 80px !important"
                  class="numericInput ml-2"
                  v-model="tiers.TierTimeout"
                  @keypress="keyChange($event)"
                />
              </div>
            </div>

            <div class="form-group row mt-2">
              <label class="col-md-12 col-form-label"></label>
            </div>
            <div class="slimScrollDiv">
              <perfect-scrollbar style="height: 320px">
                <div
                  v-for="item in tiers.Agents"
                  :key="item.UserId"
                  class="col-md-12"
                >
                  <div class="row">
                    <div class="col-xl-9 col-lg-8 col-md-6 col-sm-6 noPadding">
                      <label class="col-form-label" for="agentNames">{{
                        item.Name
                      }}</label>
                      <label class="ml-1"
                        >- ({{ getOfficeName(item.OfficeId) }})</label
                      >
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 noPadding">
                      <input
                        autocomplete="off"
                        type="number"
                        min="1"
                        class="form-control"
                        @keypress="keyChange($event)"
                        v-model="item.TierNumber"
                        @change="
                          item.TierNumber = minMaxTierNumber(item.TierNumber)
                        "
                      />
                    </div>
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
            <div class="form-group row">
              <div class="col-md-12 text-right">
                <button
                  type="button"
                  style="width: 100%"
                  class="btn btn-success btn-md waves-effect waves-light mt-2 mr-2"
                  @click="saveTiers()"
                  :disabled="saving"
                >
                  Save Tier Settings
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import types from "./types";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import deepCopy from "@/helpers/deepCopy";
import formatMixin from "@/mixins/formatMixin";
import { DatePicker } from "v-calendar";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "LeadDistributionManagement",
  components: {
    DatePicker,
  },
  data() {
    return {
      isAutoDial: false,
      methodsList: [],
      distroSettings: null,
      oldSettings: [],
      saving: false,
      tabs: [
        {
          id: 1,
          name: "schedule",
          label: "Schedule",
          iconName: "",
          active: true,
        },
        // {
        //   id: 2,
        //   name: "agentTiers",
        //   label: "Agent Tiers",
        //   iconName: "",
        //   active: false,
        // },
      ],
      config: {
        format: "LT",
        useCurrent: false,
        showClear: false,
        showClose: true,
        icons: {
          time: "fas fa-clock ",
          date: "fas fa-calendar ",
        },
      },
      oldTiers: null,
      tiers: {
        TierTimeout: 0,
        IsAutoAcceptCall: false,
        Agents: [],
      },
      allowedKeyCodes: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57], //0-9
      userOfficeId: 0,
    };
  },

  // computed: {
  //   ...mapState({
  //     offices: (state) => state.globals.offices,
  //   }),
  // },
  async mounted() {
    //await Promise.all([this.$store.dispatch(globalTypes.GET_OFFICE_NAMES)]);
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
    }

    let result;

    result = await this.$store.dispatch(types.GETLEADDISTRO).catch((err) => {});

    if (result && result.Data) {
      this.methodsList = result.Data.Methods;
      this.distroSettings = result.Data.Settings;

      if (this.distroSettings) {
        this.oldSettings = deepCopy(this.distroSettings);
        for (let i = 0; i < this.distroSettings.Schedule.length; i++) {
          this.distroSettings.Schedule[i].StartTime =
            "1/1/2020 " + this.distroSettings.Schedule[i].StartTime;
          this.distroSettings.Schedule[i].EndTime =
            "1/1/2020 " + this.distroSettings.Schedule[i].EndTime;
        }
      }
    }

    let autoDialResult = await this.$store
      .dispatch(types.GET_AUTO_DIAL)
      .catch((err) => {});
    if (autoDialResult && autoDialResult.Data) {
      this.isAutoDial = autoDialResult.Data.IsAutoDial;
    }

    // result = null;
    // result = await this.$store
    //   .dispatch(types.GET_AGENT_TIERS)
    //   .catch((err) => {});
    // if (result && result.Data) {
    //   this.bindValues(result.Data);
    // }
  },
  methods: {
    getOfficeName(officeId) {
      let rtn = "";
      if (officeId && officeId > 0) {
        let indx = this.offices.findIndex((x) => x.Id == officeId);
        if (indx > -1) {
          rtn = this.offices[indx].Name;
        }
      }

      return rtn;
    },

    minMaxTierNumber(val) {
      let isNum = /^\d+$/.test(val);
      if (!isNum) {
        return 1;
      } else if (val == 0) {
        return 1;
      } else if (val > 100) {
        return 100;
      } else return val;
    },
    handleTabChange(tabName) {
      let temp = [...this.tabs];
      temp = temp.map((item) => {
        item.active = item.name === tabName ? true : false;
        return item;
      });
      this.tabs = [...temp];
    },
    async saveSettings() {
      let invalidTimes = 0;
      this.saving = true;

      for (let i = 0; i < this.distroSettings.Schedule.length; i++) {
        this.distroSettings.Schedule[i].StartTime = new Date(
          this.distroSettings.Schedule[i].StartTime
        ).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        });
        this.distroSettings.Schedule[i].EndTime = new Date(
          this.distroSettings.Schedule[i].EndTime
        ).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        });

        this.distroSettings.Schedule[i].StartTime = this.convert12HoursTo24(
          this.distroSettings.Schedule[i].StartTime
        );
        this.distroSettings.Schedule[i].EndTime = this.convert12HoursTo24(
          this.distroSettings.Schedule[i].EndTime
        );

        if (invalidTimes == 0) {
          if (
            this.isLeadDistroTimeInValid(
              this.distroSettings.Schedule[i].StartTime,
              this.distroSettings.Schedule[i].EndTime
            )
          ) {
            invalidTimes++;
          }
        }
      }

      let currentSettings = {
        ...this.distroSettings,
        isAutoDial: this.isAutoDial,
      };

      if (JSON.stringify(this.oldSettings) == JSON.stringify(currentSettings)) {
        this.$swal.fire("Warning!", "No changes detected!", "warning");
        for (let i = 0; i < this.distroSettings.Schedule.length; i++) {
          this.distroSettings.Schedule[i].StartTime =
            "1/1/2020 " + this.distroSettings.Schedule[i].StartTime;
          this.distroSettings.Schedule[i].EndTime =
            "1/1/2020 " + this.distroSettings.Schedule[i].EndTime;
        }
      } else {
        if (invalidTimes > 0) {
          this.$swal.fire(
            "Warning!",
            "There is invalid time in schedule. Please check your time values",
            "warning"
          );
          for (let i = 0; i < this.distroSettings.Schedule.length; i++) {
            this.distroSettings.Schedule[i].StartTime =
              "1/1/2020 " + this.distroSettings.Schedule[i].StartTime;
            this.distroSettings.Schedule[i].EndTime =
              "1/1/2020 " + this.distroSettings.Schedule[i].EndTime;
          }
        } else {
          let err, result, autoDialResult;
          this.saving = true;

          autoDialResult = await this.$store.dispatch(types.SET_AUTO_DIAL, {
            isAutoDial: this.isAutoDial,
          });

          if (
            JSON.stringify(this.oldSettings) !=
            JSON.stringify(this.distroSettings)
          ) {
            [err, result] = await this.$store
              .dispatch(types.POSTLEADDISTRO, this.distroSettings)
              .catch((err) => {});
          }

          if (autoDialResult && (!result || result)) {
            this.$swal.fire(
              "Success!",
              result ? result.Message : "Settings saved successfully!",
              "success"
            );

            this.oldSettings = deepCopy(currentSettings);
            for (let i = 0; i < this.distroSettings.Schedule.length; i++) {
              this.distroSettings.Schedule[i].StartTime =
                "1/1/2020 " + this.distroSettings.Schedule[i].StartTime;
              this.distroSettings.Schedule[i].EndTime =
                "1/1/2020 " + this.distroSettings.Schedule[i].EndTime;
            }
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      }
      this.saving = false;
    },
    async bindValues(data) {
      this.tiers.TierTimeout = data.TierTimeout;
      if (!this.canSeeAllOffices()) {
        let agents = [];
        if (data.Agents != null && data.Agents.length > 0) {
          agents = data.Agents.filter((x) => x.OfficeId == this.userOfficeId);
        }
        this.tiers.Agents = agents;
      } else {
        this.tiers.Agents = data.Agents;
      }
      this.oldTiers = deepCopy(this.tiers);
    },
    async saveTiers() {
      this.saving = true;

      if (JSON.stringify(this.oldTiers) == JSON.stringify(this.tiers)) {
        this.$swal.fire("Warning!", "No changes detected!", "warning");
      } else {
        let err, result;
        this.saving = true;
        [err, result] = await this.$store
          .dispatch(types.POST_AGENT_TIERS, this.tiers)
          .catch((err) => {});

        if (result) {
          this.$swal.fire("Success!", result.Message, "success");
          this.bindValues(result.Data);
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
      this.saving = false;
    },

    checkDate(item) {
      if (new Date(item.StartTime) > new Date(item.EndTime)) {
        this.$swal.fire(
          "Warning!",
          "End Time must be greater than Start Time",
          "warning"
        );
      }
    },
    keyChange(event) {
      if (!this.allowedKeyCodes.includes(event.keyCode)) {
        event.preventDefault();
      }
    },
  },
  mixins: [formatMixin, utilitiesMixin],
};
</script>
<style lang="css">
.vc-time-date {
  display: none !important;
}
</style>
