<template>
  <div class="backgroundLock">
    <div
      style="position: relative; margin: 10% auto"
      class="offset-lg-4 col-xl-4 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
    >
      <div class="searchAndClosePanel">
        <div
          class="placeHoldersCloseButton"
          v-on:click.prevent.stop="closePanel"
        >
          <i class="fas fa-window-close"></i>
        </div>
        <div class="form-group row">
          <div class="col-md-12 noMargin">
            <label class="generalLabel">Placeholders</label>
          </div>
        </div>
        <div class="pb-2">
          <input
            type="text"
            class="form-control"
            v-model="placeHoldersListFilter"
            placeholder="Filter Placeholders"
          />
        </div>

        <perfect-scrollbar class="placeHoldersScroll">
          <div class="searchAndClosePanel" style="background-color: #ffffff">
            <div class="col-md-12">
              <div id="regularPlaceHolder" v-if="!availablePlaceHolders">
                <div
                  v-for="(item, index) in filteredPlaceHolders"
                  :key="item.Id"
                  class="form-group row"
                >
                  <div
                    v-on:click.prevent.stop="handleSelection(item)"
                    class="col-md-12 noMargin"
                    style="padding: 8px 12px; cursor: pointer"
                    :style="[
                      index % 2 === 0
                        ? { 'background-color': '#F1EFEF' }
                        : { 'background-color': '#FFFFFF' },
                    ]"
                  >
                    <label class="generalLabel">{{ item.Placeholder }}
                         <button style="background-color:#1abc9c;" v-if="copying && copyingItemId == item.Id" type="button" class="btn btn-xs waves-effect waves-light ml-2">
                          <i class="mdi mdi-check-all"></i>Copied
                      </button>
                      </label>
                     

                  </div>
                </div>
              </div>
              <div id="availablePlaceHolders" v-if="availablePlaceHolders">
                <label v-if="filteredSignaturePlaceHolders.length > 0"
                  >Signature Placeholders</label
                >
                <div
                  class="mb-2"
                  v-if="filteredSignaturePlaceHolders.length > 0"
                ></div>
                <div
                  v-for="(item, index) in filteredSignaturePlaceHolders"
                  class="form-group row"
                  :key="index"
                >
                  <div
                    v-on:click.prevent.stop="handleSelection(item)"
                    class="col-md-12 noMargin"
                    style="padding: 8px 12px; cursor: pointer"
                    :style="[
                      index % 2 === 0
                        ? { 'background-color': '#F1EFEF' }
                        : { 'background-color': '#FFFFFF' },
                    ]"
                  >
                    <label class="generalLabel">{{ item }}
                         <button style="background-color:#1abc9c;" v-if="copying && copyingItemId == item" type="button" class="btn btn-xs waves-effect waves-light ml-2">
                          <i class="mdi mdi-check-all"></i>Copied
                      </button>
                    </label>
                  </div>
                </div>
                <div
                  class="mt-2"
                  v-if="filteredSignaturePlaceHolders.length > 0"
                ></div>
                <label v-if="filteredSystemPlaceHolders.length > 0"
                  >System Placeholders</label
                >
                <div
                  class="mb-2"
                  v-if="filteredSystemPlaceHolders.length > 0"
                ></div>
                <div
                  v-for="(item, index) in filteredSystemPlaceHolders"
                  class="form-group row"
                  :key="index"
                >
                  <div
                    v-on:click.prevent.stop="handleSelection(item)"
                    class="col-md-12 noMargin"
                    style="padding: 8px 12px; cursor: pointer"
                    :style="[
                      index % 2 === 0
                        ? { 'background-color': '#F1EFEF' }
                        : { 'background-color': '#FFFFFF' },
                    ]"
                  >
                    <label class="generalLabel">{{ item }}
                        <button style="background-color:#1abc9c;" v-if="copying && copyingItemId == item" type="button" class="btn btn-xs waves-effect waves-light ml-2">
                          <i class="mdi mdi-check-all"></i>Copied
                      </button>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
import types from "./types";
import { mapState } from "vuex";
import deepCopy from "@/helpers/deepCopy";
export default {
  name: "Placeholders",
  props: {
    typeId: {
      type: Number,
      default: () => 0,
    },

    availablePlaceHolders: {
      type: Object,
      default: () => null,
    },
  },
  computed: mapState({
    placeholderList: (state) => state.placeholdersManagement.placeholderList,

    filteredPlaceHolders() {
      let rtn =
        this.placeholderList.length > 0
          ? this.placeholderList
              .filter((x) => x.PlaceholderTypeId == (this.typeId > 0 ? this.typeId : x.PlaceholderTypeId))
              .filter(
                (item) =>
                  item.Placeholder.toLowerCase().indexOf(
                    this.placeHoldersListFilter.toLowerCase()
                  ) > -1
              )
          : this.placeholderList;

      return rtn;
    },
    filteredSignaturePlaceHolders() {
      let rtn =
        this.signaturePlaceHolders.length > 0
          ? this.signaturePlaceHolders.filter(
              (item) =>
                item
                  .toLowerCase()
                  .indexOf(this.placeHoldersListFilter.toLowerCase()) > -1
            )
          : this.signaturePlaceHolders;

      return rtn;
    },
    filteredSystemPlaceHolders() {
      return this.systemPlaceHolders.length > 0
        ? this.systemPlaceHolders.filter(
            (item) =>
              item
                .toLowerCase()
                .indexOf(this.placeHoldersListFilter.toLowerCase()) > -1
          )
        : this.systemPlaceHolders;
    },
  }),
  async mounted() {
    if (this.availablePlaceHolders == null) {
      await this.$store.dispatch(types.GET_PLACEHOLDERS);
    } else {
      this.signaturePlaceHolders =
        this.availablePlaceHolders.SignaturePlaceholders;
      this.systemPlaceHolders = this.availablePlaceHolders.SystemPlaceholders;
    }
  },
  data() {
    return {
      placeHoldersListFilter: "",
      signaturePlaceHolders: [],
      systemPlaceHolders: [],
      copying:false,
      copyingItemId:0,
    };
  },
  methods: {
    closePanel() {
      this.$emit("setPlaceholderVisibility", false);
    },
    async handleSelection(item) {
      

      if(item.Id)
      {
      this.$emit("setPlaceholderContent", item.Placeholder);
      this.copying = true;
      this.copyingItemId= item.Id;

      }
      else
      { 
         this.$emit("setPlaceholderContent", item);
          this.copying = true;
          this.copyingItemId= item;
      }



      await this.sleep(500);
      this.closePanel();
    },
     sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      },
  },
};
</script>
<style>
.backgroundLock {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
}
.placeHoldersScroll {
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}
.placeHoldersCloseButton {
  position: absolute;
  top: -5px;
  right: 23px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.placeHoldersCloseButton:hover {
  color: #ff3860;
}

.searchAndClosePanel {
  padding: 10px;
  border-radius: 8px;
  background-color: #f7f7f7;
}



</style>
