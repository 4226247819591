import types from "../types";
import api from "@/services/admin/api";
import customerApi from "@/services/client/customerApi";
import to from "@/helpers/to";
import deepCopy from "@/helpers/deepCopy";
export default {
  state: {
    states: [],
    timezones: [],
    genders: [],
    offices: [],
    roles: [],
    phoneTypes: [],
    priorityTypes: [],
    customerStatuses: [],
    vehicleTypes: [],
    dispositionTypes: [],
    campaignTypes: [],
    pendingNotifications: [],
    historyList: [],
    quickSmsTemplates: [],
    quickEmailTemplates: [],
    dripEmailFromTypes: [],
    pauseReasonTypes: [],
    isUserOnline: false,
    userLastCallTime: null,
    incomingCustomerInfo: null,
    outboundCallerIdList: [],
    campaignList: [],
    favorites: [],
    csrTaskStatus: [],
    salesAgents: [],
    negotiators: [],
    csrs: [],
    taskTypeNames: [],
    taskActionNames: [],
    allEmployees: [],
    assignedCsrTaskId: null,
    pendingChangeStatusesList: [],
    pendingChangeTypesList: [],
    assignedLeadData: null,
    hardshipList: [],
    occupationList: [],
    accountTypeList: [],
    residenceOwnershipTypeList: [],
    expenseTypeList: [],
    scriptList: [],
    customerTagTypes: [],
    creditorContactList: [],
    paymentOptions: [],
    suggestCreditorNames: [],
    userListeningCall: false,
    creditorWithAlias: [],
    allUiSettings: [],
  
    dispositionOptionList: [],
    activePoaList: [],
    blockedCreditorInfo: null,
    bannedKeywords: [
      "loan offer",
      "personal loans",
      "#1",
      "100% Free",
      "100% Satisfied",
      "50% off",
      "$$$",
      "4U",
      "Accept credit cards",
      "Act now ",
      "Addresses on CD",
      "Affordable",
      "Additional income",
      "All natural",
      "Amazing",
      "Apply online",
      "As seen on",
      "Auto email removal",
      "Avoid bankruptcy",
      "Bargain",
      "Be amazed",
      "Be your own boss",
      "Being a member",
      "Beneficiary",
      "Best Price",
      "Big bucks",
      "Bill 1618",
      "Billion",
      "Brand new pager",
      "Bulk email",
      "Buy direct",
      "Buying judgments",
      "Cable converter",
      "Call free",
      "Call now",
      "Calling creditors",
      "Cancel at any time",
      "Cannot be combined with any other offer",
      "Can't live without",
      "Cards accepted",
      "Cash bonus",
      "Cashcashcash",
      "Casino",
      "Cellphone cancer scam",
      "Cents on the dollar",
      "Check or money order",
      "Cheap",
      "Claims",
      "Collect child support",
      "Compare rates",
      "Compete for your business",
      "Confidentially on all orders",
      "Congratulations",
      "Consolidate debt and credit",
      "Consolidate your debt",
      "Copy DVDs",
      "Credit bureaus",
      "Credit card offers",
      "Cures baldness",
      "Dear email",
      "Dear friend",
      "Dear somebody",
      "Diagnostic",
      "Dig up dirt on friends",
      "Discusses search engine listings",
      "Discount",
      "Do it today",
      "Don't delete",
      "Don't hesitate!",
      "Double your",
      "Drastically reduced",
      "Earn per week",
      "Earn $",
      "Earn extra cash",
      "Easy terms",
      "Eliminate bad credit",
      "Email harvest",
      "Email marketing",
      "Expect to earn",
      "Explode your business",
      "Extra income",
      "Fantastic deal",
      "Fast cash",
      "Fast Viagra delivery",
      "Financial freedom",
      "Financially independent",
      "Find out anything",
      "For free",
      "For instant access",
      "For only",
      "For just $",
      "F r e e",
      "Free access",
      "Free cell phone",
      "Free consultation",
      "Free DVD",
      "Free grant money",
      "Free gift",
      "Free hosting",
      "Free instant",
      "Free installation",
      "Free investment",
      "Free leads",
      "Free membership",
      "Free money",
      "Free offer",
      "Free preview",
      "Free priority mail",
      "Free quote",
      "Free sample",
      "Free trial",
      "Free website",
      "Full refund",
      "Get it now",
      "Get paid",
      "Get started now",
      "Gift certificate",
      "Give it away",
      "Great offer",
      "Guarantee",
      "Have you been turned down?",
      "Hidden assets",
      "Hidden charges",
      "Home employment",
      "Home based",
      "Homebased business",
      "Human growth hormone",
      "If only it were that easy",
      "Important information regarding",
      "In accordance with laws",
      "Income from home",
      "Increase your sales",
      "Increase sales",
      "Increase traffic",
      "Information you requested",
      "Insurance",
      "Internet market",
      "Internet marketing",
      "Investment",
      "Investment decision",
      "It's effective",
      "Join millions",
      "Laser printer",
      "Life insurance",
      "Limited time",
      "Loans",
      "Loan offer",
      "Long distance phone offer",
      "Lose weight spam",
      "Lower interest rates",
      "Lower monthly payment",
      "Lower your mortgage rate",
      "Lowest insurance rates",
      "Lowest price",
      "Luxury car",
      "Make $",
      "Make money",
      "Mail in order form",
      "Marketing",
      "Marketing solutions",
      "Mass email",
      "Medicine",
      "Meet singles",
      "Member stuff",
      "Message contains disclaimer",
      "Million",
      "Money back",
      "Money making",
      "Month trial offer",
      "More internet traffic",
      "Mortgage rates",
      "Multi level marketing",
      "MLM",
      "Name brand",
      "New customers only",
      "New domain extensions",
      "Nigerian",
      "No age restrictions",
      "No catch",
      "No claim forms",
      "No cost",
      "No credit check",
      "No disappointment",
      "No experience",
      "No fees",
      "No gimmick",
      "No hidden costs",
      "No inventory",
      "No investment",
      "No medical exams",
      "No middleman",
      "No obligation",
      "No purchase necessary",
      "No questions asked",
      "No selling",
      "No strings attached",
      "Not intended",
      "Notspam",
      "Now only",
      "Obligation",
      "Off shore",
      "Offer expires",
      "Offers coupon",
      "Offers extra cash",
      "Offers free (often stolen) passwords",
      "Once in lifetime",
      "One hundred percent free",
      "One hundred percent guaranteed",
      "One time mailing",
      "Online biz opportunity ",
      "Online degree",
      "Online pharmacy",
      "Online marketing",
      "Only $",
      "Opportunity",
      "Order now",
      "Order status",
      "Order today",
      "Orders shipped by priority mail",
      "Outstanding values",
      "Pennies a day",
      "People just leave money laying around",
      "Please read",
      "Potential earnings",
      "Pre-approved",
      "Priority mail",
      "Print form signature",
      "Print out and fax",
      "Prize",
      "Prizes",
      "Produced and sent out",
      "Profits",
      "Promise you",
      "Pure profit",
      "Real thing",
      "Refinance",
      "Removal instructions",
      "Remove in quotes",
      "Remove subject",
      "Removes wrinkles",
      "Reply remove subject",
      "Requires initial investment",
      "Reserves the right",
      "Reverses aging",
      "Risk free",
      "Risk-free",
      "Rolex",
      "Round the world",
      "S 1618",
      "Safeguard notice",
      "Satisfaction guaranteed",
      "Save $",
      "Save big money",
      "Save up to",
      "Score with babes",
      "Section 301",
      "See for yourself",
      "Sent in compliance",
      "Serious cash",
      "Serious only",
      "Shopping spree",
      "Sign up free today",
      "Social security number",
      "Special promotion",
      "Stainless steel",
      "Stock alert",
      "Stock disclaimer statement",
      "Stock pick",
      "Stop snoring",
      "Strong buy",
      "Stuff on sale",
      "Subject to credit",
      "Supplies are limited",
      "Subscribe",
      "Take action",
      "Talks about hidden charges",
      "Talks about prizes",
      "Tells you it's an ad",
      "Terms and conditions",
      "The best rates",
      "The following form",
      "They keep your money - no refund!",
      "They're just giving it away",
      "This isn't junk",
      "This isn't spam",
      "This won't last",
      "Thousands",
      "Trial",
      "Undisclosed recipient",
      "University diplomas",
      "Unlimited",
      "Unsecured credit",
      "Unsecured debt",
      "Urgent",
      "US dollars",
      "Vacation",
      "Vacation offers",
      "Valium",
      "Viagra",
      "Vicodin",
      "Wants credit card",
      "Warranty",
      "We hate spam",
      "We honor all",
      "Weekend getaway",
      "Weight loss",
      "What are you waiting for?",
      "While supplies last",
      "While you sleep",
      "Who really wins?",
      "Why pay more?",
      "Will not believe your eyes",
      "Winner",
      "Winning",
      "Won",
      "Work at home",
      "Work from home",
      "Xanax",
      "XXX",
      "You have been selected",
      "You are a winner",
      "You will not believe your eyes",
      "Your income",
      "You're a winner",
    ],
    triggerTypeList: [],
    triggerLogStatus: [],
  },
  actions: {
    UPDATE_USER_LOGIN_STATUS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(customerApi.updateUserLoginStatus(payload));
      return [err,result];
    },
    GET_PENDING_DISPO: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getMandatoryDispo(), {});
      return [err,result];
    },
    GET_BLOCKED_CREDITORS_AND_CREDIT_TYPES: async (
        { commit, state },
        payload
    ) => {
      let forceRefresh = false;
      if (payload && payload.ForceRefresh) {
        forceRefresh = payload.ForceRefresh;
      }
      if (!forceRefresh && state.blockedCreditorInfo != null) return;

      let err, result;
      [err, result] = await to(customerApi.getBlockedCreditorsAndCreditTypes());
      if (result) {
        commit("SET_BLOCKED_CREDITORS_AND_CREDIT_TYPES", result.Data);
      }
    },
    GET_QUICK_EMAIL_TEMPLATES: async ({ commit, state }, payload) => {
      let forceRefresh = false;
      if (payload && payload.ForceRefresh) {
        forceRefresh = payload.ForceRefresh;
      }
      if (!forceRefresh && state.quickEmailTemplates.length > 0) return;

      let err, result;
      [err, result] = await to(customerApi.getQuickEmailNames());
      if (result) {
        commit("SET_QUICK_EMAIL_TEMPLATES", result.Data);
      }
    },
    INSERT_USER_ACTIVITY: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(customerApi.insertUserActivity(payload));
      return [err, result];
    },

    GET_ACTIVE_POA_CREDITOR: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(customerApi.getActivePoaCreditor());
      if (result) {
        commit("SET_ACTIVE_POA_CREDITOR", result.Data);
      }
    },

    SEND_POA_EFAX: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(customerApi.sendEFax(payload));
      return [err, result];
    },

    GET_AGENT_IP_ADDR: async ({ commit }) => {
      let err, result;

      [err, result] = await to(customerApi.requestAgentIp());
      return [err, result];
    },
    GET_CUSTOMER_SETTLED_CREDITORS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(customerApi.getSettledCreditors(payload));
      return [err, result];
    },
    GET_DISPOSITION_OPTIONS_FOR_ADMIN: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getDispositionOptionsForAdmin());
      if (result) {
        commit("SET_DISPOSITION_OPTIONS_FOR_ADMIN", result.Data);
      }
    },
    GET_CUSTOMER_PRIMARY_PHONE: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(customerApi.getCustomerPrimaryPhone(payload));

      return [err, result];
    },
    GET_UI_SETTINGS: async ({ commit, state }, payload) => {
      let forceRefresh = false;
      if (payload && payload.ForceRefresh) {
        forceRefresh = payload.ForceRefresh;
      }
      if (!forceRefresh && state.allUiSettings.length > 0) return;

      let err, result;

      [err, result] = await to(api.getAllUiSettings());

      if (result) {
        commit("SET_UI_SETTINGS", result.Data);
      }
    },
    GET_SUGGEST_CREDITOR_PATTERN: async ({ commit, state }, payload) => {
      if (state.suggestCreditorNames.length > 0) return;
      let err, result;

      [err, result] = await to(api.suggestCreditorByPattern(payload));

      if (result) {
        commit("SET_SUGGEST_CREDITOR_PATTERN", result.Data);
      }
    },

    GET_ALL_PAYMENT_OPTIONS: async ({ commit, state }, payload) => {
      if (state.paymentOptions.length > 0) return;
      let err, result;

      [err, result] = await to(customerApi.getAllPaymentOptions(payload));

      if (result) {
        commit("SET_ALL_PAYMENT_OPTIONS", result.Data);
      }
    },

    GET_ALL_CREDITOR_CONTACT: async ({ commit, state }, payload) => {
      let forceRefresh = false;
      if (payload && payload.ForceRefresh) {
        forceRefresh = payload.ForceRefresh;
      }
      if (!forceRefresh && state.creditorContactList.length > 0) return;

      let err, result;

      [err, result] = await to(api.getAllCreditorContact(payload));

      if (result) {
        commit("SET_ALL_CREDITOR_CONTACT", result.Data);
      }
    },

    SEND_CREDITOR_FAX: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.sendFax(payload));
      return [err, result];
    },

    GET_CUSTOMER_TAG_TYPE: async ({ commit, state }, payload) => {
      let forceRefresh = false;
      if (payload && payload.ForceRefresh) {
        forceRefresh = payload.ForceRefresh;
      }
      if (!forceRefresh && state.customerTagTypes.length > 0) return;

      let err, result;

      [err, result] = await to(customerApi.getAllCustomerTagTypes());

      if (result) {
        commit("SET_CUSTOMER_TAG_TYPE", result.Data);
      }
    },
    RESET_ASSIGNED_CSR_TASK_ID: async ({ commit }) => {
      commit("SET_ASSIGNED_CSR_TASK_ID", null);
    },
    INSERT_ASSIGNED_LEAD_DATA: async ({ commit }, payload) => {
      if (payload) {
        commit("SET_ASSIGNED_LEAD_DATA", payload);
      }
    },
    UPDATE_ASSIGNED_CSR_TASK_ID: async ({ commit }, payload) => {
      commit("SET_ASSIGNED_CSR_TASK_ID", payload);
    },
    GET_OUTBOUND_DID_LIST: async ({ commit, state }, payload) => {
      if (state.outboundCallerIdList.length > 0) return;
      let err, result;

      [err, result] = await to(api.getCompanyDids(payload));

      if (result) {
        commit("SET_OUTBOUND_DID_LIST", result.Data);
      }
    },
    LOG_ERROR: async ({ commit }, payload) => {
      await to(api.logError(payload));
    },
    UPDATE_INCOMING_CUSTOMER_INFO: async ({ commit, state }, payload) => {
      commit("SET_INCOMING_CUSTOMER_INFO", payload);
    },
    GET_PAUSE_REASON_TYPES: async ({ commit, state }) => {
      if (state.pauseReasonTypes.length > 0) return;
      let err, result;

      [err, result] = await to(api.getPauseReasonTypes());
      if (result) {
        commit("SET_PAUSE_REASON_TYPES", result.Data);
      }
    },
    GET_TIMEZONES: async ({ commit, state }) => {
      if (state.timezones.length > 0) return;
      let err, result;

      [err, result] = await to(api.getTimezones());
      if (result) {
        commit("SET_TIMEZONES", result.Data);
      }
    },
    GET_STATES: async ({ commit, state }) => {
      if (state.states.length > 0) return;
      let err, result;

      [err, result] = await to(api.getStates());
      if (result) {
        commit("SET_STATES", result.Data);
      }
    },
    GET_GENDERS: async ({ commit, state }) => {
      if (state.genders.length > 0) return;
      let err, result;

      [err, result] = await to(api.getGenders());
      if (result) {
        commit("SET_GENDERS", result.Data);
      }
    },
    GET_OFFICE_NAMES: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getOfficeNames());
      if (result) {
        commit("SET_OFFICE_NAMES", result.Data);
      }
    },
    GET_ROLES: async ({ commit, state }, payload) => {
      let forceRefresh = false;
      if (payload && payload.ForceRefresh) {
        forceRefresh = payload.ForceRefresh;
      }
      if (!forceRefresh && state.roles.length > 0) return;

      let err, result;

      [err, result] = await to(api.getRoles());
      if (result) {
        commit("SET_ROLES", result.Data);
      }
    },
    GET_PHONETYPES: async ({ commit, state }) => {
      if (state.phoneTypes.length > 0) return;
      let err, result;

      [err, result] = await to(customerApi.getPhoneTypes());
      if (result) {
        commit("SET_PHONETYPES", result.Data);
      }
    },
    GET_PRIORITYTYPES: async ({ commit, state }) => {
      if (state.priorityTypes.length > 0) return;

      let err, result;

      [err, result] = await to(customerApi.getPriorityTypes());
      if (result) {
        commit("SET_PRIORITYTYPES", result.Data);
      }
    },
    GET_STATUS_NAMES: async ({ commit, state }) => {
      if (state.customerStatuses.length > 0) return;

      let err, result;

      [err, result] = await to(api.getStatusNames());
      if (result) {
        commit("SET_STATUS_NAMES", result.Data);
      }
    },
    GET_DISPOSITIONTYPES: async ({ commit, state }, payload) => {
      let forceRefresh = false;
      if (payload && payload.ForceRefresh) {
        forceRefresh = payload.ForceRefresh;
      }
      if (!forceRefresh && state.dispositionTypes.length > 0) return;

      let err, result;

      [err, result] = await to(api.getDispositionOptions());
      if (result) {
        commit("SET_DISPOSITIONTYPES", result.Data);
      }
    },

    GET_CAMPAIGNTYPES: async ({ commit, state }) => {
      if (state.campaignTypes.length > 0) return;

      let err, result;

      [err, result] = await to(customerApi.getCampaignTypes());

      if (result) {
        commit("SET_CAMPAIGNTYPES", result.Data);
      }
    },
    GET_CSRTASKSTATUS: async ({ commit, state }) => {
      if (state.csrTaskStatus.length > 0) return;

      let err, result;

      [err, result] = await to(api.getCsrTaskStatus());

      if (result) {
        commit("SET_CSRTASKSTATUS", result.Data);
      }
    },
    GET_NOTIFICATIONS: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(customerApi.getNotifications());

      if (result) {
        if (payload && payload.RemoveAutoCallReminders) {
          let data = [];
          if (result.Data) {
            data = deepCopy(result.Data);
            for (let item of data) {
              if (item.NotificationType.Id == 1) {
                item.IsAutoCallReminder = true;
              }
            }
          }
          commit("SET_NOTIFICATIONS", data);
        } else {
          commit("SET_NOTIFICATIONS", result.Data);
        }
      }
    },

    MODIFY_NOTIFICATIONS: ({ commit }, payload) => {
      commit("SET_MODIFY_NOTIFICATIONS", payload);
    },
    GET_ZIP_DATA: async ({ commit }, zipCode) => {
      let err, result;

      [err, result] = await to(
          customerApi.getZipData({ Type: "getbyid", ZipCode: zipCode })
      );
      if (result) {
        return result;
      }
    },

    GET_USER_ACTIVITY_HISTORY: async ({ commit, state }) => {
      let err, result;
      [err, result] = await to(customerApi.getUserActivityHistory());
      if (result && result.Data && result.Data.length > 0) {
        let data = result.Data;
        let distinctData = Array.from(new Set(data.map((x) => x.Id))).map(
            (id) => {
              return { Id: id, Name: data.find((y) => y.Id === id).Name };
            }
        );

        if (distinctData.length > 0) {
          commit("SET_HISTORY", distinctData.reverse());
        }
      }
    },

    GET_HISTORY: async ({ commit, state }, payload) => {
      let newHistoryList = [];
      if (payload != null) {
        if (payload.Name != "reset") {
          newHistoryList = state.historyList.filter((x) => x.Id != payload.Id);

          if (payload.Name.toLowerCase() != "hot lead") {
            newHistoryList.push({ Id: payload.Id, Name: payload.Name });
          }

          if (newHistoryList.length > 25) newHistoryList.splice(0, 1);
        } else {
          newHistoryList = [];
        }
      } else {
        newHistoryList = state.historyList;
      }
      commit("SET_HISTORY", newHistoryList);
    },
    DELETE_HISTORY: async ({ commit, state }, payload) => {
      let newHistoryList = [];

      newHistoryList = state.historyList.filter((x) => x.id != payload.id);

      commit("SET_HISTORY", newHistoryList);
    },
    GET5_REMINDERS: async ({ commit }, customerId) => {
      let err, result;

      [err, result] = await to(customerApi.getLast5Reminders(customerId));
      return [err, result];
    },
    GET5_DISPOS: async ({ commit }, customerId) => {
      let err, result;

      [err, result] = await to(customerApi.getLast5Dispos(customerId));
      return [err, result];
    },
    ACCEPT_HOT_LEAD: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.hotLeadAccepted(payload));

      return result;
    },
    RESET_STATES: async ({ commit }) => {
      commit("SET_RESET_STATES");
    },
    RESET_IDLE: async ({ commit }) => {
      await to(api.resetIdle());
    },
    GET_SMS_TEMPLATES: async ({ commit, state }, payload) => {
      let forceRefresh = false;
      if (payload && payload.ForceRefresh) {
        forceRefresh = payload.ForceRefresh;
      }
      if (!forceRefresh && state.quickSmsTemplates.length > 0) return;

      let err, result;

      [err, result] = await to(api.getSmsTemplateNames());

      if (result) {
        commit("SET_SMS_TEMPLATES", result.Data);
      }
    },
    GET_DRIP_EMAIL_FROM_TYPE: async ({ commit, state }) => {
      if (state.dripEmailFromTypes.length > 0) return;
      let err, result;

      [err, result] = await to(api.getDripEmailFromTypes());
      if (result) {
        commit("SET_DRIP_EMAIL_FROM_TYPE", result.Data);
      }
    },
    GET_ONLINE_STATUS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.insertPauseLog(payload.PauseReasonId));

      commit("SET_ONLINE_STATUS", payload.IsOnline);
    },

    GET_LAST_CALL_TIME: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getUserLastCallTime());

      if (result) commit("SET_LAST_CALL_TIME", result.Data.LastCallTime);
    },
    UPDATE_LAST_CALL_TIME: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.updateUserLastCallTime());
      if (result) commit("SET_LAST_CALL_TIME", result.Data.LastCallTime);
    },
    UPDATE_PEER_ID: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updatePeerId(payload));

      return [err, result];
    },
    GET_CAMPAIGNLIST: async ({ commit, state }, payload) => {
      if (state.campaignList.length > 0) return;
      let err, result;

      [err, result] = await to(customerApi.getCampaignsV2(payload));

      if (result) commit("SET_CAMPAIGNLIST", result.Data);
    },
    GET_FAV: async ({ commit, state }) => {
      let err, result;

      [err, result] = await to(customerApi.getFavs());
      if (result) {
        commit("SET_FAV", result.Data);
      }
    },
    POSTFAV: async ({ commit, state }, payload) => {
      let err, result;
      let indx = state.favorites.findIndex(
          (x) => x.CustomerId == payload.CustomerId
      );
      if (indx == -1) {
        [err, result] = await to(
            customerApi.insertFav({ CustomerId: payload.CustomerId })
        );
        if (result) {
          let newFav = deepCopy(state.favorites);
          newFav.push(payload);
          commit("SET_FAV", newFav);
        }
      }
    },
    UPDATEFAV: async ({ commit, state }, payload) => {
      let indx = state.favorites.findIndex(
          (x) => x.CustomerId == payload.CustomerId
      );
      if (indx > -1) {
        let newFav = state.favorites.filter(
            (x) => x.CustomerId != payload.CustomerId
        );
        newFav.push(payload);
        commit("SET_FAV", newFav);
      }
    },
    DELETEFAV: async ({ commit, state }, payload) => {
      let err, result;
      let indx = state.favorites.findIndex(
          (x) => x.CustomerId == payload.CustomerId
      );

      if (indx > -1) {
        [err, result] = await to(customerApi.deleteFav({ data: payload }));
        if (result) {
          let newFav = deepCopy(state.favorites);
          newFav.splice(indx, 1);
          commit("SET_FAV", newFav);
        }
      }
    },
    CHECK_ROUTE_NUMBER: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.checkRouteNumber(payload));

      return [err, result];
    },

    GET_SALES_AGENTS: async ({ commit, state }) => {
      if (state.salesAgents.length > 0) return;

      let err, result;

      [err, result] = await to(api.getSalesAgents());

      if (result) {
        commit("SET_SALES_AGENTS", result.Data);
      }
    },
    GET_NEGOTIATORS: async ({ commit, state }) => {
      if (state.negotiators.length > 0) return;

      let err, result;

      [err, result] = await to(api.getNegotiators());

      if (result) {
        commit("SET_NEGOTIATORS", result.Data);
      }
    },
    USPS_VERIFY: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(customerApi.verifyUsps(payload));

      return [err, result];
    },
    GET_CSRS: async ({ commit, state }) => {
      if (state.csrs.length > 0) return;

      let err, result;

      [err, result] = await to(api.getCSRs());

      if (result) {
        commit("SET_CSRS", result.Data);
      }
    },
    GET_TASK_TYPE_NAMES: async ({ commit, state }, payload) => {
      let forceRefresh = false;
      if (payload && payload.ForceRefresh) {
        forceRefresh = payload.ForceRefresh;
      }
      if (!forceRefresh && state.taskTypeNames.length > 0) return;

      let err, result;

      [err, result] = await to(api.getTaskTypesNames());

      if (result) {
        commit("SET_TASK_TYPE_NAMES", result.Data);
      }
    },

    GET_TASK_TRIGGER_TYPE: async ({ state, commit }) => {
      if (state.triggerTypeList.length > 0) return;
      let err, result;

      [err, result] = await to(api.getCsrTaskTriggerType());

      if (result) {
        commit(types.SET_TASK_TRIGGER_TYPE, result.Data);
      }
    },

    GET_CSR_TASK_TRIGGER_LOG_STATUS: async ({ state, commit }) => {
      if (state.triggerLogStatus.length > 0) return;
      let err, result;

      [err, result] = await to(api.getCsrTaskTriggerLogStatus());

      if (result) {
        commit(types.SET_CSR_TASK_TRIGGER_LOG_STATUS, result.Data);
      }
    },

    GET_TASK_ACTION_NAMES: async ({ commit, state }) => {
      if (state.taskActionNames.length > 0) return;

      let err, result;

      [err, result] = await to(api.getTaskActionNames());

      if (result) {
        commit("SET_TASK_ACTION_NAMES", result.Data);
      }
    },
    SEND_EMAIL_MG: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.sendEmailMg(payload));

      return [err, result];
    },
    GET_DASHBOARD_DATA: async ({ commit, state }) => {
      let err, result;

      [err, result] = await to(api.initializeDashboard());

      return [err, result];
    },
    GET_ALL_ACTIVE_EMPLOYEES: async ({ commit, state }, payload) => {
      if (state.allEmployees.length > 0) return;
      let err, result;

      [err, result] = await to(api.getAllActiveEmployees(payload));

      if (result) {
        commit("SET_ALL_ACTIVE_EMPLOYEES", result.Data);
      }
    },

    GET_PENDING_CHANGE_STATUSES: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getPendingChangeStatuses(payload));

      if (result) {
        commit("SET_PENDING_CHANGE_STATUSES", result.Data);
      }
    },
    GET_PENDING_CHANGE_TYPES: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getPendingChangeTypes(payload));

      if (result) {
        commit("SET_PENDING_CHANGE_TYPES", result.Data);
      }
    },

    SEND_ERROR_LOG: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(customerApi.sendHotLeadLog(payload));
      return [err, result];
    },

    GET_HARDSHIPS_LIST: async ({ commit, state }) => {
      if (state.hardshipList.length > 0) return;
      let err, result;
      [err, result] = await to(customerApi.getHardships());
      if (result) {
        commit("SET_HARDSHIPS_LIST", result.Data);
      }
    },
    GET_OCCUPATIONS_LIST: async ({ commit, state }) => {
      if (state.occupationList.length > 0) return;
      let err, result;
      [err, result] = await to(customerApi.getOccupations());
      if (result) {
        commit("SET_OCCUPATIONS_LIST", result.Data);
      }
    },
    GET_ACCOUNT_TYPES_LIST: async ({ commit, state }) => {
      if (state.accountTypeList.length > 0) return;
      let err, result;
      [err, result] = await to(customerApi.getAccountTypes());
      if (result) {
        commit("SET_ACCOUNT_TYPES_LIST", result.Data);
      }
    },
    GET_RESIDENCE_OWNERSHIP_TYPES: async ({ commit, state }) => {
      if (state.residenceOwnershipTypeList.length > 0) return;
      let err, result;
      [err, result] = await to(customerApi.getResidenceOwnershipTypes());
      if (result) {
        commit("SET_RESIDENCE_OWNERSHIP_TYPES", result.Data);
      }
    },
    GET_EXPENSE_TYPES_LIST: async ({ commit, state }) => {
      if (state.expenseTypeList.length > 0) return;
      let err, result;
      [err, result] = await to(customerApi.getExpenseTypes());
      if (result) {
        commit("SET_EXPENSE_TYPES_LIST", result.Data);
      }
    },
    GET_SCRIPTS: async ({ commit, state }) => {
      if (state.scriptList.length > 0) return;
      let err, result;
      [err, result] = await to(api.getUserScript());
      if (result) {
        commit("SET_SCRIPTS", result.Data);
      }
    },

    GET_CREDITOR_ALIAS_LIST: async ({ commit, state }) => {
      if (state.creditorWithAlias.length > 0) return;
      let err, result;
      [err, result] = await to(api.getAllCreditorAndAliases());
      if (result) {
        commit("SET_CREDITOR_ALIAS_LIST", result.Data);
      }
    },

    UPDATE_CSR_TRIGGER_LOG_ANSWERED_USER: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(
          customerApi.updateCsrTriggerLogAnsweredUser(payload)
      );
      return [err, result];
    },

    CHANGE_PASSWORD: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.changePassword(payload));
      return [err, result];
    },

    MFA_VERIFICATION_SEND: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.mfaVerificationSend(payload));
      return [err, result];
    },
    MFA_VERIFICATION_VERIFY: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.mfaVerificationVerify(payload));
      return [err, result];
    },
  },
  mutations: {
    SET_CSR_TASK_TRIGGER_LOG_STATUS: (state, payload) => {
      state.triggerLogStatus = payload;
    },
    SET_TASK_TRIGGER_TYPE: (state, payload) => {
      state.triggerTypeList = payload;
    },
    SET_ACTIVE_POA_CREDITOR: (state, payload) => {
      state.activePoaList = payload;
    },

    SET_DISPOSITION_OPTIONS_FOR_ADMIN: (state, payload) => {
      state.dispositionOptionList = payload;
    },



    SET_CREDITOR_ALIAS_LIST: (state, payload) => {
      state.creditorWithAlias = payload;
    },
    SET_USER_LISTEN_CALL: (state, payload) => {
      state.userListeningCall = payload;
    },
    SET_SUGGEST_CREDITOR_PATTERN: (state, payload) => {
      state.suggestCreditorNames = payload;
    },
    SET_ALL_PAYMENT_OPTIONS: (state, payload) => {
      state.paymentOptions = payload;
    },

    SET_ALL_CREDITOR_CONTACT: (state, payload) => {
      state.creditorContactList = payload;
    },

    SET_HARDSHIPS_LIST: (state, payload) => {
      state.hardshipList = payload;
    },
    SET_OCCUPATIONS_LIST: (state, payload) => {
      state.occupationList = payload;
    },
    SET_ACCOUNT_TYPES_LIST: (state, payload) => {
      state.accountTypeList = payload;
    },
    SET_RESIDENCE_OWNERSHIP_TYPES: (state, payload) => {
      state.residenceOwnershipTypeList = payload;
    },
    SET_EXPENSE_TYPES_LIST: (state, payload) => {
      state.expenseTypeList = payload;
    },
    SET_SCRIPTS: (state, payload) => {
      state.scriptList = payload;
    },

    SET_ASSIGNED_LEAD_DATA: (state, payload) => {
      state.assignedLeadData = payload;
    },
    SET_PENDING_CHANGE_STATUSES: (state, payload) => {
      state.pendingChangeStatusesList = payload;
    },
    SET_PENDING_CHANGE_TYPES: (state, payload) => {
      state.pendingChangeTypesList = payload;
    },

    SET_ASSIGNED_CSR_TASK_ID: (state, payload) => {
      state.assignedCsrTaskId = payload;
    },
    SET_TASK_TYPE_NAMES: (state, payload) => {
      state.taskTypeNames = payload;
    },
    SET_TASK_ACTION_NAMES: (state, payload) => {
      state.taskActionNames = payload;
    },

    SET_OUTBOUND_DID_LIST: (state, payload) => {
      state.outboundCallerIdList = payload;
    },
    SET_INCOMING_CUSTOMER_INFO: (state, payload) => {
      state.incomingCustomerInfo = payload;
    },
    SET_LAST_CALL_TIME: (state, payload) => {
      state.userLastCallTime = payload;
    },
    SET_ONLINE_STATUS: (state, payload) => {
      state.isUserOnline = payload;
    },
    SET_DRIP_EMAIL_FROM_TYPE: (state, payload) => {
      state.dripEmailFromTypes = payload;
    },
    SET_TIMEZONES: (state, payload) => {
      state.timezones = payload;
    },
    SET_STATES: (state, payload) => {
      state.states = payload;
    },
    SET_GENDERS: (state, payload) => {
      state.genders = payload;
    },
    SET_OFFICE_NAMES: (state, payload) => {
      state.offices = payload;
    },
    SET_ROLES: (state, payload) => {
      state.roles = payload;
    },
    SET_PHONETYPES: (state, payload) => {
      state.phoneTypes = payload;
    },
    SET_PRIORITYTYPES: (state, payload) => {
      state.priorityTypes = payload;
    },
    SET_STATUS_NAMES: (state, payload) => {
      state.customerStatuses = payload;
    },
    SET_VEHICLETYPES: (state, payload) => {
      state.vehicleTypes = payload;
    },
    SET_DISPOSITIONTYPES: (state, payload) => {
      state.dispositionTypes = payload;
    },
    SET_CAMPAIGNTYPES: (state, payload) => {
      state.campaignTypes = payload;
    },
    SET_NOTIFICATIONS: (state, payload) => {
      state.pendingNotifications = payload;
    },
    SET_MODIFY_NOTIFICATIONS: (state, payload) => {
      state.pendingNotifications = payload;
    },
    SET_HISTORY: (state, payload) => {
      state.historyList = payload;
    },
    SET_SMS_TEMPLATES: (state, payload) => {
      state.quickSmsTemplates = payload;
    },
    SET_QUICK_EMAIL_TEMPLATES: (state, payload) => {
      state.quickEmailTemplates = payload;
    },

    SET_PAUSE_REASON_TYPES: (state, payload) => {
      state.pauseReasonTypes = payload;
    },
    SET_CAMPAIGNLIST: (state, payload) => {
      state.campaignList = payload;
    },
    SET_CSRTASKSTATUS: (state, payload) => {
      state.csrTaskStatus = payload;
    },

    SET_FAV: (state, payload) => {
      state.favorites = payload;
    },
    SET_SALES_AGENTS: (state, payload) => {
      state.salesAgents = payload;
    },
    SET_NEGOTIATORS: (state, payload) => {
      state.negotiators = payload;
    },
    SET_CSRS: (state, payload) => {
      state.csrs = payload;
    },
    SET_ALL_ACTIVE_EMPLOYEES: (state, payload) => {
      state.allEmployees = payload;
    },
    SET_CUSTOMER_TAG_TYPE: (state, payload) => {
      state.customerTagTypes = payload;
    },
    SET_UI_SETTINGS: (state, payload) => {
      state.allUiSettings = payload;
    },

    SET_BLOCKED_CREDITORS_AND_CREDIT_TYPES: (state, payload) => {
      state.blockedCreditorInfo = payload;
    },

    SET_RESET_STATES: (state) => {
      state.states = [];
      state.timezones = [];
      state.genders = [];
      state.roles = [];
      state.phoneTypes = [];
      state.priorityTypes = [];
      state.customerStatuses = [];
      state.vehicleTypes = [];
      state.dispositionTypes = [];
      state.campaignTypes = [];
      state.pendingNotifications = [];
      state.quickSmsTemplates = [];
      state.quickEmailTemplates = [];
      state.pauseReasonTypes = [];
      state.isUserOnline = false;
      state.userLastCallTime = null;
      state.outboundCallerIdList = [];
      state.campaignList = [];
      state.favorites = [];
      state.salesAgents = [];
      state.negotiators = [];
      state.csrs = [];
      state.allEmployees = [];
      state.pendingChangeStatusesList = [];
      state.pendingChangeTypesList = [];
      state.hardshipList = [];
      state.occupationList = [];
      state.accountTypeList = [];
      state.residenceOwnershipTypeList = [];
      state.expenseTypeList = [];
      state.scriptList = [];
      state.customerTagTypes = [];
      state.creditorContactList = [];
      state.paymentOptions = [];
      state.suggestCreditorNames = [];
      state.userListeningCall = false;
      state.creditorWithAlias = [];
      state.allUiSettings = [];
  
      state.dispositionOptionList = [];
      state.activePoaList = [];
      state.triggerLogStatus = [];
      state.triggerTypeList = [];
      state.blockedCreditorInfo = null;
    },
  },
};