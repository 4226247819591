<template>
  <div>
    <div class="noPadding" v-if="!(csrTaskId > 0 || creditorId > 0)">
      <ul class="nav nav-tabs">
        <li
          v-for="tab in tabs"
          :key="tab.id"
          class="nav-item customer-notes-tab-item"
          v-showIfAuthorized="tab.authorizationId"
        >
          <a
            data-toggle="tab"
            :aria-expanded="tab.active"
            v-on:click.prevent="() => handleTabChange(tab.name)"
            class="tab-link"
            :class="{ active: tab.active }"
          >
            <span class="d-sm-inline-block tab-item-label">
              {{ tab.label }}
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div v-show="tabs[0].active && checkAuth(1672)" class="row">
      <div
        v-if="checkAuth(3816)"
        class="col-xl-8 col-lg-8 col-md-6 col-sm-12"
        style="color: black"
      >
        <vue-editor
          class="vueEditor"
          id="textEditor-cn"
          v-model="editorBody"
          :style="'height:' + getEditorHeight() + 'px;'"
          @ready="onEditorReady($event)"
          @focus="setFocus($event)"
          @selection-change="selectionChange($event)"
          @text-change="textChange($event)"
          :editorToolbar="customToolbar"
        >
        </vue-editor>

        <button
          @click="addNote()"
          type="button"
          title="Search"
          class="btn text-light rounded float-right cursor-pointer"
          style="
            position: absolute;
            bottom: 10px;
            right: 20px;
            background-color: #3498db;
            cursor: pointer;
          "
        >
          Add Note
        </button>
      </div>

      <div v-if="checkAuth(3816)" class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
        <label class="panel-heading">Quick Notes</label>
        <input
          type="text"
          class="form-control mb-1"
          v-model="quickNoteFilter"
          placeholder="Filter Notes"
        />
        <perfect-scrollbar class="textScroll">
          <div
            @click="addQuickNote(item)"
            style="cursor: pointer"
            class="quickNoteItem"
            v-for="(item, index) in filteredQuickNotes"
            :key="item.Id"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]"
          >
            <span>
              <span style="margin-left: 8px">
                {{ item.Title }}
                <button
                  style="background-color: #1abc9c"
                  v-if="copying && copyingItemId == item.Id"
                  type="button"
                  class="btn btn-xs waves-effect waves-light float-right ml-2"
                >
                  <i class="mdi mdi-check-all"></i>Copied
                </button>
              </span>
            </span>
          </div>
          <section
            v-if="filteredQuickNotes.length == 0"
            class="section mt-5"
            style="text-align: center"
          >
            <div>
              <p class="noMargin">
                <i
                  style="font-size: 24px; color: #939393"
                  class="ri-question-line"
                ></i>
              </p>
              <p class="noMargin">Nothing here.</p>
            </div>
          </section>
        </perfect-scrollbar>
      </div>
      <div class="col-md-12">
        <perfect-scrollbar
          :style="'max-height:' + getNoteGridHeight() + 'px;'"
          style="border-bottom: 1px solid #e4e4e4; position: relative"
        >
          <div
            class="datagrid-table"
            style="
              overflow-y: auto;
              overflow-x: hidden;
              font-size: 12px !important;
              border-radius: 0 !important;
              border-bottom: none !important;
            "
          >
            <div v-if="isLoading" class="loading">
              <div class="text-center" style="margin-top: 50px">
                <i class="spinner-grow text-info m-2"></i>
              </div>
            </div>
            <o-table
              v-if="tableRefreshForAgentFilter"
              :data="
                csrTaskId > 0 ||
                selectedTaskId > 0 ||
                creditorId > 0 ||
                selectedCreditorId > 0 ||
                currentAgentId > 0
                  ? taskAndCreditorNotes
                  : filteredNotes
              "
            >
              <o-table-column
                field="UserName"
                label="User"
                sortable
                searchable
                width="30%"
                position="centered"
              >
                <template #default="props">
                  <div style="width: 200px">
                    {{ props.row.UserName }}
                  </div>
                </template>
                <template #header>
                  User
                  <span
                    class="clearFilterButton ml-1"
                    v-if="currentAgentId > 0"
                  >
                    {{ currentAgentName }}
                    <i
                      v-if="currentAgentId > 0"
                      @click="cancelAgentFilter()"
                      style="padding: 0 5px"
                      class="fe-x"
                    ></i>
                  </span>
                </template>
              </o-table-column>
              <o-table-column
                field="UserRoles"
                label="User Roles"
                sortable
                searchable
                width="20%"
                position="centered"
                v-slot="props"
              >
                {{ parseUserRoles(props.row.UserRoles) }}
              </o-table-column>
              <o-table-column
                field="Note"
                label="Note"
                sortable
                searchable
                :style="'color:red !important'"
              >
                <template #default="props">
                  <div style="position: relative">
                    <span
                      v-if="
                        props.row.CsrTaskId &&
                        (csrTaskId == null || csrTaskId == 0)
                      "
                      @click="filterByTaskId(props.row.CsrTaskId)"
                      style="color: #1d1dfb; cursor: pointer"
                      >(Task#{{ props.row.CsrTaskId }})</span
                    >
                    <span
                      v-if="
                        props.row.CreditorId &&
                        (creditorId == null || creditorId == 0)
                      "
                      @click="filterByCreditorId(props.row.CreditorId)"
                      style="color: #1d1dfb; cursor: pointer"
                    >
                      Creditor:
                      {{ getCreditorDescription(props.row.CreditorId) }}</span
                    >
                    <span
                      style="display: block; overflow-x: auto"
                      class="noteContent"
                      v-html="props.row.Note"
                    ></span>
                  </div>
                </template>
                <template #header>
                  Note
                  <span
                    v-on:click.prevent.stop="
                      () => {
                        return;
                      }
                    "
                    v-if="csrTaskId > 0 || selectedTaskId > 0"
                    class="clearFilterButton ml-1"
                    style="min-width: 110px; max-width: 120px"
                  >
                    Task:# {{ csrTaskId > 0 ? csrTaskId : selectedTaskId }}
                    <i
                      v-on:click.prevent.stop="cancelTaskFilter()"
                      style="padding: 0 5px"
                      v-if="selectedTaskId > 0"
                      class="fe-x"
                    ></i>
                  </span>
                  <span
                    v-on:click.prevent.stop="
                      () => {
                        return;
                      }
                    "
                    v-if="creditorId > 0 || selectedCreditorId > 0"
                    class="clearFilterButton ml-1"
                    style="min-width: 110px; max-width: 120px"
                  >
                    Creditor: {{ getCreditorDescription(0) }}
                    <i
                      v-on:click.prevent.stop="cancelCreditorFilter()"
                      style="padding: 0 5px"
                      v-if="selectedCreditorId > 0"
                      class="fe-x"
                    ></i>
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="CreatedDate"
                label="Date"
                sortable
                position="centered"
                width="20%"
                v-slot="props"
              >
                {{ formatGridDate(props.row.CreatedDate) }}
              </o-table-column>
            </o-table>
            <section
              v-if="filteredNotes.length == 0"
              class="section"
              style="text-align: center"
            >
              <div class="content has-text-grey has-text-centered mb-4">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
            </section>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <!-- <div
      v-show="tabs[1].active && checkAuth(11824)"
      class="col-md-12 noPadding"
    >
      <div
        class="datagrid-table creditorTable"
        style="
          min-height: 300px;
          overflow-y: auto;
          overflow-x: hidden;
          font-size: 12px !important;
        "
      >
        <o-table :data="orderedPortalMessages">
          <o-table-column
            field="From"
            label="From"
            sortable
            searchable
            position="centered"
            width="20%"
            v-slot="props"
          >
            {{ props.row.From }}
          </o-table-column>
          <o-table-column
            field="Message"
            label="Message"
            sortable
            searchable
            width="65%"
            v-slot="props"
          >
            <span
              style="overflow-x: auto; display: block"
              class="noteContent"
              v-html="props.row.Message"
            ></span>
          </o-table-column>

          <o-table-column
            field="SendDate"
            label="Send Date"
            sortable
            position="centered"
            width="15%"
            v-slot="props"
          >
            {{
              props.row.SendDate != null
                ? new Date(props.row.SendDate).toLocaleString()
                : ""
            }}
          </o-table-column>
        </o-table>
        <section
          v-if="orderedPortalMessages.length == 0"
          class="section"
          style="text-align: center"
        >
          <div class="content has-text-grey has-text-centered mb-4">
            <p class="noMargin">
              <i
                style="font-size: 24px; color: #939393"
                class="ri-question-line"
              ></i>
            </p>
            <p class="noMargin">Nothing here.</p>
          </div>
        </section>
      </div>
    </div>
    <div
      v-show="tabs[2].active && checkAuth(11824)"
      class="col-md-12 noPadding"
    >
      <div
        class="datagrid-table creditorTable authTable"
        style="
          min-height: 300px;
          overflow-y: auto;
          overflow-x: hidden;
          font-size: 12px !important;
        "
      >
        <o-table :data="orderedPortalAuths">
          <o-table-column
            field="From"
            label="From"
            sortable
            searchable
            position="centered"
            width="20%"
          >
            System -Portal
          </o-table-column>
          <o-table-column
            field="Note"
            label="Message"
            sortable
            searchable
            width="65%"
            v-slot="props"
          >
            <span
              style="display: block; overflow-x: auto"
              class="noteContent"
              v-html="props.row.Note"
            ></span>
          </o-table-column>

          <o-table-column
            field="CreatedDate"
            label="Send Date"
            sortable
            position="centered"
            width="15%"
            v-slot="props"
          >
            {{
              props.row.CreatedDate != null
                ? new Date(props.row.CreatedDate).toLocaleString()
                : ""
            }}
          </o-table-column>
        </o-table>
        <section
          v-if="orderedPortalAuths.length == 0"
          class="section"
          style="text-align: center"
        >
          <div class="content has-text-grey has-text-centered mb-4">
            <p class="noMargin">
              <i
                style="font-size: 24px; color: #939393"
                class="ri-question-line"
              ></i>
            </p>
            <p class="noMargin">Nothing here.</p>
          </div>
        </section>
      </div>
    </div> -->
  </div>
</template>

<script>
import { VueEditor } from "vue3-editor";
import types from "./types";
import { mapState } from "vuex";
import _orderby from "lodash";
import deepCopy from "@/helpers/deepCopy";
import placeholderTypes from "@/components/Placeholders/types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
export default {
  name: "ClientNotes",
  components: {
    VueEditor,
  },
  props: {
    customerId: {
      Type: Number,
      Default: () => 0,
    },
    isPopup: {
      Type: Boolean,
      default: null,
    },
    tabClicked: {
      Type: Boolean,
      Default: false,
    },
    csrTaskId: {
      Type: Number,
      Default: () => null,
    },
    creditorId: {
      Type: Number,
      Default: () => null,
    },
    creditorName: {
      Type: String,
      Default: () => "",
    },
    quickNotesImported: {
      Type: Array,
      Default: () => [],
    },
    notesImported: {
      Type: Array,
      Default: () => [],
    },
    agentId: {
      Type: Number,
      Default: () => null,
    },
    agentName: {
      Type: Number,
      Default: () => null,
    },
  },
  watch: {
    tabClicked(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.watcherRefresh();
      }
    },
  },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      tableRefreshForAgentFilter: true,
      currentAgentId: this.agentId,
      currentAgentName: this.agentName,
      isLoading: false,
      tabs: [
        {
          id: 1,
          name: "clientNotes",
          label: "Client Notes",
          active: true,
          authorizationId: 1672,
        },
        // {
        //   id: 2,
        //   name: "portalMessages",
        //   label: "Portal Messages",
        //   active: false,
        //   authorizationId: 11824,
        // },
        // {
        //   id: 3,
        //   name: "portalAutorizations",
        //   label: "Portal Authorizations",
        //   active: false,
        //   authorizationId: 11824,
        // },
      ],
      datetime: new Date(),
      quickNoteFilter: "",
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],

        [{ color: [] }, { background: [] }],
      ],
      cursorPosition: 0,
      editorBody: "",
      userName: "",
      userId: 0,
      copying: false,
      copyingItemId: 0,
      notes: [],
      portalNoteList: [],
      quickNotes: [],
      selectedTaskId: 0,
      selectedCreditorId: 0,
    };
  },

  computed: mapState({
    orderedPortalMessages() {
      return _orderby.orderBy(this.portalNoteList, "SendDate", "desc");
    },
    customerNotes() {
      return this.notes.filter((x) => x.NoteTypeId != 3);
    },
    filteredNotes() {
      return _orderby.orderBy(this.customerNotes, "CreatedDate", "desc");
    },
    taskAndCreditorNotes() {
      let rtn = this.customerNotes;
      if (this.csrTaskId > 0) {
        rtn = rtn.filter(
          (x) => x.CsrTaskId != null && x.CsrTaskId == this.csrTaskId
        );
      } else if (this.creditorId > 0) {
        rtn = rtn.filter(
          (x) => x.creditorId != 0 && x.CreditorId == this.creditorId
        );
      } else if (this.selectedTaskId > 0) {
        rtn = rtn.filter((x) => x.CsrTaskId == this.selectedTaskId);
      } else if (this.selectedCreditorId > 0) {
        rtn = rtn.filter(
          (x) => x.CreditorId != 0 && x.CreditorId == this.selectedCreditorId
        );
      } else if (this.currentAgentId > 0) {
        rtn = rtn.filter((x) => x.UserId == this.currentAgentId);
      }

      return _orderby.orderBy(rtn != null ? rtn : [], "CreatedDate", "desc");
    },
    orderedPortalAuths() {
      return _orderby.orderBy(
        this.notes.filter((x) => x.NoteTypeId == 3),
        "CreatedDate",
        "desc"
      );
    },
    filteredQuickNotes() {
      return this.quickNotes.filter(
        (item) =>
          item.Title.toLowerCase().indexOf(this.quickNoteFilter.toLowerCase()) >
          -1
      );
    },
  }),
  unmounted() {
    window.removeEventListener("resize", this.resizeNoteContent);
  },
  async mounted() {
    //  window.addEventListener("resize", this.resizeGridHeight);
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo) {
      this.userName = userInfo.screenName;
      this.userId = userInfo.userId;
    }

    if (this.isPopup || this.csrTaskId > 0 || this.creditorId > 0) {
      await this.watcherRefresh();
    }

    window.addEventListener("resize", this.resizeNoteContent);
  },
  methods: {
    async cancelAgentFilter() {
      this.currentAgentId = 0;
      this.tableRefreshForAgentFilter = false;
      await this.sleep(5);
      this.tableRefreshForAgentFilter = true;
    },

    getCreditorDescription(cid) {
      let rtn = "N/A";
      let creditorId =
        cid > 0
          ? cid
          : this.creditorId > 0
          ? this.creditorId
          : this.selectedCreditorId;
      let indx = this.customerNotes.findIndex(
        (x) => x.CreditorId == creditorId
      );
      if (indx > -1) {
        rtn =
          this.customerNotes[indx].CreditorName +
          " - " +
          this.customerNotes[indx].CreditorAccount;
      } else {
        rtn = this.creditorName != "" ? this.creditorName : "N/A";
      }

      return rtn;
    },
    resizeNoteContent() {
      let activeTabs = this.tabs.filter((x) => x.active == true);
      let tab = activeTabs.length > 0 ? activeTabs[0] : null;
      let tableWidth = parseInt(window.innerWidth - 240);

      let content = document.getElementsByClassName("noteContent");
      if (content && content.length > 0) {
        for (let i = 0; i < content.length; i++) {
          if (tab.id == 1) {
            let w = parseInt(tableWidth * 0.4);
            if (w > 0) {
              content[i].style.width = w + "px";
            } else {
              content[i].style.width = "400px";
            }
          } else {
            let w = parseInt(tableWidth * 0.65);
            if (w > 0) {
              content[i].style.width = w + "px";
            } else {
              content[i].style.width = "400px";
            }
          }
        }
      }
    },
    //  resizeGridHeight() {

    //   let windowHeight = window.innerHeight;
    //   this.gridHeight = parseInt(windowHeight*0.40);

    // },
    filterByTaskId(taskId) {
      if (this.csrTaskId > 0) return;

      if (taskId > 0) {
        this.selectedTaskId = taskId;
      }
    },
    filterByCreditorId(creditorId) {
      if (this.creditorId > 0) return;

      if (creditorId > 0) {
        this.selectedCreditorId = creditorId;
      }
    },
    cancelTaskFilter() {
      this.selectedTaskId = 0;
    },
    cancelCreditorFilter() {
      this.selectedCreditorId = 0;
    },
    getEditorHeight() {
      let rtn = 268;
      if (this.isPopup) {
        rtn = 268;
      } else if (this.csrTaskId > 0) {
        rtn = 268;
      }

      return rtn;
    },
    getNoteGridHeight() {
      let rtn = 1000;
      if (this.isPopup) {
        rtn = 5000;
      } else if (this.csrTaskId > 0) {
        rtn = 5000;
      }

      return rtn;
    },
    parseUserRoles(userRoles) {
      let rtn = "-";
      if (userRoles != null) {
        if (userRoles.length > 0) {
          if (userRoles.length == 1) {
            rtn = userRoles[0];
          } else {
            rtn = userRoles.join(", ");
          }
        }
      }
      return rtn;
    },
    async watcherRefresh() {
      if (this.quickNotesImported && this.quickNotesImported.length > 0) {
        this.quickNotes = deepCopy(this.quickNotesImported);
      } else if (this.quickNotes.length == 0) {
        let qErr, qResult;
        [qErr, qResult] = await this.$store.dispatch(types.GET_QUICKNOTE_LIST);
        if (qResult && qResult.Data) {
          this.quickNotes = deepCopy(qResult.Data);
        }
      }

      if (this.notesImported && this.notesImported.length > 0) {
        this.notes = deepCopy(this.notesImported);
      } else {
        let payload = { Id: this.customerId };
        if (this.creditorId > 0) {
          payload.CreditorId = this.creditorId;
        }
        if (this.csrTaskId) {
          payload.CsrTaskId = this.csrTaskId;
        }
        this.isLoading = true;
        let err, result;
        [err, result] = await this.$store.dispatch(types.GET_NOTES, payload);
        if (result && result.Data) {
          this.notes = deepCopy(result.Data);
        }
      }
      this.isLoading = false;

      let activeTabIndex = this.tabs.findIndex((x) => x.active);

      if (activeTabIndex > -1) {
        this.handleTabChange(this.tabs[activeTabIndex].name);
      }
    },

    async handleTabChange(tabName) {
      let temp = [...this.tabs];

      // if (tabName == "portalMessages") {
      //   let err, result;

      //   [err, result] = await this.$store.dispatch(types.GET_PORTAL_NOTE, {
      //     CustomerId: this.customerId,
      //   });
      //   if (result && result.Data) {
      //     this.portalNoteList = deepCopy(result.Data);
      //   }
      // }

      temp = temp.map((item) => {
        item.active = item.name === tabName ? true : false;
        return item;
      });
      this.tabs = [...temp];
      await this.sleep(500);
      this.resizeNoteContent();
    },
    async addNote() {
      let text = this.quill.getText();

      if (text.trim().length == 0) {
        this.$swal("Warning!", "Note field is required", "warning");
        return;
      }

      let err, result;

      let payload = {
        CustomerId: this.customerId,
        CsrTaskId: this.csrTaskId,
        CreditorId: this.creditorId,
        Note: this.editorBody,
      };
      [err, result] = await this.$store.dispatch(types.ADD_NOTE, payload);
      if (result) {
        let noteItem = {
          Id: result.Data.Id,
          Note: this.editorBody,
          CsrTaskId: this.csrTaskId,
          CreditorId: this.creditorId,
          CreditorName: result.Data.CreditorName,
          CreditorAccount: result.Data.CreditorAccountNumber,
          NoteTypeId: 1,
          CreatedDate: new Date().toISOString(),
          UserName: this.userName,
          UserRoles: result.Data.UserRoles ? result.Data.UserRoles : [],
        };

        this.notes.unshift(noteItem);
        this.editorBody = "";
        this.$swal("Success", "Note added.", "success");
        this.$emit("noteAdded", noteItem);
      } else {
        this.$swal("Error", "An error occured!", "error");
      }
    },

    async addQuickNote(item) {
      this.copying = true;
      this.copyingItemId = item.Id;
      let data = {
        Text: item.Body,
        UserId: this.userId,
        CustomerId: this.customerId,
      };

      let err, result;

      [err, result] = await this.$store
        .dispatch(placeholderTypes.PROCESS_PLACEHOLDERS, data)
        .catch((err) => {});

      if (result) {
        let placeholderData = result.Data.Text;
        navigator.clipboard.writeText(placeholderData);
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      await this.sleep(500);
      this.copying = false;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    setFocus(event) {
      this.quill = event;
    },
    onEditorReady(event) {
      this.quill = event;
    },
    selectionChange(event) {
      if (event && event.index) this.cursorPosition = event.index;
    },
    textChange(event) {
      if (event && event.ops) {
        if (event.ops.length > 0) this.cursorPosition = event.ops[0].retain;
      }
    },
  },
};
</script>

<style>
.datagrid-table strong {
  font-weight: 700 !important;
}
.textScroll {
  border: 1px solid #ccc;
  height: 213px !important;
}
.vueEditor {
  width: 100%;
  height: 200px;
  position: relative;
}

.o-table__td:hover {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
  transform: scale(1.02);
}

.panel-heading {
  width: 100%;
  height: 50px;
  padding: 0.5em 1em !important;
  background-color: #f8f8f8 !important;
  border: 1px solid #ccc !important;
}
.ql-toolbar.ql-snow {
  background-color: #f8f8f8;
}
.bottomScreen {
  border: 1px solid #ccc !important;
  background-color: #f8f8f8;
}

.bottomHeaderText {
  color: #363636;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
  padding-bottom: 10px;
}
.quickNotes {
  padding-right: 5px !important;
}

.quickNoteItem {
  width: 100%;

  padding: 10px 2%;
}
.notePanel #textEditor-cn {
  height: 268px;
  max-height: 268px;
}
</style>
