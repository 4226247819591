<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'UserTimeLogReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @setPagination="setPagination"
      @clearReport="clearReport"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredUserTimeLogReport"
        detailed
        detail-key="employeeName"
        :paginated="isPaginated && filteredUserTimeLogReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="employeeName"
          label="Employee Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="employeeNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.employeeName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.employeeName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="totalLoginTime"
          label="Total Login Time"
          sortable
        >
          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.totalLoginTime }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatSecondForUserTimeLogReport(props.row.totalLoginTime * 60) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="totalOnlineTime"
          label="Total Online Time"
          sortable
        >
          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.totalOnlineTime }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatSecondForUserTimeLogReport(props.row.totalOnlineTime * 60) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="totalAwayTime" label="Total Away Time" sortable>
          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.totalAwayTime }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatSecondForUserTimeLogReport(props.row.totalAwayTime * 60) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="totalOnCallTime"
          label="Total On Call Time"
          sortable
        >
          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.totalOnCallTime }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatSecondForUserTimeLogReport(props.row.totalOnCallTime * 60) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="totalPendingDispoTime"
          label="Total Pending Dispo Time"
          sortable
        >
          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.totalPendingDispoTime }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatSecondForUserTimeLogReport(props.row.totalPendingDispoTime * 60) }}
            </span>
          </template>
        </o-table-column>

        <template v-slot:detail="props">
          <div
            v-if="props.row.Details"
            class="detailsDiv"
            v-html="createDetailsTable(props.row.Details)"
          ></div>
          <div v-if="!props.row.Details" class="detailsDiv">
            No details available.
          </div>
        </template>
      </o-table>
      <section
        v-if="filteredUserTimeLogReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import globalTypes from "@/store/types";
import draftChangeTypes from "@/views/agent/EditClient/DraftChanges/types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "UserTimeLogReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      userInfo: null,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      userTimeLogReportInitSummary: {
        employeeName: "",
        totalPendingDispoTime: "",
        totalOnCallTime: "",
        totalAwayTime: "",
        totalLoginTime: "",
        totalOnlineTime: "",
      },

      employeeNameFilter: "",
    };
  },
  computed: mapState({
    userLogTimeList: (state) => state.reports.userLogTimeList,

    filteredUserTimeLogReport() {
      let rtn = [];

      let tmpList =
        this.userLogTimeList.length > 0
          ? this.userLogTimeList.filter((item) => {
              return this.filterString(
                item.employeeName,
                this.employeeNameFilter
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }

      let totalCustomer = tmpList.length.toString();

      this.userTimeLogReportInitSummary.employeeName =
        " Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.userTimeLogReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.userTimeLogReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    await this.sleep(1000);
  },
  methods: {
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_USER_TIME_LOG_REPORT, value);
      this.loadingSpinner = false;

      await this.sleep(500);
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },

    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },

    createDetailsTable(data) {
      let html = "";
      if (data && data != {} && data != []) {
        if (Array.isArray(data)) {
          data.forEach((item) => {
            html += "<div class='col-md-12 noPadding'>";

            let columnNames = Object.keys(item);
            columnNames.forEach((c) => {
              if (!Array.isArray(item[c])) {
                const value = item[c];
                if (c === "Value" && !isNaN(value)) {
                  html +=
                    "<label class='mr-2'>" +
                    this.formatSecondForUserTimeLogReport(value * 60) +
                    "</label>";
                } else {
                  html += "<label class='mr-2'>" + value + "</label>";
                }
              } else {
                html += this.createDetailsTable(item[c]);
              }
            });
            html += "</div>";
          });
        } else {
          let columnNames = Object.keys(data);
          columnNames.forEach((c) => {
            if (!Array.isArray(data[c])) {
              const value = data[c];
              if (c === "Value" && !isNaN(value)) {
                html +=
                  "<label class='mr-2'>" +
                  this.formatSecondForUserTimeLogReport(value * 60) +
                  "</label>";
              } else {
                html += "<label class='mr-2'>" + c + ": " + value + "</label>";
              }
            } else {
              if (data[c].length > 0) {
                html +=
                  "<div class='col-md-12 noPadding detailsHeader'>" +
                  c +
                  ":</div>";
                html += this.createDetailsTable(data[c]);
              }
            }
          });
        }
      } else {
        html +=
          "<label class='mr-4'>There is no details for this user.</label>";
      }
      return html;
    },
    createSubTable(data) {
      let html = "";
      if (data.length > 0) {
        data.forEach((x) => {
          html += "<div class='col-md-12 noPadding'>";
          let columnNames = Object.keys(x);

          columnNames.forEach((item) => {
            html += "<label class='mr-4'>" + item + ": " + x[item] + "</label>";
          });
          html += "</div>";
        });
      }

      return html;
    },
  },
};
</script>

<style>
.detailsDiv {
  font-size: 12px !important;
  margin-left: 54px;
  font-weight: 400;
}
.detailsHeader {
  color: #3bafda;
  font-size: 13px;
  font-weight: 600;
}
</style>
