import Vuex from "vuex";
import globals from "./modules/globals";
import employeeManagement from "@/views/admin/EmployeeManagement/store";
import officeManagement from "@/views/admin/OfficeManagement/store";
import statusManagement from "@/views/admin/StatusManagement/store";
import rolesManagement from "@/views/admin/RolesManagement/store";
import clientManagement from "@/views/agent/EditClient/store";
import dispositionManagement from "@/views/admin/DispositionManagement/store";
import customers from "@/views/agent/ClientGrid/store";
import notifications from "@/views/agent/Notification/store";
import dispositions from "@/views/agent/Disposition/store";
import callLogs from "@/views/agent/CallLogs/store";
import phoneManagement from "@/components/PlivoParts/store";
import smsManagement from "@/views/agent/SMS/store";
import leadDistributionManagement from "@/views/admin/LeadDistributionManagement/store";
import quickSmsManagement from "@/views/admin/QuickSmsManagement/store";
import placeholdersManagement from "@/components/Placeholders/store";
import unsubscribeEmailManagement from "@/components/Unsubscribe/store";
import scriptManagement from "@/views/admin/ScriptManagement/store";
import dashboardStore from "@/views/agent/Dashboard/store";
import quickEmailManagement from "@/views/admin/QuickEmailManagement/store";
import quickNoteManagement from "@/views/admin/QuickNoteManagement/store";
import csrTaskActionManagement from "@/views/admin/CSRTaskActionManagement/store";
import csrTaskTypeManagement from "@/views/admin/CSRTaskTypeManagement/store";
import customerNotesManagement from "@/views/agent/EditClient/ClientNotes/store";
import customerTasks from "@/views/agent/EditClient/ClientTasks/store";
import vmDropManagement from "@/views/admin/VMDropManagement/store";
import pauseReasonManagement from "@/views/admin/PauseReasonManagement/store";
import vmRecorderManagement from "@/views/admin/VMRecorderManagement/store";
import campaignManagement from "@/views/admin/CampaignManagement/store";
import draftChanges from "@/views/agent/EditClient/DraftChanges/store";
import csrTaskDistro from "@/views/agent/CsrTaskDistro/store";
import reports from "@/views/reports/store";
import dripEmailManagement from "@/views/admin/DripEmailManagement/store";
import tagManagement from "@/views/admin/TagManagement/store";
import didsManagement from "@/views/admin/DidManagement/store";
import didPurchaseManagement from "@/views/admin/DidPurchaseManagement/store";
import inboundCallRulesManagement from "@/views/admin/InboundCallRulesManagement/store";
import inboundCallSettingsManagement from "@/views/admin/InboundCallSettingsManagement/store";
import outboundCallSettingsManagement from "@/views/admin/OutboundCallSettingsManagement/store";
import uiSettingsManagement from "@/views/admin/UISettingsManagement/store";
import csrTicketDashboard from "@/views/agent/CsrTickets/store";
import smsBrandsManagement from "@/views/admin/SMSBrandsManagement/store";
import customerCancelationReasonsManagement from "@/views/admin/CustomerCancelationReasonsManagement/store";
import blockedNumberManagement from "@/views/admin/BlockedNumberManagement/store";
export default new Vuex.Store({
  modules: {
    customerCancelationReasonsManagement,
    smsBrandsManagement,
    uiSettingsManagement,
    didsManagement,
    didPurchaseManagement,
    inboundCallRulesManagement,
    inboundCallSettingsManagement,
    outboundCallSettingsManagement,
    tagManagement,
    dripEmailManagement,
    reports,
    csrTaskDistro,
    draftChanges,
    globals,
    employeeManagement,
    officeManagement,
    statusManagement,
    rolesManagement,
    clientManagement,
    dispositionManagement,
    customers,
    notifications,
    dispositions,
    callLogs,
    phoneManagement,
    vmRecorderManagement,
    smsManagement,
    campaignManagement,
    leadDistributionManagement,
    quickSmsManagement,
    placeholdersManagement,
    unsubscribeEmailManagement,
    scriptManagement,
    dashboardStore,
    quickEmailManagement,
    quickNoteManagement,
    csrTaskActionManagement,
    csrTaskTypeManagement,
    customerNotesManagement,
    customerTasks,
    vmDropManagement,
    pauseReasonManagement,
    csrTicketDashboard,
    blockedNumberManagement
  },
});
