<template>
  <div class="p-4">
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      "
    >
      <div v-if="isLoading" class="loading">
        <div class="text-center" style="margin-top: 150px">
          <i class="spinner-grow text-info m-2"></i>
        </div>
      </div>
      <i
        style="font-size: 60px; color: #3bafda"
        class="ri-lock-password-fill"
      ></i>
      <label style="font-size: 15px">Multi-Factor Authentication (MFA)</label>

      <div style="display: flex; flex-direction: column; align-items: center">
        <label class="text-muted mt-3">
          Step 2: Enter the 6-Digit Verification Code.
        </label>
        <div class="mt-2" style="width: 100%; text-align: center">
          <input
            type="text"
            placeholder="_  _  _  _  _  _"
            v-myMask="{ mask: ['9  9  9  9  9  9'] }"
            style="
              width: 185px;
              display: inline-block;
              border: none !important;
              font-size: 30px;
            "
            v-model="inputs"
            id="txtCode"
          />
        </div>
        <button
          class="btn btn-primary mt-4"
          style="width: 348px"
          @click="submitAuth"
          :disabled="isLoading"
        >
          Submit
        </button>
        <div style="display: flex; justify-content: center">
          <p class="resendCodeText mt-3" @click="resendCode()">Resend Code</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalTypes from "@/store/types";
import utilitiesMixin from "@/mixins/utilitiesMixin.js";

export default {
  mixins: [utilitiesMixin],
  data() {
    return {
      selectedRadio: "",
      sendVerificationCode: true,
      isLoading: false,
      inputs: "",
    };
  },
  computed: {},
  async mounted() {
    let inputElement = document.getElementById("txtCode");
    if (typeof inputElement != "undefined" && inputElement) {
      inputElement.focus();
    }
    window.addEventListener("keydown", this.handleEnterKey);
  },
  props: {},
  methods: {
    handleEnterKey(event) {
      if (event.key == "Enter") {
        this.submitAuth();
      }
    },
    async resendCode() {
      this.$emit("authCode", false);
      this.inputs = "";
    },

    async submitAuth() {
      this.isLoading = true;
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let mfaToken = null;
      if (userInfo != null) {
        mfaToken = await this.getTokenFromLocalStorage(userInfo.email);
      }
      let payload = {
        Code: this.inputs,
        Token: mfaToken,
      };
      let err, result;
      [err, result] = await this.$store.dispatch(
        globalTypes.MFA_VERIFICATION_VERIFY,
        payload
      );
      this.isLoading = false;
      if (result) {
        this.$router.replace({ name: "home" });
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    handleInput(index, event) {
      let value = event.target.value;

      if (isNaN(value)) {
        this.inputs[index] = null;
        return;
      }

      if (value !== "") {
        this.inputs[index] = value;
        const nextIndex = index + 1;

        if (nextIndex < this.inputs.length) {
          this.$refs["input" + nextIndex].focus();
        }
      }
    },
    handleKeyUp(index, event) {
      if (
        event.key.toLowerCase() === "backspace" ||
        event.key.toLowerCase() === "delete"
      ) {
        this.inputs[index] = null;

        const prevIndex = index - 1;
        if (prevIndex >= 0) {
          this.$refs["input" + prevIndex].focus();
        } else {
          event.preventDefault();
        }
      }
    },
  },
};
</script>

<style>
.splitInputDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.splitInput {
  width: 40px;
  border: none;
  border-bottom: 3px solid rgba(0, 0, 0, 0.5);
  margin: 0 10px;
  text-align: center;
  font-size: 36px;
  cursor: not-allowed;
  pointer-events: none;
}

.splitInput:focus {
  border-bottom: 3px solid #3bafda;
  outline: none;
}

.splitInput:nth-child(1) {
  cursor: pointer;
  pointer-events: all;
}
.resendCodeText {
  font-size: 12px;
  color: #61b1f4;
  cursor: pointer;
}
.resendCodeText:hover {
  color: #a3cff3;
}
</style>
