<template>
  <div id="smsHistoryPanel" class="smsHistoryPanel" v-show="isReady">
    <div class="row">
      <div class="col-md-8">
        <div class="float-left mt-1 mr-3" v-if="customerName != ''">
          <div class="smsCustomerNameLabel ml-1">Customer Name</div>
          <div class="smsCustomerNameText">{{ customerName }}</div>
        </div>
        <select
          :disabled="customerPhones.length < 2"
          v-model="selectedPhone"
          id="ddlCustomerPhones"
          class="form-control generalSelectBox float-left mt-1"
          style="width: 200px padding: 3px"
          @change="phoneChanged($event)"
        >
          <option
            v-for="item in customerPhones"
            :key="item.Id"
            :value="item.PhoneNumber"
          >
            {{
              formatPhone(item.PhoneNumber) +
              " (" +
              (item.IsCoCustomer ? " CoCustomer" : " Customer") +
              (item.Priority == 1 ? " - Primary" : " - Secondary") +
              ")"
            }}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <div class="refreshButton">
          <i
            class="fe-refresh-cw"
            style="
              font-size: 20px;
              color: #3bafda;
              float: right;
              margin: 12px 0;
              cursor: pointer;
            "
            @click="bindLogs()"
          ></i>
        </div>
      </div>
    </div>
    <div style="width: 100%" class="slimScrollDiv scrollPanel">
      <div
        v-if="smsLogs.length == 0"
        class="content has-text-grey has-text-centered"
      >
        <p class="noMargin">
          <i
            style="font-size: 24px; color: #939393"
            class="ri-question-line"
          ></i>
        </p>
        <p class="noMargin">Nothing here.</p>
      </div>
      <perfect-scrollbar
        ref="pscroll"
        v-show="smsLogs.length > 0"
        style="height: 400px"
      >
        <div
          class="smsRow"
          :class="item.IsInBound ? '' : 'outBound'"
          v-for="item in smsLogs"
          :key="item.Id"
        >
          <div class="smsRow noMargin">
            <div class="smsImageWrapper">
              <img
                :src="item.UserPhotoUrl"
                class="smsPanelImage"
                @error="getDefaultImage(item)"
              />
            </div>
            <div style="white-space:pre-wrap; text-overflow: ellipsis; overflow: hidden;" class="smsTextWrapper">
              <span style="font-weight: 600">{{
                item.IsInBound ? item.CustomerName : item.UserName + ": "
              }}</span>
              {{ item.Text }}
              <div
                class="smsRow"
                v-if="item.IsMms && item.MmsMediaUrls.length > 0"
              >
                <a
                  :key="'attc-' + item.MmsMediaUrls.indexOf(url)"
                  class="badge badge-success mr-2"
                  style="cursor: pointer; padding: 6px"
                  target="_blank"
                  :href="url"
                  v-for="url in item.MmsMediaUrls"
                >
                  <i class="fe-external-link"></i>
                  Attachment {{ parseInt(item.MmsMediaUrls.indexOf(url)) + 1 }}
                </a>
              </div>
            </div>
          </div>
          <div
            class="smsRow noMargin"
            :class="item.IsInBound ? 'text-left' : 'text-right'"
          >
            <div v-if="!item.IsInBound" class="smsStatus">
              {{ item.SmsStatus }}
              <i
                v-if="item.SmsStatus == 'delivered'"
                class="fe-check"
                style="font-size: 12px; color: #3bafda"
              ></i>
            </div>
            <div class="smsPanelDate">{{ formatDate(item.SmsDate) }}</div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
    <div class="row" v-if="optinStatusId == 2">
      <div class="quickSmsPanel">
        <div style="width: 50%; float: left">
          <div style="width: 150px; float: left">
            <select
              v-model="selectedQuickSmsId"
              id="quicksms"
              class="form-control quickSmsDropDown"
              @change="quickSmsChange()"
            >
              <option value="null">Select quick sms</option>
              <option
                v-for="item in quickSmsTemplates"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Title }}
              </option>
            </select>
          </div>
        </div>
        <div style="width: 50%; float: right">
          <div style="width: 150px; float: right">
            <input
              type="file"
              ref="image"
              style="display: none"
              accept="image/x-png, image/jpeg, image/jpg, image/gif"
              @click="handleImageClick"
              @change="handleImageChange"
            />
            <div
              type="button"
              v-on:click.prevent.stop="$refs.image.click()"
              class="generalButton btn btn-lg waves-effect waves-light generalButton attachmentButton"
            >
              Add Attachment
            </div>
          </div>
        </div>
      </div>
      <div class="attachmentPanel" v-if="attachmentList.length > 0">
        <div
          class="attachmentItem"
          v-for="item in attachmentList"
          :key="item.Id"
        >
          <i class="fe-paperclip attachmentIcon"></i>
          <span class="attachmentText">{{ item.Name }}</span>
          <i
            class="fe-x attachmentCloseIcon"
            v-on:click.prevent.stop="cancelAttachment(item.Id)"
          ></i>
        </div>
      </div>
      <div class="messagingPanel">
        <div class="row">
          <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 noPadding">
            <textarea
              v-model="smsBody"
              id="Comment"
              class="form-control textareaInput"
              style="border: none"
              rows="6"
            ></textarea>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <button
              type="button"
              @click="sendSms()"
              :disabled="
                sendingSms ||
                optinStatusId != 2 ||
                (smsBody.trim().length == 0 && attachmentList.length == 0)
              "
              style="background-color: #3bafda"
              class="btn generalButton waves-effect width-md waves-light submit-button float-right mb-1"
            >
              <i style="font-size: 14px" class="fas fa-sms"></i>
              <span style="font-size: 14px; font-weight: 400" class="ml-1"
                >Send SMS</span
              >
              <i v-if="sendingSms" class="fas fa-spinner"></i>
            </button>
            <button
              type="button"
              style="background-color: #f1556c"
              @click="optOutNumber()"
              v-if="optinStatusId == 2"
              class="btn generalButton waves-effect width-md waves-light submit-button float-right"
            >
              <i style="font-size: 14px" class="fas fa-ban"></i>
              <span style="font-size: 14px; font-weight: 400" class="ml-1"
                >Opt-Out</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="optinStatusId == 2"></div>
    <div class="row" v-if="optinStatusId == 3">
      <div class="col-md-12">
        <div class="optOutPanel btn-danger">
          This phone number is opted out. Cannot send any SMS until they opt-in
          again via "Yes" message
        </div>
      </div>
    </div>
    <div class="row" v-if="optinStatusId == 1">
      <div class="col-md-12">
        <div class="optOutPanel btn-info">
          Opt-in code sent. Pending approval.
        </div>
      </div>
    </div>
    <div class="row" v-if="optinStatusId == 0">
      <div class="col-md-12 mb-2">
        <div class="optOutPanel">
          <button
            @click="sendOptinRequest()"
            type="button"
            style="width: 100%; background-color: #3bafda"
            class="btn generalButton width-md waves-light submit-button float-right"
          >
            <span style="font-size: 14px; font-weight: 400" class="ml-1"
              >Send Opt-In SMS</span
            >
          </button>
        </div>
      </div>
    </div>
    <div class="row" v-if="optinStatusId == null">
      <div class="col-md-12">
        <div class="optOutPanel btn-danger">
          Error: Optin Status could not get.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import types from "./types";
import placeholdersTypes from "@/components/Placeholders/types";
import quickSmsTypes from "@/views/admin/QuickSmsManagement/types";
import globalTypes from "@/store/types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import _orderby from "lodash";
import { mapState } from "vuex";
import plivoTypes from "@/components/PlivoParts/types";
import deepCopy from "../../../helpers/deepCopy";

export default {
  name: "SmsHistoryPanel",
  props: {
    phoneNumber: {
      type: String,
      default: () => "",
    },
    phoneList: {
      type: Array,
      default: () => [],
    },
    customerId: {
      type: Number,
      default: () => 0,
    },
    languageId: {
      type: Number,
      default: () => null,
    },
    customerName: {
      type: String,
      default: () => "",
    },
  },
  mixins: [formatMixin, utilitiesMixin],
  computed: mapState({
    quickSmsList: (state) => state.quickSmsManagement.quickSmsList,
    outboundCallerIdList: (state) => state.globals.outboundCallerIdList,
    bannedKeywords: (state) => state.globals.bannedKeywords,
  }),
  unmounted() {
    this.$Emitter.off("refreshSmsHistory");
  },
  async mounted() {
    if (this.phoneList.length > 0) {
      this.customerPhones = deepCopy(this.phoneList);
    } else if (this.phoneNumber.length > 0) {
      this.customerPhones = [
        {
          Id: 0,
          PhoneNumber: this.phoneNumber,
          Priority: 1,
          IsCoCustomer: false,
        },
      ];
    }

    if (this.customerPhones.length > 0) {
      let orderedList = _orderby.orderBy(
        this.customerPhones,
        "Priority",
        "asc"
      );
      this.selectedPhone = orderedList[0].PhoneNumber;

      this.bindLogs();
    }
    await this.$store.dispatch(quickSmsTypes.GET_TEMPLATES);
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    await this.$store.dispatch(globalTypes.GET_OUTBOUND_DID_LIST, 3);

    if (this.outboundCallerIdList) {
      this.callerIdList = this.outboundCallerIdList.map((x) =>
        this.cleanUpDid(x.Did)
      );
    }

    if (this.userInfo.roles && this.quickSmsList.length > 0) {
      let activeQuickSmsList = this.quickSmsList.filter((x) => x.IsActive);

      if (activeQuickSmsList.length > 0) {
        let matchList = activeQuickSmsList.filter((x) =>
          this.findCommonElements(
            x.Roles.map((y) => y.Id),
            this.userInfo.roles
          )
        );

        if (matchList.length > 0) {
          this.quickSmsTemplates = matchList.map((item) => {
            return { Id: +item.Id, Title: item.Title };
          });
        }
      }
    }

    this.$Emitter.on("refreshSmsHistory", () => {
      this.bindLogs();
    });
  },
  data() {
    return {
      isReady: false,
      customerPhones: [],
      selectedPhone: "",
      smsLogs: [],
      smsBody: "",
      selectedQuickSmsId: null,
      optinStatusId: null,
      // OPTIN_STATUS:
      //   REQUESTED: 1
      //   OPTED_IN: 2
      //   UNSUBSCRIBED: 3
      sendingSms: false,
      userInfo: null,
      attachmentList: [],
      maxAttachmentLimit: 10,
      acceptedFileTypes: ["jpg", "jpeg", "png", "gif"],
      callerIdList: [],
      quickSmsTemplates: [],
    };
  },
  methods: {
    async sendOptinRequest() {
      let err, result;

      let phone = this.selectedPhone;

      if (phone.startsWith("1")) {
        phone = this.cleanUpDid(phone);
      }

      let callerId = await this.getCallerId(phone);

      if (callerId != "" && phone != "") {
        [err, result] = await this.$store.dispatch(types.SEND_OPTIN_SMS, {
          PhoneNumber: phone,
          From: callerId,
          CustomerId: this.customerId,
          LanguageId: this.languageId,
        });
        if (result) {
          this.optinStatusId = 1;
          this.$swal("Success!", "Opt-in request sent.", "success");
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
    },
    async getCallerId(did) {
      let callerId = "";

      let err, result;
      [err, result] = await this.$store.dispatch(
        plivoTypes.GET_CALLER_ID_BY_CUSTOMER,
        { CustomerId: this.customerId, PhoneNumber: did, IsSms: true }
      );
      if (result) {
        if (result.Data && result.Data.CallerId) {
          callerId = result.Data.CallerId;
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }

      return callerId;
    },
    cleanUpDid(did) {
      let rtn = did;
      if (did.startsWith("1") && did.length == 11) {
        rtn = did.slice(1, did.length);
      }
      return rtn;
    },
    handleImageClick() {
      const input = this.$refs.image;
      input.type = "text";
      input.type = "file";
    },
    async handleImageChange(e) {
      if (e.target.files && e.target.files.length > 0) {
        if (this.attachmentList.length == this.maxAttachmentLimit) {
          this.$swal(
            "Warning!",
            "Maximum 10 attachments allowed per MMS message. Consider sending in multiple messages.",
            "warning"
          );
          e.preventDefault();
          return;
        }

        const file = e.target.files[0];
        if (file) {
          let fileExtension = file.name.split(".").pop();

          if (fileExtension) {
            if (!this.acceptedFileTypes.includes(fileExtension.toLowerCase())) {
              this.$swal(
                "Warning!",
                "File is not a valid image. Only JPG, PNG and GIF files are allowed.",
                "warning"
              );
              e.preventDefault();
              return;
            }
          }

          if (!this.isValidFileSize(file.size, 1)) {
            this.$swal(
              "Warning!",
              "Maximum allowed file size is 1 MB for each MMS attachment. Please resize your file.",
              "warning"
            );
            return;
          }

          if (
            this.attachmentList.filter(
              (x) =>
                x.Name.toLowerCase() ==
                this.cropFileNames(file.name).toLowerCase()
            ).length > 0
          ) {
            this.$swal("Warning!", "This file already exists!", "warning");
            e.preventDefault();
            return;
          }

          let items = _orderby.orderBy(
            this.attachmentList,
            [(x) => x.Id],
            "desc"
          );
          let latestId = items.length > 0 ? items[0].Id : 0;

          const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });
          this.attachmentList.push({
            Id: latestId + 1,
            Name: this.cropFileNames(file.name),
            Data: await toBase64(file),
            Size: file.size,
            Extension: fileExtension,
          });
        }
      }
    },
    isValidFileSize(size, limitMb) {
      let rtn = true;
      if (size > 1024 * 1024 * limitMb) {
        rtn = false;
      }

      return rtn;
    },

    cancelAttachment(id) {
      let indx = this.attachmentList.findIndex((x) => x.Id == id);
      if (indx > -1) {
        this.attachmentList.splice(indx, 1);
      }
    },
    findCommonElements(arr1, arr2) {
      return arr1.some((item) => arr2.includes(item));
    },
    async quickSmsChange() {
      if (this.selectedQuickSmsId != null) {
        let indx = this.quickSmsList.findIndex(
          (x) => x.Id == this.selectedQuickSmsId
        );
        if (indx > -1) {
          let data = {
            Text: this.quickSmsList[indx].SmsContent,
            UserId: this.userInfo.userId,
            CustomerId: this.customerId,
          };
          let err, result;

          [err, result] = await this.$store
            .dispatch(placeholdersTypes.PROCESS_PLACEHOLDERS, data)
            .catch();

          if (result && result.Data) {
            this.smsBody = result.Data.Text;
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }

          this.selectedQuickSmsId = null;
        }
      }
    },
    phoneChanged(event) {
      if (event.target.value) {
        this.bindLogs();
      }
    },
    async scrollToBottom() {
      if (this.$refs.pscroll) {
        this.$refs.pscroll.$el.scrollTo(
          {
            y: "100%",
          },
          5000000
        );
      }
    },

    async bindLogs() {
      this.isReady = false;
      if (this.selectedPhone.length >= 10) {
        let phone = this.cleanUpDid(this.selectedPhone);
        let result = await this.$store.dispatch(types.GET_SMS_LOGS, phone);

        if (result && result.Data) {
          this.smsLogs = result.Data.SmsLogs;
          this.optinStatusId = result.Data.OptInStatus;
        }
      }
      this.isReady = true;

      await this.sleep(1000);
      await this.scrollToBottom();
    },
    getUserPhoto(item) {
      let rtn = "";
      if (item.IsInBound) {
        rtn =
          "https://zcrmstorage.blob.core.windows.net/public-user-photos/default.png";
      } else {
        rtn = item.UserPhotoUrl;
      }
      return rtn;
    },
    getDefaultImage(item) {
      let rtn = "";
      if (item.IsInBound) {
        rtn =
          "https://zcrmstorage.blob.core.windows.net/public-user-photos/default.png";
      } else {
        rtn = item.UserPhotoUrlDefault;
      }
      event.target.src = rtn;
    },
    async optOutNumber() {
      if (this.selectedPhone.length > 0) {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              let err, result;
              [err, result] = await this.$store.dispatch(
                types.OPT_OUT_NUMBER,
                this.selectedPhone
              );

              if (result) {
                this.optinStatusId = 3;
                this.$swal("Success!", result.Message, "success");
              } else {
                let errMsg = this.getApiErrorMessage(err);
                this.$swal("Error!", errMsg, "error");
              }
            }
          });
      }
    },

    async sendSms() {
      this.sendingSms = true;
      let err, result;

      let phone = this.cleanUpDid(this.selectedPhone);

      let mmsList = [];

      let bannedWords = [];

      if (this.smsBody.length > 0) {
        let bodyText = this.smsBody.replaceAll(" ", "").toLowerCase();
        for (let item of this.bannedKeywords) {
          let cleanWord = item.replaceAll(" ", "").toLowerCase();
          if (cleanWord == "") cleanWord = item;
          if (bodyText.includes(cleanWord)) {
            bannedWords.push(item);
          }
        }
      }

      if (bannedWords.length > 0) {
        let message =
          bannedWords.length == 1
            ? "The following keyword is not allowed: " + bannedWords[0]
            : "The following keywords are not allowed: " +
              bannedWords.join(", ");
        this.$swal("Warning!", message, "warning");
        this.sendingSms = false;
        return;
      }

      if (this.attachmentList.length > 0) {
        let totalSize = 0;
        this.attachmentList.map((x) => (totalSize += x.Size));

        if (!this.isValidFileSize(totalSize, 5)) {
          this.$swal(
            "Warning!",
            "Maximum allowed total file size is 5 MB for MMS messages. Please resize your files.",
            "warning"
          );
          this.sendingSms = false;
          return;
        }

        this.attachmentList.forEach((x) => {
          mmsList.push({
            Base64Data: x.Data.replace(/^data:image\/[a-z]+;base64,/, ""),
            Extension: x.Extension,
          });
        });
      }

      let callerId = await this.getCallerId(phone);

      if (callerId != "") {
        [err, result] = await this.$store.dispatch(types.SEND_SMS, {
          PhoneNumber: phone,
          Body: this.smsBody,
          From: callerId,
          MmsMedia: mmsList,
        });

        if (result && this.userInfo != null) {
          let smsObj = {
            IsInBound: false,
            Text: this.smsBody,
            SmsDate: new Date(),
            SmsStatus: "queued",
            CustomerName: "",
            UserName: this.userInfo.firstName + " " + this.userInfo.lastName,
            UserPhotoUrl: this.userInfo.photoUrl,
            UserPhotoUrlDefault: this.userInfo.defaultPhotoUrl,
          };

          this.smsLogs.push(smsObj);
          this.$swal("Success", "Sent", "success");
          this.smsBody = "";
          this.attachmentList = [];
          await this.sleep(500);
          await this.scrollToBottom();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } else {
        this.$swal(
          "Error!",
          "Caller Id could not assign for sending sms!",
          "error"
        );
      }

      this.sendingSms = false;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>
<style lang="css" scoped>
.smsHistoryPanel {
  padding: 0 0 10px 0;
}
.smsRow {
  width: 100%;
  float: left;
  margin: 5px 0;
}
.smsImageWrapper {
  width: 64px;
  float: left;
}
.outBound .smsImageWrapper {
  float: right;
}
.quickSmsPanel {
  width: 100%;
  margin: 0 24px;
  padding: 10px 12px;
  border: solid 1px #e6e6e6;
  background-color: #f9f9f9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.attachmentPanel {
  width: 100%;
  margin: 0 24px;
  padding: 5px 12px;
  border: solid 1px #e6e6e6;
  background-color: #f9f9f9;
}
.attachmentItem {
  width: 160px;
  float: right;
  margin: 2px 5px;
  padding: 5px 0 5px 0px;
  border-radius: 8px;
  background-color: #6c757d;
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: white;
}
.attachmentIcon {
  font-size: 13px;
  font-weight: 400;
  margin: 0 3px;
}
.attachmentCloseIcon {
  float: right;
  font-size: 13px;
  font-weight: 400;
  padding: 4px 5px;
  cursor: pointer;
}
.messagingPanel {
  width: 100%;
  margin: 0 24px;
  padding: 10px 12px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: none;
}
.refreshButton {
  width: 40px;
  float: right;
}
.smsCustomerNameLabel {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #939393;
}
.smsCustomerNameText {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2a404c;
}
.smsPanelImage {
  width: 32px;
  margin-top: 12px;
  display: inline-block;
  border-radius: 50%;
  box-shadow: 0 0 2px 2px #999;
}
.smsPanelDate {
  width: auto;
  display: inline-block;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #606160;
  margin: 0 0 0 72px;
}
.outBound .smsPanelDate {
  width: auto;
  display: inline-block;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #606160;
  margin: 0 72px 0 16px;
}

.smsTextWrapper {
  width: auto;
  min-width: 200px;
  max-width: 80%;
  float: left;
  padding: 12px 12px;
  position: relative;
  text-align: left;
  border-radius: 16px;
  background: #96d2e8;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.outBound .smsTextWrapper {
  float: right;
  background-color: #e6e6e6;
  position: relative;
}

.smsStatus {
  display: inline-block;
  text-align: right;
  font-size: 12px;
}
.quickSmsDropDown {
  border-radius: 8px;
  background-color: #24d171;
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #ffffff;
}
.attachmentButton {
  float: right;
  border-radius: 8px;
  background-color: #24d171;
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #ffffff;
}

.bubble:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid #fefefe;
  border-top: 10px solid #fefefe;
  border-bottom: 10px solid transparent;
  left: -19px;
  top: 6px;
}

.bubbleRight:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #96d2e8;
  border-right: 10px solid transparent;
  border-top: 10px solid #96d2e8;
  border-bottom: 10px solid transparent;
  right: -19px !important;
  top: 6px;
}
.optOutPanel {
  width: 100%;
  padding: 10px;
  float: left;
  color: white;
  text-align: center;
}
.scrollPanel {
  float: left;
  margin: 5px 0;
  padding: 8px;
  border-top: 1px solid #e6e6e6;
}
</style>
