<template>
  <div class="form-group row" id="reportsSummary">
    <div class="col-md-12">
      <form class="newPanelWrapper" role="form">
        <div class="row mt-2">
          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="!hideFilters && reportName != 'UserTimeLogReport'"
          >
            <label class="generalLabel" for="">{{ dateFilterText }}</label>
            <select
              v-model="dateCombo"
              placeholder="Select Timeframe"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option
                v-for="item in dateOptions"
                :key="item.key"
                :value="item.key"
              >
                {{ item.label }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-6 col-sm-12"
            v-if="
              dateCombo === 'customDateRange' || dateCombo === 'specificDate'
            "
          >
            <label class="generalLabel">Start Date</label>
            <DatePicker mode="date" :locale="'en-US'" v-model="date1">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  autocomplete="off"
                  class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="Start Date"
                />
              </template>
            </DatePicker>
          </div>
          <div
            class="col-xl-2 col-lg-3 col-md-6 col-sm-12"
            v-if="dateCombo === 'customDateRange'"
          >
            <label class="generalLabel">End Date</label>
            <DatePicker mode="date" :locale="'en-US'" v-model="date2">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  autocomplete="off"
                  class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="End Date"
                />
              </template>
            </DatePicker>
          </div>
          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="reportName == 'LeadAssignReport' && checkAuth(81550)"
          >
            <label class="generalLabel">Filter By</label>
            <select
              v-model="filterBy"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="1">Lead Created Date</option>
              <option :value="2">Deal Submitted Date</option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-6 col-sm-12"
            v-if="reportName === 'UserTimeLogReport'"
          >
            <label class="generalLabel">Start Date</label>
            <DatePicker
              mode="date"
              :locale="'en-US'"
              v-model="userTimeLogDate1"
              :min-date="selectedDate"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  autocomplete="off"
                  class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="Start Date"
                />
              </template>
            </DatePicker>
          </div>
          <div
            class="col-xl-2 col-lg-3 col-md-6 col-sm-12"
            v-if="reportName === 'UserTimeLogReport'"
          >
            <label class="generalLabel">End Date</label>
            <DatePicker
              mode="date"
              :locale="'en-US'"
              v-model="userTimeLogDate2"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  autocomplete="off"
                  class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="End Date"
                />
              </template>
            </DatePicker>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12"
            v-if="reportName === 'CustomerTagReport'"
          >
            <label class="generalLabel" for="">Tag Type</label>
            <select
              v-model="TagTypeId"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="null">Select Tag Type</option>
              <option
                v-for="item in customerTagTypes"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.TagName }}
              </option>
            </select>
          </div>
          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12"
            v-if="reportName == 'CSRTaskReport'"
          >
            <label class="generalLabel" for="">Task Status</label>
            <select
              v-model="csrTaskStatusId"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="null">Select Task Status</option>
              <option
                v-for="item in taskStatuses"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="
              reportName === 'AgentPhoneReport' ||
              reportName === 'CPAAgentReport' ||
              reportName === 'CPAOfficeReport' ||
              reportName === 'CPAStateReport' ||
              reportName === 'CPAPubReport' ||
              reportName === 'UserTimeLogReport'
            "
          >
            <label class="generalLabel">Office</label>
            <select
              v-model="officeId"
              class="generalSelectBox"
              style="width: 100% !important"
              :disabled="isSelectDisabled"
            >
              <option
                v-if="reportName != 'UserTimeLogReport'"
                :value="0"
              >
                All Offices
              </option>
              <option
                v-for="item in filteredOffices"
                :key="item.Id"
                :value="item.Id"
                :selected="filteredOffices.length == 1"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>
          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="reportName === 'CSRTaskContactScheduleReport'"
          >
            <label class="generalLabel">Task Type</label>
            <select
              v-model="taskType"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="null">All Task Types</option>
              <option
                v-for="item in taskTypeNames"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="reportName === 'CSRTaskContactScheduleReport'"
          >
            <label class="generalLabel">Trigger Type</label>
            <select
              v-model="triggerType"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="null">All Trigger Types</option>
              <option
                v-for="item in triggerTypeList"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="reportName === 'CSRTaskContactScheduleReport'"
          >
            <label class="generalLabel">Trigger Status</label>
            <select
              v-model="triggerStatus"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="null">All Trigger Statuses</option>
              <option
                v-for="item in triggerLogStatus"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="reportName === 'AgentPhoneReport'"
          >
            <label class="generalLabel">Role</label>
            <select
              v-model="role"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="0">All Roles</option>
              <option
                v-for="item in filteredRoles"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
            style="margin-top: 28px"
          >
            <div class="row" style="margin-top: -2px">
              <div class="col-md-3" v-if="hideFilters">
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  @click="refresh()"
                  :disabled="loadingSpinner"
                >
                  <i
                    v-if="loadingSpinner"
                    class="spinner-border spinner-border-sm mr-1"
                  ></i>
                  {{
                    this.reportName == "CustomerDispositionSettingsReport" ||
                    this.reportName == "CSRTaskDistroQueueReport" ||
                    this.reportName == "CSRTaskDistroQueueReport"
                      ? "Submit"
                      : "Refresh"
                  }}
                </button>
              </div>

              <div class="col-md-3" v-if="!hideFilters">
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left col-ml-1"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  @click="filterReport()"
                  :disabled="loadingSpinner"
                >
                  <i
                    v-if="loadingSpinner"
                    class="spinner-border spinner-border-sm mr-1"
                  ></i>
                  Filter
                </button>
              </div>

              <div
                class="col-md-3"
                v-if="!hideExport && exportData && exportData.length > 0"
              >
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  @click="exportReport()"
                >
                  Export
                </button>
              </div>

              <div
                class="col-md-3"
                v-if="!hidePrint && exportData && exportData.length > 0"
              >
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  @click="print()"
                >
                  Print
                </button>
              </div>

              <div
                class="col-md-3"
                v-if="exportData && exportData.length > 0 && !hideFilters"
              >
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  @click="clear()"
                >
                  Clear
                </button>
              </div>

              <div
                class="col-md-3"
                v-if="
                  exportData &&
                  exportData.length > 0 &&
                  this.reportName == 'CustomerDispositionSettingsReport'
                "
              >
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  @click="changeView()"
                >
                  {{ viewType == "gridView" ? "Show Graph" : "Show Grid" }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-1" v-if="reportName === 'CustomerStatusReport'">
          <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12 float-left">
            <div style="width: 20px; float: left; margin: 0 12px 0 0">
              <input
                v-model="groupedByCampaign"
                type="checkbox"
                class="form-control"
                @change="groupByCampaignChange()"
              />
            </div>
            <label class="col-form-label">Group by campaign</label>
          </div>
        </div>
      </form>
    </div>
    <div class="form-group row" v-if="exportData && exportData.length > 0">
      <div class="col-md-12">
        <div
          class="col-xl-2 col-lg-2 col-md-12 col-sm-12"
          v-show="reportName != 'InboundStatisticsReport'"
        >
          <span class="badge badge-success" style="padding: 10px 16px">
            Total Records: {{ recordCount }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import { Printd } from "printd";
import _orderby from "lodash";
import { DatePicker } from "v-calendar";

export default {
  name: "ReportSummary",
  components: { DatePicker },
  props: {
    exportData: {
      default() {
        return [];
      },
    },
    reportName: {
      type: String,
      default: () => "",
    },

    recordCount: {
      type: Number,
      default: () => 0,
    },
    loadingSpinner: {
      Type: Boolean,
      Defult: false,
    },
  },
  data() {
    return {
      selectedDate: new Date("2024-07-17"),
      userSubOffices: [],
      userTimeLogDate1: new Date(),
      userTimeLogDate2: new Date().setDate(new Date().getDate() + 1),
      reportType: "All",
      customerId: null,
      filterBy: 1,
      Agents: null,
      AgentName: "",
      reportIds: 0,
      userOfficeId: 0,
      hasAccessOtherOffices: false,
      officeId: 0,
      role: 0,
      triggerStatus: null,
      TagTypeId: null,
      taskType: null,
      triggerType: null,
      csrTaskStatusId: null,
      date1: null,
      date2: null,
      config: {
        format: "MM/DD/YYYY",
        useCurrent: false,
        showClear: false,
        showClose: true,
        maxDate: new Date(),
      },
      dateFilterText: "Date Option",
      dateCombo: "today",
      dateOptions: [
        {
          key: "today",
          label: "Today",
        },
        {
          key: "yesterday",
          label: "Yesterday",
        },
        {
          key: "thisWeek",
          label: "This Week",
        },
        {
          key: "thisMonth",
          label: "This Month",
        },
        {
          key: "lastMonth",
          label: "Last Month",
        },
        {
          key: "thisYear",
          label: "This Year",
        },
        {
          key: "specificDate",
          label: "Specific Date",
        },
        {
          key: "customDateRange",
          label: "Custom Date Range",
        },
      ],
      hideFilters: false,
      hideExport: false,
      hidePrint: false,
      groupedByCampaign: false,
      uri: "data:application/vnd.ms-excel;charset=UTF-8;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64: function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format: function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      },
      viewType: "gridView",
    };
  },

  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_TASK_TYPE_NAMES),
      this.$store.dispatch(globalTypes.GET_CUSTOMER_TAG_TYPE),
      this.$store.dispatch(globalTypes.GET_OFFICE_NAMES),
      this.$store.dispatch(globalTypes.GET_ROLES),
      this.$store.dispatch(globalTypes.GET_ALL_ACTIVE_EMPLOYEES),
    ]);

    if (this.reportName == "CSRTaskReport") {
      this.dateFilterText = "Due Date";
      await this.$store.dispatch(globalTypes.GET_CSRTASKSTATUS);
    } else if (this.reportName == "CSRRealtimeTasksReport") {
      this.hideFilters = true;
      this.hideExport = true;
      this.hidePrint = true;
    } else if (this.reportName == "PendingDispositionReport") {
      this.hideFilters = true;
    } else if (this.reportName == "CustomerDispositionSettingsReport") {
      this.hideFilters = true;
    } else if (this.reportName == "CSRTaskDistroQueueReport") {
      this.hideFilters = true;
    } else if (this.reportName == "AgentPhoneReport") {
      this.reportIds = 55417;
    } else if (this.reportName == "LeadAssignReport") {
      this.dateFilterText = "Lead Created Date";
    } else if (this.reportName == "CSRTaskContactScheduleReport") {
      this.$store.dispatch(globalTypes.GET_TASK_TRIGGER_TYPE);
      this.$store.dispatch(globalTypes.GET_CSR_TASK_TRIGGER_LOG_STATUS);
    } else {
      this.dateFilterText = "Date Option";
    }

    let retrievedData = sessionStorage.getItem(
      "reportFilters-" + this.reportName
    );
    if (typeof retrievedData != undefined && retrievedData) {
      let data = JSON.parse(retrievedData);
      if (data) {
        this.dateCombo = data.Option;
        this.date1 = data.StartDate;
        this.date2 = data.EndDate;

        if (
          typeof data.UserTimeLogStartDate != undefined &&
          data.UserTimeLogStartDate
        ) {
          this.userTimeLogDate1 = data.UserTimeLogStartDate;
        }

        if (
          typeof data.UserTimeLogEndDate != undefined &&
          data.UserTimeLogEndDate
        ) {
          this.userTimeLogDate2 = data.UserTimeLogEndDate;
        }

        if (
          typeof data.PaymentsAndFeesCustomerId != undefined &&
          data.PaymentsAndFeesCustomerId
        ) {
          this.customerId = data.PaymentsAndFeesCustomerId;
        }

        if (typeof data.ReportId != undefined && data.ReportId) {
          this.reportIds = data.ReportId;
        }

        if (typeof data.TaskStatusId != undefined && data.TaskStatusId) {
          this.csrTaskStatusId = data.TaskStatusId;
        }

        if (
          typeof data.IsGroupedByCampaign != undefined &&
          data.IsGroupedByCampaign
        ) {
          this.groupedByCampaign = data.IsGroupedByCampaign;
        }

        if (typeof data.TagTypeId != undefined && data.TagTypeId) {
          this.TagTypeId = data.TagTypeId;
        }

        if (typeof data.OfficeId != undefined && data.OfficeId) {
          this.officeId = data.OfficeId;
        }
        if (typeof data.RoleId != undefined && data.RoleId) {
          this.role = data.RoleId;
        }
        if (typeof data.Agents != undefined && data.Agents) {
          this.Agents = data.Agents;
        }

        if (typeof data.TaskTypeId != undefined && data.TaskTypeId) {
          this.taskType = data.TaskTypeId;
        }
        if (typeof data.TriggerTypeId != undefined && data.TriggerTypeId) {
          this.triggerType = data.TriggerTypeId;
        }
        if (typeof data.TriggerStatusId != undefined && data.TriggerStatusId) {
          this.triggerStatus = data.TriggerStatusId;
        }

        if (
          typeof data.DateFilterTypeId != undefined &&
          data.DateFilterTypeId
        ) {
          this.filterBy = data.DateFilterTypeId;
        }
      }
    }

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
      this.userSubOffices = userInfo.subOffices;
    }

    let matchedOffice = this.offices.find((x) => x.Id == this.userOfficeId);
    if (matchedOffice) {
      this.officeId = matchedOffice.Id;
    }

    this.hasAccessOtherOffices = this.canSeeAllOffices();
  },
  computed: mapState({
    triggerLogStatus: (state) => state.globals.triggerLogStatus,
    triggerTypeList: (state) => state.globals.triggerTypeList,
    taskTypeNames: (state) => state.globals.taskTypeNames,
    taskStatuses: (state) => state.globals.csrTaskStatus,
    customerTagTypes: (state) => state.globals.customerTagTypes,
    offices: (state) => state.globals.offices,
    roles: (state) => state.globals.roles,

    filteredOffices() {
      let rtn = [];
      if (this.checkAuth(1454)) {
        rtn = this.offices;
      } else if (this.checkAuth(55011)) {
        rtn = this.userSubOffices
          ? this.offices.filter(
              (x) =>
                this.userSubOffices.includes(x.Id) || x.Id == this.userOfficeId
            )
          : this.offices.filter((x) => x.Id == this.userOfficeId);
      } else {
        rtn = this.offices.filter((x) => x.Id == this.userOfficeId);
      }
      return _orderby.orderBy(rtn, "Name", "asc");
    },

    isSelectDisabled() {
      return this.filteredOffices.length == 1;
    },

    salesRoles() {
      return this.roles.filter((x) => x.ModuleFunctions.includes(1848));
    },
    filteredRoles() {
      let rtn = [];
      if (this.checkAuth(1454)) {
        rtn = this.salesRoles;
      } else if (this.checkAuth(55011)) {
        rtn = this.userSubOffices
          ? this.salesRoles.filter(
              (x) =>
                this.userSubOffices.includes(x.OfficeId) ||
                x.OfficeId == this.userOfficeId ||
                x.OfficeId == null
            )
          : this.salesRoles.filter(
              (x) => x.OfficeId == this.userOfficeId || x.OfficeId == null
            );
      } else {
        rtn = this.salesRoles.filter(
          (x) => x.OfficeId == this.userOfficeId || x.OfficeId == null
        );
      }

      rtn =
        this.officeId > 0
          ? rtn.filter((x) => x.OfficeId == this.officeId || x.OfficeId == null)
          : rtn;

      return _orderby.orderBy(rtn, "Name", "asc");
    },

    allEmployees: (state) => state.globals.allEmployees,
    filteredAgents() {
      return this.allEmployees.filter((x) =>
        x.IsPhoneEnabled == true && this.hasAccessOtherOffices
          ? x
          : x.OfficeId == this.userOfficeId
      );
    },
  }),
  methods: {
    disabledAfterJuly17th2024(date) {
      let cutOffDate = new Date("2024-07-17");
      return date > cutOffDate;
    },
    agentChange() {
      let matchedAgent = this.filteredAgents.find((x) => x.Id == this.Agents);
      this.AgentName = matchedAgent.Name;
      this.$emit("selectedAgentChange", this.Agents, this.AgentName);
    },

    filterReport() {
      this.checkDateFields();
      let payload = {
        Option: this.dateCombo.toLowerCase(),
        StartDate: new Date(this.date1).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
        EndDate: new Date(this.date2).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
      };

      let userTimeLogPayload = {
        StartDate: new Date(this.userTimeLogDate1).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
        EndDate: new Date(this.userTimeLogDate2).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
        OfficeId: this.officeId,
      };

      if (this.reportName == "AgentPhoneReport") {
        payload.OfficeId = this.officeId;
        payload.RoleId = this.role;
        payload.ReportId = this.reportIds;
      }

      if (
        this.reportName == "CPAAgentReport" ||
        this.reportName == "CPAOfficeReport" ||
        this.reportName == "CPAStateReport" ||
        this.reportName == "CPAPubReport"
      ) {
        payload.OfficeId = this.officeId;
      }

      if (this.reportName == "CSRTaskReport") {
        payload.TaskStatusId = this.csrTaskStatusId;
        payload.ReportId = 0;
      }

      if (this.reportName == "CustomerStatusReport") {
        payload.IsGroupedByCampaign = this.groupedByCampaign;
      }

      if (this.reportName == "CustomerTagReport") {
        payload.TagTypeId = this.TagTypeId;
      }

      if (this.reportName == "LeadAssignReport") {
        payload.DateFilterTypeId = this.filterBy;
      }

      if (this.reportName == "CSRTaskContactScheduleReport") {
        payload.TaskTypeId = this.taskType;
        payload.TriggerTypeId = this.triggerType;
        payload.TriggerStatusId = this.triggerStatus;
      }

      sessionStorage.setItem(
        "reportFilters-" + this.reportName,
        JSON.stringify({
          Option: this.dateCombo,
          StartDate: new Date(this.date1).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
          EndDate: new Date(this.date2).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
          TaskStatusId: this.csrTaskStatusId,
          IsGroupedByCampaign: this.groupedByCampaign,
          TagTypeId: this.TagTypeId,
          OfficeId: this.officeId,
          RoleId: this.role,
          ReportId: this.reportIds,
          Agents: this.Agents,
          AgentName: this.AgentName,
          DateFilterTypeId: this.filterBy,
          TaskTypeId: this.taskType,
          TriggerTypeId: this.triggerType,
          TriggerStatusId: this.triggerStatus,
          PaymentsAndFeesCustomerId: this.customerId,
          UserTimeLogStartDate: this.userTimeLogDate1,
          UserTimeLogEndDate: this.userTimeLogDate2,
        })
      );

      if (this.reportName == "UserTimeLogReport") {
        this.$emit("setPayload", userTimeLogPayload);
      } else {
        this.$emit("setPayload", payload);
      }
    },

    groupByCampaignChange() {
      this.filterReport();
    },
    changeView() {
      if (this.viewType == "gridView") {
        this.viewType = "graphView";
      } else {
        this.viewType = "gridView";
      }
      this.$emit("viewChanged", this.viewType);
    },
    tableToExcel(table, name) {
      let ctx = {
        worksheet: name || "Worksheet",
        table: table.innerHTML,
      };

      let element = document.createElement("a");
      element.setAttribute(
        "href",
        this.uri + this.base64(this.format(this.template, ctx))
      );
      element.setAttribute(
        "download",
        name + " _" + new Date().getTime() + ".xls"
      );
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      this.$emit("setPagination", true);
    },
    checkDateFields() {
      if (this.dateCombo == "customDateRange" && this.date1 >= this.date2) {
        this.date1 = new Date(
          this.date2.getFullYear(),
          this.date2.getMonth(),
          this.date2.getDate(),
          0,
          0,
          0
        );
        this.date1 = this.date1.addDays(-1);
      }
    },

    async exportReport() {
      this.$emit("setPagination", false);
      await this.sleep(1000);

      if (this.reportName == "LeadAssignReport") {
        let table = document.querySelector(".report-wrapper > table");
        let temp = table.cloneNode(true);
        if (temp.hasChildNodes) {
          this.tableToExcel(temp, this.reportName);
        }
      } else if (this.reportName == "InboundStatisticsReport") {
        let tables = document.querySelectorAll(
          ".report-wrapper > .table-wrapper > table"
        );
        let exportTable = document.createElement("table");
        tables.forEach((item) => {
          let temp = item.cloneNode(true);
          if (temp.hasChildNodes) {
            let theadNode = temp.childNodes[1];
            if (theadNode.hasChildNodes) {
              let headers = theadNode.childNodes[0];
              for (let i = 1; i < theadNode.childNodes.length; i++) {
                theadNode.removeChild(theadNode.childNodes[i]);
              }
              theadNode.appendChild(headers);
            }
            exportTable.appendChild(theadNode);
            exportTable.appendChild(temp.childNodes[1]);
          }
        });

        this.tableToExcel(exportTable, this.reportName);
      } else {
        let table = document.querySelector(
          ".report-wrapper > .table-wrapper > table"
        );
        let temp = table.cloneNode(true);
        if (temp.hasChildNodes) {
          let theadNode = temp.childNodes[1];

          if (theadNode.hasChildNodes) {
            let headers = theadNode.childNodes[0];

            for (let i = 1; i < theadNode.childNodes.length; i++) {
              theadNode.removeChild(theadNode.childNodes[i]);
            }
            theadNode.appendChild(headers);
          }
          this.tableToExcel(temp, this.reportName);
        }
      }
    },

    async print() {
      let cssText = `
              thead {

                text-align:left !important;
              }
              th{
                width:200px;
              }
              input{display:none !important;}
              tbody td .btn {display:none !important;}
            `;
      let d = new Printd();

      this.$emit("setPagination", false);
      await this.sleep(1000);

      if (this.reportName == "LeadAssignReport") {
        let table = document.querySelector(".report-wrapper > table");
        if (table == null) return;
        let temp = table.cloneNode(true);
        if (temp.hasChildNodes) {
          d.print(temp, [cssText]);
        }
      } else if (this.reportName == "InboundStatisticsReport") {
        let tables = document.querySelectorAll(
          ".report-wrapper > .table-wrapper > table"
        );

        let printTable = document.createElement("table");
        tables.forEach((item) => {
          let temp = item.cloneNode(true);
          printTable.appendChild(temp.childNodes[1]);
          printTable.appendChild(temp.childNodes[1]);
        });
        d.print(printTable, [cssText]);
      } else {
        let table = document.querySelector(
          ".report-wrapper >.table-wrapper > table"
        );
        if (table == null) return;
        let temp = table.cloneNode(true);
        if (temp.hasChildNodes) {
          d.print(temp, [cssText]);
        }
      }
      this.$emit("setPagination", true);
    },

    clear() {
      this.TagTypeId = null;
      this.dateCombo = "today";
      this.date1 = null;
      this.date2 = null;
      this.Agents = null;
      this.$emit("clearReport");
    },
    refresh() {
      this.$emit("refreshReport");
    },
  },
  watch: {
    filterBy: function (newVal) {
      if (this.reportName == "LeadAssignReport") {
        if (newVal == 1) {
          this.dateFilterText = "Lead Created Date";
        } else {
          this.dateFilterText = "Deal Submitted Date";
        }
      }
    },
    filteredOffices() {
      if (this.filteredOffices.length === 1) {
        this.officeId = this.filteredOffices[0].Id;
      }
    },
  },
  mixins: [utilitiesMixin, formatMixin],
};
</script>

<style scoped>
.o-drop__item--active {
  background-color: #ffffff;
  color: #3bafda;
}
</style>
