<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div v-if="isLoading" class="newLoading">
      <div class="text-center" style="margin-top: 200px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div style="display: flex" class="mb-4">
      <div style="position: absolute; top: 6px">
        <label>Cancelation Form</label>
      </div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>

    <perfect-scrollbar style="max-height: 80vh">
      <div class="col-md-12 mb-2 row">
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 noPadding">
          <div class="col-md-12" style="display: flex; gap: 90px">
            <div>
              <label class="generalLabel">Balance:</label>
            </div>
            <div>
              <label
                class="generalLabel"
                :style="
                  cancellationInfoList.GlobalBalance < 0
                    ? 'color:red'
                    : 'color:black'
                "
                >{{
                  isNaN(cancellationInfoList.GlobalBalance)
                    ? "$0.00"
                    : formatMoney(cancellationInfoList.GlobalBalance)
                }}</label
              >
            </div>
          </div>
          <div class="col-md-12" style="display: flex; gap: 27px">
            <div>
              <label class="generalLabel">Pending Balance:</label>
            </div>
            <div>
              <label
                class="generalLabel"
                :style="
                  cancellationInfoList.PendingBalance < 0
                    ? 'color:red'
                    : 'color:black'
                "
                >{{
                  isNaN(cancellationInfoList.PendingBalance)
                    ? "$0.00"
                    : formatMoney(cancellationInfoList.PendingBalance)
                }}</label
              >
            </div>
          </div>
          <div class="col-md-12" style="display: flex; gap: 71px">
            <div>
              <label class="generalLabel">Account Fee:</label>
            </div>
            <div>
              <label
                v-if="cancellationInfoList.AccountFee"
                class="generalLabel"
                :style="
                  cancellationInfoList.AccountFee < 0
                    ? 'color:red'
                    : 'color:black'
                "
                >{{ formatMoney(cancellationInfoList.AccountFee) }}</label
              >
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="col-md-12 noPadding mb-2">
            <label class="generalLabel">Reason</label>
            <select
              v-if="filteredReasonsList"
              class="form-control"
              v-model="clientStatusChangeData.CancelationReasonId"
            >
              <option :value="null">Please select a reason</option>
              <option
                v-for="item in reasonsList"
                :key="item.Id"
                :value="item.Id"
                v-show="item.IsActive"
              >
                {{ item.Reason }}
              </option>
            </select>
          </div>
          <textarea
            v-model="clientStatusChangeData.Notes"
            id="noteTextEditor"
            class="form-control textareaInput generalTextBox"
            rows="5"
            placeholder="Write a reason"
          ></textarea>
        </div>
      </div>

      <div v-if="creditorList.length > 0" class="col-md-12 mb-2">
        <div class="col-md-12">
          <label class="generalLabel">Pending Settlement Fees:</label>
        </div>

        <perfect-scrollbar class="col-md-12" style="max-height: 209px">
          <table class="datagrid-table col-md-12">
            <thead>
              <tr style="text-align: center">
                <th>Creditor</th>
                <th>Amount</th>
                <th>Enter Amount</th>
                <th>Fee Type</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="text-align: center"
                v-for="item in creditorList"
                :key="item.Id"
              >
                <td style="width: 20%">
                  <a>{{ item.Name }}</a>
                </td>
                <td style="width: 25%">{{ formatMoney(item.Fees) }}</td>
                <td style="width: 25%">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="item.EnterAmount"
                    @input="sliceAmount(item)"
                    v-myMask="{
                      alias: 'currency',
                      prefix: '$',
                      rightAlign: false,
                    }"
                  />
                </td>

                <td style="width: 30%">
                  <select v-model="item.FeeType" class="form-control">
                    <option :value="null">Please Select</option>
                    <option
                      v-for="item in feeTypeList"
                      :key="item.FeeType"
                      :value="item.FeeType"
                    >
                      {{ item.Description }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </perfect-scrollbar>
      </div>

      <div class="col-md-12 row">
        <div class="col-md-12 pb-2">
          <label class="generalLabel">Refund Type</label>
          <select
            v-if="clientStatusReleaseTypeList"
            class="form-control"
            v-model="clientStatusChangeData.ReleaseTypeId"
          >
            <option :value="null">Please Select</option>
            <option
              v-for="item in clientStatusReleaseTypeList"
              :value="item.ReleaseTypeId"
              :key="item.ReleaseTypeId"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>

        <div
          class="col-md-5"
          v-if="
            clientStatusChangeData.ReleaseTypeId == 1 ||
            clientStatusChangeData.ReleaseTypeId == 2
          "
        >
          <label class="generalLabel">Money back amount:</label>
          <input
            v-model="clientStatusChangeData.RefundAmount"
            class="generalTextBox"
            style="width: 200px"
            type="text"
            v-myMask="{ alias: 'currency', prefix: '$', rightAlign: false }"
          />
        </div>
        <div class="col-md-12 pb-2">
          <label class="generalLabel">Emergency 2 business day</label>
          <input
            type="checkbox"
            id="feesTakenCBoxPopUp"
            v-model="clientStatusChangeData.IsEmergency"
            class="checkbox-input generalCheckBox mr-2"
            style="
              float: left;
              border-radius: 4px !important;
              border: solid 1px #d1d1d1 !important;
            "
          />
        </div>

        <div class="col-md-12">
          <button
            @click="submitDraftChangesPopUp"
            type="button"
            class="btn btn-success waves-effect waves-light ml-2"
            :class="checkAuth(12411) ? 'col-md-6' : 'col-md-3'"
            style="float: right"
            :disabled="isLoading"
          >
            {{ checkAuth(12411) ? "Request and Approve" : "Submit" }}
          </button>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";

export default {
  name: "DraftChangesPopUp",
  components: {},
  mixins: [formatMixin, utilitiesMixin],
  props: {
    customerId: {
      Type: Number,
      default: 0,
    },
    officeId: {
      Type: Number,
      default: 0,
    },
    fromStatusId: {
      Type: Number,
      default: 0,
    },
    toStatusId: {
      Type: Number,
      default: 0,
    },
    feeTypes: {
      Type: Array,
      default: [],
    },
  },
  data() {
    return {
      creditorList: [],
      isLoading: false,
      cancellationInfoList: {},
      suggestBoxOpen: false,
      currentRowIndex: null,
      clientStatusReleaseTypeList: [],
      clientStatusChangeData: {
        CustomerId: 0,
        IsEmergency: false,
        Notes: "",
        FromStatusId: 0,
        ToStatusId: 0,
        ReleaseTypeId: null,
        RefundAmount: 0,
        CancelationReasonId: null,
      },
      feeTypeList: [],
    };
  },
  computed: mapState({
    reasonsList: (state) => state.draftChanges.reasonsList,

    filteredReasonsList() {
      return this.reasonsList.some((x) => x.IsActive);
    },
  }),
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch(
      types.GET_CUSTOMER_CANCELATION_REASON_STATUS_CHANGE,
      null
    );
    let err, result;
    [err, result] = await this.$store.dispatch(
      types.GET_CLIENT_STATUS_RELEASE_TYPES
    );

    if (result && result.Data) {
      this.clientStatusReleaseTypeList = deepCopy(result.Data);
    }
    await this.getFeeTypes();
    await this.getCancelliationInfo();
    this.isLoading = false;
  },

  methods: {
    notesAndReason() {
      let matchingReason = this.reasonsList.find(
        (x) => x.Id == this.clientStatusChangeData.CancelationReasonId
      );
      if (
        this.clientStatusChangeData.Notes &&
        this.clientStatusChangeData.CancelationReasonId
      ) {
        return (
          matchingReason.Reason + " - " + this.clientStatusChangeData.Notes
        );
      } else if (this.clientStatusChangeData.Notes) {
        return this.clientStatusChangeData.Notes;
      } else {
        return matchingReason.Reason;
      }
    },

    sliceAmount(item) {
      if (item.EnterAmount > item.Fees) {
        item.EnterAmount = item.Fees;
      }
    },
    async getFeeTypes() {
      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_FEES_TYPES, {
        CustomerId: this.customerId,
        OfficeId: this.officeId,
      });
      if (result && result.Data) {
        this.feeTypeList = deepCopy(result.Data);
      }
    },

    async getCancelliationInfo() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_CUSTOMER_CANCELLATION_INFO,
        {
          CustomerId: this.customerId,
          OfficeId: this.officeId,
        }
      );
      if (result && result.Data) {
        if (result.Data.Creditors) {
          this.creditorList = [];
          for (let item of result.Data.Creditors) {
            this.creditorList.push({
              Id: item.Id,
              Name: item.Name,
              Fees: item.Fees,
              FeeType: null,
              EnterAmount: 0,
            });
          }
        }
        this.cancellationInfoList = deepCopy(result.Data);
      }
    },

    emptyTheObjectsOnUnmount() {
      this.clientStatusChangeData = null;
    },
    async submitDraftChangesPopUp() {
      if (
        this.filteredReasonsList == false &&
        this.clientStatusChangeData.Notes.trim().length == 0
      ) {
        this.$swal("Warning!", "Notes field is mandatory!", "warning");
        return;
      }

      if (
        this.filteredReasonsList &&
        this.clientStatusChangeData.CancelationReasonId == null
      ) {
        this.$swal("Warning!", "Please select a reason!", "warning");
        return;
      }

      if (this.clientStatusChangeData.ReleaseTypeId == null) {
        this.$swal("Warning!", "Refund Type is mandatory!", "warning");
        return;
      }

      if (
        (this.clientStatusChangeData.ReleaseTypeId == 1 ||
          this.clientStatusChangeData.ReleaseTypeId == 2) &&
        this.clientStatusChangeData.RefundAmount == 0
      ) {
        this.$swal("Warning!", "Refund Amount is mandatory!", "warning");
        return;
      }
      if (this.clientStatusChangeData.ReleaseTypeId == 3) {
        this.clientStatusChangeData.RefundAmount = 0;
      }

      let hasNullFeeType =
        this.creditorList.filter((x) => x.FeeType == null && x.EnterAmount > 0)
          .length > 0;

      if (hasNullFeeType == true) {
        this.$swal("Warning!", "Fee Type is mandatory!", "warning");
        return;
      }

      let totalAmount = 0;
      for (let i = 0; i < this.creditorList.length; i++) {
        totalAmount += parseFloat(this.creditorList[i].EnterAmount);
      }

      let refundAmount = this.clientStatusChangeData.RefundAmount;
      let accontFee = this.cancellationInfoList.AccountFee;
      if (this.cancellationInfoList.GlobalBalance < 10.75) accontFee = 0;

      let collectedValues =
        parseFloat(totalAmount) + parseFloat(refundAmount) + accontFee;

      if (collectedValues > this.cancellationInfoList.GlobalBalance) {
        this.$swal(
          "Warning!",
          "Sum of pending settlement fee amounts, account fee amount, and the refund amount cannot be greater than the account balance.",
          "warning"
        );
        return;
      }

      let applicableFees = [];
      for (let item of this.creditorList) {
        if (item.EnterAmount > 0) {
          applicableFees.push({
            FeeAmount: item.EnterAmount,
            GlobalFeeType: item.FeeType,
            FeeDate: this.formatShortDate(new Date()),
            CreditorId: item.Id,
          });
        }
      }

      this.$swal
        .fire({
          title: "Confirm",
          text: "Are you sure to submit this request?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            this.isLoading = true;
            let err, result;
            this.clientStatusChangeData.CustomerId = this.customerId;
            [err, result] = await this.$store.dispatch(
              types.CREATE_CLIENT_STATUS,
              {
                CustomerId: this.clientStatusChangeData.CustomerId,
                IsEmergency: this.clientStatusChangeData.IsEmergency,
                ReleaseTypeId: this.clientStatusChangeData.ReleaseTypeId,
                Notes: this.notesAndReason(),
                RefundAmount:
                  this.clientStatusChangeData.ReleaseTypeId == 3
                    ? 0
                    : this.clientStatusChangeData.RefundAmount,
                FromStatusId: this.fromStatusId,
                ToStatusId: this.toStatusId,
                ApplicableFees: applicableFees,
                CancelationReasonId:
                  this.clientStatusChangeData.CancelationReasonId,
              }
            );

            if (result) {
              let successMessage = this.checkAuth(12411)
                ? "The requested changes are approved."
                : "Draft changes requested.";
              this.$swal("Success!", successMessage, "success");
              this.$emit("statusChangePopupSubmit");
              this.$emit("closeStatusChangePopup", null);
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
          this.isLoading = false;
        });
    },

    closePanel() {
      this.$emit("closeStatusChangePopup", this.fromStatusId);
    },
  },
};
</script>

<style scoped>
thead {
  position: sticky;
  top: 0px;
  background: white;
}
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}
</style>
