export default {
  methods: {
    async getDecryptedValue(value) {
      let decryptedVal = "";
      if (value && value != "") {
        decryptedVal = await this.AES256_GCM_decrypt(value);
      }
      return decryptedVal;
    },

    async sha256(message) {
      // encode as UTF-8
      const msgBuffer = new TextEncoder("utf-8").encode(message);

      // hash the message
      const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

      // convert ArrayBuffer to Array
      const hashArray = Array.from(new Uint8Array(hashBuffer));

      // convert bytes to hex string
      const hashHex = hashArray
        .map((b) => ("00" + b.toString(16)).slice(-2))
        .join("");

      return hashHex;
    },

    //AES OPERATIONS
    async AES256_GCM_ENCRYPT(plainText) {
      let keyInfo = JSON.parse(sessionStorage.getItem("keyInfo"));
      let keyObj = await this.ImportKey(keyInfo.key);
      let aesIv = await this.ImportAesIv(keyInfo.iv);
      if (keyObj == null) {
        alert("Failed to Generate AES Keys Check The browser Comptabilty ");
        return;
      }

      let buff = new TextEncoder("utf-8").encode(plainText);

      return this._arrayBufferToBase64(
        await crypto.subtle.encrypt(
          {
            name: "AES-CBC",
            iv: aesIv,
          },
          keyObj,
          buff
        )
      );
    },
    async AES256_GCM_decrypt(cipherText) {
      let keyInfo = JSON.parse(sessionStorage.getItem("keyInfo"));
      let keyObj = await this.ImportKey(keyInfo.key);
      let aesIv = await this.ImportAesIv(keyInfo.iv);

      if (keyObj == null) {
        alert("Failed to Generate AES Keys Check The browser Comptabilty ");
        return;
      }

      return new TextDecoder("utf-8").decode(
        await crypto.subtle.decrypt(
          {
            name: "AES-CBC",
            iv: aesIv,
          },
          keyObj,
          this._base64ToArrayBuffer(cipherText)
        )
      );
    },
    async ImportKey(key) {
      let secretKeyBase64 = key;
      let secretArr = this._base64ToArrayBuffer(secretKeyBase64);

      return await window.crypto.subtle.importKey(
        "raw", //can be "jwk" or "raw"
        new Uint8Array(secretArr),
        {
          //this is the algorithm options
          name: "AES-CBC",
        },
        false, //whether the key is extractable (i.e. can be used in exportKey)
        ["encrypt", "decrypt"] //can "encrypt", "decrypt", "wrapKey", or "unwrapKey"
      );
    },
    async ImportAesIv(iv) {
      let ivBase64 = iv;
      let aesIv = this._base64ToArrayBuffer(ivBase64);

      return await aesIv;
    },

    _base64ToArrayBuffer(base64) {
      let binary_string = window.atob(base64);
      let len = binary_string.length;
      let bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },

    _arrayBufferToBase64(buffer) {
      let binary = "";
      let bytes = new Uint8Array(buffer);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
    checkAuth(moduleFunctionId) {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

      if (
        userInfo &&
        userInfo.moduleFunctions &&
        userInfo.moduleFunctions.length > 0
      ) {
        let arrPermission = userInfo.moduleFunctions
          .split(",")
          .map(function (item) {
            return parseInt(item, 10);
          });

        return arrPermission.includes(parseInt(moduleFunctionId));
      } else {
        return false;
      }
    },

    async getTokenFromLocalStorage(email) {
      let rtn = null;
      let storageName = "mfaInfo-" + (await this.sha256(email));
      let tmpLocal = JSON.parse(localStorage.getItem(storageName));
      if (tmpLocal != null) {
        if (tmpLocal.Token != null) {
          rtn = tmpLocal.Token;
        }
      }
      return rtn;
    },
    async resetTokenStorage(email, token) {
      let storageName = "mfaInfo-" + (await this.sha256(email));
      localStorage.removeItem(storageName);
      let content = { Token: token };
      localStorage.setItem(storageName, JSON.stringify(content));
    },

    canSeeAllOffices() {
      let rtn = this.checkAuth(1454);
      return rtn;
    },
    canSeeSubOffices() {
      let rtn = this.checkAuth(55011);
      return rtn;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    getItemsFromSplittedString(value) {
      let rtn = [];
      if (value != null && value != "") {
        rtn = value.split(",").map(function (x) {
          return parseInt(x);
        });
      }
      return rtn;
    },
    JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
      //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      let arrData =
        typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

      let CSV = "";
      //Set Report title in first row or line
      //CSV += ReportTitle + "\r\n\n";

      //This condition will generate the Label/Header
      if (ShowLabel) {
        let row = "";

        //This loop will extract the label from 1st index of on array
        for (let index in arrData[0]) {
          //Now convert each value to string and comma-seprated
          row += index + ",";
        }

        row = row.slice(0, -1);

        //append Label row with line break
        CSV += row + "\r\n";
      }

      //1st loop is to extract each row
      for (let i = 0; i < arrData.length; i++) {
        let row = "";

        //2nd loop will extract each column and convert it in string comma-seprated
        for (let index in arrData[i]) {
          row += '"' + arrData[i][index] + '",';
        }

        row.slice(0, row.length - 1);

        //add a line break after each row
        CSV += row + "\r\n";
      }

      if (CSV == "") {
        alert("Invalid data");
        return;
      }

      //Generate a file name
      let fileName = ReportTitle + new Date().toUTCString();
      //this will remove the blank-spaces from the title and replace it with an underscore

      //Initialize file format you want csv or xls
      let uri = "data:text/csv;charset=utf-8," + escape(CSV);

      // Now the little tricky part.
      // you can use either>> window.open(uri);
      // but this will not work in some browsers
      // or you will not get the correct file extension

      //this trick will generate a temp <a /> tag
      let link = document.createElement("a");
      link.href = uri;

      //set the visibility hidden so it will not effect on your web-layout
      link.style = "visibility:hidden";
      link.download = fileName + ".csv";

      //this part will append the anchor tag and remove it after automatic click
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getTimeDifference(dt1, dt2) {
      return Math.abs((dt1.getTime() - dt2.getTime()) / 1000);
    },
    maxNchars(val, n) {
      let rtn = val;
      if (val.length > n) rtn = val.slice(0, n - 3) + "...";
      return rtn;
    },
    addYearsToDate(date, year) {
      let now = date.getFullYear();
      return new Date(now + year, 1, 1);
    },
    addDays(date, days) {
      let result = new Date(date);

      result.setDate(result.getDate() + days);

      return result;
    },
    addMonths(date, month) {
      let result = new Date(date);
      result.setMonth(result.getMonth() + month);
      return result;
    },
    addMonthsFix(date, months) {
      let d = date.getDate();
      date.setMonth(date.getMonth() + +months);
      if (date.getDate() != d) {
        date.setDate(0);
      }
      return date;
    },
    getMonthName(date) {
      return date.toLocaleString("default", { month: "long" });
    },
    calculatePercentage(value1, total) {
      return value1 == 0 || total == 0 ? 0 : parseFloat((value1 / total) * 100);
    },
    filterString(columnValue, filterKey) {
      let rtn = "";
      if (typeof columnValue != "undefined" && columnValue != null) {
        rtn = columnValue.toString().toLowerCase();
      }

      return rtn.indexOf(filterKey.toLowerCase()) > -1;
    },
    filterNumeric(columnValue, filterKey) {
      let rtn = true;
      if (
        typeof columnValue != "undefined" &&
        columnValue != null &&
        filterKey != ""
      ) {
        rtn = columnValue == parseInt(filterKey);
      }

      return rtn;
    },
    setReportGridHeight(windowHeight, summary) {
      let rtn = 300;
      let gap = 200;
      if (typeof summary != "undefined" && summary != null) {
        gap += summary.clientHeight;
      }
      rtn = parseInt(windowHeight - gap);
      rtn = rtn < 300 ? 300 : rtn;
      return rtn;
    },
    getApiErrorMessage(err) {
      let errMsg = "An Error Occured!";
      if (typeof err != "undefined" && err) {
        if (typeof err.response != "undefined" && err.response) {
          if (err.response.status == 401) {
            errMsg = "You are not authorized to perform this action.";
          }
          if (typeof err.response.data != "undefined" && err.response.data) {
            if (
              typeof err.response.data.Errors != "undefined" &&
              err.response.data.Errors
            )
              errMsg = err.response.data.Errors.join(",");
          }
        }
      }
      return errMsg;
    },
    base64ForExcelExport: function (s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    formatForExcelExport: function (s, c) {
      return s.replace(/{(\w+)}/g, function (m, p) {
        return c[p];
      });
    },
    exportExcel(table, name) {
      let uri = "data:application/vnd.ms-excel;charset=UTF-8;base64,";
      let template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';

      let ctx = {
        worksheet: name || "Worksheet",
        table: table.innerHTML,
      };

      let element = document.createElement("a");
      element.setAttribute(
        "href",
        uri +
          this.base64ForExcelExport(this.formatForExcelExport(template, ctx))
      );
      element.setAttribute(
        "download",
        name + " _" + new Date().getTime() + ".xls"
      );
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    cropFileNames(name) {
      name = name.length > 50 ? name.substr(0, 50) + "..." : name;
      return name;
    },

    linkUrl(url) {
      if (url != "") {
        let element = document.createElement("a");
        element.setAttribute("href", url);
        element.setAttribute("target", "_blank");
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    },
    getDisabledCreditorStatus() {
      //4: withdrawn
      //18: blocked
      return [4, 18];
    },
  },
};
