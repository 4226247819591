export default {

  CREATE_AND_POPULATE_CUSTOMER_DURING_CALL:"CREATE_AND_POPULATE_CUSTOMER_DURING_CALL",
  CREATE_CUSTOMER_AFTER_CALL:"CREATE_CUSTOMER_AFTER_CALL",
  LOG_BUFFER_CALL:"LOG_BUFFER_CALL",
  GET_3RD_PARTY_LIST: "GET_3RD_PARTY_LIST",
  GET_ANNOUNCE_DEPARTMENT_DIDS: "GET_ANNOUNCE_DEPARTMENT_DIDS",
  SET_ANNOUNCE_DEPARTMENT_DIDS: "SET_ANNOUNCE_DEPARTMENT_DIDS",
  CONVERT_MAILER_DUMP_TO_CUSTOMER: "CONVERT_MAILER_DUMP_TO_CUSTOMER",
  GET_ENDPOINT: "GET_ENDPOINT",
  SET_ENDPOINT: "SET_ENDPOINT",
  GET_ENDPOINT_LIST: "GET_ENDPOINT_LIST",
  SET_ENDPOINT_LIST: "SET_ENDPOINT_LIST",
  GET_DID_LIST: "GET_DID_LIST",
  GET_EMPLOYEES: "GET_EMPLOYEES",
  SET_EMPLOYEES: "SET_EMPLOYEES",
  GET_CALLER_ID_ENDPOINT: "GET_CALLER_ID_ENDPOINT",
  GET_DETAILS_BY_PHONE: "GET_DETAILS_BY_PHONE",
  HOLD_CALL: "HOLD_CALL",
  GRAB_CALL: "GRAB_CALL",
  GET_DISPOSITIONS: "GET_DISPOSITIONS",
  SET_DISPOSITIONS: "SET_DISPOSITIONS",
  TRANSFER_TO_AGENT: "TRANSFER_TO_AGENT",
  TRANSFER_TO_DID: "TRANSFER_TO_DID",
  GET_AVAILABLE_VM_DROPS: "GET_AVAILABLE_VM_DROPS",
  SET_AVAILABLE_VM_DROPS: "SET_AVAILABLE_VM_DROPS",
  TRANSFER_TO_VM_DROP: "TRANSFER_TO_VM_DROP",
  TRANSFER_TO_AGENT_VM: "TRANSFER_TO_AGENT_VM",
  TRANSFER_TO_INGROUP: "TRANSFER_TO_INGROUP",
  INSERT_MANDATORY_DISPO: "INSERT_MANDATORY_DISPO",
  GET_MANDATORY_DISPO: "GET_MANDATORY_DISPO",
  INSERT_CUSTOMER_DISPO_LOG: "INSERT_CUSTOMER_DISPO_LOG",
  NEXT_CALL_LIST: "NEXT_CALL_LIST",
  RESET_STATES: "RESET_STATES",
  SET_RESET_STATES: "SET_RESET_STATES",
  GET_CAMPAIGN_LEADS: "GET_CAMPAIGN_LEADS",

  TRANSFER_BOTH_SIDE: "TRANSFER_BOTH_SIDE",
  KICK_CONFERENCE: "KICK_CONFERENCE",
  DELETE_CONFERENCE: "DELETE_CONFERENCE",
  GET_LIVE_CONFERENCE_NAMES: "GET_LIVE_CONFERENCE_NAMES",
  TRANSFER_TO_CONFERENCE: "TRANSFER_TO_CONFERENCE",
  KICK_3RD_PARTY: "KICK_3RD_PARTY",
  TRANSFER_TO_NEW_CONFERENCE: "TRANSFER_TO_NEW_CONFERENCE",
  SEND_INCOMING_CALL_LOG: "SEND_INCOMING_CALL_LOG",

  GET_CREDITOR_CONTACTS: "GET_CREDITOR_CONTACTS",
  SET_CREDITOR_CONTACTS: "SET_CREDITOR_CONTACTS",
  INSERT_CREDITOR_CONTACT: "INSERT_CREDITOR_CONTACT",
  UPDATE_CREDITOR_CONTACT: "UPDATE_CREDITOR_CONTACT",
  DELETE_CREDITOR_CONTACT: "DELETE_CREDITOR_CONTACT",

  REQUEST_CSR_TASK: "REQUEST_CSR_TASK",
  CHECK_CAN_REQUEST_TASK: "CHECK_CAN_REQUEST_TASK",
  GET_CALLER_ID_BY_CUSTOMER: "GET_CALLER_ID_BY_CUSTOMER",
  RESET_CAMPAIGN_LEADS: "RESET_CAMPAIGN_LEADS",

  GET_ANNOUNCE_CALL_LOG: "GET_ANNOUNCE_CALL_LOG",
  INSERT_ANNOUNCE_CALL_LOG: "INSERT_ANNOUNCE_CALL_LOG",
  UPDATE_ANNOUNCE_CALL_LOG: "UPDATE_ANNOUNCE_CALL_LOG",
};
