<template>
  <div
    style="
      position: relative;
      margin: 5% auto;
      padding: 15px 0;
      background-color: white;
    "
    class="offset-xl-4 col-xl-4 offset-lg-2 col-lg-8 col-md-12 col-sm-12 shadow-lg"
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="exitButton" @click="closePopup()">
      <i class="fas fa-window-close"></i>
    </div>
    <div class="col-md-12 pt-2">
      <label></label>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-3">
          <label class="mt-1 ml-2">Action:</label>
        </div>
        <div class="col-md-9">
          <select id="actionSelect" class="form-control" v-model="taskAction">
            <option :value="null">Select a task action</option>
            <option
              v-for="item in task.CsrTaskTypeActions"
              :key="item.Id"
              :value="item"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-if="taskAction && taskAction.OverrideSubtaskCreation == true">
      <div class="row">
        <div class="col-md-3">
          <label class="generalLabel mt-1 ml-2">Select a Task Type </label>
        </div>

        <div class="col-md-9">

          <select v-model="selectedTaskType" id="slcTasks" class="form-control">
            <option :value="null">Not Selected</option>
            <option
              v-for="item in filteredTaskTypes"
              :key="item.Id"
              :value="item"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>
        <div class="col-md-12" v-if="selectedTaskType">
          <ClientAddNewTaskPopUp
            :taskType="selectedTaskType"
            :customerId="task.CustomerId"
            :isInnerComponent="true"
            :previousTaskId="task.Id"
            @submitTaskAction="submitTaskAction"
          ></ClientAddNewTaskPopUp>
        </div>
      </div>
    </div>

    <div class="col-md-12 pt-4" v-if="checkRequiresPhone()">
      <div class="row">
        <div class="col-md-3">
          <label class="mt-1 ml-2">Postpone Date:</label>
        </div>
        <div class="col-md-9">
          <DatePicker
            mode="datetime"
            :available-dates="datepickerConfig"
            :locale="'en-US'"
            v-model="taskDueDate"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                id="DateOfBirthOfTaskPopup"
                class="form-control"
                :value="inputValue"
                placeholder="Postpone Date"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-if="checkRequiresPhone()">
      <div class="row">
        <label class="col-md-3">&nbsp;</label>

        <label class="col-md-9"
          >Allowed Postpone Range: 1
          {{
            task.CsrTaskType.CsrTaskTypeSetting.MaxPostponeDays > 1
              ? "-" + task.CsrTaskType.CsrTaskTypeSetting.MaxPostponeDays
              : ""
          }}
          Day</label
        >
      </div>
    </div>
    <div class="col-md-12 mt-4">
      <div class="row">
        <!-- <div class="offset-xl-1 col-xl-3 col-lg-4 col-md-4 col-xs-12">
            <button id="ctCanelButton" class="btn btn-danger form-control">
              Cancel
            </button>
          </div> -->
        <!-- <div class="offset-xl-2 col-xl-3 col-lg-4 col-md-4  col-xs-12 text-right"> 
            <button id="ctIncomplateButton" class="btn btn-warning form-control">
              Incomplete
            </button>
          </div> -->
        <div class="col-md-12" v-if="!(taskAction && taskAction.OverrideSubtaskCreation == true && selectedTaskType && selectedTaskType.Id > 0)">
          <button
            :disabled="taskAction == null || isLoading"
            @click="submitTask()"
            id="ctComplateButton"
            class="btn btn-success form-control"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientAddNewTaskPopUp from "./ClientAddNewTaskPopUp.vue";
import { mapState } from "vuex";
import _orderby from "lodash";
import { DatePicker } from "v-calendar";
import globalTypes from "@/store/types";
import types from "./types";
import utilitiesMixin from "@/mixins/utilitiesMixin";
export default {
  name: "ClientTaskPopUp",
  mixins: [utilitiesMixin],
  components: {
    DatePicker,
    ClientAddNewTaskPopUp,
  },
  props: {
    task: {
      Type: Object,
      Default: () => null,
    },
  },
  computed: mapState({
    taskTypes: (state) => state.globals.taskTypeNames,
    filteredTaskTypes() {
      return this.checkAuth(200010)
        ? _orderby.orderBy(this.taskTypes, "Name", "asc")
        : _orderby.orderBy(
            this.taskTypes.filter((x) => x.isTicketType != true),
            "Name",
            "asc"
          );
    },
  }),
  async mounted() {

    await Promise.all([
      this.$store.dispatch(globalTypes.GET_TASK_TYPE_NAMES),
    ]);
  },
  data() {
    return {
      isLoading: false,
      taskDueDate: new Date().toLocaleString(),
      taskAction: null,
      selectedTaskType: null,
      datepickerConfig: {
        start: new Date(),
        end: this.addDays(
          new Date(),
          this.task.CsrTaskType.CsrTaskTypeSetting.MaxPostponeDays
        ),
      },
    };
  },
  methods: {
    submitTaskAction(isAdded){
      if(isAdded == true)
      {
        this.submitTask();
      }
    },
    checkRequiresPhone() {
      let rtn = false;
      if(this.taskAction)
      {
      let indx = this.task.CsrTaskTypeActions.findIndex(
        (x) => x.Id == this.taskAction.Id
      );
      if (indx > -1) {
        rtn = this.task.CsrTaskTypeActions[indx].RequiresDateChange;
      }
      }

      return rtn;
    },
    async submitTask() {
      this.isLoading = true;
      if (this.task.Id > 0 && this.taskAction && this.taskAction.Id > 0) {
        let err, result;

        [err, result] = await this.$store.dispatch(types.SUBMIT_TASK, {
          Id: this.task.Id,
          ActionId: this.taskAction.Id,
          Date: new Date(this.taskDueDate).toLocaleString(),
          OverrideSubTask: this.taskAction.OverrideSubtaskCreation
        });
        if (result && result.Data) {
          this.$Emitter.emit("refreshCsrTaskDistro");
          this.$emit("updateTask");
          this.$emit("closeTaskPopup");
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
          this.$emit("closeTaskPopup");
        }
      }
      this.isLoading = false;
    },
    closePopup() {
      this.$emit("closeTaskPopup");
    },
    addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
  },
};
</script>

<style>
.clientTaskPopUpBackground {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainPopUp {
  position: absolute;
  height: auto;
  border-radius: 5px;
  background-color: white;
}

.popUpButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.allButtons {
  display: flex;
  justify-content: space-evenly;
}
.CTactionSelect {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.allowedPostPonteRangeText {
  display: flex;
  justify-content: center;
  background-color: rgba(240, 240, 240, 0.123);
  width: auto;
  padding: 4px 8px;
}

.bottomButtons {
  display: flex;
  justify-content: space-around;
  padding: 30px 0;
}
.exitButton {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 2em;
  color: #f1556c;
  cursor: pointer;
  z-index: 1001;
}
</style>
