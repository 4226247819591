import axios from "axios";

const API_URL = process.env.VUE_APP_API;

const instance = axios.create({
  baseURL: "",
  timeout: 120000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(sessionStorage.userInfo).token;

    if (token !== null && token !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
export default {

  getCustomerOptinStatus: (data) => {
    return instance.post(`${API_URL}/api/Sms`, {
      Type: "getsmsoptinstatus",
      Data: data
    });
  },
  getUserActivityHistory: (data) => {
    return instance.post(`${API_URL}/api/UserActivity`, {
      Type: "getHistory",
    });
  },

  getBlockedCreditorsAndCreditTypes: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getBlockedCreditorInfo",
    });
  },

  getQuickEmailNames: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getquickemail",
      Data: data,
    });
  },
  getContactTriggersByTaskId: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskContactTrigger`, {
      Type: "getcontacttriggerbytask",
      Data: data,
    });
  },

  OptOutAutoCall: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskContactTrigger`, {
      Type: "updateautocalloptinstatus",
      Data: data,
    });
  },

  getXChangeCustomers: (data) => {
    return instance.post(`${API_URL}/api/XChange`, {
      Type: "grid.get",
      Data: data,
    });
  },

  assignXChangeCustomer: (data) => {
    return instance.post(`${API_URL}/api/XChange`, {
      Type: "grid.assign",
      Data: data,
    });
  },

  paymentChangesCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "payment.create",
      Data: data,
    });
  },
  getPayeeDetails: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "payment.getpayee",
      Data: data,
    });
  },
  getSettledCreditors: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcustomercreditor",
      Data: data,
    });
  },
  requestAgentIp: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getrequestip",
    });
  },
  getCustomerPrimaryPhone: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "getPhoneByCustomerId",
      Data: data,
    });
  },

  getCsrTicketDashboard: (payload) => {
    return instance.post(`${API_URL}/api/TicketDashboard`, payload);
  },
  updateBulkRole: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "bulkroleupdate",
      Data: data,
    });
  },

  updateBulkUser: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "bulkassignuserupdate",
      Data: data,
    });
  },

  updateBulkDate: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "bulkduedateupdate",
      Data: data,
    });
  },
  getUserAccessRoles: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getuseraccessroles",
    });
  },
  getAllUsersAccessTickets: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getallusersaccesstickets",
    });
  },
  getAllCustomerTagTypes: () => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "getAllCustomerTagType",
    });
  },
  getCustomer: (customerId) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "getbyid",
      Data: { Id: customerId },
    });
  },
  getPhoneTypes: () => {
    return instance.post(`${API_URL}/api/Reference`, { Type: "getphonetypes" });
  },
  getPriorityTypes: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getprioritytypes",
    });
  },
  getHardships: () => {
    return instance.post(`${API_URL}/api/Reference`, { Type: "gethardships" });
  },
  getOccupations: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getoccupations",
    });
  },

  getCreditRating: () => {
    return instance.get(`${API_URL}/api/CreditRating`);
  },
  getMaritalStatus: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getmaritalstatustypes",
    });
  },
  getRelationShip: () => {
    return instance.get(`${API_URL}/api/RelationShip`);
  },
  getOccupation: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getoccupations",
    });
  },

  deleteEmail: (payload) => {
    return instance.post(`${API_URL}/api/Customer`, payload);
  },
  deletePhone: (payload) => {
    return instance.post(`${API_URL}/api/Customer`, payload);
  },

  getDispositions: (customerId) => {
    return instance.post(`${API_URL}/api/CustomerDispositionLog`, {
      Type: "getdispositionlogsbycustomerid",
      Data: { CustomerId: customerId },
    });
  },
  insertDispositionV2: (data) => {
    return instance.post(`${API_URL}/api/CustomerDispositionLog`, {
      Type: "insertV2",
      Data: data,
    });
  },
  getCustomerLogs: (customerId) => {
    return instance.post(`${API_URL}/api/CustomerDispositionLog`, {
      Type: "getsystemlogsbycustomerid",
      Data: { CustomerId: customerId },
    });
  },

  getCampaignTypes: () => {
    return instance.get(`${API_URL}/api/CampaignType`);
  },
  getAssignedAgents: (agentId) => {
    return instance.post(`${API_URL}/api/User`, {
      Type: "assignedAgents",
      Data: { Id: agentId },
    });
  },
  getNotifications: () => {
    return instance.post(`${API_URL}/api/Notification`, { Type: "pending" });
  },
  checkNewNotifications: (data) => {
    return instance.post(`${API_URL}/api/Notification`, {
      Type: "getnew",
      Data: data,
    });
  },
  updateUserLoginStatus: (data) => {
    return instance.post(`${API_URL}/api/Notification`, {
      Type: "updateloginstatuslog",
      Data: data,
    });
  },
  dismissNotification: (notificationId) => {
    return instance.post(`${API_URL}/api/Notification`, {
      Type: "dismiss",
      Id: notificationId,
    });
  },
  postponeNotification: (notificationId, postponeMinute) => {
    return instance.post(`${API_URL}/api/Notification`, {
      Type: "postpone",
      Id: notificationId,
      Minutes: postponeMinute,
    });
  },
  getNotificationsByDate: (year, month) => {
    return instance.post(`${API_URL}/api/Notification/Year`, {
      Type: "pendingbymonthandyear",
      Month: month,
      Year: year,
    });
  },
  getCustomerRemindes: (customerId) => {
    return instance.post(`${API_URL}/api/Notification`, {
      Type: "customerall",
      CustomerId: customerId,
    });
  },
  insertCustomer: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "insert",
      Data: data,
    });
  },
  updateCustomer: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "update",
      Data: data,
    });
  },

  getCustomers: (data) => {
    return instance.post(`${API_URL}/api/CustomerSearch`, data);
  },
  insertNotification: (data) => {
    return instance.post(`${API_URL}/api/Notification`, {
      Type: "insert",
      Notification: data,
    });
  },
  updateNotification: (data) => {
    return instance.post(`${API_URL}/api/Notification`, {
      Type: "update",
      Notification: data,
    });
  },
  deleteNotification: (id) => {
    return instance.post(`${API_URL}/api/Notification/`, {
      Type: "delete",
      Id: id,
    });
  },
  getZipData: (data) => {
    return instance.post(`${API_URL}/api/ziplookup`, data);
  },
  deleteCustomer: (customerId) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "delete",
      Data: { Id: customerId },
    });
  },
  getLast5Reminders: (customerId) => {
    return instance.post(`${API_URL}/api/Notification`, {
      Type: "customerlast5",
      CustomerId: customerId,
    });
  },
  getLast5Dispos: (customerId) => {
    return instance.post(`${API_URL}/api/CustomerDispositionLog`, {
      Type: "getlast5dispositionlogsbycustomerid",
      Data: { CustomerId: customerId },
    });
  },
  getFavs: () => {
    return instance.get(`${API_URL}/api/UserFavorite`);
  },
  insertFav: (data) => {
    return instance.post(`${API_URL}/api/UserFavorite`, data);
  },
  deleteFav: (data) => {
    return instance.delete(`${API_URL}/api/UserFavorite`, data);
  },

  sendOptinRequest: (data) => {
    return instance.post(`${API_URL}/api/Sms`, {
      Type: "sendoptinrequestsms",
      Data: data,
    });
  },

  getSmsLogs: (data) => {
    return instance.post(`${API_URL}/api/Sms`, {
      Type: "smslogs",
      Data: { PhoneNumber: data },
    });
  },

  optOutNumber: (data) => {
    return instance.post(`${API_URL}/api/Sms`, {
      Type: "optout",
      Data: { PhoneNumber: data },
    });
  },

  sendSms: (data) => {
    return instance.post(`${API_URL}/api/Sms`, {
      Type: "sendsms",
      Data: data,
    });
  },

  getCallLogs: (data) => {
    return instance.post(`${API_URL}/api/CallLog/ByPhoneNumbers`, data);
  },

  getCampaignsWithDisabled: (CampaignId) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "getforcustomerpage",
      Data: { Id: CampaignId },
    });
  },

  getAccountTypes: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getaccounttypes",
    });
  },
  getResidenceOwnershipTypes: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getresidenceownershiptypes",
    });
  },
  getExpenseTypes: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getexpensetypes",
    });
  },
  getCompanyLogo: (data) => {
    return instance.get(`${API_URL}/api/DomainLogo`);
  },
  getCustomerDocumentTemplates: () => {
    return instance.post(`${API_URL}/api/CustomerDocument`, {
      Type: "listtemplates",
    });
  },
  getCustomerDocumentList: (data) => {
    return instance.post(`${API_URL}/api/CustomerDocument`, {
      Type: "list",
      Data: data,
    });
  },
  deleteCustomerDocument: (data) => {
    return instance.post(`${API_URL}/api/CustomerDocument`, {
      Type: "delete",
      Data: data,
    });
  },
  renameCustomerDocument: (data) => {
    return instance.post(`${API_URL}/api/CustomerDocument`, {
      Type: "rename",
      Data: data,
    });
  },
  uploadExtraDocuments: (data) => {
    return instance.post(`${API_URL}/api/CustomerDocument`, {
      Type: "upload",
      Data: data,
    });
  },

  getNotes: (data) => {
    return instance.post(`${API_URL}/api/CustomerNotes`, {
      Type: "getnotes",
      CustomerId: data.Id,
      CsrTaskId: data.CsrTaskId,
      CreditorId: data.CreditorId,
    });
  },
  addNotes: (data) => {
    return instance.post(`${API_URL}/api/CustomerNotes`, {
      Type: "addnote",
      CustomerId: data.CustomerId,
      Note: data.Note,
      CsrTaskId: data.CsrTaskId,
      CreditorId: data.CreditorId,
    });
  },
  getSalesAgents: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getsalesagents",
    });
  },
  getCsrs: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcsrs",
    });
  },


  getCreditorStatus: () => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "getCreditorStatus",
    });
  },
  getSettlementStatus: () => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "getSettlementStatus",
    });
  },

  verifyUsps: (data) => {
    return instance.post(`${API_URL}/api/UspsVerification`, {
      Type: "verify",
      Address: data.Address,
      Address2: data.Address2,
      City: data.City,
      StateShort: data.StateShort,
      Zip: data.Zip,
    });
  },

  unassignTask: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "unassign",
      Data: data,
    });
  },

  getAllTasks: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "getallv2", //getall
      Data: data,
    });
  },

  getTask: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "gettask",
      Data: data,
    });
  },

  assignTask: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "assign",
      Data: data,
    });
  },

  submitTask: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "submit",
      Data: data,
    });
  },

  addNewTask: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "addnew",
      Data: data,
    });
  },



  gcsTabSkipPaymentCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "skippayment.create",
      Data: data,
    });
  },

  gcsTabDraftCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "draft.create",
      Data: data,
    });
  },
  feeChangesCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "fee.create",
      Data: data,
    });
  },

  gcsTabClinetInfoCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "clientinfo.create",
      Data: data,
    });
  },

  gcsTabDocReviewCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "docreview.create",
      Data: data,
    });
  },

  gcsTabClientStatusCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "clientstatus.create",
      Data: data,
    });
  },
  gcsTabClientStatusReleaseTypes: () => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "clientstatus.releasetypes",
    });
  },
  gcsTabAdditionalNoteCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "additionalnote.create",
      Data: data,
    });
  },
  gcsTabExtraPaymentCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "extrapayment.create",
      Data: data,
    });
  },
  gcsTabFeesGetTypes: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "fees.gettypes",
      Data: data,
    });
  },
  gcsTabExtraPaymentDraftTypes: () => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "extrapayment.drafttypes",
    });
  },




  phonePayInfo: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.tab.info.phonepayinfo",
      Data: data,
    });
  },

  accountNumberValidation: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "accountnumbervalidation",
      Data: data,
    });
  },

  getCampaigns: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcampaignnames",
    });
  },
  getCampaignsV2: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcampaignnamesV2",
      Data: data,
    });
  },

  createAuthorizationText: (data) => {
    return instance.post(`${API_URL}/api/DocumentAuthorizationType`, {
      Type: "createAuthorizationText",
      Data: data,
    });
  },
  getAuthorizationType: (data) => {
    return instance.post(`${API_URL}/api/DocumentAuthorizationType`, {
      Type: "getAuthorizationType",
      Data: data,
    });
  },
  getAllAuthorizationType: () => {
    return instance.post(`${API_URL}/api/DocumentAuthorizationType`, {
      Type: "getAll",
    });
  },
  replaceRepeatableText: (data) => {
    return instance.post(`${API_URL}/api/DocumentAuthorizationType`, {
      Type: "replaceRepeatableText",
      Data: data,
    });
  },

  updateBulkStatus: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "bulkstatusupdate",
      Data: data,
    });
  },
  updateBulkAgent: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "bulkagentupdate",
      Data: data,
    });
  },
  distributeCsrTask: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "distribute",
      Data: data,
    });
  },
  checkCanRequestTask: (id) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "checktasktyperole",
      Data: { UserId: id },
    });
  },


  editPayeeAddress: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.address.edit",
      Data: data,
    });
  },
  removePayeeAddress: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.address.remove",
      Data: data,
    });
  },
  addPayeeAddress: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.address.add",
      Data: data,
    });
  },

  editPayeeBank: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.bank.edit",
      Data: data,
    });
  },
  removePayeeBank: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.bank.remove",
      Data: data,
    });
  },
  addPayeeBank: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.bank.add",
      Data: data,
    });
  },

  addPayee: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.add",
      Data: data,
    });
  },
  searchPayee: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.search",
      Data: data,
    });
  },

  getCustomerMessages: (data) => {
    return instance.post(`${API_URL}/api/UserMessage/`, {
      Type: "getcustomermessages",
      Data: data,
    });
  },
  getSendMessage: (data) => {
    return instance.post(`${API_URL}/api/UserMessage/`, {
      Type: "sendmessage",
      Data: data,
    });
  },

  getCustomerInfoForPortal: (data) => {
    return instance.post(`${API_URL}/api/UserMessage/`, {
      Type: "getcustomerdata",
      Data: data,
    });
  },
  csrTaskUpdateNote: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "updatenote",
      Data: data,
    });
  },

  sendHotLeadLog: (data) => {
    return instance.post(`${API_URL}/api/ServiceBusLog`, {
      Type: "insert",
      Data: data,
    });
  },

  getAllPaymentOptions: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getallpaymentoptions",
    });
  },
  getActivePoaCreditor: (data) => {
    return instance.post(`${API_URL}/api/CreditorContact`, {
      Type: "getactivepoacreditor",
      Data: data,
    });
  },

  sendEFax: (data) => {
    return instance.post(`${API_URL}/api/PoaInfo`, {
      Type: "SendEfax",
      Data: data,
    });
  },

  getCustomerCancellationInfo: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.cancellationinfo",
      Data: data,
    });
  },

  insertUserActivity: (data) => {
    return instance.post(`${API_URL}/api/UserActivity`, {
      Type: "insert",
      Data: data,
    });
  },

  updateCsrTriggerLogAnsweredUser: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "UpdateCsrTriggerLogAnsweredUser",
      Data: data,
    });
  },


  pushToTCI: (data) => {
    return instance.post(`${API_URL}/api/DecisionLenderApi`, data);
  },


};
