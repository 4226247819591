import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Oruga from "@oruga-ui/oruga-next";
import "@oruga-ui/oruga-next/dist/oruga-full.css";
import inputmask from "inputmask";
import vClickOutside from "click-outside-vue3";
import mitt from "mitt";
import vueSweetalert2 from "vue-sweetalert2";
import "vue3-ts-picker/dist/vue3-ts-picker.css";
import "vue-swatches/dist/vue-swatches.css";
import "sweetalert2/dist/sweetalert2.min.css";
import axios from "axios";
import "@/assets/style/bootstrap.scss";
import "@/assets/style/custom.scss";
import "@/assets/style/icons.scss";
import "@/assets/style/main.scss";
import "@/assets/style/datepicker.css";
import "@/assets/style/fullcalendar.min.css";
import "@/assets/style/icons.min.css";
import "@/assets/style/loader.css";
import "@/assets/style/timeline.min.css";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import "animate.css";
import datejs from "datejs";
import VueApexCharts from "vue3-apexcharts";
import Vue3TouchEvents from "vue3-touch-events";

const app = createApp(App)
  .use(router)
  .use(store)
  .use(VueApexCharts)
  .use(Vue3TouchEvents)
  .use(PerfectScrollbar)
  .use(vClickOutside)
  .use(Oruga, {
    table: {
      override: true,
      mobileCards: false,
      wrapperClass: "b-table table-wrapper",
      tableClass: "table",
      borderedClass: "is-bordered",
      stripedClass: "is-striped",
      narrowedClass: "is-narrow",
      hoverableClass: "is-hoverable",
      emptyClass: "is-empty",
      detailedClass: "detail",
      footerClass: "table-footer",
      scrollableClass: "is-scrollable",
      trSelectedClass: "is-selected",
      thSortableClass: "is-sortable",
    },
  })
  .use(vueSweetalert2)
  .use(datejs);
app.config.globalProperties.$loginLogo = `${process.env.VUE_APP_API}/api/DomainLogo/`;
app.config.globalProperties.$logo = "";
app.config.globalProperties.$MaxAutoAcceptCallIndex = 14;
app.config.globalProperties.$Emitter = mitt();
app.config.globalProperties.$CsmVersion = process.env.VUE_APP_CRM_VERSION;
window.MyPeer = null;

app.config.globalProperties.$ServiceBus = null;
app.config.globalProperties.$ServiceBusAdmin = null;
app.config.compilerOptions.isCustomElement = (tag) => {
  return tag.startsWith("array-");
};

axios
  .get(`${process.env.VUE_APP_API}/api/DomainLogo/`)
  .then((res) => {
    app.config.globalProperties.$loginLogo = res.request.responseURL;
    app.config.globalProperties.$logo =
      app.config.globalProperties.$loginLogo.replace(
        /(\.[\w\d_-]+)$/i,
        "-light$1"
      );
  })
  .catch((err) => {});

app.mount("#app");
app.directive("showIfAuthorized", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      if (!arrPermission.includes(parseInt(binding.value))) {
        //vnode.elm.parentElement.removeChild(vnode.elm);
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });

        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;
        //vnode.data.directives = null;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("enableIfAuthorized", {
  beforeMount(el, binding) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      if (!arrPermission.includes(parseInt(binding.value))) {
        if (el.tagName === "INPUT" && el.type === "text") {
          el.setAttribute("readonly", "");
          el.style.opacity = 0.5;
        }
        el.setAttribute("disabled", "'disabled'");
        el.style.opacity = 0.5;
      }
    }
  },
});
app.directive("myMask", {
  // mask directive
  mounted(el, binding) {
    let maskOpts = binding.value;
    maskOpts.showMaskOnHover = false;
    maskOpts.autoUnmask = true;
    inputmask(maskOpts).mask(el);
  },
  unmounted() {},
});
app.directive("showAdminText", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrAdmin = [
        1453, 9542, 5146, 3432, 8913, 8914, 5377, 6580, 952, 1580, 1496, 3841,
        1662, 2774, 8361, 3891, 3901, 1588, 64326, 1453, 3908, 3917, 3922, 240,
        273, 921085, 3923, 677991,
      ];

      for (let value of arrAdmin) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("showClientSettings", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [1096, 952, 5146, 3432, 1580, 1588, 64326, 55840];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("showEmployeeSettings", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [1662, 9542, 1496, 6580, 6581, 3841];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("showCompanySettings", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [1453, 2774, 8361, 3891, 921085, 677991];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});

app.directive("showTasksModule", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [7470, 9127, 8593, 10022, 12400, 200010];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("showBulkOperations", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [7525, 7535];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("showReports", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [
        3590, 8113, 8263, 7261, 3715, 5366, 5578, 8162, 3821, 6180, 6582, 7567,
        9547, 9677, 8275, 64325, 4721, 61346, 33453, 81547, 97696, 55417, 8234,
        48214, 55836, 55840, 65837, 65838, 65839, 65840,
      ];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("showPhoneSettings", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [3908, 3917, 3922, 240, 273,54984];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
