<template>
  <div
    class="col-md-12 newPanelWrapper"
    style="background-color: #ffffff; z-index: 1001"
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-md-12 clientPortalBox">
      <h5 style="color: white" class="float-right font-weight-bold">
        {{ isPortalMessage ? "PORTAL" : "EMAIL" }}
      </h5>
      <div class="closeButtons" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>

    <div class="pt-2 row">
      <div class="col-md-6">
        <label for="emailTo-puf" class="generalLabel">Email *</label>
        <input
          autocomplete="none"
          id="emailTo-puf"
          type="text"
          :oninput="(this.email.To = this.email.To.toLowerCase())"
          v-model="email.To"
          class="form-control"
          placeholder="Email"
          :class="v$.email.To.$error ? 'has-error' : ''"
          @change="v$.email.To.$touch"
        />
      </div>
      <div class="col-md-6">
        <label for="emailCc-puf" class="generalLabel">Email CC</label>
        <input
          autocomplete="none"
          id="emailCc-puf"
          type="text"
          v-model="email.Cc"
          class="form-control"
          placeholder="Email CC"
        />
      </div>
      <div class="col-md-6 pt-2">
        <label for="emailBcc-puf" class="generalLabel">Email BCC</label>
        <input
          autocomplete="none"
          v-model="email.Bcc"
          id="emailBcc-puf"
          type="text"
          class="form-control"
          placeholder="Email BCC"
        />
      </div>
      <div class="col-md-6 pt-2">
        <label for="emailSubject-puf" class="generalLabel">Subject *</label>
        <input
          autocomplete="none"
          id="emailSubject-puf"
          type="text"
          v-model="email.Subject"
          class="form-control"
          placeholder="Subject"
          :class="v$.email.Subject.$error ? 'has-error' : ''"
          @change="v$.email.Subject.$touch"
        />
      </div>
    </div>

    <div class="pt-2">
      <div>
        <label>Message Body:</label>
      </div>
      <div
        v-if="!email.IsExternalHtml"
        class="portalTextEditor"
        style="color: black"
      >
        <vue-editor
          v-model="email.Body"
          id="textEditor-pu"
          :editorToolbar="customToolbar"
        >
        </vue-editor>
      </div>
      <div
        v-if="email.IsExternalHtml"
        style="width: 100%; height: 200px; overflow: auto"
        @keypress.prevent="() => {}"
        @keydown.prevent="() => {}"
        @keyup.prevent="() => {}"
        @contextmenu.prevent="() => {}"
      >
        <div
          class="col-md-12"
          style="
            border: 1px solid #ccc;
            padding: 10px;
            pointer-events: none !important;
          "
          v-html="email.Body"
        ></div>
      </div>
      <div class="row mt-2" v-if="email.IsExternalHtml">
        <div class="col-md-6">
          <label class="generalLabel">Replace this text</label>
          <input
            type="text"
            class="form-control"
            placeholder="Replace"
            v-model="replaceText"
          />
        </div>
        <div class="col-md-6">
          <label class="generalLabel">To</label>
          <div style="display: flex; gap: 10px">
            <input
              type="text"
              class="form-control"
              placeholder="To"
              v-model="toText"
            />
            <button class="btn btn-success" @click="replaceString">
              <i class="fas fa-exchange-alt"></i>
            </button>
            <button
              v-if="previousBody != ''"
              class="btn btn-danger"
              @click="undoChanges"
            >
              <i class="fas fa-undo"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-2">
      <div v-if="!isPortalAuthMessage">
        <label>Attachments:</label>
        <span
          id="txtSelectFile"
          type="button"
          class="badge badge-info ml-2"
          style="padding: 8px 10px"
          v-on:click.prevent.stop="$refs.uploader.click()"
        >
          Select File
        </span>
        <input
          type="file"
          ref="uploader"
          style="display: none"
          accept="docx,pdf,png,jpg,jpeg"
          @click="handleFileClick"
          @change="handleFileChange"
        />
      </div>

      <div class="form-group row" v-if="!isPortalAuthMessage">
        <div
          style="
            max-height: 100px;
            max-width: 100%;
            overflow-x: auto;
            overflow-y: auto;
            border: 1px solid #ffffff;
            border-radius: 0.25rem;
            padding: 0 8px 10px 8px;
          "
          class="col-xl-12 col-lg-12 col-md-12 col-xs-12"
        >
          <div
            v-for="item in email.Attachments"
            :key="item.DocumentName"
            class="mr-2"
          >
            <span style="width: auto; float: left">
              {{ item.DocumentName }}</span
            >
            <i
              @click="removeAttachment(item)"
              style="float: left; color: red; margin: 1px 3px; cursor: pointer"
              class="ri-close-circle-fill"
            ></i>
          </div>
        </div>
      </div>

      <div class="pt-2">
        <button
          v-if="!(isPortalMessage || isPortalAuthMessage)"
          type="button"
          @click="sendEmail()"
          class="btn form-control btn-success"
          :disabled="v$.$invalid || sendEmailSpinner == true"
        >
          <i
            v-if="sendEmailSpinner"
            class="spinner-border spinner-border-sm"
          ></i>
          Send Email
        </button>
        <button
          v-if="isPortalMessage || isPortalAuthMessage"
          type="button"
          @click="sendPortalMessage()"
          class="btn form-control btn-success"
          :disabled="v$.$invalid || sendPortalMessageSpinner == true"
        >
          <i
            v-if="sendPortalMessageSpinner"
            class="spinner-border spinner-border-sm"
          ></i>
          Send Portal Message
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue3-editor";
import placeholderTypes from "@/components/Placeholders/types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";
import paTypes from "@/views/agent/EditClient/PortalAuthorizations/types";
import useValidate from "@vuelidate/core";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "PortalUserInterface",
  props: {
    customer: {
      type: Object,
      default: () => null,
    },
    customerId: {
      type: Number,
      default: () => 0,
    },
    customerPrimaryEmail: {
      type: String,
      default: () => "",
    },
    quickEmailData: {
      type: Object,
      default: () => {},
    },
    isPortalMessage: {
      type: Boolean,
      default: true,
    },
    isPortalAuthMessage: {
      type: Boolean,
      default: false,
    },
    authTypeList: {
      type: Array,
      default: () => [],
    },
    attorneyName: {
      type: String,
      default: () => "",
    },
  },
  mixins: [formatMixin, utilitiesMixin],
  components: {
    VueEditor,
  },
  data() {
    return {
      isLoading: false,
      previousBody: "",
      replaceText: "",
      toText: "",
      v$: useValidate(),
      sendEmailSpinner: false,
      sendPortalMessageSpinner: false,
      userId: 0,
      saving: false,
      deleting: false,
      activeItem: null,
      bankNameFilter: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"],
      ],
      email: {
        From: "",
        To: "",
        ReplyTo: "",
        Cc: "",
        Bcc: "",
        Subject: "",
        Body: "",
        Attachments: [],
        CustomerId: null,
      },
      validFileExtensions: [
        "doc",
        "docx",
        "pdf",
        "xls",
        "xlsx",
        "jpg",
        "jpeg",
        "png",
      ],
    };
  },
  async mounted() {
    this.isLoading = true;
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.email.To = this.customerPrimaryEmail;
    if (!this.isPortalAuthMessage) {
      if (this.quickEmailData) {
        this.email.Bcc = this.quickEmailData.BccAddress;

        this.email.Subject = this.quickEmailData.Subject;

        if (this.customer && this.customer.LanguageId == 2) {
          this.email.Body = this.quickEmailData.SpanishContent;
        } else {
          this.email.Body = this.quickEmailData.Body;
        }
        this.email.IsExternalHtml = this.quickEmailData.IsExternalHtml;
      }

      if (userInfo) {
        this.userId = userInfo.userId;
        this.email.From = userInfo.email;
        this.email.Alias = userInfo.firstName + " " + userInfo.lastName;
        this.email.ReplyTo = userInfo.email;
        this.email.CustomerId = this.customerId;

        if (this.email.Body != "") {
          let data = {
            Text: this.email.Body,
            UserId: this.userId,
            CustomerId: this.customerId,
          };

          let err, result;

          [err, result] = await this.$store
            .dispatch(placeholderTypes.PROCESS_PLACEHOLDERS, data)
            .catch((err) => {});

          if (result) {
            let placeholderData = result.Data.Text;
            placeholderData = placeholderData.replace(/\n/g, " ");
            this.email.Body = placeholderData;
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }

        if (this.email.Subject != "") {
          let err, result;
          let data = {
            Text: this.email.Subject,
            UserId: this.userId,
            CustomerId: this.customerId,
          };
          [err, result] = await this.$store
            .dispatch(placeholderTypes.PROCESS_PLACEHOLDERS, data)
            .catch((err) => {});
          if (result) {
            let placeholderData = result.Data.Text;
            this.email.Subject = placeholderData;
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }

        if (this.email.Bcc != "") {
          let err, result;
          let data = {
            Text: this.email.Bcc,
            UserId: this.userId,
            CustomerId: this.customerId,
          };
          [err, result] = await this.$store
            .dispatch(placeholderTypes.PROCESS_PLACEHOLDERS, data)
            .catch((err) => {});
          if (result) {
            let placeholderData = result.Data.Text;
            this.email.Bcc = placeholderData;
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      }
    } else {
      let body = "";
      let err, result;
      let customerInfo = null;
      [err, result] = await this.$store.dispatch(
        paTypes.GET_CUSTOMER_INFO_FOR_PORTAL,
        { CustomerId: this.customerId }
      );
      if (result) {
        customerInfo = result.Data;
      }

      if (customerInfo != null) {
        body =
          "<p style='margin-bottom:10px !important;'><strong><u>Customer Information</u></strong></p>";
        body += `<p>Customer First Name: <u>${customerInfo.FirstName}</u></p>`;
        body += `<p>Customer Last Name: <u>${customerInfo.LastName}</u></p>`;
        if (customerInfo.Address != "") {
          body += `<p>Address: <u>${customerInfo.Address}</u></p>`;
        }
        if (customerInfo.SSN != "") {
          body += `<p>SSN: <u>${customerInfo.SSN}</u></p>`;
        }
        if (customerInfo.DateOfBirth != "") {
          body += `<p>Customer Date of Birth: <u>${customerInfo.DateOfBirth}</u></p>`;
        }
        if (
          customerInfo.GCSDedicatedAccount != null &&
          customerInfo.GCSDedicatedAccount != ""
        ) {
          body += `<p>GCS Dedicated Account: <u>${customerInfo.GCSDedicatedAccount}</u></p>`;
        }
        if (customerInfo.Email != "") {
          body += `<p>Customer Email Address: <u>${customerInfo.Email}</u></p>`;
        }

        body += `<p>Bank Name: <u>${customerInfo.BankName}</u>`;

        if (customerInfo.RoutingNumber != "") {
          body += `  &nbsp;R/T #: <u>${customerInfo.RoutingNumber}</u>`;
        }
        if (customerInfo.AccountNumber != "") {
          body += `  &nbsp;Account #: <u>${customerInfo.AccountNumber}</u>`;
        }
        body += "</p>";

        //body += `<p style='margin-bottom:10px !important;'><strong><u>Requested Change Details:</u></strong></p>`;
      }
      body += "<p></p>";
      for (let item of this.authTypeList) {
        //let index = this.authTypeList.indexOf(item);
        body = body + "<p>" + item.Data + "</p>";
      }

      let cName = userInfo ? userInfo.companyName : "";

      let companyName = this.attorneyName != "" ? this.attorneyName : cName;

      this.email.Body = body;
      this.email.Subject = "E-Sign Request from " + companyName;
      this.email.Alias = companyName + "    Customer Care";
    }
    this.isLoading = false;
  },

  computed: {},
  methods: {
    replaceString() {
      if (this.replaceText && this.toText) {
        this.previousBody = this.email.Body;
        const regex = new RegExp(this.replaceText, "g");
        if (regex.test(this.email.Body)) {
          this.email.Body = this.email.Body.replace(regex, this.toText);
        } else {
          this.$swal(
            "Warning",
            "Couldn't find the text to be replaced.",
            "warning"
          );
        }
      }
    },
    undoChanges() {
      if (this.previousBody) {
        this.email.Body = this.previousBody;
        this.previousBody = "";
      }
    },

    closePanel() {
      this.$emit("closePortalUserInterface");
    },
    async sendEmail() {
      this.sendEmailSpinner = true;
      if (this.email.From != "" && this.email.To != "") {
        let err, result;

        [err, result] = await this.$store.dispatch(
          globalTypes.SEND_EMAIL_MG,
          this.email
        );

        if (result) {
          this.$swal("Success", result.Message, "success");
          this.$emit("closePortalUserInterface");
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
      this.sendEmailSpinner = false;
    },
    async sendPortalMessage() {
      this.sendPortalMessageSpinner = true;

      if (this.email.Body == "") {
        this.$swal("Warning", "Message body required!", "warning");
        return;
      }
      let err, result;

      let payload = {
        Body: this.email.Body,
        CustomerId: this.customerId,
        ToEmail: this.email.To,
        Subject: this.email.Subject,
        Alias: this.email.Alias,
        Cc: this.email.Cc,
        Bcc: this.email.Bcc,
        Attachments: this.email.Attachments,
        AuthorizationTypes:
          this.authTypeList.length > 0
            ? this.authTypeList.map((x) => x.TypeId)
            : [],
      };

      if (payload.AuthorizationTypes.length > 0) {
        // let bodyTemp = payload.Body;
        // bodyTemp = bodyTemp.replaceAll("</br", "");
        // bodyTemp = bodyTemp.replaceAll("<br>", "");
        // let body =
        //   "<div style='font-family: sans-serif,Arial, Helvetica; font-size: 16px; line-height: 24px!important;'>";
        // body += bodyTemp;
        // body += "</div>";
        //payload.Body = body;
      }
      [err, result] = await this.$store.dispatch(
        paTypes.SEND_PORTAL_MESSAGE,
        payload
      );
      if (result) {
        this.$swal("Success", "Message sent.", "success");
        this.$Emitter.emit("resetAuthorizationTab");
        this.closePanel();
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.sendPortalMessageSpinner = false;
    },
    removeAttachment(item) {
      let indx = this.email.Attachments.findIndex(
        (x) => x.DocumentName == item.DocumentName
      );
      if (indx > -1) {
        this.email.Attachments.splice(indx, 1);
      }
    },
    handleFileClick() {
      this.$refs.uploader.value = "";
      const input = this.$refs.uploader;
      input.type = "file";
    },
    async handleFileChange(e) {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        let fileExtension = "";
        if (file) {
          fileExtension = file.name.split(".").pop();

          if (!this.validFileExtensions.includes(fileExtension.toLowerCase())) {
            this.$swal("Warning!", "Invalid file type!", "warning");
            return;
          }

          let fileName = this.cropFileNames(
            this.getFileNameWithoutExt(file.name)
          );
          let fileIndex = this.email.Attachments.findIndex(
            (x) => x.DocumentName == fileName
          );
          if (fileIndex > -1) {
            this.$swal("Warning!", "This file is aldready exists!", "warning");
            return;
          }

          if (!this.isValidFileSize(file.size, 25)) {
            this.$swal(
              "Warning!",
              "Maximum allowed file size is 25 MB. Please resize your file.",
              "warning"
            );
            return;
          }

          let url = URL.createObjectURL(file);

          const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });

          let data = await toBase64(file);

          if (data.indexOf("data:") > -1) {
            data = data.split(",")[1];
          }

          let uploadData = {
            DocumentName: fileName + "." + fileExtension,
            DocumentData: data,
          };

          this.email.Attachments.push(uploadData);
        }
      }
    },
    isValidFileSize(size, limitMb) {
      let rtn = true;
      if (size > 1024 * 1024 * limitMb) {
        rtn = false;
      }

      return rtn;
    },
  },
  validations() {
    return {
      email: {
        To: { required },
        Subject: { required },
      },
    };
  },
};
</script>

<style>
.portalTextEditor #textEditor-pu {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.closeButtons {
  position: absolute;
  right: 5px;
  z-index: 1000;
  font-size: 25px;
  cursor: pointer;
  color: rgb(228, 228, 228);
}
.closeButtons:hover {
  color: #ff3860;
}

.clientPortalBox {
  height: auto;

  background-color: #3bafda;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}
</style>
