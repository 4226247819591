<template>
  <div class="widget box" style="height: 100%; position: relative">
    <div v-if="isLoading" class="loading">
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        "
      >
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div
      v-if="
        componentName != 'BoxWidget' && componentName != 'OperationHourWidget'
      "
      class="header clearfix"
    >
      <div>
        <h4 v-if="!widgetData.newSnf && !widgetData.newSnfBottomBoxes">
          {{ title }}
        </h4>

        <h4 v-if="widgetData.newSnfBottomBoxes">
          {{ title }}
          <span class="badge badge-primary">{{
            widgetData.amount == true
              ? formatMoney(widgetData.current)
              : widgetData.current
          }}</span>
        </h4>

        <div
          v-if="widgetData.newSnf"
          style="display: flex; flex-direction: column"
        >
          <div>
            <h4>
              {{ title }}
              <span class="badge badge-primary">{{ widgetData.current }}</span>
            </h4>

            <h5 class="ml-1">
              {{ "(" + widgetData.totalAmount + ")" }}
            </h5>
            <div
              v-if="widgetData.refresh == true"
              class="refresh ml-2"
              @click="refreshData()"
            >
              <i class="fe-refresh-cw" title="Refresh"></i>
            </div>
          </div>
        </div>

        <div
          v-if="widgetData.refresh == true && !widgetData.newSnf"
          class="refresh ml-2"
          @click="refreshData()"
        >
          <i class="fe-refresh-cw" title="Refresh"></i>
        </div>
      </div>
      <div
        v-if="widgetData.timeInterval == true"
        class="time"
        v-click-outside="hideMenu"
      >
        <div class="selected" @click="toggleMenu()">
          {{ selectedItem.Value }}
          <div class="arrow">▼</div>
        </div>
        <div class="options" v-if="isMenuOpen">
          <span
            :key="item.Key"
            @click="selectItem(item)"
            v-for="item in menuOptions"
            class="optionItem"
            >{{ item.Value }}</span
          >
        </div>
      </div>
    </div>

    <div class="content" v-if="componentData != null && isLoading == false">
      <component
        :title="title"
        :widgetData="componentData"
        :is="componentName"
        :secondTitle="secondTitle"
      ></component>
    </div>
  </div>
</template>

<script>
import SummaryWidget from "./Widgets/SummaryWidget";
import TableWidget from "./Widgets/TableWidget";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import BarChartsWidget from "./Widgets/BarChartsWidget";
import OperationHourWidget from "./Widgets/OperationHourWidget";
import types from "@/views/agent/Dashboard/types";
import { mapState } from "vuex";
import deepCopy from "../helpers/deepCopy";
import NewDonuthChartWidget from "./Widgets/NewDonuthChartWidget";
import BoxWidget from "./Widgets/BoxWidget";

export default {
  props: {
    widgetData: Object,
    title: String,
    span: Number,
    componentName: String,
    dateOption: String,
    secondTitle: String,
  },
  components: {
    SummaryWidget,
    TableWidget,
    BarChartsWidget,
    NewDonuthChartWidget,
    BoxWidget,
    OperationHourWidget,
  },
  mixins: [formatMixin, utilitiesMixin],

  data() {
    return {
      componentData: null,
      isMenuOpen: false,
      isLoading: false,
      menuOptions: [
        { Key: "today", Value: "Today" },
        { Key: "thismonth", Value: "This Month" },
      ],
      selectedItem: { Key: "today", Value: "Today" },
    };
  },
  async mounted() {
    this.setMenuOptions();
    this.componentData = deepCopy(this.widgetData);
  },
  methods: {
    setMenuOptions() {
      if (this.title.toLowerCase() == "office performance") {
        let today = new Date();
        let month = today.getMonth();
        let day = today.getDate();

        for (let i = 0; i < 11; i++) {
          let date = today.setMonth(today.getMonth() - 1);
          let year = new Date(date).getFullYear();
          let monthShortName = new Date(date).toLocaleString("en-us", {
            month: "short",
          });
          this.menuOptions.push({
            Key: new Date(date),
            Value: monthShortName + " " + year,
          });
        }
      } else if (this.title.toLowerCase() == "docusign stats") {
        this.menuOptions = [];
        this.menuOptions.push({ Key: "today", Value: "Today" });
        this.menuOptions.push({ Key: "yesterday", Value: "Yesterday" });
        this.menuOptions.push({ Key: "thisweek", Value: "This Week" });
        this.menuOptions.push({ Key: "lastweek", Value: "Last Week" });
        this.menuOptions.push({ Key: "thismonth", Value: "This Month" });
        this.menuOptions.push({ Key: "lastmonth", Value: "Last Month" });
      }
    },
    getPayload() {
      let option = "";
      let startDate = "";
      let endDate = "";
      let widgetTitle = this.title.toLowerCase();
      if (widgetTitle == "office performance") {
        if (this.selectedItem.Key == "today") {
          option = "today";
        } else if (this.selectedItem.Key == "thismonth") {
          option = "thismonth";
        } else {
          option = "customdaterange";
          let month = this.selectedItem.Key.getMonth();
          let year = this.selectedItem.Key.getFullYear();
          startDate = new Date(year, month, 1).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          endDate = new Date(year, month + 1, 0).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
        }
      } else if (widgetTitle == "docusign stats") {
        option = this.selectedItem.Key;
      }

      return {
        Title: "",
        Option: option,
        StartDate: startDate,
        EndDate: endDate,
      };
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    hideMenu() {
      if (this.isMenuOpen) this.isMenuOpen = false;
    },
    async selectItem(item) {
      if (item.Key !== this.selectedItem.Key) {
        this.selectedItem = item;
        this.hideMenu();
        await this.refreshData();
      } else {
        this.hideMenu();
      }
    },
    async refreshData() {
      this.isLoading = true;
      let err, result;
      let payload = this.getPayload();
      if (this.title.toLowerCase() == "office performance") {
        payload.Title = "OfficePerformance";
        [err, result] = await this.$store.dispatch(
          types.GET_OFFICE_PERFORMANCE,
          payload
        );
        if (result && result.Data) {
          this.componentData = {
            data: deepCopy(result.Data),
            columns: this.widgetData.columns,
          };
        }
      } else if (this.title.toLowerCase() == "pending welcome calls") {
        await this.$store.dispatch(types.GET_WELCOME_CALL, payload);
      } else if (this.title.toLowerCase() == "docusign stats") {
        await this.$store.dispatch(types.GET_SNF_DASHBOARD, payload);
      } else if (this.title.toLowerCase() == "weekly affiliate sales") {
        [err, result] = await this.$store.dispatch(
          types.GET_SNF_DASHBOARD_SALES,
          payload
        );
      } else if (this.title.toLowerCase() == "daily sales") {
        [err, result] = await this.$store.dispatch(
          types.GET_SNF_DASHBOARD_SALES,
          payload
        );
        if (result && result.Data) {
          this.componentData = {
            current: result.Data.DailySales,
            rate: result.Data.DailyChangeRate,
            prev: result.Data.PreviousDaySales,
            type: "daily",
            newSnf: true,
            totalAmount: result.Data.DailyEnrolledAmount,
            salesSnf: result.Data.SnfDailySales,
            salesFlg: result.Data.FlgDailySales,
            debtAmountSnf: result.Data.SnfDailyEnrolledAmount,
            debtAmountFlg: result.Data.FlgDailyEnrolledAmount,
          };
        }
      } else if (this.title.toLowerCase() == "weekly sales") {
        [err, result] = await this.$store.dispatch(
          types.GET_SNF_DASHBOARD_SALES,
          payload
        );
        if (result && result.Data) {
          this.componentData = {
            current: result.Data.CurrentWeekSales,
            rate: result.Data.WeeklyChangeRate,
            prev: result.Data.PreviousWeekSales,
            type: "weekly",
            newSnf: true,
            totalAmount: result.Data.WeeklyEnrolledAmount,
            salesSnf: result.Data.SnfCurrentWeekSales,
            salesFlg: result.Data.FlgCurrentWeekSales,
            debtAmountSnf: result.Data.SnfWeeklyEnrolledAmount,
            debtAmountFlg: result.Data.FlgWeeklyEnrolledAmount,
          };
        }
      } else if (this.title.toLowerCase() == "monthly sales") {
        [err, result] = await this.$store.dispatch(
          types.GET_SNF_DASHBOARD_SALES,
          payload
        );
        if (result && result.Data) {
          this.componentData = {
            current: result.Data.CurrentMonthlySales,
            rate: result.Data.MonthlyChangeRate,
            prev: result.Data.PreviousMonthlySales,
            type: "monthly",
            newSnf: true,
            totalAmount: result.Data.MonthEnrolledAmount,
            salesSnf: result.Data.SnfCurrentMonthSales,
            salesFlg: result.Data.FlgCurrentMonthSales,
            debtAmountSnf: result.Data.SnfMonthEnrolledAmount,
            debtAmountFlg: result.Data.FlgMonthEnrolledAmount,
          };
        }
      } else if (this.title.toLowerCase() == "pending welcome calls") {
        await this.$store.dispatch(types.GET_WELCOME_CALL, payload);
      } else if (
        this.title.toLowerCase() == "settlements today" ||
        this.title.toLowerCase() == "monthly settlements" ||
        this.title.toLowerCase() == "yearly settlement total" ||
        this.title.toLowerCase() == "debt settled today" ||
        this.title.toLowerCase() == "monthly settled debt" ||
        this.title.toLowerCase() == "yearly settled debt"
      ) {
        await this.$store.dispatch(
          types.GET_SNF_SETTLEMENTS_DASHBOARD,
          payload
        );
      } else if (
        this.title.toLowerCase() == "number of settlements" ||
        this.title.toLowerCase() == "total settled debt"
      ) {
        await this.$store.dispatch(types.GET_NEGOTIATORS_DASHBOARD);
      } else if (
        this.secondTitle.toLowerCase() == "snf customer status dashboard"
      ) {
        await this.$store.dispatch(types.GET_SNF_DASHBOARD_CUSTOMER_STATUS);
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  &.box {
    border-radius: 10px;
    margin-top: 0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
  }

  .header {
    padding: 16px;
    padding-bottom: 0;
    height: 59px;
    h4 {
      line-height: 28px;
      font-size: 20px;
      font-weight: 600;
      float: left;
      margin: 0;
      color: #2a404c;
    }
    h5 {
      line-height: 28px;
      font-size: 16px;
      font-weight: 600;
      float: left;
      margin: 0;
      color: #2a404c;
    }
    .refresh {
      height: 27px;
      float: right;
      font-size: 16px;
      font-weight: 600;
      color: #3bafda;
      cursor: pointer;
    }
    .time {
      height: 27px;
      float: right;
      border-radius: 8px;
      width: 100px;
      background-color: #f7f7f7;
      position: relative;
      cursor: pointer;
      .options {
        width: auto;
        min-width: 100px;
        position: absolute;
        right: 0;
        padding: 5px;
        background-color: #f7f7f7;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 600;
        color: #999;
        z-index: 999;
        .optionItem {
          width: 100%;
          float: left;
          margin: 5px 0;
          text-align: center;
          cursor: pointer;
        }
        .optionItem:hover {
          color: #3bafda;
        }
      }
      .selected {
        text-align: center;
        line-height: 27px;
        font-size: 12px;
        font-weight: 600;
        color: #3bafda;
        .arrow {
          font-size: 6px;
          display: inline-block;
          position: relative;
          left: 4px;
          top: -1px;
        }
      }
    }
  }
  .content {
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    clear: both;
  }
  .loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1000;
  }
}
</style>
