export default {
  GET_USER_TIME_LOG_REPORT:"GET_USER_TIME_LOG_REPORT",
  SET_USER_TIME_LOG_REPORT:"SET_USER_TIME_LOG_REPORT",

  GET_CPA_PUB_REPORT: "GET_CPA_PUB_REPORT",
  SET_CPA_PUB_REPORT: "SET_CPA_PUB_REPORT",

  GET_CPA_AGENT_REPORT: "GET_CPA_AGENT_REPORT",
  SET_CPA_AGENT_REPORT: "SET_CPA_AGENT_REPORT",

  GET_CPA_OFFICE_REPORT: "GET_CPA_OFFICE_REPORT",
  SET_CPA_OFFICE_REPORT: "SET_CPA_OFFICE_REPORT",

  GET_CPA_STATE_REPORT: "GET_CPA_STATE_REPORT",
  SET_CPA_STATE_REPORT: "SET_CPA_STATE_REPORT",

  GET_CSR_TASK_CONTACT_SCHEDULE_REPORT: "GET_CSR_TASK_CONTACT_SCHEDULE_REPORT",
  SET_CSR_TASK_CONTACT_SCHEDULE_REPORT: "SET_CSR_TASK_CONTACT_SCHEDULE_REPORT",

  GET_CALL_METRICS_REPORT: "GET_CALL_METRICS_REPORT",
  SET_CALL_METRICS_REPORT: "sET_CALL_METRICS_REPORT",

  GET_CSR_TASK_DISTRIBUTION_QUEUE_REPORT:
    "GET_CSR_TASK_DISTRIBUTION_QUEUE_REPORT",
  SET_CSR_TASK_DISTRIBUTION_QUEUE_REPORT:
    "SET_CSR_TASK_DISTRIBUTION_QUEUE_REPORT",

  GET_DISPOSITION_SETTINGS_REPORT: "GET_DISPOSITION_SETTINGS_REPORT",
  SET_DISPOSITION_SETTINGS_REPORT: "SET_DISPOSITION_SETTINGS_REPORT",

  GET_USER_ACTIVITY_REPORT: "GET_USER_ACTIVITY_REPORT",
  SET_USER_ACTIVITY_REPORT: "SET_USER_ACTIVITY_REPORT",

  GET_CSR_TASK_SUMMARY_REPORT: "GET_CSR_TASK_SUMMARY_REPORT",
  SET_CSR_TASK_SUMMARY_REPORT: "SET_CSR_TASK_SUMMARY_REPORT",

  GET_AGENT_PHONE_REPORT: "GET_AGENT_PHONE_REPORT",
  SET_AGENT_PHONE_REPORT: "SET_AGENT_PHONE_REPORT",

  GET_AGENT_STATUS_REPORT: "GET_AGENT_STATUS_REPORT",
  SET_AGENT_STATUS_REPORT: "SET_AGENT_STATUS_REPORT",

  GET_CALL_LOG_DETAIL: "GET_CALL_LOG_DETAIL",

  GET_DB_SALES_COMPENSATION_REPORT: "GET_DB_SALES_COMPENSATION_REPORT",

  SET_LIVE_CALL_ROLE_LIST: "SET_LIVE_CALL_ROLE_LIST",

  GET_CUSTOMER_TAG_REPORT: "GET_CUSTOMER_TAG_REPORT",
  SET_CUSTOMER_TAG_REPORT: "SET_CUSTOMER_TAG_REPORT",

  GET_PENDING_DISPOSITION_REPORT: "GET_PENDING_DISPOSITION_REPORT",
  SET_PENDING_DISPOSITION_REPORT: "SET_PENDING_DISPOSITION_REPORT",

  GET_CALL_LOG_REPORT: "GET_CALL_LOG_REPORT",
  SET_CALL_LOG_REPORT: "SET_CALL_LOG_REPORT",

  GET_CUSTOMER_STATUS_REPORT: "GET_CUSTOMER_STATUS_REPORT",
  SET_CUSTOMER_STATUS_REPORT: "SET_CUSTOMER_STATUS_REPORT",

  GET_MISSED_CALL_REPORT: "GET_MISSED_CALL_REPORT",
  SET_MISSED_CALL_REPORT: "SET_MISSED_CALL_REPORT",

  GET_SMS_HISTORY_REPORT: "GET_SMS_HISTORY_REPORT",
  SET_SMS_HISTORY_REPORT: "SET_SMS_HISTORY_REPORT",

  GET_REMINDERS_REPORT: "GET_REMINDERS_REPORT",
  SET_REMINDERS_REPORT: "SET_REMINDERS_REPORT",

  GET_CSR_REALTIME_TASK_REPORT: "GET_CSR_REALTIME_TASK_REPORT",
  SET_CSR_REALTIME_TASK_REPORT: "SET_CSR_REALTIME_TASK_REPORT",

  GET_CSR_TASK_REPORT: "GET_CSR_TASK_REPORT",
  SET_CSR_TASK_REPORT: "SET_CSR_TASK_REPORT",

  GET_LEAD_ASSIGN_REPORT: "GET_LEAD_ASSIGN_REPORT",
  SET_LEAD_ASSIGN_REPORT: "SET_LEAD_ASSIGN_REPORT",

  GET_CSR_TASK_PERFORMANCE_REPORT: "GET_CSR_TASK_PERFORMANCE_REPORT",
  SET_CSR_TASK_PERFORMANCE_REPORT: "SET_CSR_TASK_PERFORMANCE_REPORT",

  GET_INBOUND_STATISTICS_REPORT: "GET_INBOUND_STATISTICS_REPORT",
  SET_INBOUND_STATISTICS_REPORT: "SET_INBOUND_STATISTICS_REPORT",

  GET_LIVE_CALL_REPORT: "GET_LIVE_CALL_REPORT",
  SET_LIVE_CALL_REPORT: "SET_LIVE_CALL_REPORT",

  GET_PENDING_CHANGES_REPORT: "GET_PENDING_CHANGES_REPORT",
  SET_PENDING_CHANGES_REPORT: "SET_PENDING_CHANGES_REPORT",

  GET_INCOMING_VM_REPORT: "GET_INCOMING_VM_REPORT",
  SET_INCOMING_VM_REPORT: "SET_INCOMING_VM_REPORT",

  GET_CAMPAIGN_PERFORMANCE_REPORT: "GET_CAMPAIGN_PERFORMANCE_REPORT",
  SET_CAMPAIGN_PERFORMANCE_REPORT: "SET_CAMPAIGN_PERFORMANCE_REPORT",

  GET_AGENT_DROP_RATE_REPORT: "GET_AGENT_DROP_RATE_REPORT",
  SET_AGENT_DROP_RATE_REPORT: "SET_AGENT_DROP_RATE_REPORT",

  RESET_REPORTS: "RESET_REPORTS",
  RESET_REPORTS_DATA: "RESET_REPORTS_DATA",
};
