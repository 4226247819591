export default {

  GET_CUSTOMER_OPTIN_STATUS:"GET_CUSTOMER_OPTIN_STATUS",
  PUSH_TO_TCI: "PUSH_TO_TCI",
  POST_LEAD_TRAC: "POST_LEAD_TRAC",
  UPDATE_DRAFT_AMOUNT: "UPDATE_DRAFT_AMOUNT",
  GET_CUSTOMERDISPOSITIONS: "GET_CUSTOMERDISPOSITIONS",
  MODIFY_CUSTOMERDISPOSITIONS: "MODIFY_CUSTOMERDISPOSITIONS",
  SET_CUSTOMERDISPOSITIONS: "SET_CUSTOMERDISPOSITIONS",
  MODIFY_DISPOSITIONLOGS: "MODIFY_DISPOSITIONLOGS",
  GET_CLIENT: "GET_CLIENT",
  DELETEEMAIL: "DELETEEMAIL",
  DELETEPHONE: "DELETEPHONE",
  GETASSIGNEDAGENTS: "GETASSIGNEDAGENTS",
  INSERT_NEW_CLIENT: "INSERT_NEW_CLIENT",
  UPDATE_CLIENT: "UPDATE_CLIENT",
  GET_CALL_LOGS: "GET_CALL_LOGS",
  GET_CAMPAIGNS: "GET_CAMPAIGNS",
  RESET_STATES: "RESET_STATES",
  SET_RESET_STATES: "SET_RESET_STATES",
  GET_CUSTOMERDOCUMENTS: "GET_CUSTOMERDOCUMENTS",
  SET_CUSTOMERDOCUMENTS: "SET_CUSTOMERDOCUMENTS",
  GENERATE_CUSTOMER_TEMPLATE: "GENERATE_CUSTOMER_TEMPLATE",
  GET_CUSTOMERDOCUMENTLIST: "GET_CUSTOMERDOCUMENTLIST",
  REFRESH_CUSTOMERDOCUMENTLIST: "REFRESH_CUSTOMERDOCUMENTLIST",
  SET_CUSTOMERDOCUMENTLIST: "SET_CUSTOMERDOCUMENTLIST",
  DOWNLOAD_CUSTOMER_DOCUMENT: "DOWNLOAD_CUSTOMER_DOCUMENT",
  DELETE_CUSTOMER_DOCUMENT: "DELETE_CUSTOMER_DOCUMENT",
  RENAME_CUSTOMER_DOCUMENT: "RENAME_CUSTOMER_DOCUMENT",
  UPLOAD_EXTRA_DOCUMENT: "UPLOAD_EXTRA_DOCUMENT",
  UPLOAD_DOCUMENTTOGLOBAL: "UPLOAD_DOCUMENTTOGLOBAL",
  VALIDATE_ACCOUNT_NUMBER: "VALIDATE_ACCOUNT_NUMBER",



  CREATE_CLIENT_INFO_CHANGES: "CREATE_CLIENT_INFO_CHANGES",
  CREATE_DOCUMENT_REVIEW_CHANGES: "CREATE_DOCUMENT_REVIEW_CHANGES",
};