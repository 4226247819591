<template>
  <div
    class="col-lg-12 col-md-12 col-sm-12 newPanelWrapper noMargin"
    style="background-color: #ffffff"
  >
    <div class="form-group row">
      <span
        v-touch:tap="onTapItem"
        v-if="isPopup"
        class="closeButton"
        v-on:click.prevent.stop="closeScriptPopup()"
      >
        <i class="fe-x"></i>
      </span>
      <div class="col-md-12 noMargin">
        <label class="generalLabel" for="ScriptTitle">Scripts</label>
      </div>
      <div class="col-md-12 noMargin">
        <select
          id="ScriptTitle"
          @change="selectedScriptChanged($event)"
          @click="selectBoxClick()"
          class="generalSelectBox form-control"
        >
          <option value="0">Select a script</option>
          <option
            :selected="
              isPopup
                ? imitateSelectedId == item.Id
                : selectedScript != null
                ? selectedScript.Id == item.Id
                : null
            "
            v-for="item in filteredScripts"
            :key="item.Id"
            :value="item.Id"
          >
            {{ item.Title }}
          </option>
        </select>
        <div v-if="showSelectOptions" class="optionWrapper">
          <div
            v-for="item in filteredScripts"
            :key="item.Id"
            class="optionItem"
            v-on:click.prevent.stop="triggerSelectBox(item.Id)"
          >
            {{ item.Title }}
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row mt-2">
      <div class="col-md-12 noMargin">
        <label class="generalLabel" for="scriptContent">Script Text</label>
      </div>
      <div class="col-md-12 mt-1">
        <div
          id="scriptContent"
          ref="scriptContent"
          style="
            height: 320px;
            max-height: 320px;
            overflow-x: hidden;
            overflow-y: auto;
          "
          class="form-control generalTextBox editorWrapper"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ScriptTab",
  props: {
    filteredScripts: {
      type: Array,
      default: () => [],
    },
    selectedScript: {
      type: Object,
      default: () => null,
    },
    isPopup: {
      type: Boolean,
      default: () => false,
    },
  },
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  mounted() {
    if (this.selectedScript) {
      this.setScriptText(this.selectedScript.Id);
      if (this.isPopup) this.imitateSelectedId = this.selectedScript.Id;
      else this.$emit("updateSelectedScript", this.selectedScript);
    }
  },

  data() {
    return {
      showSelectOptions: false,
      imitateSelectedId: 0,
    };
  },
  methods: {
    onTapItem() {
      this.$emit("popupOpen", false);
    },

    emptyTheObjectsOnUnmount() {
      this.showSelectOptions = null;
      this.imitateSelectedId = null;
    },
    triggerSelectBox(itemId) {
      this.imitateSelectedId = itemId;
      this.showSelectOptions = false;
      this.setScriptText(itemId);
    },
    selectBoxClick() {
      if (this.isPopup) {
        this.showSelectOptions = !this.showSelectOptions;
      }
    },
    selectedScriptChanged(event) {
      let id = event.target.value;
      if (typeof id != "undefined" && id > 0) {
        this.setScriptText(id);
      } else {
        this.setScriptText(0);
      }
    },
    setScriptText(id) {
      let scripts = this.filteredScripts.filter((x) => x.Id == id);

      if (scripts.length > 0) {
        this.$refs.scriptContent.innerHTML = scripts[0].Script;
        if (this.isPopup == false)
          this.$emit("updateSelectedScript", scripts[0]);
      } else {
        this.$refs.scriptContent.innerHTML = "";
      }
    },
    closeScriptPopup() {
      this.$emit("popupOpen", false);
    },
  },
};
</script>
<style lang="css" scoped>
.editorWrapper p {
  margin-bottom: 0 !important;
}
.optionWrapper {
  width: 94%;
  height: auto;
  background-color: white;
  position: absolute;
  top: 35px;
  left: 3%;
  z-index: 920;
  border: 1px solid #7b9cd3;
}
.optionItem {
  width: 100%;
  float: left;
  padding: 2px 16px;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
}
.optionItem:hover {
  background-color: #1e90ff;
  color: white;
}
.selectedItem {
  background-color: #1e90ff;
  color: white;
}
.closeButton {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  cursor: pointer;
  z-index: 901;
}
</style>
