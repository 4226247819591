<template>
  <div class="row admin-page officePage">
    <Placeholders
      :typeId="0"
      @setPlaceholderVisibility="isPlaceholdersOpen = $event"
      @setPlaceholderContent="setPlaceholder($event)"
      v-if="isPlaceholdersOpen"
    ></Placeholders>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
      <div class="table is-striped table is-hoverable">
        <button
          v-if="checkAuth(7465)"
          class="col-xl-12 btn btn-primary waves-effect waves-light addTemplateButton"
          @click="handleAddNote"
        >
          Add a New Quick Note
        </button>
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="noteFilter"
            placeholder="Filter quick notes"
          />
        </div>
        <div class="list col-md-12">
          <div
            class="item row"
            v-for="(item, index) in filteredNotes"
            :class="{ active: activeItem === item.Id }"
            :disabled="saving || deleting"
            :key="item.Id"
            @click="() => handleListSelection(item, index)"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]"
          >
            <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
              <i class="ri-file-4-line" style="vertical-align: middle"></i>
              <span style="margin-left: 10px">{{ item.Title }}</span>
            </span>
          </div>
        </div>
        <div
          v-if="isMobileDevice()"
          style="
            border-top: 1px solid rgb(0, 0, 0, 0);
            margin: 20px 0 0 9px;
            padding-bottom: 20px;
          "
          class="col-md-12 scrollToSelectedItem"
        ></div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12" v-if="isEditing">
      <div class="panelContent" style="padding-top: 0">
        <div class="form-group row">
          <div class="col-md-12">
            <label for="Title" class="form-label">Title *:</label>
          </div>
          <div class="col-md-12">
            <input
              autocomplete="off"
              class="form-control"
              v-model="selectedRow.Title"
              id="title-qnm"
              type="text"
              placeholder="Title"
              :class="v$.selectedRow.Title.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.Title.$touch"
            />
          </div>

          <div class="col-md-12 pt-4">
            <label for="roles">Roles:</label>
            <VueMultiselect
              id="roles"
              v-model="selectedRow.QuickNoteRoles"
              :options="sortedRoles"
              :multiple="true"
              :close-on-select="false"
              placeholder="Please Select"
              label="Name"
              track-by="Id"
              :class="v$.selectedRow.QuickNoteRoles.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.QuickNoteRoles.$touch"
            />
          </div>
          <div class="col-md-12 pt-4">
            <div class="subjectDiv">
              <label for="title" class="title is-5">Body: *</label>
              <button
                type="button"
                id="placeholderbtn"
                v-on:click.prevent="() => openPlaceholders('Body', 1)"
                title="manage"
                class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
                style="background-color: #3498db"
              ></button>
            </div>
            <textarea
              id="textEditor-qnm"
              v-model="selectedRow.Body"
              :class="v$.selectedRow.Body.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.Body.$touch"
              class="form-control textareaInput generalTextBox mt-2 pt-1"
              rows="8"
            ></textarea>
          </div>
          <!-- <div class="col-md-12 mt-2">
            <div class="subjectDiv">
              <label for="title" class="title is-5">Spanish Body:</label>
            </div>
            <textarea
              id="textEditor-qnm"
              v-model="selectedRow.SpanishContent"
              class="form-control textareaInput generalTextBox"
              rows="8"
            ></textarea>
          </div> -->

          <div
            class="col-md-12 mt-2"
            style="display: flex; justify-content: flex-end; gap: 10px"
          >
            <button
              v-if="selectedRow.Id && checkAuth(5344)"
              class="btn btn-danger"
              style="width: 200px"
              @click="() => handleDelete(selectedRow.Id)"
            >
              Delete
            </button>
            <button
              v-if="checkAuth(4387) || !selectedRow.Id"
              class="btn btn-success"
              :disabled="v$.$invalid || saving || deleting"
              style="width: 200px"
              @click="handleSave"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import { mapState } from "vuex";
import types from "./types";
import globalTypes from "@/store/types";
import deepCopy from "@/helpers/deepCopy";
import formatMixin from "@/mixins/formatMixin.js";
import Placeholders from "@/components/Placeholders/Placeholders";
import useValidate from "@vuelidate/core";
import _orderby from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import { required } from "vuelidate/lib/validators";

const init = {
  Id: 0,
  Title: "",
  Body: "",
  SpanishContent: "",
  QuickNoteRoles: [],
};

export default {
  name: "QuickNoteManagement",
  mixins: [formatMixin, utilitiesMixin],
  components: {
    VueMultiselect,
    Placeholders,
  },
  computed: mapState({
    roles: (state) => state.globals.roles,
    sortedRoles() {
      return _orderby.orderBy(this.filterRolesByOfficeAccess(this.roles), "Name", "asc");
    },
    list: (state) => state.quickNoteManagement.quickNoteList,

    filteredNotes() {

      let noteList = deepCopy(this.list);
      if(!this.hasAccessOtherOffices)
      {
        for(let item of noteList)
        {
          if(item.QuickNoteRoles == null)
          {
            item.QuickNoteRoles = [];
          }
        }
      }

      return noteList != null
        ? noteList.filter(
                (x) =>
                !this.hasAccessOtherOffices ? 
                (x.QuickNoteRoles && x.QuickNoteRoles.length > 0 && !x.QuickNoteRoles.some(
                    (y) =>
                      !this.sortedRoles.map((z) => z.Id).includes(y.Id)
                  )) : noteList
              ).filter(
            (item) =>
              item.Title.toLowerCase().indexOf(this.noteFilter.toLowerCase()) >
              -1
          )
        : [];
    },
  }),
  data() {
    return {
      v$: useValidate(),
      htmlForEditor: "",
      saving: false,
      deleting: false,
      isEditing: false,
      activeItem: null,
      isCancelledBeforeSave: false,
      noteFilter: "",
      options: [
        { Id: 1, Name: "Test1" },
        { Id: 2, Name: "Test2" },
        { Id: 3, Name: "Test3" },
      ],
      oldValue: JSON.stringify(init),
      selectedRow: deepCopy(init),
      valueToRevert: deepCopy(init),
      copySettings: "null",
      cursorPosition: 0,
      isPlaceholdersOpen: false,
      placeholderTypeId: 1,
      subOfficeIds:[],
      userOfficeId:null,
      hasAccessOtherOffices: false,
      hasAccessSubOffices: false,
    };
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch(types.GET_QUICKNOTES),
      this.$store.dispatch(globalTypes.GET_ROLES),
    ]);
    this.hasAccessOtherOffices = this.canSeeAllOffices();
    this.hasAccessSubOffices = this.canSeeSubOffices();
    
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null) {
      this.subOfficeIds = userInfo.subOffices;
      this.userOfficeId = userInfo.officeId;
 
    }
  },

  methods: {
    filterRolesByOfficeAccess(roles) {

      if (this.hasAccessOtherOffices) {
        return roles;
      } else if (
              this.hasAccessSubOffices &&
              this.subOfficeIds &&
              this.subOfficeIds.length > 0
      ) {
        this.subOfficeIds.push(this.userOfficeId);
        return roles.filter((x) => this.subOfficeIds.includes(x.OfficeId));
      } else {
        return roles.filter((x) => x.OfficeId == this.userOfficeId);
      }
      },
    // setPlaceholder(placeholder) {
    //   navigator.clipboard.writeText(placeholder);

    //   let textarea;
    //   textarea = document.getElementById("textEditor");

    //   let start = textarea.selectionStart;
    //   let end = textarea.selectionEnd;
    //   let value = textarea.value;
    //   if (this.selectedRow) {
    //     this.selectedRow.Body =
    //       value.slice(0, start) + " " + placeholder + " " + value.slice(end);
    //   }

    //   // update cursor to be at the end of insertion
    //   start += value.length;
    //   textarea.selectionStart = start + placeholder.length;

    //   textarea.focus();
    // },
    openPlaceholders(typeId) {
      this.isPlaceholdersOpen = true;
      this.placeholderTypeId = typeId;
    },
    setPlaceholder(value) {
      navigator.clipboard.writeText(value);
    },
    reset() {
      this.activeItem = null;
      this.selectedRow = deepCopy(init);
      this.isEditing = false;
    },
    async handleAddNote() {
      if (
        this.cleanHtml(this.oldValue) !=
          this.cleanHtml(JSON.stringify(this.selectedRow)) &&
        this.cleanHtml(JSON.stringify(this.selectedRow)) !=
          this.cleanHtml(JSON.stringify(init))
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
      }

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        let indx = this.list.findIndex((x) => x.Id == this.valueToRevert.Id);
        this.list[indx] = this.valueToRevert;
        this.list.push();

        this.isCancelledBeforeSave = false;
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
      }
    },

    async handleListSelection(row, index) {
      if (
        this.cleanHtml(this.oldValue) !=
          this.cleanHtml(JSON.stringify(this.selectedRow)) &&
        this.cleanHtml(JSON.stringify(this.selectedRow)) !=
          this.cleanHtml(JSON.stringify(init))
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.valueToRevert = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeItem = row.Id;

        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
      }
      this.isEditing = true;
      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        let indx = this.list.findIndex((x) => x.Id == this.valueToRevert.Id);
        this.list[indx] = this.valueToRevert;
        this.list.push();

        this.valueToRevert = deepCopy(row);

        this.oldValue = JSON.stringify(row);
        this.activeItem = row.Id;

        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
      }

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    async handleSave(values) {
      if (
        this.cleanHtml(this.oldValue) ==
        this.cleanHtml(JSON.stringify(this.selectedRow))
      ) {
        this.$swal("Warning!", "No Changes Detected!", "warning");

        return;
      }
      this.saving = true;

      let err, result, type;

      let isQuickNoteExists =
        this.list.filter(
          (x) => x.Title.toLowerCase() == this.selectedRow.Title.toLowerCase()
        ).length > 0;

      if (this.selectedRow.Id != 0) {
        type = types.PUT_QUICKNOTE;

        isQuickNoteExists =
          this.list.filter(
            (x) => x.Title.toLowerCase() == this.selectedRow.Title.toLowerCase()
          ).length > 1;
      } else {
        type = types.POST_QUICKNOTE;
      }
      if (isQuickNoteExists && type == types.POST_QUICKNOTE) {
        this.saving = false;
        this.$swal(
          "Warning!",
          "This Quick Note name is already exists in list!",
          "warning"
        );
        this.saving = false;
        return;
      }

      let tempRoleList = null;
      if (this.selectedRow.QuickNoteRoles) {
        tempRoleList = this.selectedRow.QuickNoteRoles;
        this.selectedRow.QuickNoteRoles = this.selectedRow.QuickNoteRoles.map(
          (x) => x.Id
        );
      }
      [err, result] = await this.$store.dispatch(type, this.selectedRow);
      this.selectedRow.QuickNoteRoles = tempRoleList;
      if (result) {
        this.$swal("Success!", result.Message, "success");

        if (type == types.POST_QUICKNOTE) {
          this.selectedRow.Id = result.Data.Id;
          this.list.push(this.selectedRow);

          this.oldValue = JSON.stringify(this.selectedRow);
        } else {
          this.oldValue = JSON.stringify(this.selectedRow);
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },

    async handleDelete(Id) {
      this.deleting = true;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.list.indexOf(this.selectedRow);
            let err, result;

            [err, result] = await this.$store.dispatch(
              types.DELETE_QUICKNOTE,
              Id
            );

            if (!err) {
              this.reset();
            }

            if (result) {
              this.list.splice(rowIndex, 1);

              this.reset();

              this.$swal("Deleted!", result.Message, "success");
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
      this.deleting = false;
    },
  },
  validations() {
    return {
      selectedRow: {
        Title: { required },
        Body: { required },
        QuickNoteRoles:{
          hasRole(){
            let rtn = false;
            if(this.hasAccessOtherOffices)
              rtn = true;
            else {
              if (this.selectedRow.QuickNoteRoles && this.selectedRow.QuickNoteRoles.length > 0) {
                rtn = true;
              }
            }
            return rtn;
          }


        }
      },
    };
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
.has-error {
  border: 2px solid red !important;
}

.subjectDiv {
  display: flex;
  justify-content: space-between;
}
</style>
