import axios from "axios";

const API_URL = process.env.VUE_APP_API;

export default {
  authenticate: (payload) => {
    return axios.post(`${API_URL}/api/Login`, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  resetPasswordRequest: (data) => {
    return axios.post(`${API_URL}/api/PasswordResetRequest`, {
      Email: data,
    });
  },
  resetPassword: (data) => {
    return axios.post(`${API_URL}/api/ResetPassword`, data);
  },
};
