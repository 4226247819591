<template>
  <div class="row admin-page officePage">
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 panelWrapper">
      <button
        type="button"
        class="col-md-12 btn btn-primary waves-effect waves-light"
        @click="handleAddDisposition"
      >
        Add New Disposition
      </button>
      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="dispoFilter"
            placeholder="Filter by Disposition"
          />
        </div>
      </div>
      <div class="list col-md-12">
        <div
          class="office item row"
          v-for="(dispoItem, index) in filteredDispos"
          :key="dispoItem.Id"
          @click="() => handleDispoSelection(dispoItem, index)"
          :disabled="saving || deleting"
          :style="[
            index % 2 === 0
              ? { 'background-color': '#F1EFEF' }
              : { 'background-color': '#FFFFFF' },
          ]"
        >
          <span
            :class="{ adminActiveMenuItem: activeDisposition === dispoItem.Id }"
          >
            <i class="ri-sound-module-fill"></i>
            <span style="margin-left: 8px">{{ dispoItem.Name }}</span>
          </span>
        </div>
      </div>
      <div
        v-if="isMobileDevice()"
        style="
          border-top: 1px solid rgb(0, 0, 0, 0);
          margin: 20px 0 0 9px;
          padding-bottom: 20px;
        "
        class="col-md-12 scrollToSelectedItem"
      ></div>
    </div>
    <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12">
      <form
        v-if="isEditing"
        class="form-horizontal formWrapper panelWrapper float-left"
        role="form"
      >
        <div class="panelContent">
          <div class="form-group row">
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <label class="form-label" for="DispositionName"
                >Disposition Name *</label
              >
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <input
                autocomplete="off"
                type="text"
                id="DispositionName"
                class="form-control"
                v-model="selectedRow.Name"
                :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Name.$touch"
                placeholder="Disposition Name"
                v-on:input="checkLength"
              />
              <p style="color: #ff7979" v-if="isMaxLimitReached">
                Maximum 50 characters are allowed!
              </p>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <label class="form-label" for="DisplayOrder"
                >Display Order *</label
              >
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <input
                autocomplete="off"
                type="number"
                min="1"
                max="1000"
                pattern="\d+"
                @change="
                  () => {
                    if (
                      selectedRow.Priority < 1 ||
                      selectedRow.Priority > 1000
                    ) {
                      selectedRow.Priority = 1;
                    }
                  }
                "
                id="displayOrder-dm"
                class="form-control"
                v-model="selectedRow.Priority"
                placeholder="0"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-3 col-lg-4 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label">Copy Settings From</label>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <select v-model="copySettings" id="toStatus" class="form-control">
                <option value>Please Select</option>
                <option
                  v-for="item in sortedDispoList.filter(
                    (x) => x.Id != activeDisposition
                  )"
                  :key="item.Id"
                  :value="item"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3">
              <button
                type="button"
                @click="handleCopySettings"
                class="btn btn-primary waves-effect waves-light float-right widthFull"
              >
                Apply
              </button>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <label class="form-label" for="ddlsubTask">
                <u>Display on Customer Statuses:</u>
              </label>
              <VueMultiselect
                id="ddlsubTask"
                v-model="selectedRow.FromStatusList"
                :options="mappedStatusList"
                :multiple="true"
                :close-on-select="false"
                placeholder="Please Select"
                label="StatusName"
                track-by="Id"
                :class="v$.selectedRow.FromStatusList.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.FromStatusList.$touch"
              />
            </div>
          </div>
          <div class="form-group row mt-1">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <label class="form-label" for="ddlsubTask">
                Roles (leave empty for all roles)
              </label>
              <VueMultiselect
                v-model="selectedRow.RoleList"
                :options="filteredRoles"
                :multiple="true"
                :close-on-select="false"
                placeholder="Please Select"
                label="Name"
                track-by="Id"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12">
              <label class="form-label" for="StatusTo">Change Status To*</label>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <select
                v-model="selectedRow.ToStatusId"
                id="ChangeStatusTo"
                class="form-control"
                :class="v$.selectedRow.ToStatusId.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.ToStatusId.$touch"
              >
                <option :value="null">Do not change</option>
                <option
                  v-for="item in statusList"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12">
              <label class="form-label" for="smsTemplates">Auto SMS</label>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <select
                v-model="selectedRow.SmsId"
                id="smsTemplates"
                class="form-control"
              >
                <option value="null">Do not send</option>
                <option
                  v-for="item in sortedQuickSmsList"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Title }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12">
              <label class="form-label" for="tasktype"
                >Auto Create CSR Task</label
              >
            </div>

            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <select
                v-model="selectedRow.TaskTypeId"
                id="tasktype"
                class="form-control"
              >
                <option :value="null">Do not create</option>
                <option
                  v-for="item in taskTypeNames"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <input
                autocomplete="off"
                type="checkbox"
                id="cbCommentRequired"
                class="checkbox-input form-control"
                v-model="selectedRow.IsCommentRequired"
              />
              <label class="form-label">Comment Required</label>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <input
                type="checkbox"
                id="cbReminderRequired"
                class="checkbox-input form-control"
                v-model="selectedRow.IsReminderRequired"
              />
              <label class="form-label">Reminder Required</label>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <button
                type="button"
                @click="handleSave"
                :disabled="v$.$invalid || saving || deleting"
                class="animated-btn btn btn-success btn-bordered-success waves-effect width-md waves-light submit-button float-right"
              >
                <i v-if="saving" class="fas fa-spinner mr-1"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import types from "./types";
import _orderby from "lodash";
import deepCopy from "@/helpers/deepCopy";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import VueMultiselect from "vue-multiselect";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";

const init = {
  Id: 0,
  Name: "",
  Priority: 0,
  FromStatusList: [],
  ToStatusId: null,
  IsCommentRequired: false,
  IsReminderRequired: false,
  SmsId: null,
  RoleList: [],
  TaskTypeId: null,
};

export default {
  name: "DispositionManagement",
  data() {
    return {
      isMaxLimitReached: false,
      selectedRow: Object.assign({}, init),
      v$: useValidate(),
      copySettings: "",
      isEditing: false,
      saving: false,
      deleting: false,
      activeDisposition: null,
      dispoFilter: "",
      oldValue: "",
      valueToRevert: deepCopy(init),
      isCancelledBeforeSave: false,
      userOfficeId: 0,
      hasAccessOtherOffices: false,
    };
  },
  mixins: [formatMixin, utilitiesMixin],
  components: { VueMultiselect },
  computed: mapState({
    taskTypeNames: (state) => state.globals.taskTypeNames,
    statusList: (state) => state.globals.customerStatuses,
    dispoList: (state) => state.dispositionManagement.list,
    allRoleList: (state) => state.globals.roles,
    sortedDispoList() {
      return _orderby.orderBy(this.dispoList, "Name", "asc");
    },
    quickSmsList: (state) => state.globals.quickSmsTemplates,
    sortedQuickSmsList() {
      return _orderby.orderBy(this.quickSmsList, "Title", "asc");
    },
    filteredDispos() {
      return _orderby.orderBy(
        this.dispoList.filter(
          (item) =>
            item.Name.toLowerCase().indexOf(this.dispoFilter.toLowerCase()) > -1
        ),
        "Priority"
      );
    },
    mappedStatusList() {
      return this.statusList
        .sort((x) => x.StatusName)
        .map((item) => {
          return { Id: +item.Id, StatusName: item.Name };
        });
    },
    filteredRoles() {
      return this.allRoleList
        .filter((x) =>
          this.hasAccessOtherOffices ? x : x.OfficeId == this.userOfficeId
        )
        .sort((x) => x.Name)
        .map((item) => {
          return { Id: +item.Id, Name: item.Name };
        });
    },
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(types.GET_DISPOSITIONSMngmt),
      this.$store.dispatch(globalTypes.GET_STATUS_NAMES),
      this.$store.dispatch(globalTypes.GET_SMS_TEMPLATES, {
        ForceRefresh: true,
      }),
      this.$store.dispatch(globalTypes.GET_ROLES),
      this.$store.dispatch(globalTypes.GET_TASK_TYPE_NAMES),
    ]);

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();
  },

  methods: {
    checkLength() {
      if (this.selectedRow.Name.length > 50) {
        this.selectedRow.Name = this.selectedRow.Name.slice(0, 50);
        this.isMaxLimitReached = true;
      } else {
        this.isMaxLimitReached = false;
      }
    },

    async handleAddDisposition() {
      let currentValue = JSON.stringify(this.selectedRow);
      if (
        this.oldValue != currentValue &&
        this.oldValue != "" &&
        currentValue != JSON.stringify(init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.selectedRow = { ...init };
        this.activeDisposition = null;
        this.isEditing = true;
      }
      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);
        let indx = this.dispoList.findIndex(
          (x) => x.Id == this.valueToRevert.Id
        );
        this.dispoList[indx] = this.valueToRevert;
        this.dispoList.push();
        this.activeDisposition = null;
        this.isEditing = true;
        this.copySettings = "";
        this.selectedRow = { ...init };
      }
      this.isCancelledBeforeSave = false;
    },
    async handleSave() {
      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal.fire("Warning!", "No Changes Detected!", "warning");
        return;
      }
      let selectedId = this.selectedRow.Id;
      let task = this.taskTypeNames.find(
        (x) => x.Id == this.selectedRow.TaskTypeId
      );
      if (typeof task != "undefined" && task != null) {
        if (selectedId == task.AutoDispositionId) {
          this.$swal.fire(
            "Warning!",
            "The selected task will create the same disposition.",
            "warning"
          );
          return;
        }
      }

      this.saving = true;

      let err, result, type;

      let isDispositionOptionExists =
        this.dispoList.filter(
          (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
        ).length > 0;

      if (this.selectedRow.Id) {
        type = types.UPDATE_DISPOSITIONMngmt;
        isDispositionOptionExists =
          this.dispoList.filter(
            (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
          ).length > 1;
      } else {
        type = types.INSERT_DISPOSITIONMngmt;
      }
      if (isDispositionOptionExists) {
        this.saving = false;
        this.$swal.fire(
          "Warning!",
          "This disposition name is already exists in list!",
          "warning"
        );
        this.saving = false;
        return;
      }

      this.selectedRow.ToStatusId =
        this.selectedRow.ToStatusId == "null"
          ? null
          : this.selectedRow.ToStatusId;

      this.selectedRow.SmsId =
        this.selectedRow.SmsId == "null" ? null : this.selectedRow.SmsId;

      [err, result] = await this.$store.dispatch(type, this.selectedRow);

      if (result) {
        this.$swal("Success!", result.Message, "success");
        if (type == types.INSERT_DISPOSITIONMngmt) {
          this.selectedRow = deepCopy(result.Data);
          this.dispoList.push(this.selectedRow);
          this.activeDisposition = this.selectedRow.Id;
          this.oldValue = JSON.stringify(this.selectedRow);
        } else {
          this.oldValue = JSON.stringify(this.selectedRow);
        }
        await this.$store.dispatch(globalTypes.GET_DISPOSITIONTYPES, {
          ForceRefresh: true,
        });
        await this.$store.dispatch(globalTypes.GET_TASK_TYPE_NAMES, {
          ForceRefresh: true,
        });
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },

    handleCancel() {
      this.isEditing = false;
    },

    async handleDispoSelection(row, index) {
      let currentValue = JSON.stringify(this.selectedRow);
      if (
        this.oldValue != currentValue &&
        this.oldValue != "" &&
        currentValue != JSON.stringify(init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.valueToRevert = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeDisposition = row.Id;
        this.copySettings = "";
        this.selectedRow = row;
        this.isEditing = true;
      }

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);
        let indx = this.dispoList.findIndex(
          (x) => x.Id == this.valueToRevert.Id
        );
        this.dispoList[indx] = this.valueToRevert;
        this.dispoList.push();
        this.activeDisposition = row.Id;
        this.selectedRow = row;
        this.valueToRevert = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.copySettings = "";
        this.isEditing = true;
      }
      this.isCancelledBeforeSave = false;

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleCopySettings() {
      if (this.copySettings.length <= 0) return;

      let temp = Object.assign({}, this.copySettings);
      if (temp) {
        this.selectedRow.Priority = temp.Priority;
        this.selectedRow.ToStatusId = temp.ToStatusId;
        this.selectedRow.FromStatusList = temp.FromStatusList;
        this.selectedRow.IsCommentRequired = temp.IsCommentRequired;
        this.selectedRow.IsReminderRequired = temp.IsReminderRequired;
        this.selectedRow.SmsId = temp.SmsId;
      }
    },
    reset() {
      this.activeDisposition = null;
      this.selectedRow = { ...init };
      this.isEditing = false;
    },
  },
  validations() {
    return {
      selectedRow: {
        Name: { required },
        FromStatusList: {
          atLeastOne(list) {
            return list.length > 0;
          },
        },
        ToStatusId: {

        },
      },
    };
  },
};
</script>
<style scoped></style>
