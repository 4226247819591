<template>
  <div class="row admin-page">
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 panelWrapper">
      <button
        type="button"
        class="col-md-12 btn btn-primary waves-effect waves-light"
        @click="handleAddNew"
      >
        Add New Tag
      </button>
      <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          v-model="tagFilter"
          placeholder="Filter tags"
        />
      </div>
      <div class="list col-md-12">
        <div
          class="role item row"
          v-for="(item, index) in filteredCustomerTags"
          :key="item.Id"
          :class="{ active: activeItem === index }"
          :style="[
            index % 2 === 0
              ? { 'background-color': '#F1EFEF' }
              : { 'background-color': '#FFFFFF' },
          ]"
          :disabled="saving"
          @click="() => handleSelection(item, index)"
        >
          <span :class="{ adminActiveMenuItem: activeItem === index }">
            <i class="fas fa-tag"></i>
            <span style="margin-left: 8px">{{ item.TagName }}</span>
          </span>
        </div>
      </div>
    </div>
    <div
      class="col-xl-4 col-lg-6 col-md-12 col-sm-12 noMargin"
      v-if="isEditing"
    >
      <div class="form-group row">
        <div class="col-md-12">
          <label class="col-form-label" for="tagName">Tag Name</label>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <input
            v-model="selectedRow.TagName"
            autocomplete="off"
            type="text"
            id="tagName"
            class="form-control"
            placeholder="Enter tag name"
            :class="v$.selectedRow.TagName.$error ? 'has-error' : ''"
            @blur="v$.selectedRow.TagName.$touch"
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <label class="col-form-label" for="tagType">Tag Type</label>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <select
            v-model="selectedRow.TagDataType"
            class="form-control"
            id="tagType"
            :class="v$.selectedRow.TagDataType.$error ? 'has-error' : ''"
            @blur="v$.selectedRow.TagDataType.$touch"
          >
            <option :value="null">Select tag type</option>
            <option
              v-for="item in sortedDataTypes"
              :key="item.Id"
              :value="item.Name"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>
      </div>

      <div
        v-if="selectedRow.TagDataType == 'Option Box'"
        class="form-group row"
      >
        <div class="col-md-9 col-9">
          <input
            v-model="optionBoxInput"
            type="text"
            class="form-control"
            placeholder="Please enter"
          />
        </div>
        <div class="col-md-3 col-3 noPadding">
          <i
            class="ri-add-fill text-success pr-1"
            style="font-size: 26px; font-weight: bold; cursor: pointer"
            @click="handleAddOption()"
          ></i>
          <i
            class="ri-close-line text-danger"
            style="font-size: 26px; font-weight: bold; cursor: pointer"
            @click="clearInput()"
          ></i>
        </div>
        <div style="max-height: 257px; overflow-y: auto" class="col-md-12 mt-2">
          <div v-if="addingList.length > 0">
            <label>Option</label>
          </div>
          <div
            class="col-md-12 form-group row"
            v-for="item in addingList"
            :key="item.Id"
          >
            <label
              >{{ item.Name }}

              <i
                @click="removeOption(item)"
                class="ri-close-line text-danger removeButtons"
                style="color: red; cursor: pointer"
              ></i>
            </label>
          </div>
        </div>
      </div>

      <div class="col-md-12 noPadding row">
        <label class="col-form-label" for="displayAsWarningPopUp"
          >Display as warning pop up</label
        >
        <input
          id="displayAsWarningPopUp"
          v-model="selectedRow.IsPopUp"
          type="checkbox"
          class="checkbox-input generalCheckBox float-right ml-2"
          style="
            margin-top: 10px;
            border-radius: 4px !important;
            border: solid 1px #d1d1d1 !important;
          "
        />
      </div>

      <div class="form-group row mt-2">
        <div
          class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
          v-if="selectedRow && selectedRow.Id > 0"
        >
          <button
            type="button"
            style="width: 100%"
            class="float-right btn btn-danger btn-md waves-effect waves-light"
            @click="deleteCustomerTag()"
          >
            Delete
          </button>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <button
            type="button"
            style="width: 100%"
            class="float-right btn btn-success btn-md waves-effect waves-light"
            :disabled="v$.$invalid || saving"
            @click="saveCustomerTag()"
          >
            <i v-if="saving" class="fas fa-spinner mr-1"></i>
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import types from "./types";
import globalTypes from "@/store/types.js";
import deepCopy from "@/helpers/deepCopy";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import _orderby from "lodash";
export default {
  name: "TagManagement",
  mixins: [utilitiesMixin],
  components: {},
  computed: mapState({
    customerTaglist: (state) => state.tagManagement.customerTaglist,
    filteredCustomerTags() {
      return this.customerTaglist.filter(
        (item) =>
          item.TagName.toLowerCase().indexOf(this.tagFilter.toLowerCase()) > -1
      );
    },
    sortedDataTypes() {
      return this.dataTypeList.length > 0
        ? _orderby.orderBy(this.dataTypeList, "Name", "asc")
        : [];
    },
  }),
  data() {
    return {
      addingList: [],
      optionBoxInput: "",
      isEditing: false,
      saving: false,
      v$: useValidate(),
      oldValue: "",
      isCancelledBeforeSave: false,
      selectedRow: Object.assign({}, this.tagInit()),
      tagFilter: "",
      activeItem: null,
      dataTypeList: [
        {
          Id: 1,
          Name: "CheckItem",
        },
        {
          Id: 2,
          Name: "Date",
        },
        {
          Id: 3,
          Name: "Date and Time",
        },
        {
          Id: 9,
          Name: "Expense",
        },
        {
          Id: 4,
          Name: "Money",
        },
        {
          Id: 5,
          Name: "Number",
        },
        {
          Id: 6,
          Name: "Percentage",
        },
        {
          Id: 7,
          Name: "Text",
        },
        {
          Id: 8,
          Name: "Option Box",
        },
        {
          Id: 9,
          Name: "Income",
        },
      ],
    };
  },
  methods: {
    handleAddOption() {
      let largestNumber = 0;
      this.addingList.forEach((i) => {
        if (i.Id > largestNumber) {
          largestNumber = i.Id;
        }
      });

      if (this.optionBoxInput) {
        let item = {
          Id: largestNumber + 1,
          Name: this.optionBoxInput,
        };

        if (this.addingList.some((x) => x.Name == item.Name)) {
          this.$swal("Warning!", "Option already exists!", "warning");
          return;
        } else {
          this.addingList.push(item);
          this.optionBoxInput = "";
        }
      }
    },

    clearInput() {
      this.optionBoxInput = "";
    },

    removeOption(listItem) {
      let index = this.addingList.findIndex((x) => x.Id == listItem.Id);
      if (index > -1) {
        this.addingList.splice(index, 1);
      }
    },

    tagInit() {
      return {
        Id: null,
        TagName: "",
        TagDataType: null,
        TagDataOptions: "",
        IsPopUp: false,
      };
    },
    async handleAddNew() {
      if (
        this.selectedRow &&
        this.selectedRow.Id != null &&
        JSON.stringify(this.selectedRow) != this.oldValue
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = true;
              this.addingList = [];
            } else {
              this.isCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.isEditing = true;

        this.selectedRow = Object.assign({}, this.tagInit());
        this.activeItem = null;
      }
      if (this.isCancelledBeforeSave) {
        this.isEditing = true;
        this.selectedRow = Object.assign({}, this.tagInit());
        this.oldValue = JSON.stringify(this.selectedRow);
        this.activeItem = null;
      }
      this.isCancelledBeforeSave = false;
    },
    async handleSelection(row, index) {
      if (
        this.selectedRow &&
        JSON.stringify(this.selectedRow) != this.oldValue &&
        JSON.stringify(this.selectedRow) != JSON.stringify(this.tagInit())
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = true;
            } else {
              this.isCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.selectedRow = deepCopy(row);
        if (
          this.selectedRow.TagDataOptions &&
          this.selectedRow.TagDataOptions != ""
        ) {
          this.addingList = JSON.parse(this.selectedRow.TagDataOptions);
        } else {
          this.addingList = [];
        }
        this.oldValue = JSON.stringify(row);
        this.activeItem = index;
        this.isEditing = true;
      }
      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(row);
        if (
          this.selectedRow.TagDataOptions &&
          this.selectedRow.TagDataOptions != ""
        ) {
          this.addingList = JSON.parse(this.selectedRow.TagDataOptions);
        } else {
          this.addingList = [];
        }
        this.oldValue = JSON.stringify(row);
        this.activeItem = index;
        this.isEditing = true;
      }
      this.isCancelledBeforeSave = false;
    },
    reset() {
      this.activeItem = null;
      this.selectedRow = Object.assign({}, this.tagInit());
      this.isEditing = false;
    },
    async deleteCustomerTag() {
      await this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.customerTaglist.findIndex(
              (x) => x.Id == this.selectedRow.Id
            );
            let err, result;
            this.saving = true;
            [err, result] = await this.$store.dispatch(
              types.DELETE_CUSTOMER_TAG,
              { Id: this.selectedRow.Id }
            );
            if (!err) {
              this.reset();
            }
            if (result) {
              if (rowIndex > -1) {
                this.customerTaglist.splice(rowIndex, 1);
                this.reset();
                this.$store.dispatch(globalTypes.GET_CUSTOMER_TAG_TYPE, {
                  ForceRefresh: true,
                });
              }
              this.$swal.fire("Deleted!", result.Message, "success");
              this.selectedRow = null;
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
      this.saving = false;
    },
    async saveCustomerTag() {
      if (this.addingList.length > 0) {
        this.selectedRow.TagDataOptions = JSON.stringify(this.addingList);
      }

      let isTagExists =
        this.customerTaglist.filter(
          (x) => x.TagName.toLowerCase() == this.selectedRow.TagName.toLowerCase()
        ).length > 0;

        if (isTagExists) {
        this.$swal.fire(
          "Warning!",
          "This tag name is already exists in tag list!",
          "warning"
        );
        return;
      }

      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal.fire("Warning!", "No changes detected!", "warning");
      } else {
        this.saving = true;
        let err, result, type;
        type =
          this.selectedRow.Id > 0
            ? types.UPDATE_CUSTOMER_TAG
            : types.CREATE_CUSTOMER_TAG;

        [err, result] = await this.$store.dispatch(type, this.selectedRow);
        if (result) {
          this.$swal.fire("Success!", result.Message, "success");

          if (type == types.CREATE_CUSTOMER_TAG) {
            this.selectedRow = deepCopy(result.Data);
            this.customerTaglist.push(this.selectedRow);
          } else if (type == types.UPDATE_CUSTOMER_TAG) {
            this.selectedRow = deepCopy(result.Data);
            let indx = this.customerTaglist.findIndex(
              (x) => x.Id == this.selectedRow.Id
            );
            if (indx >= 0) {
              this.customerTaglist[indx] = deepCopy(result.Data);
              this.customerTaglist.push();
            }
          }
          this.oldValue = JSON.stringify(result.Data);
          this.$store.dispatch(globalTypes.GET_CUSTOMER_TAG_TYPE, {
            ForceRefresh: true,
          });
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
        this.saving = false;
      }
    },
  },
  async mounted() {
    await Promise.all([this.$store.dispatch(types.GET_ALL_CUSTOMER_TAG)]);
  },

  validations() {
    return {
      selectedRow: {
        TagName: { required },
        TagDataType: { required },
      },
    };
  },
};
</script>

<style></style>
