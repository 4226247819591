export default {
  methods: {
    formatSSN(deg) {
      if (!deg) return "";

      if (deg.length == 4) {
        return "***-**-" + deg;
      }
      let val = deg.replace(/\D/g, "");
      val = val.replace(/^(\d{3})/, "$1-");
      val = val.replace(/-(\d{2})/, "-$1-");
      val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
      return val;
    },
    formatDate(date) {
      return new Date(date).toLocaleString("en-US", {
        hour12: true,
        dateStyle: "short",
        timeStyle: "short",
      });
    },
    formatGridDate(date) {
      return date == null || date == ""
        ? ""
        : new Date(date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
          });
    },
    formatGridDateSecond(date) {
      return date == null || date == ""
        ? ""
        : new Date(date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit",
          });
    },
    formatShortDate(date) {
      return date == null || date == ""
        ? ""
        : new Date(date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
    },
    getDateNowUtcTimeStamp() {
      let localDate = new Date();
      let utcDate = Date.UTC(
        localDate.getUTCFullYear(),
        localDate.getUTCMonth(),
        localDate.getUTCDate(),
        localDate.getUTCHours(),
        localDate.getUTCMinutes(),
        localDate.getUTCSeconds()
      );

      return utcDate;
    },
    formatMoney(value) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });

      return value != null || value != "" || !isNaN(value)
        ? formatter.format(value)
        : "";
    },
    formatFloatingNumber(value) {
      let formatter = new Intl.NumberFormat("en-US", {
        maximumSignificantDigits: 2,
      });

      return value != null || value != "" ? formatter.format(value) : "";
    },
    formatNumberWithoutDecimalPoints(value) {
      return value != null || value != ""
        ? value.toLocaleString("en-US", {})
        : "";
    },
    cleanFormatPhone(number) {
      let rtn = number.replace("@phone.plivo.com", "");
      rtn = rtn.replaceAll("+1", "");
      rtn = rtn.replaceAll("(", "");
      rtn = rtn.replaceAll(")", "");
      rtn = rtn.replaceAll("-", "");
      rtn = rtn.replaceAll("+", "");
      rtn = rtn.replaceAll(" ", "");

      return rtn;
    },
    cleanHtml(text) {
      return text.replace(/( |<([^>]+)>)/gi, "");
    },
    cleanSpecialChars(word) {
      let rtn = word;

      if (typeof word != "undefined" && word != null) {
        rtn = word.replace(/[^\w ]/g, "");
      }

      return rtn != null ? rtn.toLowerCase() : "";
    },
    formatPhone(number) {
      let cleaned = number.replace("@phone.plivo.com", "");

      cleaned = ("" + number).replace(/\D/g, "");

      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return ["(", match[2], ") ", match[3], "-", match[4]].join("");
      }
    },

    tryFormatPhone(number) {
      let cleaned = ("" + number).replace(/\D/g, "");

      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return ["(", match[2], ") ", match[3], "-", match[4]].join("");
      } else return number;
    },
    formatSecond(scnd) {
      let sec_num = parseInt(scnd, 10);
      if (!Number.isInteger(sec_num)) return "00:00";
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - hours * 3600) / 60);
      let seconds = sec_num - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (hours > 0) return hours + ":" + minutes + ":" + seconds;
      else return minutes + ":" + seconds;
    },
    formatSecondForUserTimeLogReport(scnd) {
      let sec_num = parseInt(scnd, 10);
      if (!Number.isInteger(sec_num)) return "00:00:00";

      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - hours * 3600) / 60);
      let seconds = sec_num - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      return hours + ":" + minutes + ":" + seconds;
    },
    getTime12Hours(hour, minute) {
      let rtn = "";

      if (
        typeof hour != "undefined" &&
        hour != null &&
        typeof minute != "undefined" &&
        minute != null
      ) {
        minute = minute < 10 ? "0" + minute : minute;

        if (parseInt(hour) > 0) {
          if (hour > 12) {
            rtn = parseInt(hour - 12) + ":" + minute + " PM";
          } else if (hour == 12) {
            rtn = hour + ":" + minute + " PM";
          } else {
            rtn = hour + ":" + minute + " AM";
          }
        } else {
          rtn = "12:" + minute + " AM";
        }
      }

      return rtn;
    },
    convert12HoursTo24(time) {
      if (time.includes("AM") || time.includes("PM")) {
        let hours = Number(time.match(/^(\d+)/)[1]);
        let minutes = Number(time.match(/:(\d+)/)[1]);
        let AMPM = time.match(/\s(.*)$/)[1].toLowerCase();

        if (AMPM == "pm" && hours < 12) hours = hours + 12;
        if (AMPM == "am" && hours == 12) hours = hours - 12;
        let sHours = hours.toString();
        let sMinutes = minutes.toString();
        if (hours < 10) sHours = "0" + sHours;
        if (minutes < 10) sMinutes = "0" + sMinutes;

        return sHours + ":" + sMinutes;
      } else return time;
    },
    isLeadDistroTimeInValid(startTime, endTime) {
      let rtn = false;

      if (startTime.includes(":") && endTime.includes(":")) {
        let hours1 = Number(startTime.split(":")[0]);
        let minutes1 = Number(startTime.split(":")[1]);

        let hours2 = Number(endTime.split(":")[0]);
        let minutes2 = Number(endTime.split(":")[1]);

        if (hours1 > hours2 || (hours1 == hours2 && minutes1 > minutes2))
          rtn = true;
      }

      return rtn;
    },
    isMobileDevice() {
      {
        let check = false;
        (function (a) {
          if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
              a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
              a.substr(0, 4)
            )
          )
            check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
      }
    },
    calculateTableSummary(list, columnType, totalType) {
      let total = 0;

      for (let item of list) {
        if (columnType.toLowerCase() == "numeric") total += parseInt(item);
        if (columnType.toLowerCase() == "decimal") total += parseFloat(item);
      }

      if (totalType.toLowerCase() == "total") return total;
      else if (totalType.toLowerCase() == "avg") {
        return total == 0 || list.length == 0
          ? 0
          : parseFloat(total / list.length);
      }

      return total;
    },
    calculatePercentageSummary(
      firstColumnValues,
      firstColumnType,
      secondColumnValues,
      secondColumnType
    ) {
      let column1total = 0;
      let column2total = 0;
      for (let item of firstColumnValues) {
        if (firstColumnType.toLowerCase() == "numeric")
          column1total += parseInt(item);
        if (firstColumnType.toLowerCase() == "decimal")
          column1total += parseFloat(item);
      }
      for (let item of secondColumnValues) {
        if (secondColumnType.toLowerCase() == "numeric")
          column2total += parseInt(item);
        if (secondColumnType.toLowerCase() == "decimal")
          column2total += parseFloat(item);
      }

      return column1total == 0 || firstColumnValues.length == 0
        ? 0
        : parseFloat((column1total / column2total) * 100);
    },

    formatCell(value, type) {
      let rtn = value;
      if (type == "numberic") {
        rtn = value;
      } else if (type == "percentage") {
        rtn = parseFloat(value).toFixed(2) + "%";
      } else if (type == "decimal") {
        rtn = this.formatMoney(value);
      } else if (type == "date") {
        rtn = this.formatDate(value);
      } else if (type == "time") {
        rtn = this.formatSecond(value);
      }
      return rtn;
    },
    getExtension(fileName) {
      let rtn = "";
      if (fileName && fileName.indexOf(".") > -1) {
        rtn = "." + fileName.split(".").pop();
      }
      return rtn;
    },
    getFileNameWithoutExt(fileName) {
      let rtn = fileName;
      if (fileName && fileName.indexOf(".") > -1) {
        let exten = "." + fileName.split(".").pop();
        rtn = fileName.replace(exten, "");
      }
      return rtn;
    },
    convertNull(value) {
      return value == "null" ? null : value;
    },

    formatPercantage(value) {
      return (value * 100).toFixed(0) + "%";
    },
  },
};
