<template>
  <Popover class="more-actions" :height="panelHeight">
    <template v-slot:header>
      <div class="container">More Actions</div>
    </template>
    <template v-slot:body>
      <div v-if="moreActionMenuVisible" class="contact-list cold-transfer">
        <!-- <p
          class="contact"
          :key="item.Id"
          v-for="item in announceDepartments"
          v-on:click.prevent.stop="announceDepartment(item)"
        >
          {{ item.Name }}
        </p> -->
        <p class="contact" v-on:click.prevent.stop="openKeyCode()">
          Send key code
        </p>
        <p
          v-if="!checkAuth(100030)"
          class="contact"
          v-on:click.prevent.stop="openAnnounceCall()"
        >
          Announce Call
        </p>
        <!-- <p
          class="contact"
          v-on:click.prevent.stop="openAnnounceToDepartment()"
        >
          Announce to Department
        </p> -->
        <p class="contact" v-on:click.prevent.stop="open3rdParty()">
          3rd Party Call
        </p>
        <p class="contact" v-if="loading">
          <i class="spinner-grow"></i>
        </p>
      </div>

      <div v-if="keyCodeVisible" class="full-width p-12">
        <div class="full-width mb-2">
          <i style="margin-right: 5px" class="icon remixicon-keyboard-line"></i>
          <span>Send Key Code</span>
          <button
            class="no-button float-right"
            type="button"
            v-on:click.prevent.stop="backToMenu()"
          >
            &lt; Back
          </button>
        </div>
        <input
          class="form-control full-width"
          type="text"
          v-model="keyCode"
          @keypress="keyChange($event)"
          v-on:click.prevent.stop="() => {}"
          v-on:keydown.enter.prevent="handleKeyCode()"
        />
        <button
          type="button"
          @click="handleKeyCode()"
          class="btn btn-primary btn-primary-bordered full-width mt-12"
        >
          Send
        </button>
      </div>
      <div v-if="announceCallVisible" class="full-width">
        <div class="transfer">
          <div class="container mt-1">
            Call Agent
            <button
              class="no-button"
              type="button"
              v-on:click.prevent.stop="backToMenu()"
            >
              &lt;Back
            </button>
            <input
              v-on:click.prevent.stop="() => {}"
              class="form-control mt-1"
              type="text"
              v-model="agentFilter"
            />
          </div>

          <div class="contact-list">
            <div
              class="contact"
              @click="announceCall(item)"
              :key="item.Id"
              v-for="item in softPhoneEmployees"
            >
              {{ item.Name }}
              <i
                :style="'background-color: ' + statusColor(item.EndpointName)"
                class="ri-phone-fill"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div v-if="call3PartyVisible" class="full-width">
        <div class="container">
          <div class="full-width mb-2">
            <span>3rd Party Call</span>
            <button
              class="no-button float-right"
              type="button"
              v-on:click.prevent.stop="backToMenu()"
            >
              &lt; Back
            </button>
          </div>
          <input
            class="form-control full-width"
            type="text"
            v-model="callNumber"
            @change="refreshValue"
            v-myMask="{ mask: ['(999) 999-9999'] }"
            v-on:click.prevent.stop="() => {}"
            v-on:keydown.enter.prevent="call3rdParty()"
            :disabled="activeCall.is3rdPartyCall"
          />
          <!-- <div class="full-width mt-2 mb-2">
            <div
              @click="callListItem(item)"
              class="full-width mt-1"
              v-for="item in thirdPartyList"
              :key="item.PhoneNumber"
            >
              {{ item.DisplayName }}
            </div>
          </div> -->
          <button
            type="button"
            @click="call3rdParty()"
            class="btn btn-primary btn-primary-bordered full-width mt-12"
            :disabled="activeCall.is3rdPartyCall"
          >
            Call
          </button>
          <button
            v-if="activeCall.is3rdPartyCall"
            type="button"
            style="background-color: #ed3030"
            @click="hangUp3rdParty()"
            class="btn btn-danger btn-danger-bordered full-width mt-12"
          >
            Hang up 3rd Party
          </button>
          <button
            v-if="activeCall.is3rdPartyCall"
            type="button"
            @click="kickAgent()"
            class="btn btn-warning btn-warning-bordered full-width mt-12"
          >
           Leave Conference
          </button>
        </div>
      </div>

      <div v-if="announceToDepartmentVisible" class="full-width">
        <div class="transfer">
          <div class="container mt-1">
            Announce to Department
            <button
              class="no-button"
              type="button"
              v-on:click.prevent.stop="backToMenu()"
            >
              &lt;Back
            </button>
            <input
              v-on:click.prevent.stop="() => {}"
              class="form-control mt-1"
              type="text"
              v-model="roleFilter"
            />
          </div>

          <div class="contact-list">
            <div
              class="contact"
              @click="announceToDepartment(item)"
              :key="item.Id"
              v-for="item in filteredRoles"
            >
              {{ item.Name }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </Popover>
</template>

<script>
import Popover from "../../Popover";
import types from "../types";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import _orderby from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "../../../helpers/deepCopy";
export default {
  components: {
    Popover,
  },
  props: {
    endPointName: {
      type: String,
      default: () => "",
    },
    activeCall: {
      type: Object,
      default: () => null,
    },
  },
  mixins: [utilitiesMixin],
  data() {
    return {
      loading: false,

      //announceDepartmentsLoaded: false,
      roleFilter: "",
      userOfficeId: 0,
      panelHeight: 200,
      keyCodeVisible: false,
      announceCallVisible: false,
      call3PartyVisible: false,
      moreActionMenuVisible: true,
      announceToDepartmentVisible: false,
      agentFilter: "",
      callNumber: "",
      keyCode: "",
      allowedKeyCodes: [35, 42, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57], // *#0-9,
      thirdPartyList: [],
    };
  },
  computed: mapState({
    employeeList: (state) => state.globals.allEmployees,
    endPointList: (state) => state.phoneManagement.endPointList,
    softPhoneEmployees() {
      return _orderby.orderBy(
        this.checkAuth(100005)
          ? this.employeeList.filter(
              (x) =>
                x.Name.toLowerCase().indexOf(this.agentFilter.toLowerCase()) >
                -1
            )
          : this.employeeList
              .filter((x) => x.OfficeId == this.userOfficeId)
              .filter((item) => item.IsPhoneEnabled)
              .filter((item) => item.EndpointName != "")
              .filter((item) => item.EndpointName != this.endPointName)
              .filter(
                (x) =>
                  x.Name.toLowerCase().indexOf(this.agentFilter.toLowerCase()) >
                  -1
              ),
        "Name"
      );
    },
    // announceDepartmentEmployees() {
    //   return _orderby.orderBy(

    //       this.employeeList
    //           .filter((item) => item.IsPhoneEnabled)
    //           .filter((item) => item.EndpointName != "")
    //           .filter((item) => item.EndpointName != this.endPointName)
    //           .filter(
    //             (x) =>
    //               x.Name.toLowerCase().indexOf(this.agentFilter.toLowerCase()) >
    //               -1
    //           ),
    //     "Name"
    //   );
    // },
    roles: (state) => state.globals.roles,
    filteredRoles() {
      return this.roles.length > 0
        ? _orderby.orderBy(
            this.roles
              .filter((x) => x.ModuleFunctions.includes(100050))
              .filter(
                (x) =>
                  x.Name.toLowerCase().indexOf(this.roleFilter.toLowerCase()) >
                  -1
              ),
            "Name"
          )
        : [];
    },
    //announceDepartments: (state) => state.phoneManagement.announceDepartments,
  }),

  async mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo) {
      this.userOfficeId = userInfo.officeId;
    }
    await this.$store.dispatch(types.GET_ENDPOINT_LIST);
    await this.$store.dispatch(globalTypes.GET_ROLES);
    this.loading = true;
    //await this.$store.dispatch(types.GET_ANNOUNCE_DEPARTMENT_DIDS);

    this.loading = false;

    if (this.activeCall && this.activeCall.is3rdPartyCall) {
      let phoneNumber = this.unformatPhone(this.activeCall.phone3rdParty);
      if (phoneNumber.length == 10) {
        this.callNumber = phoneNumber;
      }
    }
  },
  methods: {
    // callListItem(item) {
    //   if (item && item.PhoneNumber && item.PhoneNumber.length >= 10) {
    //     this.$emit("call3rdParty", {
    //       Did: item.PhoneNumber,
    //       MuteOnMerge: item.MuteOnMerge,
    //       ValidateDocusign: item.ValidateDocusign,
    //     });
    //   }
    // },
    announceDepartment(item) {
      if (item.Did != "") {
        this.$emit("departmentWelcomeCall", {
          Department: item.Name,
          DepartmentDid: item.Did,
        });
      }
    },

    // announceToDepartment(item) {

    //   let endPointList = this.announceDepartmentEmployees
    //     .filter((x) => x.EndpointName != "")
    //     .filter((x) => x.Roles.includes(item.Id))
    //     .map((x) => x.EndpointName);

    //   if (endPointList.length > 0) {
    //     this.$emit("departmentAnnounceCall", {RoleId: item.Id,  Department: item.Name } );
    //   } else {
    //     this.$swal(
    //       "Warning!",
    //       "No Available Agents in this department.",
    //       "warning"
    //     );
    //   }
    // },
    refreshValue(event) {
      if (event.target.value && event.target.value != "") {
        this.callNumber = event.target.value;
      }
    },

    closeAll() {
      this.moreActionMenuVisible = false;
      this.keyCodeVisible = false;
      this.moreActionMenuVisible = false;
      this.announceCallVisible = false;
      this.call3PartyVisible = false;
      this.announceToDepartmentVisible = false;
      this.panelHeight = 200;
    },
    openKeyCode() {
      this.closeAll();
      this.keyCodeVisible = true;
    },
    openAnnounceCall() {
      this.closeAll();
      this.announceCallVisible = true;
      this.panelHeight = 300;
    },
    async open3rdParty() {
      this.closeAll();
      this.panelHeight = 300;
      this.call3PartyVisible = true;
      // let err, result;
      // this.loading = true;
      // [err, result] = await this.$store.dispatch(types.GET_3RD_PARTY_LIST);
      // if (result) {
      //   this.thirdPartyList = deepCopy(result.Data);
      // }
      // this.loading = false;
    },
    openAnnounceToDepartment() {
      this.closeAll();
      this.announceToDepartmentVisible = true;
      this.panelHeight = 300;
    },
    backToMenu() {
      this.closeAll();
      this.moreActionMenuVisible = true;
    },
    handleKeyCode() {
      this.$emit("sendDtmf", this.keyCode);
      this.keyCode = "";
    },
    keyChange(event) {
      if (!this.allowedKeyCodes.includes(event.keyCode)) {
        event.preventDefault();
      }
    },
    announceCall(item) {
      if (
        this.endPointList &&
        this.endPointList.length > 0 &&
        this.softPhoneEmployees.length > 0
      ) {
        let indx = this.endPointList.findIndex(
          (x) => x.UserName == item.EndpointName
        );
        if (indx > -1) {
          if (this.endPointList[indx].IsOnline) {
            this.$emit("announceCall", item);
          } else {
            this.$swal("Warning!", "Agent is not online.", "warning");
          }
        } else this.$swal("Error!", "Agent not found. Unable to call", "error");
      }
    },
    statusColor(EndpointName) {
      let rtn = "gray";

      if (
        this.endPointList &&
        this.endPointList.length > 0 &&
        this.softPhoneEmployees.length > 0
      ) {
        let indx = this.endPointList.findIndex(
          (x) => x.UserName == EndpointName
        );
        if (indx > -1) {
          let item = this.endPointList[indx];
          if (item.IsOnCall) {
            rtn = "#ed3030";
          } else if (item.IsOnline) {
            rtn = "#24d15f";
          }
        }
      }
      return rtn;
    },
    call3rdParty() {
      if (this.callNumber != "" && this.callNumber.length >= 10) {
        this.$emit("call3rdParty", {
          Did: this.callNumber,
          MuteOnMerge: false,
          ValidateDocusign: false,
        });
      }
    },
    hangUp3rdParty() {
      if (this.callNumber != "") this.$emit("hangUp3rdParty", this.callNumber);
    },
    kickAgent(){
      this.$emit("kickAgent");
    },
    unformatPhone(did) {
      let rtn = "";
      if (did && did.length > 2) {
        rtn = did
          .replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .replace(" ", "")
          .replace("+1", "")
          .replace("+", "");
      }
      return rtn;
    },
  },
};
</script>
