<template>
  <div v-show="(tabId != null && tabId == Customer.Id) || tabId == null">
    <div
      v-if="isLoading"
      class="policyLoader noPadding"
      style="left: -12px; width: 105%"
    >
      <div class="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div>
      <div class="row">
        <div
          class="col-md-12 policy-header"
          style="margin: 0; position: relative; padding: 0"
          :style="
            attorneyOfficeName != ''
              ? 'background: linear-gradient(180deg, rgba(244,249,255,1) 0%, rgba(255 219 219) 100%)'
              : 'background-color: #f4f9ff'
          "
        >
          <div class="row pb-2" style="position: relative">
            <div class="col-xl-12 col-lg-12 col-md-12">
              <div class="row" style="padding: 15px 15px 0 15px">
                <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12">
                  <span
                    class="generalLabel"
                    style="font-size: 18px; color: #2a404c"
                    >{{ Customer.CustomerProfile.FirstName }}
                    {{ Customer.CustomerProfile.LastName }}
                    {{ Customer.Id > 0 ? "- " + Customer.Id : "" }}
                  </span>
                </div>
                <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 noPadding">
                  <button
                    type="button"
                    @click="handleSave"
                    :id="'CustomerSaveButton' + customerId.toString()"
                    :disabled="invalid || saving"
                    class="btn generalButton float-right ml-1 mr-1"
                    style="background-color: #3bafda; color: #ffffff"
                  >
                    <i
                      v-if="saving"
                      class="fas fa-spinner"
                      style="font-size: 18px"
                    ></i>
                    <i
                      class="fas fa-save"
                      style="font-size: 18px; margin-top: 5px"
                    ></i>
                  </button>

                  <button
                    type="button"
                    @click="handleFavorite"
                    v-if="Customer && Customer.Id > 0"
                    :disabled="saving || Customer.Id == 0"
                    class="btn generalButton float-right ml-1 mr-1"
                    style="color: #3bafda"
                    title="Favorite"
                  >
                    <i
                      style="font-size: 18px; margin-top: 5px"
                      v-if="!isFavorited"
                      class="far fa-star"
                    ></i>
                    <i
                      style="font-size: 18px; margin-top: 5px"
                      v-if="isFavorited"
                      class="fas fa-star"
                    ></i>
                  </button>

                  <button
                    type="button"
                    @click="handleSms"
                    :disabled="saving"
                    v-if="
                      Customer.CustomerProfile.CustomerPhones &&
                      Customer.CustomerProfile.CustomerPhones.length > 0 &&
                      isAuthorizedToSee(6231)
                    "
                    class="btn generalButton float-right ml-1 mr-1"
                    style="background-color: #3bafda; color: #ffffff"
                    title="Send SMS"
                  >
                    <i
                      class="fas fa-sms"
                      style="font-size: 18px; margin-top: 5px"
                    ></i>
                  </button>

                  <button
                    v-if="hasAuth(3912)"
                    type="button"
                    class="btn generalButton float-right ml-1 mr-1"
                    style="background-color: #3bafda; color: #fff"
                    @click="openQuickEmails()"
                  >
                    <i
                      class="fas fa-envelope"
                      style="font-size: 18px; margin-top: 5px"
                    ></i>
                  </button>
                  <button
                    type="button"
                    class="btn generalButton float-right ml-1 mr-1"
                    style="background-color: #3bafda; color: #fff"
                    @click="refreshCustomerFromDB(false)"
                  >
                    <i
                      class="fas fa-sync"
                      style="font-size: 18px; margin-top: 5px"
                    ></i>
                  </button>
                  <button
                    v-if="false"
                    type="button"
                    class="btn generalButton float-right ml-1 mr-1"
                    style="background-color: #1abc9c; color: #fff"
                  >
                    Next Client
                  </button>
                  <button
                    v-if="false"
                    type="button"
                    class="btn generalButton float-right ml-1 mr-1"
                    style="background-color: #f1556c; color: #fff"
                  >
                    Previous Client
                  </button>
                </div>
              </div>

              <div class="row" style="padding: 0 0 0 3px">
                <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12 noMargin">
                  <div class="col-md-12 noMargin">
                    <label
                      style="font-size: 14px"
                      class="generalLabel"
                      for="assignedAgent"
                      >Assigned Agent</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      id="assignedAgent"
                      class="headerSelectBox"
                      v-model="Customer.SalesUserId"
                      v-enableIfAuthorized="'1071'"
                    >
                      <option :value="null">Unassigned</option>
                      <option
                        v-for="item in finalSalesAgentList"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12 noMargin">
                  <div class="col-md-12 noMargin">
                    <label
                      style="font-size: 14px"
                      class="generalLabel"
                      for="office"
                      >Office</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      :disabled="!hasAuth(1454) && !hasAuth(55011)"
                      id="office"
                      class="headerSelectBox"
                      v-model="Customer.OfficeId"
                      @change="officeChanged"
                    >
                      <option :disabled="Customer.Id > 0" :value="null">
                        Unassigned
                      </option>
                      <option
                        v-for="item in filteredOffices"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12 noMargin">
                  <div class="col-md-12 noMargin">
                    <label
                      style="font-size: 14px"
                      class="generalLabel"
                      for="assignedAgent"
                      >Status</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      v-model="Customer.StatusId"
                      id="customerStatus"
                      class="headerSelectBox"
                      v-enableIfAuthorized="'1040'"
                    >
                      <option :value="convertNull('null')">
                        Select Status
                      </option>
                      <option
                        v-for="item in customerStatuses"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 policyWrapper" style="padding-top: 4px">
          <div
            v-if="
              Customer.OpenTasks &&
              checkAuth(10028) &&
              Customer.OpenTasks.length > 0
            "
            style="
              box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
              border: 1px rgba(255, 178, 178, 0.363) solid;
              padding: 5px 4px 0px 4px;
              margin-top: 5px;
              margin-bottom: 5px;
              background-color: #f0556c;
              color: white;
              border-radius: 0.25rem;
            "
          >
            <div style="display: flex">
              <div>
                <label style="color: white" class="generalLabel mr-1"
                  >Open Tasks:</label
                >
                <label class="generalLabel" style="color: white">
                  {{ limitedTasks.join(",") }}</label
                >
              </div>
              <div v-if="Customer.OpenTasks.length > 3" class="dropdown">
                <span style="cursor: pointer; padding: 8px 10px; color: white">
                  <i class="fas fa-info-circle"></i>
                </span>

                <div style="color: black" class="dropdown-content">
                  <a
                    class="generalLabel"
                    v-for="item in remainingTasks"
                    :key="item"
                    >{{ item }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 policy-form noPadding mt-1">
            <ul class="nav">
              <li
                v-show="getTabIsVisible(tab)"
                v-for="(tab, index) in tabs"
                :key="tab.id"
                style="margin: 0 15px 8px 0"
              >
                <a
                  data-toggle="tab"
                  :aria-expanded="tab.active"
                  v-on:click.prevent="() => handleTabChange(index)"
                  class="policyTabItem"
                  :class="{ policyTabActive: tab.active }"
                >
                  <span class="d-inline-block">
                    <i style="font-size: 16px" :class="tab.iconName"></i>
                  </span>
                  <span class="d-none d-sm-inline-block tab-item-label">
                    {{ tab.label }}
                  </span>
                  <span
                    v-if="
                      Customer.OpenTasks &&
                      Customer.OpenTasks.length > 0 &&
                      checkAuth(10028) &&
                      tab.id == 4
                    "
                    class="badge noti-icon-badge badge-danger ml-1"
                    style="padding: 4px 7px"
                    >{{ Customer.OpenTasks.length }}</span
                  >
                </a>
              </li>
            </ul>
            <div class="col-md-12 noPadding">
              <div v-show="tabs[0].active">
                <ClientProfile
                  :Customer="Customer"
                  ref="profileTab"
                  @update:Customer="Customer = $event"
                  @saveCustomerFromTags="saveCustomerFromTags"
                ></ClientProfile>
              </div>
              <div v-show="tabs[1].active">
                <CoClientsProfile
                  :Customer="Customer"
                  @update:Customer="Customer = $event"
                ></CoClientsProfile>
              </div>

              <div v-show="tabs[2].active && customerId > 0">
                <ClientTasks
                  :customerId="customerId"
                  :tabClicked="tabs[2].active"
                  :phoneNumber="primaryPhoneNumber"
                >
                </ClientTasks>
              </div>
              <div v-show="tabs[3].active && customerId > 0">
                <ClientNotes
                  :customerId="customerId"
                  :tabClicked="tabs[3].active"
                  :csrTaskId="0"
                ></ClientNotes>
              </div>
              <div v-show="tabs[4].active && customerId > 0">
                <ClientDocuments
                  :customerId="customerId"
                  :customerOfficeId="Customer.OfficeId"
                  :tabClicked="tabs[4].active"
                  @set-loading="isLoading = $event"
                ></ClientDocuments>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="backgroundLock"
          v-if="isQuickEmailPopupOpen"
          style="-webkit-box-align: center; align-items: center; display: flex"
        >
          <div
            style="position: relative; margin: 10% auto"
            class="offset-xl-4 col-xl-3 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
          >
            <QuickEmails
              :customerId="Customer.Id"
              :languageId="Customer.LanguageId"
              @closeQuickEmailPopup="closeQuickEmailPopup"
              @quickEmailSelected="quickEmailSelected"
            ></QuickEmails>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="backgroundLock"
          v-if="isStatusChangePopupOpen"
          style="-webkit-box-align: center; align-items: center"
        >
          <div
            style="position: relative; margin: 3% auto"
            class="offset-xl-4 offset-lg-3 col-xl-5 col-lg-7 col-md-12 col-sm-12 col-xs-12"
          >
            <ClientStatusChangePopup
              :customerId="Customer.Id"
              :officeId="Customer.OfficeId"
              :fromStatusId="initialStatusId"
              :toStatusId="Customer.StatusId"
              @closeStatusChangePopup="closeStatusChangePopup"
              @statusChangePopupSubmit="statusChangePopupSubmit"
            >
            </ClientStatusChangePopup>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import deepCopy from "@/helpers/deepCopy";
import globalTypes from "@/store/types";
import ClientProfile from "./ClientProfile";
import types from "./types";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
import ClientDocuments from "./ClientDocuments";
import ClientTasks from "./ClientTasks/ClientTasks";
import CoClientsProfile from "./CoClientsProfile";
import QuickEmails from "@/components/QuickEmails.vue";
import ClientNotes from "@/views/agent/EditClient/ClientNotes/ClientNotes.vue";
import { mapState } from "vuex";
import _orderby from "lodash";

import ClientStatusChangePopup from "./DraftChanges/ClientStatusChangePopup";

export default {
  name: "EditClient",
  components: {
    ClientDocuments,
    ClientProfile,
    ClientTasks,
    CoClientsProfile,
    QuickEmails,
    ClientNotes,
    ClientStatusChangePopup,
  },
  mixins: [utilitiesMixin, formatMixin],

  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    activeTabId: {
      type: Number,
      default: () => 0,
    },
  },
  emits: ["updateCustomerId", "updateTabName"],
  data() {
    return {
      isDraftAmountDifferentInDB: false,
      OtherTasks: [
        "Summons Documents",
        "Portal Inquiry Review",
        "Global NSF",
        "Client Contact",
      ],

      clientInfo: {},
      finalSalesAgentList: [],
      customerPrefix: null,
      userInfo: null,
      customerInit: Object.assign({}, this.customerInitFnc()),
      next3MonthsCashFlowList: [],
      attorneyOfficeName: "",
      balanceSummaryText: "",

      creditors: [],

      isRefreshing: false,
      Customer: Object.assign({}, this.customerInitFnc()),
      assignedAgent: "",
      assignedAgentList: [],
      customerToSave: {},
      oldValue: "",
      isLoading: false,
      arrPermission: [],
      tabs: [
        {
          id: 1,
          name: "tab1",
          label: "Profile",
          iconName: "fe-user",
          active: false,
          AuthorizationId: 1050,
        },
        {
          id: 2,
          name: "tab2",
          label: "Co-Customers",
          iconName: "fe-users",
          active: false,
          AuthorizationId: 1050,
        },

        {
          id: 3,
          name: "tab3",
          label: "Tasks",
          iconName: " fas fa-tasks",
          active: false,
          AuthorizationId: 10028,
        },
        {
          id: 4,
          name: "tab4",
          label: "Notes",
          iconName: "fas fa-file-alt",
          active: false,
          AuthorizationId: 1672,
        },
        {
          id: 5,
          name: "tab5",
          label: "Documents",
          iconName: "fas fa-book",
          active: false,
          AuthorizationId: 1825,
        },
      ],
      customerAvailableTemplates: [],
      saving: false,
      invalid: false,

      isFavorited: false,
      isQuickEmailPopupOpen: false,
      selectedQuickEmailFromPopup: null,

      customerPrimaryEmail: "",
      blueBoxUpdating: false,

      datepickerConfig: {
        end: this.addYearsToDate(new Date(), 4),
      },
      datepickerNoConfig: {
        /* this parameter is required to reset config*/
      },
      isStatusChangePopupOpen: false,
      isInitiallySoldStatus: false,
      initialStatusId: 0,
      skipStatusControl: false,
      changeDetailType: {
        ChangeTypeId: 0,
        OldValue: "",
        NewValue: "",
      },
      InitialInfo: {
        FirstName: "",
        MI: "",
        LastName: "",
        BankAccount: "",
        Routing: "",
        BankAccountTypeId: 0,
        Address: "",
        AddressLine2: "",
        City: "",
        StateId: 0,
        Zip: "",
        DoB: null,
        Ssn: "",
      },
      tabId: null,
      userOfficeId: 0,
      primaryPhoneNumber: "",
      paymentCalculatorIssues: "",
      userSubOffices: [],
      pasPhoneNumbers: [],
    };
  },

  computed: mapState({
    customerTagTypes: (state) => state.globals.customerTagTypes,
    expenseTypeList: (state) => state.globals.expenseTypeList,
    incomingCustomerInfo: (state) => state.globals.incomingCustomerInfo,
    campaignList: (state) => state.globals.campaignList,
    customerStatuses: (state) => state.globals.customerStatuses,
    offices: (state) => state.globals.offices,
    salesAgents: (state) => state.globals.salesAgents,
    negotiators: (state) => state.globals.negotiators,
    accountTypeList: (state) => state.globals.accountTypeList,
    states: (state) => state.globals.states,
    assignedLeadData: (state) => state.globals.assignedLeadData,
    scripts: (state) => state.globals.scriptList,
    filteredSalesAgents() {
      let rtn = [];
      if (this.checkAuth(1454)) {
        rtn = this.salesAgents;
      } else if (this.checkAuth(55011)) {
        rtn =
          this.userSubOffices.length > 0
            ? this.salesAgents.filter(
                (x) =>
                  x.OfficeId == this.userOfficeId ||
                  this.userSubOffices.includes(x.OfficeId)
              )
            : this.salesAgents.filter((x) => x.OfficeId == this.userOfficeId);
      } else {
        rtn = this.salesAgents.filter((x) => x.OfficeId == this.userOfficeId);
      }

      return _orderby.orderBy(rtn, "Name", "asc");
    },
    filteredOffices() {
      let rtn = [];
      if (this.checkAuth(1454)) {
        rtn = this.offices;
      } else if (this.checkAuth(55011)) {
        rtn =
          this.userSubOffices.length > 0
            ? this.offices.filter(
                (x) =>
                  x.Id == null ||
                  x.Id == this.userOfficeId ||
                  this.userSubOffices.includes(x.Id)
              )
            : this.offices.filter(
                (x) => x.Id == null || x.Id == this.userOfficeId
              );
      } else {
        rtn = this.offices.filter((x) => x.Id == this.userOfficeId);
      }

      return _orderby.orderBy(rtn, "Name", "asc");
    },

    fullName() {
      return (
        this.Customer.CustomerProfile.FirstName +
        " " +
        this.Customer.CustomerProfile.LastName
      );
    },
    totalIncome() {
      let totalCoCustomerIncome = 0;
      if (this.Customer.CoCustomerProfiles.length) {
        let includedCoCustomers = this.Customer.CoCustomerProfiles.filter(
          (x) => !x.IsExcluded
        );

        for (let item of includedCoCustomers) {
          totalCoCustomerIncome += parseFloat(item.Income);
        }
      }

      let additionalIncome = 0;
      if (
        this.Customer &&
        this.Customer.CustomerTags.length > 0 &&
        this.customerTagTypes &&
        this.customerTagTypes.length > 0
      ) {
        let incomeTagIds = this.customerTagTypes
          .filter((x) => x.TagDataType == "Income")
          .map((x) => x.Id);
        let customerIncomes = this.Customer.CustomerTags.filter((x) =>
          incomeTagIds.includes(x.TagTypeId)
        );
        let totalTagIncome = 0;
        for (let item of customerIncomes) {
          totalTagIncome += item.TagValue ? parseFloat(item.TagValue) : 0;
        }
        additionalIncome = totalTagIncome;
      }

      return (
        parseFloat(this.Customer.CustomerProfile.Income) +
        parseFloat(totalCoCustomerIncome) +
        parseFloat(additionalIncome)
      );
    },
    totalDebtAmount() {
      return this.Customer.CreditorTotalBalance > 0
        ? this.Customer.CreditorTotalBalance
        : this.Customer.CustomerProfile.DebtAmount;
    },

    limitedTasks() {
      return this.Customer.OpenTasks.slice(0, 3);
    },
    remainingTasks() {
      return this.Customer.OpenTasks.slice(3);
    },
  }),

  watch: {
    activeTabId(val) {
      this.tabId = val;
    },
    fullName(val) {
      this.$emit("updateTabName", val);
    },
  },
  async mounted() {
    this.isDraftAmountDifferentInDB = false;
    this.isLoading = true;

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (this.userInfo) {
      this.userOfficeId = this.userInfo.officeId;
      this.userSubOffices = this.userInfo.subOffices;
    }

    if (this.checkAuth(3994)) {
      this.$store.dispatch(globalTypes.INSERT_USER_ACTIVITY, {
        CustomerId: this.customerId,
        UserActivityTypeId: 1,
      });
    }

    await Promise.all([
      this.$store.dispatch(globalTypes.GET_OFFICE_NAMES),
      this.$store.dispatch(globalTypes.GET_STATUS_NAMES),
      this.$store.dispatch(globalTypes.GET_TIMEZONES),
      this.$store.dispatch(globalTypes.GET_STATES),
      this.$store.dispatch(globalTypes.GET_ACCOUNT_TYPES_LIST),
      this.$store.dispatch(globalTypes.GET_EXPENSE_TYPES_LIST),
      this.$store.dispatch(globalTypes.GET_GENDERS),
      this.$store.dispatch(globalTypes.GET_PHONETYPES),
      this.$store.dispatch(globalTypes.GET_PRIORITYTYPES),
      this.$store.dispatch(globalTypes.GET_FAV),
      this.$store.dispatch(globalTypes.GET_SALES_AGENTS),
      this.$store.dispatch(globalTypes.GET_NEGOTIATORS),
      this.$store.dispatch(globalTypes.GET_CUSTOMER_TAG_TYPE),
      this.$store.dispatch(globalTypes.GET_SCRIPTS),
      this.$store.dispatch(globalTypes.GET_ALL_PAYMENT_OPTIONS),
    ]);

    this.finalSalesAgentList = deepCopy(this.filteredSalesAgents);

    if (this.userInfo != null) {
      this.userOfficeId = this.userInfo.officeId;
      if (
        this.userInfo.customerIdPrefix != null &&
        this.userInfo.customerIdPrefix.length > 0
      ) {
        this.customerPrefix = this.userInfo.customerIdPrefix;
      }
      if (this.userInfo.roles != null && this.userInfo.roles.length > 0) {
        await this.$store.dispatch(globalTypes.GET_CAMPAIGNLIST, {
          Roles: this.userInfo.roles,
        });
      }
    }

    this.arrPermission = this.userInfo.moduleFunctions
      .split(",")
      .map(function (item) {
        return parseInt(item, 10);
      });

    this.$Emitter.on("updateStatus", (data) => {
      if (this.Customer.Id == data.customerId) {
        this.Customer.StatusId = data.statusId;
      }
    });

    if (this.checkAuth(778851)) {
      this.fillPASPhoneNumbers();
    }

    if (this.customerId > 0) {
      //Bind Customer Data

      let err, result;

      [err, result] = await this.$store.dispatch(
        types.GET_CLIENT,
        this.customerId
      );

      if (result) {
        if (result.Data) {
          this.Customer = Object.assign({}, result.Data);

          if (
            result.Data.CustomerProfile.CustomerPhones &&
            result.Data.CustomerProfile.CustomerPhones.length > 0
          ) {
            let phones = _orderby.orderBy(
              result.Data.CustomerProfile.CustomerPhones,
              "Priority",
              "asc"
            );

            if (phones && phones.length > 0) {
              this.primaryPhoneNumber = phones[0].PhoneNumber;
            }
          }

          this.officeChanged();
          let indx = this.finalSalesAgentList.findIndex(
            (x) => x.Id == this.Customer.SalesUserId
          );
          if (indx == -1) {
            // if current assigned agent is not a sales agent anymore

            let salesAgentItem = {
              Id: this.Customer.SalesUserId,
              Name: this.Customer.SalesUserName,
              OfficeId: 1,
            };
            this.finalSalesAgentList.push(salesAgentItem);
          }
          this.setNullObjectsInModel();
          this.tabs[0].active = true;

          this.oldValue = JSON.stringify(this.Customer);
          this.isInitiallySoldStatus = this.isSoldStatus();
          this.initialStatusId = this.Customer.StatusId;
          this.setInitialInfo(); // set initial info to compare for info changes.

          let leadData = deepCopy(this.assignedLeadData);
          if (leadData && leadData.Id == this.Customer.Id) {
            let statusName = "";
            let statusIndx = this.customerStatuses.findIndex(
              (x) => x.Id == this.Customer.StatusId
            );
            if (statusIndx > -1) {
              statusName = this.customerStatuses[statusIndx].Name;
            }
            this.$Emitter.emit("refreshPhonePanelAfterCustomerSave", {
              Id: this.Customer.Id,
              AssignedUserId: this.Customer.SalesUserId,
              PhoneList: this.Customer.CustomerProfile.CustomerPhones,
            });
          }

          let PopupTags = this.Customer.CustomerTags.filter((x) => x.IsPopUp);
          let PopUpText = "";
          for (let item of PopupTags) {
            let tagName = this.getTagTypeName(item.TagTypeId);
            let tagType = this.getTagDataType(item.TagTypeId);
            PopUpText +=
              tagName +
              ":  " +
              this.formatTagValue(tagType, item.TagValue) +
              "<br>";
          }
          if (PopUpText.length > 0) {
            this.$swal("Customer Tag Warning!", PopUpText, "warning");
          }
        }
      }
    } else {
      this.tabs[0].active = true;
      this.Customer = deepCopy(this.customerInit);
      if (this.incomingCustomerInfo) {
        if (this.incomingCustomerInfo.CampaignId > 0) {
          if (this.Customer.CustomerCampaignDetail) {
            this.Customer.CustomerCampaignDetail.CampaignId =
              this.incomingCustomerInfo.CampaignId;
          } else {
            this.Customer.CustomerCampaignDetail = {
              CampaignId: this.incomingCustomerInfo.CampaignId,
              VendorLeadId: null,
              Medium: null,
              Source: null,
              Term: null,
            };
          }
        }

        if (
          this.incomingCustomerInfo.PhoneNumber &&
          this.incomingCustomerInfo.PhoneNumber.length > 8
        ) {
          this.Customer.CustomerProfile.CustomerPhones.push({
            Id: 0,
            PhoneNumber: this.incomingCustomerInfo.PhoneNumber,
            PhoneTypeId: 1,
            Priority: 1,
            Edit: false,
          });
        }

        if (this.incomingCustomerInfo.LanguageId > 0) {
          this.Customer.LanguageId = this.incomingCustomerInfo.LanguageId;
        }
      }
      await this.$store.dispatch(
        globalTypes.UPDATE_INCOMING_CUSTOMER_INFO,
        null
      );
    }

    if (!this.Customer.Id) {
      if (this.customerStatuses.length > 0) {
        let priorityList = this.customerStatuses.map((x) => x.Priority);

        let highestPriority = Math.min.apply(Math, priorityList);

        let beginningStatusId = this.customerStatuses.find(
          (x) => x.Priority == highestPriority
        ).Id;

        if (beginningStatusId) this.Customer.StatusId = beginningStatusId;
      }

      if (this.Customer.SalesUserId == 0 || this.Customer.SalesUserId == null) {
        // SET ASSIGNEDUSER for a new Client
        if (this.userInfo && this.userInfo.userId) {
          this.Customer.SalesUserId = this.userInfo.userId;
        }
      }
    }

    let favorites = this.$store.state.globals.favorites;
    if (favorites.length > 0) {
      let indx = favorites.findIndex((x) => x.CustomerId == this.Customer.Id);
      if (indx > -1) {
        this.isFavorited = true;
      } else {
        this.isFavorited = false;
      }
    }
    this.isLoading = false;
    //scroll to top on page open

    window.scrollTo(0, 0);
  },
  methods: {
    setPasNumberByState() {
      if (this.Customer) {
        if (
          this.Customer.CustomerProfile &&
          this.Customer.CustomerProfile.Address &&
          this.Customer.CustomerProfile.Address.StateId > 0
        ) {
          let customerTags = this.Customer.CustomerTags;
          if (
            customerTags &&
            customerTags.length > 0 &&
            this.customerTagTypes.length > 0
          ) {
            let pasTag = this.customerTagTypes.find(
              (x) => this.cleanTagName(x.TagName) == "pasnumber"
            );
            if (typeof pasTag != "undefined" && pasTag) {
              let indx = customerTags.findIndex(
                (x) => x.TagTypeId == pasTag.Id
              );
              if (indx > -1) {
                let pasPhone = this.getPASPhoneNumber(
                  this.Customer.CustomerProfile.Address.StateId
                );
                if (pasPhone != "") {
                  this.Customer.CustomerTags[indx].TagValue = pasPhone;
                } else {
                  this.Customer.CustomerTags[indx].TagValue = null;
                }
              }
            }
          }
        }
      }
    },
    resetPasNumberByState() {
      if (this.Customer) {
        let customerTags = this.Customer.CustomerTags;
        if (
          customerTags &&
          customerTags.length > 0 &&
          this.customerTagTypes.length > 0
        ) {
          let pasTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "pasnumber"
          );
          if (typeof pasTag != "undefined" && pasTag) {
            let indx = customerTags.findIndex((x) => x.TagTypeId == pasTag.Id);
            if (indx > -1) {
              this.Customer.CustomerTags[indx].TagValue = null;
            }
          }
        }
      }
    },

    fillPASPhoneNumbers() {
      this.pasPhoneNumbers.push({ StateId: 24, Phone: "218-302-0060" }); //Minnesota
      this.pasPhoneNumbers.push({ StateId: 44, Phone: "281-805-0981" }); //Texas
      this.pasPhoneNumbers.push({ StateId: 9, Phone: "302-206-5455" }); //Delaware
      this.pasPhoneNumbers.push({ StateId: 17, Phone: "785-205-0772" }); //Kansas
      this.pasPhoneNumbers.push({ StateId: 18, Phone: "502-805-4077" }); //Kentuky
      this.pasPhoneNumbers.push({ StateId: 20, Phone: "207-719-2994" }); //Maine
      this.pasPhoneNumbers.push({ StateId: 41, Phone: "854-214-2086" }); //South Carolina
      this.pasPhoneNumbers.push({ StateId: 42, Phone: "605-307-7020" }); //South Dakota
      this.pasPhoneNumbers.push({ StateId: 10, Phone: "813-308-4940" }); //Florida
      this.pasPhoneNumbers.push({ StateId: 1, Phone: "659-208-7466" }); //Alabama
      this.pasPhoneNumbers.push({ StateId: 2, Phone: "231-403-0528" }); //Alaska
      this.pasPhoneNumbers.push({ StateId: 3, Phone: "520-542-2661" }); //Arizona
      this.pasPhoneNumbers.push({ StateId: 4, Phone: "870-300-3454" }); //Arkansas
      this.pasPhoneNumbers.push({ StateId: 5, Phone: "530-512-6201" }); //California
      this.pasPhoneNumbers.push({ StateId: 19, Phone: "225-254-3704" }); //Louisianna
      this.pasPhoneNumbers.push({ StateId: 22, Phone: "413-284-9680" }); //Massachusetts
      this.pasPhoneNumbers.push({ StateId: 23, Phone: "231-403-0528" }); //Michigan
      this.pasPhoneNumbers.push({ StateId: 25, Phone: "601-299-9184" }); //Mississippi
      this.pasPhoneNumbers.push({ StateId: 26, Phone: "660-694-4071" }); //Missouri
      this.pasPhoneNumbers.push({ StateId: 28, Phone: "308-223-4720" }); //Nebraska
      this.pasPhoneNumbers.push({ StateId: 36, Phone: "704-780-0533" }); //Ohio
      this.pasPhoneNumbers.push({ StateId: 37, Phone: "580-527-7911" }); //Oklahoma
      this.pasPhoneNumbers.push({ StateId: 48, Phone: "240-301-7712" }); //Washington D.C.
      this.pasPhoneNumbers.push({ StateId: 33, Phone: "914-809-8039" }); //New York
    },
    getPASPhoneNumber(stateId) {
      let rtn = "";
      if (this.pasPhoneNumbers.length > 0) {
        let indx = this.pasPhoneNumbers.findIndex((x) => x.StateId == stateId);

        rtn = indx > -1 ? this.pasPhoneNumbers[indx].Phone : "";
      }

      return rtn;
    },

    async pushToTCI() {
      if (this.isDataChanged()) {
        this.$swal(
          "You have unsaved changes!",
          "Please save your changes first!",
          "warning"
        );
        return;
      }

      let err, result;
      [err, result] = await this.$store.dispatch(types.PUSH_TO_TCI, {
        CustomerId: this.customerId,
      });
      if (result) {
        this.$swal("Success!", "Successfully pushed to TCI.", "success");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },

    saveCustomerFromTags() {
      this.handleSave();
    },

    checkSalesUserId() {
      if (this.finalSalesAgentList && this.finalSalesAgentList.length > 0) {
        let indx = this.finalSalesAgentList.findIndex(
          (x) => x.Id == this.Customer.SalesUserId
        );
        if (indx == -1) {
          this.Customer.SalesUserId = null;
        }
      } else {
        this.Customer.SalesUserId = null;
      }
    },
    async officeChanged() {
      if (this.Customer && !this.checkAuth(1454) && this.checkAuth(55011)) {
        let officeId = this.Customer.OfficeId;
        if (officeId > 0) {
          this.finalSalesAgentList = this.filteredSalesAgents.filter(
            (x) => x.OfficeId == officeId
          );
        }

        //this.checkSalesUserId();
      }
    },

    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },

    compareArray(arr1, arr2) {
      return arr1.some((item) => arr2.includes(item));
    },
    getTagTypeName(id) {
      let rtn = "";
      if (this.customerTagTypes.length > 0 && id > 0) {
        let index = this.customerTagTypes.findIndex((x) => x.Id == id);
        rtn = index > -1 ? this.customerTagTypes[index].TagName : "";
      }

      return rtn;
    },
    getTagDataType(id) {
      let rtn = "";
      if (this.customerTagTypes.length > 0 && id > 0) {
        let index = this.customerTagTypes.findIndex((x) => x.Id == id);
        rtn = index > -1 ? this.customerTagTypes[index].TagDataType : "";
      }

      return rtn;
    },
    formatTagValue(tagTypeName, tagValue) {
      let rtn = tagValue;
      if (tagTypeName == "Money") {
        rtn = this.formatMoney(tagValue);
      } else if (tagTypeName == "Date") {
        rtn = this.formatShortDate(tagValue);
      } else if (tagTypeName == "Date and Time") {
        rtn = this.formatGridDate(tagValue);
      } else if (tagTypeName == "Percantage") {
        rtn = this.formatPercentage(tagValue);
      } else if (tagTypeName == "CheckItem") {
        rtn = tagValue == true ? "Yes" : "No";
      }
      return rtn;
    },
    getNextCashFlowData(cashFlowData, months) {
      let balance = 0;
      let currentDate = new Date();
      this.addMonthsFix(currentDate, months);

      let currentMonth = currentDate.getMonth();
      let nextDate = this.addMonthsFix(currentDate, months + 1);
      while (nextDate.getMonth() != currentMonth) {
        nextDate = this.addDays(nextDate, -1);
      }

      let arr = cashFlowData.filter(
        (x) =>
          new Date(x.Date) <=
          new Date(
            nextDate.getFullYear(),
            nextDate.getMonth(),
            nextDate.getDate(),
            23,
            59,
            59
          )
      );
      if (arr.length > 0) {
        balance = arr[arr.length - 1].Balance;
      }

      return balance;
    },

    getMaxDayofMonth(date) {
      let rtn = 0;
      let year = new Date(date).getFullYear();

      let months = new Date(date).getMonth();

      rtn = new Date(year, months + 1, 0).getDate();

      return isNaN(rtn) ? 0 : rtn;
    },

    isAuthorizedToSee(authId) {
      let rtn = false;

      if (this.userInfo != null && this.userInfo.moduleFunctions != null) {
        let arrPermission = this.userInfo.moduleFunctions
          .split(",")
          .map(function (item) {
            return parseInt(item, 10);
          });

        if (arrPermission.includes(parseInt(authId))) {
          rtn = true;
        }
      }
      return rtn;
    },
    customerInitFnc() {
      return {
        CompanyId: null,
        OfficeId: null,
        SalesUserId: null,
        StatusId: null,
        LanguageId: null,
        CreatedDate: null,
        CreatedUserId: null,
        HardshipId: null,
        HardshipStatementNotes: null,
        CreditorTotalBalance: 0.0,

        IsTermSettlement: false,
        TermSettlementStartDate: null,
        TermSettlementEndDate: null,
        Bank: {
          Name: null,
          RoutingNumber: null,
          AccountTypeId: null,
          AccountNumberEncrypted: null,
          ValidationStatus: null,
        },
        CustomerCampaignDetail: {
          CampaignId: null,
          Name: null,
          VendorLeadId: null,
          Medium: null,
          Source: null,
          Term: null,
        },
        CustomerProfile: {
          FirstName: null,
          LastName: null,
          SsnEncrypted: null,
          MiddleInitial: null,
          GenderId: null,
          MothersMaidenName: null,
          OccupationId: null,
          DebtAmount: 0.0,
          Income: 0,
          DateOfBirth: null,
          ResidenceOwnershipId: null,
          ResidenceYears: null,
          DeclaredBankruptcy: null,
          ChapterId: null,
          YearFiled: null,
          YearDischarged: null,
          CreditRatingId: null,
          Address: {
            Line1: null,
            Line2: null,
            Zip: null,
            City: null,
            StateId: null,
            TimeZoneId: null,
            ValidationStatus: null,
          },
          CustomerEmails: [],
          CustomerPhones: [],
          ExtraTermMonths: 0,
        },
        CoCustomerProfiles: [],
        CustomerExpenses: [],

        CustomerTags: [],
      };
    },
    emptyTheObjectsOnUnmount() {
      this.isRefreshing = null;
      this.Customer = null;
      this.assignedAgent = null;
      this.assignedAgentList = null;
      this.customerToSave = null;
      this.oldValue = null;
      this.isLoading = null;
      this.arrPermission = null;
      this.tabs = null;
      this.saving = null;
      this.invalid = null;
      this.isFavorited = null;
      this.isQuickEmailPopupOpen = null;
      this.selectedQuickEmailFromPopup = null;

      this.customerPrimaryEmail = null;
      this.datepickerConfig = null;
      this.datepickerNoConfig = null;

      this.isStatusChangePopupOpen = null;
      this.isInitiallySoldStatus = null;
      this.initialStatusId = null;
      this.skipStatusControl = null;
      this.changeDetailType = null;
      this.InitialInfo = null;
      this.tabId = null;
    },
    blockChanges() {
      let rtn = false;

      if (!this.hasAuth(96483)) {
        rtn = this.customerStatuses.some(
          (x) => x.Id == this.Customer.StatusId && x.IsBlockMakingChange
        );
      }
      return rtn;
    },

    cleanTagName(item) {
      let rtn = "";
      if (item) {
        rtn = item.toLowerCase();
        rtn = rtn.replaceAll(" ", "");
      }

      return rtn;
    },

    buildMoneyWarningMessage(oldMsg, newMsg) {
      let rtn = oldMsg;
      if (rtn.length > 0) {
        rtn = rtn + ", ";
      }
      rtn = rtn + newMsg;
      return rtn;
    },

    setValueFromPath(object, path, value) {
      path
        .split(".")
        .reduce(
          (o, p, i) =>
            (o[p] = path.split(".").length === ++i ? value : o[p] || {}),
          object
        );
    },
    getObjectValueFromPath(object, path) {
      path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
      path = path.replace(/^\./, ""); // strip a leading dot
      let a = path.split(".");
      for (let i = 0; i < a.length; i++) {
        let k = a[i];
        if (k in object) {
          object = object[k];
        } else {
          return;
        }
      }
      return object;
    },
    findPath(ob, key) {
      let path = [];
      let keyExists = function (obj) {
        if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
          return false;
        } else if (Object.prototype.hasOwnProperty.call(obj, key)) {
          return true;
        } else if (Array.isArray(obj)) {
          let parentKey = path.length ? path.pop() : "";

          for (let i = 0; i < obj.length; i++) {
            path.push(`${parentKey}[${i}]`);
            const result = keyExists(obj[i], key);
            if (result) {
              return result;
            }
            path.pop();
          }
        } else {
          for (let k in obj) {
            path.push(k);
            let result = keyExists(obj[k], key);
            if (result) {
              return result;
            }
            path.pop();
          }
        }
        return false;
      };

      keyExists(ob);

      return path.join(".") + "." + key;
    },

    recursiveChangeComparison(obj1, obj2, changeArray) {
      let r = changeArray;
      Object.keys(obj1).forEach((key) => {
        let value1 = obj1[key];
        let value2 = obj2[key];

        if (
          typeof value1 !== "object" &&
          typeof value2 !== "object" &&
          value1 != value2
        ) {
          r.push(key);
        } else if (
          Array.isArray(value1) &&
          Array.isArray(value2) &&
          JSON.stringify(value1) != JSON.stringify(value2)
        ) {
          r.push(key);
        } else if (
          typeof value1 === "object" &&
          value1 != null &&
          typeof value2 === "object" &&
          value2 != null
        ) {
          this.recursiveChangeComparison(value1, value2, changeArray);
        }
      });
      return r;
    },
    dataSyncProcess(customerId, userId, userName, objectType) {
      if (objectType == "Customer") {
        this.handleSyncCustomer(customerId, userName);
      }
    },
    getTabIsVisible(tab) {
      if (tab.id == 1 || tab.id == 2) {
        return this.hasAuth(tab.AuthorizationId);
      } else {
        return this.hasAuth(tab.AuthorizationId) && this.customerId > 0;
      }
    },
    hasAuth(mfid) {
      return this.arrPermission.includes(mfid);
    },
    isSoldStatus() {
      let rtn = false;
      let indx = this.customerStatuses.findIndex(
        (x) => x.Id == this.Customer.StatusId
      );
      if (this.customerStatuses[indx]) {
        rtn = this.customerStatuses[indx].IsSold;
      }

      return rtn;
    },

    setInitialInfo() {
      this.InitialInfo.FirstName = this.Customer.CustomerProfile.FirstName;
      this.InitialInfo.MI = this.Customer.CustomerProfile.MiddleInitial;
      this.InitialInfo.LastName = this.Customer.CustomerProfile.LastName;
      this.InitialInfo.BankAccount = this.Customer.Bank.AccountNumberEncrypted;
      this.InitialInfo.Routing = this.Customer.Bank.RoutingNumber;
      this.InitialInfo.BankAccountTypeId = this.Customer.Bank.AccountTypeId;
      this.InitialInfo.Address = this.Customer.CustomerProfile.Address.Line1;
      this.InitialInfo.AddressLine2 =
        this.Customer.CustomerProfile.Address.Line2;
      this.InitialInfo.City = this.Customer.CustomerProfile.Address.City;
      this.InitialInfo.StateId = this.Customer.CustomerProfile.Address.StateId;
      this.InitialInfo.Zip = this.Customer.CustomerProfile.Address.Zip;
      this.InitialInfo.DoB = this.Customer.CustomerProfile.DateOfBirth;
      this.InitialInfo.Ssn = this.Customer.CustomerProfile.SsnEncrypted;
    },

    closeStatusChangePopup(data) {
      this.isStatusChangePopupOpen = false;
      if (data > 0) {
        this.Customer.StatusId = data;
      }
    },

    statusChangePopupSubmit() {
      this.skipStatusControl = true;
      this.handleSave();
    },

    setNullObjectsInModel() {
      if (this.Customer.CustomerGlobalInformation == null) {
        this.Customer.CustomerGlobalInformation = {
          Id: 0,
          AccountNumber: null,
          DraftAmount: null,
          EnrolledDate: null,
          EnrollmentMonths: 12,
          GlobalBalanceOffline: null,
          StartDate: null,
          FirstPaymentDate: null,
          PaymentOptionTypeId: null,
          DayOfMonth: null,
          IsDpg: null,
        };
      }

      if (this.Customer.Bank == null) {
        this.Customer.Bank = {
          Name: null,
          RoutingNumber: null,
          AccountTypeId: null,
          AccountNumberEncrypted: null,
        };
      }

      if (this.Customer.CustomerProfile.Address == null) {
        this.Customer.CustomerProfile.Address = {
          Line1: null,
          Line2: null,
          City: null,
          StateId: null,
          TimeZoneId: null,
          Zip: null,
        };
      }

      if (this.Customer.CustomerCampaignDetail == null) {
        this.Customer.CustomerCampaignDetail = {
          CampaignId: null,
          VendorLeadId: null,
          Medium: null,
          Source: null,
          Term: null,
        };
      }

      if (
        this.Customer.CustomerProfile.CustomerEmails &&
        this.Customer.CustomerProfile.CustomerEmails &&
        this.Customer.CustomerProfile.CustomerEmails.length > 0
      ) {
        let emails = _orderby.orderBy(
          this.Customer.CustomerProfile.CustomerEmails,
          "Priority",
          "asc"
        );
        if (emails.length > 0) this.customerPrimaryEmail = emails[0].Email;
      }
    },

    async handleSyncCustomer(customerId, userName) {
      let err, result;
      let updateOldCust = true;
      [err, result] = await this.$store.dispatch(types.GET_CLIENT, customerId);

      if (result) {
        if (result.Data) {
          let showMessage = false;
          let localChangesArr = [];
          let remoteChangesArr = [];

          // Change Detection Process
          //get local change Array
          if (JSON.stringify(this.Customer) != this.oldValue) {
            // there are local changes. fill up local change array
            this.recursiveChangeComparison(
              this.Customer,
              JSON.parse(this.oldValue),
              localChangesArr
            );
            showMessage = true;
            updateOldCust = false;
          }

          let scrollX = window.pageXOffset;
          let scrollY = window.pageYOffset;
          this.isRefreshing = true;

          let tmpOldVal = this.oldValue;
          if (!showMessage) {
            let tmp = Object.assign({}, result.Data);
            if (tmp.CustomerCampaignDetail == null) {
              tmp.CustomerCampaignDetail = {
                CampaignId: null,
                VendorLeadId: null,
                Medium: null,
                Source: null,
                Term: null,
              };
            }
            this.Customer = Object.assign({}, tmp);
          } else {
            showMessage = false;
            //get remote change array
            this.recursiveChangeComparison(
              result.Data,
              JSON.parse(tmpOldVal),
              remoteChangesArr
            );
            // compare local and remote changes arrays. if there is any similarity show message.
            for (let item of localChangesArr) {
              if (remoteChangesArr.includes(item)) {
                showMessage = true;
                break;
              }
            }
            //update changed values only...
            if (remoteChangesArr.length > 0) {
              for (let item of remoteChangesArr) {
                let changedPath = this.findPath(this.Customer, item);
                if (changedPath.startsWith(".")) {
                  changedPath = changedPath.slice(1, changedPath.length);
                }
                let resultObj = deepCopy(result.Data);
                let newValue = this.getObjectValueFromPath(
                  resultObj,
                  changedPath
                );
                this.setValueFromPath(this.Customer, changedPath, newValue);
              }
            }
          }

          this.setNullObjectsInModel();

          if (updateOldCust) this.oldValue = JSON.stringify(this.Customer);
          this.isInitiallySoldStatus = this.isSoldStatus();
          this.initialStatusId = this.Customer.StatusId;
          this.setInitialInfo(); // set initial info to compare for info changes.
          this.isRefreshing = false;

          await this.handleScroll(scrollX, scrollY);

          if (showMessage && userName != "System") {
            this.$swal(
              "Warning",
              userName + " just made some changes on some fields you edited.",
              "warning"
            );
          }
        }
      }

      this.$forceUpdate();
    },

    async refreshCustomerFromDB(refreshGrid) {
      let err, result;
      this.isRefreshing = true;
      this.isLoading = true;

      [err, result] = await this.$store.dispatch(
        types.GET_CLIENT,
        this.Customer.Id
      );

      if (result) {
        if (result.Data) {
          this.Customer = Object.assign({}, result.Data);

          this.setNullObjectsInModel();
          if (
            typeof this.$refs.profileTab != "undefined" &&
            this.$refs.profileTab
          ) {
            this.$refs.profileTab.setCustomerExpenseUiSettings();
            this.$refs.profileTab.setCustomerTagsUiSettings();
          }

          this.oldValue = JSON.stringify(this.Customer);
          this.isInitiallySoldStatus = this.isSoldStatus();
          this.initialStatusId = this.Customer.StatusId;
          this.setInitialInfo(); // set initial info to compare for info changes.
          //this.handleTabChange(0);
        }
      }
      if (refreshGrid) {
        this.$emit("handleSearch");
      }
      this.$forceUpdate();
      this.isRefreshing = false;
      this.isLoading = false;
    },
    async revertNullObjects() {
      if (
        JSON.stringify(this.customerToSave.Bank) ==
        JSON.stringify(this.customerInit.Bank)
      ) {
        this.customerToSave.Bank = null;
      }
      if (
        JSON.stringify(this.customerToSave.CustomerProfile.Address) ==
        JSON.stringify(this.customerInit.CustomerProfile.Address)
      ) {
        this.customerToSave.CustomerProfile.Address = null;
      }

      if (
        JSON.stringify(this.customerToSave.CustomerCampaignDetail) ==
        JSON.stringify(this.customerInit.CustomerCampaignDetail)
      ) {
        this.customerToSave.CustomerCampaignDetail = null;
      }
    },

    handleFavorite() {
      this.isFavorited = !this.isFavorited;
      if (this.isFavorited) {
        this.$store.dispatch(globalTypes.POSTFAV, {
          CustomerId: this.Customer.Id,
          FirstName: this.Customer.CustomerProfile.FirstName,
          LastName: this.Customer.CustomerProfile.LastName,
        });
      } else {
        this.$store.dispatch(globalTypes.DELETEFAV, {
          CustomerId: this.Customer.Id,
        });
      }
    },
    isDataChanged() {
      if (this.oldValue != null && this.oldValue != "") {
        let parseOldValue = JSON.parse(this.oldValue);

        parseOldValue.CustomerExpenses = parseOldValue.CustomerExpenses.filter(
          (x) => x.Amount > 0
        );
        parseOldValue.CustomerExpenses.forEach((x) => delete x.UiOrder);

        parseOldValue.CustomerTags = parseOldValue.CustomerTags.filter(
          (x) => x.TagValue != null
        );
        parseOldValue.CustomerTags.forEach((x) => delete x.UiOrder);

        this.oldValue = JSON.stringify(parseOldValue);
      }

      this.Customer.CustomerExpenses = this.Customer.CustomerExpenses.filter(
        (x) => x.Amount > 0
      );
      this.Customer.CustomerExpenses.forEach((x) => delete x.UiOrder);

      this.Customer.CustomerTags = this.Customer.CustomerTags.filter(
        (x) => x.TagValue != null
      );
      this.Customer.CustomerTags.forEach((x) => delete x.UiOrder);

      let rtn = false;
      let current = JSON.stringify(this.Customer);

      if (
        typeof this.Customer.Id == "undefined" &&
        this.Customer.CustomerProfile.FirstName != null &&
        this.Customer.CustomerProfile.FirstName.length > 0
      ) {
        rtn = true;
      } else if (this.oldValue != current && this.Customer.Id > 0) {
        rtn = true;
      }
      return rtn;
    },
    checkNullOrEmpty(value) {
      return value == null || value == "";
    },
    validateCoCustomers() {
      let rtn = true;

      if (this.Customer && this.Customer.CoCustomerProfiles) {
        if (this.Customer.CoCustomerProfiles.length > 0) {
          for (let i in this.Customer.CoCustomerProfiles) {
            rtn *= !this.checkNullOrEmpty(
              this.Customer.CoCustomerProfiles[i].FirstName
            );
            rtn *= !this.checkNullOrEmpty(
              this.Customer.CoCustomerProfiles[i].LastName
            );
          }
        }
      }

      return rtn;
    },
    isCustomerTagsValid() {
      let isValid = true;
      if (this.Customer.CustomerTags.length > 0) {
        let tags = deepCopy(
          this.Customer.CustomerTags.filter((x) => x.TagValue != null)
        );

        for (let item of tags) {
          if (
            item.TagValue == null ||
            (typeof item.TagValue == "string" && item.TagValue.length == 0)
          ) {
            isValid = false;
            break;
          }
        }
      }

      return isValid;
    },

    getStateShort(stateId) {
      let rtn = "";
      let tmpStates = this.states.filter((x) => x.Id == stateId);
      if (tmpStates.length > 0) {
        rtn = tmpStates[0].ShortCode;
      }

      return rtn;
    },

    validateCustomerAge(date) {
      let rtn = true;

      if (date) {
        let customerDob = new Date(date);
        let checkDate = new Date(
          customerDob.getFullYear() + 18,
          customerDob.getMonth(),
          customerDob.getDate()
        );
        rtn = new Date() >= checkDate;
      }

      return rtn;
    },
    validateDraftDate(date) {
      let rtn = true;

      if (date) {
        let draftDate = new Date(date);
        let checkDate = new Date(
          draftDate.getFullYear(),
          draftDate.getMonth(),
          draftDate.getDate()
        );
        rtn = checkDate >= new Date();
      }

      return rtn;
    },
    async handleSave() {
      if (
        this.checkNullOrEmpty(this.Customer.CustomerProfile.FirstName) ||
        this.checkNullOrEmpty(this.Customer.CustomerProfile.LastName) ||
        !this.Customer.CustomerProfile.CustomerPhones ||
        this.Customer.CustomerProfile.CustomerPhones.length == 0
      ) {
        this.$swal(
          "Warning!",
          "First name, last name and phone required!",
          "warning"
        );
        return;
      }

      if (!this.validateCoCustomers()) {
        this.$swal(
          "Warning!",
          "Co Customers name fields are required!",
          "warning"
        );
        return;
      }

      if (this.blockChanges() && this.Customer.Id > 0) {
        this.$swal(
          "Warning!",
          "You are not authorized to make changes!",
          "warning"
        );

        return;
      }

      if (!this.isCustomerTagsValid()) {
        this.$swal(
          "Warning!",
          "Some customer tags are empty. All tag fields are required.",
          "warning"
        );

        return;
      }

      try {
        if (
          this.Customer.CustomerTags &&
          this.Customer.CustomerTags.length > 0
        ) {
          let tags = _orderby.groupBy(this.Customer.CustomerTags, "TagTypeId");

          let keys = Object.keys(tags);
          for (let key of keys) {
            let tagItem = tags[key];

            if (tagItem.length > 1) {
              this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
                Message: "Tag Duplication on Save: " + JSON.stringify(tagItem),
              });
            }
          }
        }
      } catch {
        //error
      }

      if (
        this.validateCustomerAge(this.Customer.CustomerProfile.DateOfBirth) ==
        false
      ) {
        this.$swal(
          "Warning!",
          "Customer/Co-Customers can not be under 18 years of age.",
          "warning"
        );
        return;
      }

      if (this.Customer.CoCustomerProfiles.length > 0) {
        let coCustomersAgeValidation = true;
        this.Customer.CoCustomerProfiles.filter((x) => !x.IsExcluded).forEach(
          (item) => {
            coCustomersAgeValidation *= this.validateCustomerAge(
              item.DateOfBirth
            );
          }
        );
        if (coCustomersAgeValidation == false) {
          this.$swal(
            "Warning!",
            "Co-Customers can not be under 18 years of age.",
            "warning"
          );
          return;
        }
      }

      //this.checkSalesUserId();

      this.saving = true;

      if (!this.isDataChanged() && !this.isDraftAmountDifferentInDB) {
        this.saving = false;
        this.customerToSave = {};
        this.$swal("Warning!", "No Changes Detected!", "warning");
        this.$Emitter.emit("updateCustomerProfileTags", {});

        return;
      } else {
        try {
          this.isDraftAmountDifferentInDB = false;
          if (
            this.isInitiallySoldStatus &&
            !this.isSoldStatus() &&
            !this.skipStatusControl
          ) {
            // show pop up...
            this.isStatusChangePopupOpen = true;
            this.saving = false;
            return;
          }

          this.skipStatusControl = false;

          let err, result;
          this.customerToSave = deepCopy(this.Customer);

          //set PhoneIds
          if (
            this.customerToSave.CustomerProfile.CustomerPhones &&
            this.customerToSave.CustomerProfile.CustomerPhones.length > 0
          ) {
            this.customerToSave.CustomerProfile.CustomerPhones =
              this.customerToSave.CustomerProfile.CustomerPhones.map((x) => {
                if (x.Id < 0) x.Id = 0;
                return x;
              });
          }
          //set EmailIds
          if (this.customerToSave.CustomerProfile.CustomerEmails.length > 0) {
            this.customerToSave.CustomerProfile.CustomerEmails =
              this.customerToSave.CustomerProfile.CustomerEmails.map((x) => {
                if (x.Id < 0) x.Id = 0;
                return x;
              });
          }

          //set v-masks formatted fields

          this.customerToSave.CustomerProfile.Income = parseFloat(
            this.Customer.CustomerProfile.Income
          );
          this.customerToSave.CustomerProfile.YearFiled = parseFloat(
            this.Customer.CustomerProfile.YearFiled
          );
          this.customerToSave.CustomerProfile.YearDischarged = parseFloat(
            this.Customer.CustomerProfile.YearDischarged
          );

          if (this.customerToSave.CustomerProfile.DateOfBirth != null) {
            this.customerToSave.CustomerProfile.DateOfBirth =
              this.formatShortDate(this.Customer.CustomerProfile.DateOfBirth);
          }

          if (this.customerToSave.CoCustomerProfiles.length > 0) {
            for (let index in this.customerToSave.CoCustomerProfiles) {
              if (
                this.customerToSave.CoCustomerProfiles[index].DateOfBirth !=
                null
              ) {
                this.customerToSave.CoCustomerProfiles[index].DateOfBirth =
                  this.formatShortDate(
                    this.Customer.CoCustomerProfiles[index].DateOfBirth
                  );
              }
            }
          }

          // set null childs
          this.revertNullObjects();

          if (this.Customer.CompanyId == null) {
            this.customerToSave.CompanyId = this.userInfo.companyId;
          }

          let type = "";
          if (this.Customer.Id) {
            type = types.UPDATE_CLIENT;
          } else {
            type = types.INSERT_NEW_CLIENT;
          }

          [err, result] = await this.$store.dispatch(type, this.customerToSave);

          if (result) {
            this.$swal("Success!", result.Message, "success");

            this.Customer = deepCopy(result.Data);

            let phones = _orderby.orderBy(
              result.Data.CustomerProfile.CustomerPhones,
              "Priority",
              "asc"
            );

            if (phones && phones.length > 0) {
              this.primaryPhoneNumber = phones[0].PhoneNumber;
            }

            this.setNullObjectsInModel();

            this.oldValue = JSON.stringify(this.Customer);
            this.$emit("updateCustomerId", this.Customer.Id);

            let statusName = "";
            let indx = this.customerStatuses.findIndex(
              (x) => x.Id == this.Customer.StatusId
            );
            if (indx > -1) {
              statusName = this.customerStatuses[indx].Name;
            }

            this.$Emitter.emit("refreshPhonePanelAfterCustomerSave", {
              Id: this.Customer.Id,
              AssignedUserId: this.Customer.SalesUserId,
              PhoneList: this.Customer.CustomerProfile.CustomerPhones,
            });

            this.refreshCustomerFromDB(true);
            this.saving = false;
            this.customerToSave = {};
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        } catch (err) {
          this.$swal("Error!", JSON.stringify(err), "error");
        }

        this.saving = false;
      }
    },
    async handleSaveSilent() {
      if (
        this.checkNullOrEmpty(this.Customer.CustomerProfile.FirstName) ||
        this.checkNullOrEmpty(this.Customer.CustomerProfile.LastName) ||
        !this.Customer.CustomerProfile.CustomerPhones ||
        this.Customer.CustomerProfile.CustomerPhones.length == 0
      ) {
        return false;
      }

      let err, result;
      this.customerToSave = deepCopy(this.Customer);

      //set PhoneIds
      if (
        this.customerToSave.CustomerProfile.CustomerPhones &&
        this.customerToSave.CustomerProfile.CustomerPhones.length > 0
      ) {
        this.customerToSave.CustomerProfile.CustomerPhones =
          this.customerToSave.CustomerProfile.CustomerPhones.map((x) => {
            if (x.Id < 0) x.Id = 0;
            return x;
          });
      }
      //set EmailIds
      if (this.customerToSave.CustomerProfile.CustomerEmails.length > 0) {
        this.customerToSave.CustomerProfile.CustomerEmails =
          this.customerToSave.CustomerProfile.CustomerEmails.map((x) => {
            if (x.Id < 0) x.Id = 0;
            return x;
          });
      }

      //set v-masks formatted fields

      this.customerToSave.CustomerProfile.Income = parseFloat(
        this.Customer.CustomerProfile.Income
      );
      this.customerToSave.CustomerProfile.YearFiled = parseFloat(
        this.Customer.CustomerProfile.YearFiled
      );
      this.customerToSave.CustomerProfile.YearDischarged = parseFloat(
        this.Customer.CustomerProfile.YearDischarged
      );

      if (this.customerToSave.CustomerProfile.DateOfBirth != null) {
        this.customerToSave.CustomerProfile.DateOfBirth = this.formatShortDate(
          this.Customer.CustomerProfile.DateOfBirth
        );
      }

      if (this.customerToSave.CoCustomerProfiles.length > 0) {
        for (let index in this.customerToSave.CoCustomerProfiles) {
          if (
            this.customerToSave.CoCustomerProfiles[index].DateOfBirth != null
          ) {
            this.customerToSave.CoCustomerProfiles[index].DateOfBirth =
              this.formatShortDate(
                this.Customer.CoCustomerProfiles[index].DateOfBirth
              );
          }
        }
      }

      this.revertNullObjects();
      if (this.Customer.Id) {
        [err, result] = await this.$store.dispatch(
          types.UPDATE_CLIENT,
          this.customerToSave
        );

        if (result) {
          this.Customer = deepCopy(result.Data);

          this.setNullObjectsInModel();
          this.oldValue = JSON.stringify(this.Customer);
          this.customerToSave = {};
        } else {
          let errMsg = this.getApiErrorMessage(err);
        }
      }
    },
    handleReminders() {},
    async handleScroll(x, y) {
      await this.sleep(10);
      window.scrollTo(x, y);
    },
    handleSms() {
      let phoneData = [];
      let phones = this.Customer.CustomerProfile.CustomerPhones;

      if (phones.length > 0) {
        phones = _orderby.orderBy(phones, "Priority", "asc");
        for (let p of phones) {
          phoneData.push({
            Id: p.Id,
            PhoneNumber: p.PhoneNumber,
            Priority: p.Priority,
            IsCoCustomer: false,
          });
        }
      }

      if (this.Customer.CoCustomerProfiles) {
        let activeCoCustomers = this.Customer.CoCustomerProfiles.filter(
          (x) => x.IsExcluded == false
        );

        let coPhones = [];
        if (activeCoCustomers.length > 0) {
          for (let item of activeCoCustomers) {
            if (item.CoCustomerPhones && item.CoCustomerPhones.length > 0) {
              for (let p of item.CoCustomerPhones) {
                coPhones.push(p);
              }
            }
          }
        }

        if (coPhones.length > 0) {
          coPhones = _orderby.orderBy(coPhones, "Priority", "asc");

          for (let p of coPhones) {
            phoneData.push({
              Id: p.Id,
              PhoneNumber: p.PhoneNumber,
              Priority: p.Priority,
              IsCoCustomer: true,
            });
          }
        }
      }

      if (phoneData.length > 0) {
        this.$Emitter.emit("openSms", {
          Phones: "",
          PhoneList: phoneData,
          CustomerId: this.Customer.Id,
          LanguageId: this.Customer.LanguageId,
        });
      } else {
        this.$swal("Warning", "There is no phone number", "warning");
      }
    },
    handleTabChange(index) {
      if (
        document.getElementById(
          "CustomerSaveButton" + this.customerId.toString()
        ) != null
      ) {
        if (
          document.getElementById(
            "CustomerSaveButton" + this.customerId.toString()
          ).disabled
        ) {
          //this.$refs.observer.validate(); temp
          return;
        }
      }

      this.tabs.map((t) => {
        t.active = false;
      });
      this.tabs[index].active = true;
    },
    openQuickEmails() {
      if (
        this.Customer &&
        this.Customer.CustomerProfile.CustomerEmails &&
        this.Customer.CustomerProfile.CustomerEmails.length > 0
      ) {
        this.isQuickEmailPopupOpen = true;
      } else {
        this.$swal("Warning", "This customer has no email address", "warning");
      }
    },
    closeQuickEmailPopup() {
      this.isQuickEmailPopupOpen = false;
    },

    async quickEmailSelected(item) {
      this.selectedQuickEmailFromPopup = item;
      this.isQuickEmailPopupOpen = false;

      let toEmail = this.customerPrimaryEmail;
      if (item.TypeId == 2) {
        let activeCoCustomers = this.Customer.CoCustomerProfiles.filter(
          (x) => x.IsExcluded == false
        );
        if (activeCoCustomers.length > 0) {
          let emails = [];
          for (let item of activeCoCustomers) {
            if (item.CoCustomerEmails && item.CoCustomerEmails.length > 0) {
              let orderedEmails = _orderby.orderBy(
                item.CoCustomerEmails,
                "Priority",
                "asc"
              );
              let emailToAdd = orderedEmails[0].Email;
              if (this.customerPrimaryEmail != emailToAdd) {
                if (emails.filter((x) => x == emailToAdd).length == 0) {
                  emails.push(emailToAdd);
                }
              }
            }
          }

          if (emails.length > 0) {
            toEmail = toEmail + ";" + emails.join(";");
          }
        }
      } else if (item.TypeId == 3 && item.ToAddress != "") {
        toEmail = item.ToAddress;
      }

      this.$Emitter.emit("openPortalUserInterface", {
        Customer: this.Customer,
        CustomerId: this.Customer.Id,
        CustomerEmail: toEmail,
        QuickEmail: item,
        IsPortalMessage: this.hasAuth(3958),
        IsPortalAuthMessage: false,
        AuthList: [],
      });
    },
  },
};
</script>

<style lang="css" scoped>
.headerSelectBox {
  width: 100%;
  border-radius: 6px;
  padding: 7px 12px;
  border: solid 1px #d1d1d1;
  background-color: #ffffff;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #2a404c;
  width: 100%;
}

.negotiatorSelectBox {
  width: 60%;
  border-radius: 6px;
  padding: 7px 12px;
  border: solid 1px #d1d1d1;
  background-color: #ffffff;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #2a404c;
}

.assignNegButton {
  width: 35%;
  border-radius: 8px;
  -webkit-box-shadow: 0 2px 10px 0 rgb(107 85 202 / 20%);
  box-shadow: 0 2px 10px 0 rgb(107 85 202 / 20%);
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
}

.blueBoxPanel {
  width: 420px;
  position: absolute;
  top: -106px;
  right: 12px;
  z-index: 99;
  margin: 0;
  padding: 5px 6px 5px 14px;
  border-bottom-left-radius: 0.8em;
  border-bottom-right-radius: 0.8em;
  background-color: #bddbf5;
}

.blueBoxRow {
  margin: 5px 0;
}

@media screen and (max-width: 1023px) {
  .blueBoxPanel {
    padding: 10px 12px 10px 40px;
    border: none;
  }
}

.blueBoxControlWrapper {
  width: 100%;
  margin: 4px 0;
}

.blueBoxLabel {
  width: 36%;
  float: left;
  margin: 5px 1%;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222;
  display: block;
}

.blueBoxRedLabel {
  font-size: 13px;
  color: red;
  font-weight: 600;
  padding-left: 5px;
}

.blueBoxIconWrapper {
  width: 50px;
  padding: 5px;
  float: left;
}

.blueBoxIcon {
  cursor: pointer;
  color: black;
  font-size: 20px;
  font-weight: bold;
}

.blueBoxLabelAutoSize {
  width: auto;
  float: left;
  margin: 5px 1%;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222;
  display: block;
}

.blueBoxDaysInput {
  width: 45px;
  float: left;
  padding: 2px 5px;
  border-radius: 4px !important;
  border: solid 1px #d1d1d1 !important;
}

.blueBoxControl {
  width: 150px;
  float: left;
  border-radius: 6px;
  padding: 5px 2%;
  border: solid 1px #d1d1d1;
  background-color: #ffffff;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #2a404c;
}

.blueMaxMonthLabelPanel {
  width: 80px;
  position: absolute;
  top: 6px;
  left: 102px;
  font-size: 8px;
  font-weight: bold;
  color: red;
  line-height: 16px;
}

.blueBoxServiceFeeLabel {
  float: right;
  font-size: 12px;
  color: red;
}

.mouthsBlueBoxControl {
  width: 50px;
  border-radius: 6px;
  padding: 5px;
  border: solid 1px #d1d1d1;
  background-color: #ffffff;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #2a404c;
}

.blueBoxHeader {
  width: 93%;
  font-size: 18px;
  padding: 3px 0;
  line-height: 24px;
  border-bottom: 1px solid #999;
}

.o-drop__item--active {
  background-color: transparent;
  color: #000000;
}

.o-drop__item--active:hover {
  background-color: #d1d1d171;
  color: #000000;
}

.o-drop__item {
  min-width: 17rem !important;
  font-size: revert !important;
}

.editCtextRed {
  color: #ff2525;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 0px;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  width: 200px;
  border-radius: 10px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f8f8f8;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
