import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
import deepCopy from "@/helpers/deepCopy";

export default {
  state: {
    dashboardData: null,
    officePerformanceData: null,
    csrDashboardReportList: [],

  },
  actions: {
    GET_CSR_DASHBOARD_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCSRDashboardReport(payload));
      if (result) {
        commit("SET_CSR_DASHBOARD_REPORT", result.Data);
      }
    },
    GET_OFFICE_PERFORMANCE_STATE: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getDashboard(payload));
      if (result) {
        commit("SET_OFFICE_PERFORMANCE_STATE", result.Data);
      }
    },
    GET_OFFICE_PERFORMANCE: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getDashboard(payload));
      return [err, result];
    },

    GET_SUMMARY_TILES: async ({ commit, state }, payload) => {
      let err, result;

      if (state.dashboardData == null) {
        [err, result] = await to(api.initializeDashboard());

        if (result) {
          commit(types.SET_SUMMARY_TILES, result.Data);
        }
      }
    },
    REFRESH_DASHBOARD_DATA: async ({ commit, state }, payload) => {
      let err, result;
      if (payload.Title == "OfficePerformance") {
        [err, result] = await to(
          api.getDashboard({
            Option: payload.Option,
            StartDate: payload.StartDate,
            EndDate: payload.EndDate,
          })
        );
        return [err, result];
      }
    },
    RESET: async ({ commit }) => {
      commit(types.SET_SUMMARY_TILES, null);
    },
  },
  mutations: {
    SET_OFFICE_PERFORMANCE_STATE: (state, payload) => {
      state.officePerformanceData = payload;
    },
    SET_CSR_DASHBOARD_REPORT: (state, payload) => {
      state.csrDashboardReportList = payload;
    },
    SET_SUMMARY_TILES: (state, payload) => {
      state.dashboardData = payload;
    }
  },
};
