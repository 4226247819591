<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'CustomerTagReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredCustomerTag"
        :paginated="isPaginated && filteredCustomerTag.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerId }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ addPrefixToCustomerId(props.row.CustomerId) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="CustomerName"
          label="Customer Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="customerNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.CustomerName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="TagName" label="Tag Name" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="tagNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.TagName }}
          </template>
        </o-table-column>

        <o-table-column field="TagValue" label="Tag Value" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="tagValueFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatTagValue(props.row.TagValue, props.row.TagDataType) }}
          </template>
        </o-table-column>
        <o-table-column
          field="CreatedDate"
          label="Created Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="createdDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatGridDate(props.row.CreatedDate) }}
          </template>
        </o-table-column>
        <o-table-column
          field="CreatedBy"
          label="Created By"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="createdByFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CreatedBy }}
          </template>
        </o-table-column>
        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredCustomerTag.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import globalTypes from "@/store/types";
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "CustomerTagReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],

      customerTagReportInitSummary: {
        CustomerId: "",
        CustomerName: "",
        TagName: "",
        TagValue: "",
        CreatedDate: "",
        CreatedBy: "",
      },
      CustomerIdFilter: "",
      customerNameFilter: "",
      tagNameFilter: "",
      tagValueFilter: "",
      createdDateFilter: "",
      createdByFilter: "",
    };
  },
  computed: mapState({
    documentsCustomerTagList: (state) => state.reports.customerTagList,
    customerTagTypes: (state) => state.globals.customerTagTypes,

    filteredCustomerTag() {
      let rtn = [];

      let tmpList =
        this.documentsCustomerTagList.length > 0
          ? this.documentsCustomerTagList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.CustomerName, this.customerNameFilter) &&
                this.filterString(item.TagName, this.tagNameFilter) &&
                this.filterString(item.TagValue, this.tagValueFilter) &&
                this.filterString(item.CreatedBy, this.createdByFilter) &&
                new Date(item.CreatedDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.createdDateFilter) > -1
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.customerTagReportInitSummary.CustomerId = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.customerTagReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.customerTagReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    this.$store.dispatch(globalTypes.GET_CUSTOMER_TAG_TYPE);

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }
  },
  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    formatTagValue(value, dataType) {
      if (value == null) return "";

      if (dataType == "Date") {
        let dateValue = Date.parse(value);
        return isNaN(dateValue) || dateValue == null
          ? value
          : this.formatShortDate(value);
      } else if (dataType == "Date and Time") {
        let dateAndTimeValue = Date.parse(value);
        return isNaN(dateAndTimeValue) ? value : this.formatGridDate(value);
      } else if (dataType == "Money") {
        let moneyValue = parseFloat(value);
        return isNaN(moneyValue) ? value : this.formatMoney(value);
      } else {
        return value;
      }
    },

    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_CUSTOMER_TAG_REPORT, value);
      this.loadingSpinner = false;
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
