<template>
  <div style="width: 100%">
    <div
      v-if="dashboardData == null || getTimePassed <= 4"
      class="bgWrapper animate__animated animate__lightSpeedInLeft slowAnimation"
      :style="
        'width:' +
        100 +
        '%;' +
        'min-height:' +
        800 +
        'px;' +
        'height:' +
        100 +
        '%;' +
        'left:' +
        240 +
        'px;' +
        'background-color: #3bafda;'
      "
    ></div>
    <div
      v-if="dashboardData != null"
      id="dashboardBgWrapper"
      class="bgWrapper gradientBg"
      :style="
        'width:' +
        bgWidth +
        'px;' +
        'min-height:' +
        bgHeight +
        'px;' +
        'left:' +
        bgLeft +
        'px;' +
        'background-image:url(' +
        dashboardData.Background.ImageUrl +
        ');'
      "
    ></div>
    <div class="row">
      <div
        v-if="dashboardData != null"
        class="dashboard col-md-12 noPadding"
        style="margin-top: -59px"
      >
        <div
          style="width: 100%"
          :style="
            'min-height:' +
            (bgHeight - 72) +
            'px;' +
            'max-height:' +
            (bgHeight - 72) +
            'px;'
          "
        >
          <div class="dateWrapper">
            {{
              new Date().toLocaleDateString("en-us", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            }}
          </div>

          <div
            v-if="officePerformanceData"
            class="col-md-12 dashboardWidgets"
            style="z-index: 100"
          >
            <div class="col-md-12">
              <Widget
                componentName="TableWidget"
                title="Office Performance"
                :dateOption="dateFilter"
                :widgetData="{
                  data: officePerformanceData,
                  columns: dashboardTableColumns,
                  refresh: true,
                  timeInterval: true,
                  foot: true,
                }"
              />
            </div>
          </div>
          <div class="col-md-12" v-if="checkAuth(18922)">
            <div
              style="display: flex; flex-direction: column"
              class="col-md-12 row dashboardWidgets"
            >
              <div
                style="
                  min-height: 60px;
                  background-color: rgb(255, 255, 255, 1);
                  padding-top: 20px;
                  padding-bottom: 15px;
                  border-radius: 10px;
                "
              >
                <div style="display: flex; justify-content: space-between">
                  <label class="generalLabel ml-3">{{
                    csrDashboardReportList.AgentName
                  }}</label>
                  <i
                    class="fe-refresh-cw mr-3 refresh"
                    @click="refreshcsrDashboardReport()"
                  ></i>
                </div>
                <div class="col-md-12 row">
                  <div v-if="dbCsrDashboardLoading" class="loading">
                    <div class="text-center" style="margin-top: -50px">
                      <i class="spinner-grow text-info m-2"></i>
                    </div>
                  </div>
                </div>

                <div v-if="!dbCsrDashboardLoading && csrDashboardReportList">
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style="display: flex; flex-direction: row; z-index: 100"
                  >
                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: csrDashboardReportList.InBoundCall,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Inbound Calls Taken"
                      />
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: csrDashboardReportList.OutBoundCall,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Outbound Calls Made"
                      />
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: csrDashboardReportList.TalkTime,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Talk Time"
                      />
                    </div>
                  </div>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3"
                    style="display: flex; flex-direction: row; z-index: 100"
                  >
                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: csrDashboardReportList.LoggedTime,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Logged In Time"
                      />
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: csrDashboardReportList.ClosedTask,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Task Completed"
                      />
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Widget
                        :widgetData="{
                          current: csrDashboardReportList.AvgCallTime,
                          refresh: 'none',
                        }"
                        componentName="SummaryWidget"
                        title="Average Call Time"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="timeAndGreetingWrapper">
            <span class="timeText">
              {{ timeInfo }}
            </span>
            <span class="greetingText">
              {{ greetingInfo }}
            </span>
          </div>

          <div class="messageWrapper">
            <span class="messageText">
              {{ dashboardData.Background.Message }}

              <span
                v-if="dashboardData.Background.MessageSource"
                class="meessageSourceText"
              >
                <sup>- {{ dashboardData.Background.MessageSource }}</sup>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import types from "./types";
import { mapState } from "vuex";
import Widget from "@/components/Widget";
import deepCopy from "@/helpers/deepCopy";
import globalTypes from "@/store/types";
import _orderby from "lodash";

export default {
  name: "Dashboard",
  components: { Widget },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      errorMessage: "",
      isErrorMessage: false,
      menuOptions: null,
      dbCsrDashboardLoading: false,
      hasAccessOtherOffices: false,
      userOfficeId: 0,
      salesMonthlyDashboardRole: 0,
      salesMonthlyDashboardOffice: 0,
      salesMonthlyDashboardMonth: null,
      userId: null,
      leftMenuOpen: true,
      isLoading: false,
      isMobile: false,
      bgHeight: 0,
      bgWidth: 0,
      bgLeft: 240,
      timeInfo: "",
      greetingInfo: "",
      userFullName: "",
      dateFilter: "Today",
      dashboardTableColumns: [
        {
          field: "OfficeName",
          label: "Office Name",
          width: "17.7%",
          type: "text",
          showTotal: "",
        },
        {
          field: "EnrollmentCount",
          label: "Deals #",
          width: "11.6%",
          type: "numeric",
          showTotal: "total",
        },
        {
          field: "ActiveClientCount",
          label: "Active #",
          width: "11.5%",
          type: "numeric",
          showTotal: "total",
        },
        {
          field: "AverageDeals",
          label: "Avg Deals #",
          width: "12.6%",
          type: "float",
          showTotal: "total",
        },
        {
          field: "AverageDebt",
          label: "Avg Deal $",
          width: "11.7%",
          type: "decimal",
          showTotal: "total",
        },
        {
          field: "TotalCampaignInbound",
          label: "Campaign Inbound #",
          width: "11.8%",
          type: "numeric",
          showTotal: "total",
        },
        {
          field: "TotalOutbound",
          label: "Total Outbound #",
          width: "12.1%",
          type: "numeric",
          showTotal: "total",
        },
        {
          field: "PotentialRevenue",
          label: "Potential Rev $",
          width: "10%",
          type: "decimal",
          showTotal: "total",
        },
      ],
      timeNow: new Date(),
      timePassed: new Date(),
    };
  },
  computed: mapState({
    officePerformanceData: (state) =>
      state.dashboardStore.officePerformanceData,
    dashboardData: (state) => state.dashboardStore.dashboardData,
    csrDashboardReportList: (state) =>
      state.dashboardStore.csrDashboardReportList,
    roles: (state) => state.globals.roles,
    salesRoles() {
      return _orderby.orderBy(
        this.roles.filter((x) => x.ModuleFunctions.includes(1848)),
        "Name",
        "asc"
      );
    },

    offices: (state) => state.globals.offices,
    filteredOffices() {
      return _orderby.orderBy(
        this.offices.filter((x) =>
          this.hasAccessOtherOffices == true ? true : x.Id == this.userOfficeId
        )
      );
    },
    getTimePassed() {
      let rtn = parseInt(this.getTimeDifference(this.timePassed, this.timeNow));

      return rtn;
    },
  }),
  async mounted() {
    this.isLoading = true;
    this.setMenuOptions();

    if (this.salesMonthlyDashboardMonth == null) {
      this.salesMonthlyDashboardMonth = this.menuOptions[0].Key;
    }

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();

    let dashboardList = [];
    dashboardList.push(this.$store.dispatch(types.GET_SUMMARY_TILES));

    /*NF DASHBOARD START */
    if (this.checkAuth(18654)) {
      dashboardList.push(
        this.$store.dispatch(types.GET_OFFICE_PERFORMANCE_STATE, {
          Option: "today",
        })
      );
    }

   

  
    

  
  
   

  
    /*SNF DASHBOARD END */

    await Promise.all(dashboardList);

    /*D-B DASHBOARD START */
   
    if (this.checkAuth(18922)) {
      await this.refreshcsrDashboardReport();
    }
      await this.$store.dispatch(globalTypes.GET_ROLES);
      await this.$store.dispatch(globalTypes.GET_OFFICE_NAMES);
    

    /*D-B DASHBOARD END */

    this.isLoading = false;

    this.isMobile = this.isMobileDevice();
    window.addEventListener("resize", this.setDashboardSize);

    if (userInfo) {
      this.userFullName = userInfo.firstName + " " + userInfo.lastName;
    } else {
      this.userFullName = "";
    }
    this.getTime();
    this.getGreetings();
    await this.sleep(200);
    this.setDashboardSize();
    setInterval(() => {
      this.getTime();
    }, 1000);
    setInterval(async () => {
      this.getGreetings();
    }, 60000);

    this.$Emitter.on("resizeDashboard", (isMenuOpen) => {
      if (typeof isMenuOpen != "undefined" && isMenuOpen != null) {
        this.leftMenuOpen = isMenuOpen;
        this.setDashboardSize();
      }
    });
   
  },
  unmounted() {
    window.removeEventListener("resize", this.setDashboardSize);
  },
  methods: {
    getPayload() {
      let startDate = "";
      let endDate = "";

      let month = this.salesMonthlyDashboardMonth.getMonth();
      let year = this.salesMonthlyDashboardMonth.getFullYear();
      startDate = new Date(year, month, 1).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      endDate = new Date(year, month + 1, 0).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      return {
        StartDate: startDate,
        EndDate: endDate,
      };
    },

    setMenuOptions() {
      this.menuOptions = [];
      let today = new Date();
      for (let i = 0; i < 12; i++) {
        let date = new Date(today);
        date.setMonth(today.getMonth() - i);
        let year = date.getFullYear();
        let monthShortName = date.toLocaleString("en-us", { month: "short" });
        let value = i == 0 ? "This Month" : monthShortName + " " + year;
        this.menuOptions.push({
          Key: date,
          Value: value,
        });
      }
    },

    snfNegotiatorStatisticsTotalCount() {
      let rtn = 0;
      rtn =
        this.negotiatorStatisticsList.ThisMonthSnfCount +
        this.negotiatorStatisticsList.ThisMonthFlgCount;
      return rtn;
    },

    snfNegotiatorStatisticsPreviousCount() {
      let rtn = 0;
      if (
        this.negotiatorStatisticsList &&
        this.negotiatorStatisticsList.PreviousMonthSnfCount &&
        this.negotiatorStatisticsList.PreviousMonthFlgCount
      ) {
        rtn =
          this.negotiatorStatisticsList.PreviousMonthSnfCount +
          this.negotiatorStatisticsList.PreviousMonthFlgCount;
      }
      return rtn;
    },

    snfNegotiatorStatisticsRate() {
      let rtn = 0;
      let monthlyCount = this.snfNegotiatorStatisticsTotalCount();
      let previousCount = this.snfNegotiatorStatisticsPreviousCount();
      rtn = (monthlyCount / previousCount - 1) * 100 * -1;
      return rtn;
    },

    snfNegotiatorStatisticsTotalAmount() {
      let rtn = 0;
      rtn =
        this.negotiatorStatisticsList.ThisMonthSnfDebt +
        this.negotiatorStatisticsList.ThisMonthFlgDebt;
      return rtn;
    },

    snfNegotiatorStatisticsPreviousAmount() {
      let rtn = 0;
      if (
        this.negotiatorStatisticsList &&
        this.negotiatorStatisticsList.PreviousMonthSnfDebt &&
        this.negotiatorStatisticsList.PreviousMonthFlgDebt
      ) {
        rtn =
          this.negotiatorStatisticsList.PreviousMonthSnfDebt +
          this.negotiatorStatisticsList.PreviousMonthFlgDebt;
      }
      return rtn;
    },

    snfNegotiatorStatisticsRateAmount() {
      let rtn = 0;
      let monthlyCount = this.snfNegotiatorStatisticsTotalAmount();
      let previousCount = this.snfNegotiatorStatisticsPreviousAmount();
      rtn = (monthlyCount / previousCount - 1) * 100 * -1;
      return rtn;
    },

    snfSettlementsDashDailySales() {
      let rtn = 0;

      rtn =
        this.snfSettlementsList.SnfDailyCount +
        this.snfSettlementsList.FlgDailyCount;
      return rtn;
    },

    snfSettlementsDashDailyPreviousCount() {
      let rtn = 0;
      if (
        this.snfSettlementsList.SnfYesterdayCount &&
        this.snfSettlementsList.FlgYesterdayCount
      ) {
        rtn =
          this.snfSettlementsList.SnfYesterdayCount +
          this.snfSettlementsList.FlgYesterdayCount;
      }
      return rtn;
    },

    snfSettlementsDashDailyCountRate() {
      let rtn = 0;
      let dailySales = this.snfSettlementsDashDailySales();
      let previousCount = this.snfSettlementsDashDailyPreviousCount();
      rtn = (dailySales / previousCount - 1) * 100 * -1;
      return rtn;
    },

    snfSettlementsDashDailySalesDebt() {
      let rtn = 0;
      rtn =
        this.snfSettlementsList.SnfDailyDebtAmount +
        this.snfSettlementsList.FlgDailyDebtAmount;
      return rtn;
    },

    snfSettlementsDashDailyPreviousAmount() {
      let rtn = 0;
      if (
        this.snfSettlementsList &&
        this.snfSettlementsList.SnfYesterdayDebtAmount &&
        this.snfSettlementsList.FlgYesterdayDebtAmount
      ) {
        rtn =
          this.snfSettlementsList.SnfYesterdayDebtAmount +
          this.snfSettlementsList.FlgYesterdayDebtAmount;
      }
      return rtn;
    },

    snfSettlementsDashDailyAmountRate() {
      let rtn = 0;
      let dailySales = this.snfSettlementsDashDailySalesDebt();
      let previousCount = this.snfSettlementsDashDailyPreviousAmount();
      rtn = (dailySales / previousCount - 1) * 100 * -1;
      return rtn;
    },

    /////////////////////////////////////////////

    snfSettlementsDashMonthlySales() {
      let rtn = 0;
      if (
        this.snfSettlementsList &&
        this.snfSettlementsList.SnfMonthlyCount &&
        this.snfSettlementsList.FlgMonthlyCount
      ) {
        rtn =
          this.snfSettlementsList.SnfMonthlyCount +
          this.snfSettlementsList.FlgMonthlyCount;
      }
      return rtn;
    },

    snfSettlementsDashMonthlyPreviousCount() {
      let rtn = 0;
      if (
        this.snfSettlementsList &&
        this.snfSettlementsList.SnfPrvMonthCount &&
        this.snfSettlementsList.FlgPrvMonthCount
      ) {
        rtn =
          this.snfSettlementsList.SnfPrvMonthCount +
          this.snfSettlementsList.FlgPrvMonthCount;
      }
      return rtn;
    },

    snfSettlementsDashMonthlyCountRate() {
      let rtn = 0;
      let monthlySales = this.snfSettlementsDashMonthlySales();
      let previousCount = this.snfSettlementsDashMonthlyPreviousCount();
      rtn = (monthlySales / previousCount - 1) * 100 * -1;
      return rtn;
    },

    snfSettlementsDashMonthlySalesDebt() {
      let rtn = 0;
      if (
        this.snfSettlementsList &&
        this.snfSettlementsList.SnfMonthlyDebtAmount &&
        this.snfSettlementsList.FlgMonthlyDebtAmount
      ) {
        rtn =
          this.snfSettlementsList.SnfMonthlyDebtAmount +
          this.snfSettlementsList.FlgMonthlyDebtAmount;
      }
      return rtn;
    },

    snfSettlementsDashMonthlyPreviousAmount() {
      let rtn = 0;
      if (
        this.snfSettlementsList &&
        this.snfSettlementsList.SnfPrvMonthDebtAmount &&
        this.snfSettlementsList.FlgPrvMonthDebtAmount
      ) {
        rtn =
          this.snfSettlementsList.SnfPrvMonthDebtAmount +
          this.snfSettlementsList.FlgPrvMonthDebtAmount;
      }
      return rtn;
    },

    snfSettlementsDashMonthlyAmountRate() {
      let rtn = 0;
      let monthlySales = this.snfSettlementsDashMonthlySalesDebt();
      let previousCount = this.snfSettlementsDashMonthlyPreviousAmount();
      rtn = (monthlySales / previousCount - 1) * 100 * -1;
      return rtn;
    },

    ////////////////////////////////////////////

    snfSettlementsDashYearlySales() {
      let rtn = 0;
      if (
        this.snfSettlementsList &&
        this.snfSettlementsList.SnfYearlyTotalCount &&
        this.snfSettlementsList.FlgYearlyTotalCoun
      ) {
        rtn =
          this.snfSettlementsList.SnfYearlyTotalCount +
          this.snfSettlementsList.FlgYearlyTotalCoun;
      }
      return rtn;
    },
    snfSettlementsDashYearlySalesDebt() {
      let rtn = 0;
      if (
        this.snfSettlementsList &&
        this.snfSettlementsList.SnfYearlyDebtAmount &&
        this.snfSettlementsList.FlgYearlyDebtAmount
      ) {
        rtn =
          this.snfSettlementsList.SnfYearlyDebtAmount +
          this.snfSettlementsList.FlgYearlyDebtAmount;
      }
      return rtn;
    },

    /////////////////////
    changeOffice() {
      this.salesMonthlyDashboardRole = 0;
    },
    changeTeams() {
      this.salesMonthlyDashboardOffice = 0;
    },


    async refreshcsrDashboardReport() {
      this.dbCsrDashboardLoading = true;
      await this.$store.dispatch(types.GET_CSR_DASHBOARD_REPORT, {
        Option: "today",
      });
      this.dbCsrDashboardLoading = false;
    },

    


    setDashboardSize() {
      this.bgHeight = window.innerHeight - 70;

      let widgets = document.getElementsByClassName("dashboardWidgets");

      if (typeof widgets != "undefined" && widgets && widgets.length > 0) {
        let totalWidgetHeight = 0;
        for (let i = 0; i < widgets.length; i++) {
          totalWidgetHeight += widgets[i].clientHeight + 20;
        }

        if (totalWidgetHeight + 250 > this.bgHeight) {
          this.bgHeight = totalWidgetHeight + 350;
        }
      }

      this.bgWidth =
        this.isMobile || !this.leftMenuOpen || window.innerWidth < 768
          ? window.innerWidth
          : window.innerWidth - 240;

      this.bgLeft =
        this.isMobile || !this.leftMenuOpen || window.innerWidth < 768
          ? 0
          : 240;
    },
    getTime() {
      let date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      this.timeInfo = hours + ":" + minutes + " " + ampm;
      this.timePassed = new Date();
    },
    getGreetings() {
      let date = new Date();
      let hours = date.getHours();
      let greetingsText;
      if (hours < 12) greetingsText = "Good Morning, ";
      else if (hours < 17) greetingsText = "Good Afternoon, ";
      else greetingsText = "Good Evening, ";

      if (this.userFullName != "") {
        this.greetingInfo = greetingsText + this.userFullName;
      }
    },


  },
};
</script>

<style lang="css">
.bgWrapper {
  position: fixed;
  top: 70px;
}

.gradientBg {
  /*background-image: radial-gradient(circle at 50% 52%, #ffffff, #d9f5ff 100%);*/
  /*background-image: url("https://csmdashboard.azurewebsites.net/bg/29.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
}

.dateWrapper {
  margin-left: 20px;
  width: 100%;
  font-size: 3em;
  color: white;
}

.timeAndGreetingWrapper {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0;
}

.timeText {
  display: block;
  margin: 0 20px;
  font-size: 5em;
  line-height: 0.8em;
  color: white;
}

.greetingText {
  display: block;
  margin: 0px 28px;
  font-size: 2.5em;
  color: white;
}

.messageWrapper {
  width: 101%;
  position: absolute;
  bottom: -72px;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

.messageText {
  display: block;
  padding: 5px 20px;
  font-size: 1.5em;
  color: #fdfdfd;
  font-style: italic;
}

.meessageSourceText {
  font-size: 0.6em;
  font-style: italic;
}

.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}

.refresh {
  height: 27px;
  float: right;
  font-size: 16px;
  font-weight: 600;
  color: #3bafda;
  cursor: pointer;
}

.slowAnimation {
  --animate-duration: 8s !important;
}
.dashboardWidgets {
  margin-bottom: 20px !important;
}
</style>
