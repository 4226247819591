<template>
  <div id="app">
    <router-view @authenticated="setAuthenticated"> </router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      authenticated: false,
    };
  },
  beforeCreate() {
    if (this.$router.currentRoute.name != "login") this.$router.push("login");
  },
  mounted() {
    let isExpired = false;
    let expireUTC = sessionStorage.getItem("expireUTC");
    if (expireUTC) {
      let expireLocalDate = new Date(expireUTC);
      let dtNow = new Date();
      if (dtNow > expireLocalDate) isExpired = true;
    }
    if (sessionStorage.getItem("userInfo") && !isExpired) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
      if (
        !(
          this.$route.fullPath.indexOf("login") > -1 ||
          this.$route.fullPath == "/"
        )
      ) {
        window.location.href = "/login";
      }
    }
  },

  methods: {
    setAuthenticated(status) {
      this.authenticated = status;
      if (!status) {
        if (
          typeof this.$router.currentRoute.name != "undefined" &&
          this.$router.currentRoute.name.toLowerCase() != "login"
        ) {
          window.location.href = "/login";
        }
      }
    },
  },
};
</script>
<style scoped>
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
</style>
