import axios from "axios";
import { id } from "rhea";

const API_URL = process.env.VUE_APP_API;

const instance = axios.create({
  baseURL: "",
  timeout: 120000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(sessionStorage.userInfo).token;

    if (token !== null && token !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
export default {
  getCallLogReport: (data) => {
    return instance.post(`${API_URL}/api/CallLogsReport`, {
      Type: "getReport",
      Data: data,
    });
  },

  getIncomingCallBlock() {
    return instance.post(`${API_URL}/api/IncomingCallBlock`, {
      Type: "get",
    });
  },
  insertIncomingCallBlock(data) {
    return instance.post(`${API_URL}/api/IncomingCallBlock`, {
      Type: "post",
      Data: data,
    });
  },
  deleteIncomingCallBlock(data) {
    return instance.post(`${API_URL}/api/IncomingCallBlock`, {
      Type: "delete",
      Data: data,
    });
  },
  createAndPopulateCustomerDuringCall: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "CreateCustomerDependsOnSoldStatus",
      Data: data,
    });
  },
  createCustomerAfterCall: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "insertwithmandatorydispo",
      Data: data,
    });
  },
  logBufferCall: (data) => {
    return instance.post(`${API_URL}/api/BufferCall`, data);
  },
  get3rdPartyList: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "Get3rdPartyList",
    });
  },
  getCsrTaskDistributionQueueReport: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskDistributionQueueReport`, data);
  },
  getCustomerDispositionSettingsReport: (data) => {
    return instance.post(
      `${API_URL}/api/CustomerDispositionSettingsReport`,
      data
    );
  },

  getUserActivityReport: (data) => {
    return instance.post(`${API_URL}/api/UserActivity`, {
      Type: "get",
      Data: data,
    });
  },

  getCsrTaskSummaryReport: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskSummaryReport`, data);
  },

  getCsrTaskTriggerLogStatus: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcsrtasktriggerlogstatus",
    });
  },

  getCsrTaskTriggerType: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcsrtasktriggertype",
    });
  },

  getSpeechCoicebySpeechText: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskType`, {
      Type: "getspeechvoicebyspeechtext",
      Data: data,
    });
  },

  getDispositionOptionsForAdmin: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcompanydispositionsforadmin",
    });
  },

  uploadCampaignData: (data) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "uploaddata",
      Data: data,
    });
  },
  getAllUiSettings: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getalluisettings",
    });
  },
  getCompanyUiSettings: (data) => {
    return instance.post(`${API_URL}/api/CompanyUISetting`, {
      Type: "getcompanyuisetting",
      Data: data,
    });
  },
  insertCompanyUiSetting: (data) => {
    return instance.post(`${API_URL}/api/CompanyUISetting`, {
      Type: "create",
      Data: data,
    });
  },
  updateCompanyUiSetting: (data) => {
    return instance.post(`${API_URL}/api/CompanyUISetting`, {
      Type: "update",
      Data: data,
    });
  },

  getOffices: () => {
    return instance.get(`${API_URL}/api/Office`);
  },
  saveOffice: (data) => {
    return instance.post(`${API_URL}/api/Office`, data);
  },
  updateOffice: (data) => {
    return instance.put(`${API_URL}/api/Office`, data);
  },
  deleteOffice: (id) => {
    return instance.delete(`${API_URL}/api/Office/${id}`);
  },
  getEmployees: (isRetiredIncluded, officeId, roleId) => {
    return instance.post(`${API_URL}/api/User`, {
      Type: "getUsers",
      Data: {
        OfficeId: officeId,
        RoleId: roleId,
        IsRetiredIncluded: isRetiredIncluded,
      },
    });
  },
  getEmployeeById: (id) => {
    return instance.post(`${API_URL}/api/User`, {
      Type: "getUser",
      Data: { Id: id },
    });
  },
  getEmployeeByTimeZone: (id, timezoneId) => {
    return instance.post(`${API_URL}/api/User`, {
      Type: "getByTimeZone",
      Id: id,
      timezoneId: timezoneId,
    });
  },
  saveEmployee: (data) => {
    return instance.post(`${API_URL}/api/User`, { Type: "insert", Data: data });
  },
  updateEmployee: (data) => {
    return instance.post(`${API_URL}/api/User`, { Type: "update", Data: data });
  },
  updateRetired: (id, isRetired) => {
    return instance.post(`${API_URL}/api/User`, {
      Type: "RetireUser",
      Data: { UserId: id, IsRetired: isRetired },
    });
  },
  deleteEmployee: (id) => {
    return instance.post(`${API_URL}/api/User`, {
      Type: "Delete",
      Data: { Id: id },
    });
  },
  deleteAllVmTemplates: (id) => {
    return instance.post(`${API_URL}/api/VoicemailTemplateUser`, {
      Type: "deleteallbyuserid",
      Data: id,
    });
  },
  getRoles: () => {
    return instance.get(`${API_URL}/api/Role`);
  },
  getModules: () => {
    return instance.get(`${API_URL}/api/Module`);
  },
  saveRole: (data) => {
    return instance.post(`${API_URL}/api/Role`, data);
  },
  updateRole: (data) => {
    return instance.put(`${API_URL}/api/Role`, data);
  },
  deleteRole: (id) => {
    return instance.delete(`${API_URL}/api/Role/${id}`);
  },
  getGenders: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getgendertypes",
    });
  },
  getStates: () => {
    return instance.get(`${API_URL}/api/State`);
  },
  getTimezones: () => {
    return instance.get(`${API_URL}/api/TimeZone`);
  },
  reportError: () => {
    return instance.post(`${API_URL}/api/Exception`);
  },
  getStatusNames: () => {
    return instance.post(`${API_URL}/api/Reference`, { Type: "getstatuses" });
  },
  getCustomerStatuses: () => {
    return instance.get(`${API_URL}/api/CustomerStatus`);
  },
  insertCustomerStatus: (data) => {
    return instance.post(`${API_URL}/api/CustomerStatus`, data);
  },
  updateCustomerStatus: (data) => {
    return instance.put(`${API_URL}/api/CustomerStatus`, data);
  },
  deleteCustomerStatus: (id) => {
    return instance.delete(`${API_URL}/api/CustomerStatus/${id}`);
  },
  getDispositionOptions: () => {
    return instance.get(`${API_URL}/api/CustomerDisposition`);
  },
  getDispositionForManagement: () => {
    return instance.post(`${API_URL}/api/DispositionManagement`, {
      Type: "get",
    });
  },
  insertDispositionOption: (data) => {
    return instance.post(`${API_URL}/api/DispositionManagement`, {
      Type: "post",
      Data: data,
    });
  },
  updateDispositionOption: (data) => {
    return instance.post(`${API_URL}/api/DispositionManagement`, {
      Type: "put",
      Data: data,
    });
  },

  createEndpoint: (userId) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "CreateEndpoint",
      Data: {
        UserId: userId,
      },
    });
  },
  deleteEndpoint: (userId) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "DeleteEndpoint",
      Data: { UserId: userId },
    });
  },
  getCompanyDids: (assignTypeId) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "CompanyDid",
      Data: { Id: assignTypeId },
    });
  },
  updateDid: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "UpdateDid",
      Data: data,
    });
  },
  updateDidAssignType: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "DidAssignType",
      Data: data,
    });
  },
  getAssignTypes: () => {
    return instance.get(`${API_URL}/api/CompanyDidAssignType`);
  },
  getAssignableUsers: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "DidAssignableUsers",
    });
  },
  getPhoneNumbers: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "AvailablePhoneNumbers",
      Data: { Pattern: data },
    });
  },
  buyPhoneNumbers: (phone) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "BuyPhoneNumber",
      Data: {
        PhoneNumber: phone,
      },
    });
  },
  getEndPoint: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetEndpoint",
      Data: { Id: 0 },
    });
  },
  getEndPointList: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetEndpoint",
      Data: { Id: 1 },
    });
  },
  getCallerIdEndPoint: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetCallerInEndpointCalls",
    });
  },
  getDetailsByPhoneNumber: async (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetDetailsByPhoneNumber",
      Data: data,
    });
  },
  holdCall: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "HoldCall",
      Data: data,
    });
  },
  grabCall: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GrabCall",
      Data: data,
    });
  },
  transferToAgent: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferToAgent",
      Data: data,
    });
  },
  transferToDid: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferToDid",
      Data: data,
    });
  },
  getVMDrops: () => {
    return instance.get(`${API_URL}/api/VMDrop`);
  },
  getAvailableVMDrops: (data) => {
    return instance.post(`${API_URL}/api/VoicemailTemplateUser`, {
      Type: "getavailabledrops",
      Data: data,
    });
  },
  transferToVmDrop: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferToVmDrop",
      Data: data,
    });
  },
  transferToVmBox: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferToVmBox",
      Data: data,
    });
  },
  transferToIngroup: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferToInGroup",
      Data: data,
    });
  },
  getVMRecordings: () => {
    return instance.post(`${API_URL}/api/VoicemailTemplateUser`, {
      Type: "getvoicemailtemplates",
    });
  },
  saveVMRecording: (data) => {
    return instance.post(`${API_URL}/api/VoicemailTemplateUser`, {
      Type: "saverecording",
      Data: data,
    });
  },
  deleteVMRecording: (id) => {
    return instance.post(`${API_URL}/api/VoicemailTemplateUser`, {
      Type: "deletebytemplateid",
      Data: { VoicemailTemplateId: id },
    });
  },
  getCampaigns: () => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "getall",
    });
  },
  getCampaignTypes: () => {
    return instance.get(`${API_URL}/api/CampaignType`);
  },
  insertCampaign: (data) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "create",
      Data: data,
    });
  },
  insertCampaignV2: (data) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "createV2",
      Data: data,
    });
  },

  updateCampaignV2: (data) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "updateV2",
      Data: data,
    });
  },
  getVMTemplates: () => {
    return instance.get(`${API_URL}/api/VoicemailTemplate`);
  },
  insertVMTemplate: (data) => {
    return instance.post(`${API_URL}/api/VoicemailTemplate`, data);
  },
  updateVMTemplate: (data) => {
    return instance.put(`${API_URL}/api/VoicemailTemplate`, data);
  },
  getLeadDistroSettings: () => {
    return instance.post(`${API_URL}/api/LeadDistroSettings`, {
      Type: "getleaddistrosettings",
    });
  },
  updateLeadDistroSettings: (data) => {
    return instance.post(`${API_URL}/api/LeadDistroSettings`, {
      Type: "updateleaddistrosettings",
      Data: data,
    });
  },
  getAgentTiers() {
    return instance.post(`${API_URL}/api/LeadDistroSettings`, {
      Type: "getusertiers",
    });
  },
  updateAgentTiers: (data) => {
    return instance.post(
      `${API_URL}/api/LeadDistroSettings/UpdateTierSettings`,
      {
        Type: "updatetiersettings",
        Data: data,
      }
    );
  },
  hotLeadAccepted: (data) => {
    return instance.post(`${API_URL}/api/HotLead/AcceptLead`, data);
  },
  insertMandatoryDispo: (data) => {
    return instance.post(`${API_URL}/api/MandatoryDisposition/Add`, data);
  },
  getMandatoryDispo: (data) => {
    return instance.get(`${API_URL}/api/MandatoryDisposition/Pending`, data);
  },
  getAssignableCampaigns: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "DidAssignableCampaigns",
    });
  },
  updateDidCampaign: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "UpdateCampaignDid",
      Data: data,
    });
  },
  getLiveCalls: () => {
    return instance.get(`${API_URL}/api/LiveCallReport`);
  },
  resetIdle: () => {
    return instance.post(`${API_URL}/api/UserPauseReasonLog`, {
      Type: "updatelastcalltime",
    });
  },
  getPlaceholders() {
    return instance.get(`${API_URL}/api/Placeholder/GetAll`);
  },
  getQuickSms() {
    return instance.post(`${API_URL}/api/QuickSms`, { Type: "GetAll" });
  },
  insertQuickSms: (data) => {
    return instance.post(`${API_URL}/api/QuickSms`, {
      Type: "Save",
      Data: data,
    });
  },
  updateQuickSms: (data) => {
    return instance.post(`${API_URL}/api/QuickSms`, {
      Type: "Update",
      Data: data,
    });
  },
  getSmsTemplateNames() {
    return instance.post(`${API_URL}/api/QuickSms`, {
      Type: "GetAllForDropdown",
    });
  },
  processPlaceholder: (data) => {
    return instance.post(`${API_URL}/api/Placeholder/Process`, data);
  },
  getDripEmail() {
    return instance.post(`${API_URL}/api/DripEmailTemplate`, {
      Type: "GetAll",
    });
  },
  getDripEmailFromTypes() {
    return instance.get(`${API_URL}/api/DripEmailFromType`);
  },
  insertDripEmail: (data) => {
    return instance.post(`${API_URL}/api/DripEmailTemplate`, {
      Type: "Insert",
      Data: data,
    });
  },
  updateDripEmail: (data) => {
    return instance.post(`${API_URL}/api/DripEmailTemplate`, {
      Type: "Update",
      Data: data,
    });
  },
  deleteDripEmailTrigger: (triggerId) => {
    return instance.post(`${API_URL}/api/DripEmailTemplate`, {
      Type: "DeleteTrigger",
      Data: { Id: triggerId },
    });
  },
  getCampaignAgents() {
    return instance.get(`${API_URL}/api/Agent/CanTakeLeads`);
  },
  unsubscribeEmail: (data) => {
    return instance.post(`${API_URL}/api/Unsubscribe`, { Data: data });
  },
  getPauseReasonTypes() {
    return instance.get(`${API_URL}/api/PauseReason`);
  },
  insertPauseReasonType: (data) => {
    return instance.post(`${API_URL}/api/PauseReason`, data);
  },
  updatePauseReasonType: (data) => {
    return instance.put(`${API_URL}/api/PauseReason`, data);
  },
  deletePauseReasonType: (id) => {
    return instance.delete(`${API_URL}/api/PauseReason`, { data: { Id: id } });
  },
  insertPauseLog: (id) => {
    return instance.post(`${API_URL}/api/UserPauseReasonLog/`, {
      Type: "updatepausereasonlog",
      PauseReasonId: id,
    });
  },
  getUserLastCallTime() {
    return instance.post(`${API_URL}/api/UserPauseReasonLog`, {
      Type: "getlastcalltime",
    });
  },
  updateUserLastCallTime: (data) => {
    return instance.post(`${API_URL}/api/UserPauseReasonLog/`, {
      Type: "updatelastcalltime",
    });
  },

  getCampaignLeadsV2: (data) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "campaignsearch",
      Data: data,
    });
  },
  convertMailerDump: (data) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "converttocustomer",
      Data: data,
    });
  },
  getCustomerTagReport: (data) => {
    return instance.post(`${API_URL}/api/CustomerTagReport`, data);
  },

  getCsrTaskContactScheduleReport: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskContactScheduleReport`, data);
  },

  getAgentPerformanceReport: (data) => {
    return instance.post(`${API_URL}/api/AgentPerformanceReport`, data);
  },

  getAgentPerformanceNewReport: (data) => {
    return instance.post(`${API_URL}/api/AgentPerformanceNewReport`, data);
  },

  getUserTimeLogReport: (data) => {
    return instance.post(`${API_URL}/api/UserTimeLogReport`, {
      Option: "thisyear",
      Data: data,
    });
  },

  getCallLogDetail: (data) => {
    return instance.post(`${API_URL}/api/CallLogsReport`, {
      Type: "getCallLogDetail",
      Data: data,
    });
  },
  getAgentStatusReport: (data) => {
    return instance.post(`${API_URL}/api/AgentStatusReport`, data);
  },

  getCustomerStatusReport: (data) => {
    return instance.post(`${API_URL}/api/CustomerStatusReport`, data);
  },
  getMissedCallReport: (data) => {
    return instance.post(`${API_URL}/api/MissedCallReport`, data);
  },
  getSmsHistoryReport: (data) => {
    return instance.post(`${API_URL}/api/SmsHistoryReport`, data);
  },
  getRemindersReport: (data) => {
    return instance.post(`${API_URL}/api/RemindersReport`, data);
  },
  getCsrTaskPerformanceReport: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskPerformanceReport`, data);
  },
  getInboundStatisticsReport: (data) => {
    return instance.post(`${API_URL}/api/InboundStatistics`, data);
  },
  getLiveCallReport: (data) => {
    return instance.post(`${API_URL}/api/LiveCallReport`, data);
  },
  getPendingDispositionReport: (data) => {
    return instance.post(`${API_URL}/api/PendingDispositionReport`, data);
  },

  getDbSalesCompensationReport: (data) => {
    return instance.post(`${API_URL}/api/SalesCompensationReport`, data);
  },

  getUserScript() {
    return instance.get(`${API_URL}/api/UserScript/GetAll`);
  },
  insertScript: (data) => {
    return instance.post(`${API_URL}/api/UserScript/Insert`, data);
  },
  updateScript: (data) => {
    return instance.put(`${API_URL}/api/UserScript/Update`, data);
  },
  deleteScript: (id) => {
    return instance.delete(`${API_URL}/api/UserScript/Delete`, {
      data: { Id: id },
    });
  },
  getCsrRealtimeTasksReport: () => {
    return instance.post(`${API_URL}/api/CsrRealtimeTasksReport`);
  },
  getCsrTaskReport: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskReport`, data);
  },
  getLeadAssignReport: (data) => {
    return instance.post(`${API_URL}/api/LeadAssignReport`, data);
  },
  getIncomingVmReport: (data) => {
    return instance.post(`${API_URL}/api/IncomingVMReport`, data);
  },

  transferBothSideToConference: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferBothSidesToConference",
      Data: data,
    });
  },
  kickMemberFromConference: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "ConferenceKickMember",
      Data: data,
    });
  },

  getCallMetricsReport: (data) => {
    return instance.post(`${API_URL}/api/ServiceBusLog`, {
      Type: "report",
      Data: data,
    });
  },

  getAnnounceCallLog: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetByPhoneNumberAnnounceCallLog",
      Data: data,
    });
  },
  insertAnnounceCallLog: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "AddNewAnnounceCallLog",
      Data: data,
    });
  },
  updateAnnounceCallLog: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "UpdateAnnounceCallLog",
      Data: data,
    });
  },
  deleteConference: (confName) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "DeleteConference",
      Data: { ConferenceName: confName },
    });
  },
  getLiveConferenceNames(data) {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetLiveConferenceNames",
      Data: data,
    });
  },
  transferToConference: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferToConference",
      Data: data,
    });
  },
  deleteCreditorContact(id) {
    return instance.post(`${API_URL}/api/CreditorContact`, {
      Type: "delete",
      Data: { Id: id },
    });
  },
  updateCreditorContact(data) {
    return instance.post(`${API_URL}/api/CreditorContact`, {
      Type: "update",
      Data: data,
    });
  },
  getAllCreditorContact(data) {
    return instance.post(`${API_URL}/api/CreditorContact`, {
      Type: "getall",
      Data: data,
    });
  },
  sendFax(data) {
    return instance.post(`${API_URL}/api/SendEfax`, {
      Type: "sendFaxViaSmtp",
      Data: data,
    });
  },

  createCreditorContact(data) {
    return instance.post(`${API_URL}/api/CreditorContact`, {
      Type: "create",
      Data: data,
    });
  },
  ////////
  updatePeerId: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "UpdatePeerId",
      Data: data,
    });
  },
  kick3rdParty: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "ConferenceKick3rdParties",
      Data: data,
    });
  },
  transferToNewConference: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferConferenceToConference",
      Data: data,
    });
  },
  getAnnounceCallDepartments: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetAnnounceCallDepartments",
    });
  },
  logError: (data) => {
    return instance.post(`${API_URL}/api/Exception`, data);
  },
  sendIncomingCallLog: (data) => {
    return instance.post(`${API_URL}/api/UserIncomingCallStatusLog`, data);
  },
  getQuickEmailTypes: () => {
    return instance.post(`${API_URL}/api/QuickEmail`, {
      Type: "GetQuickEmailTypes",
    });
  },
  getQuickEmails: () => {
    return instance.post(`${API_URL}/api/QuickEmail`, {
      Type: "GetQuickEmails",
    });
  },
  saveQuickEmails: (data) => {
    return instance.post(`${API_URL}/api/QuickEmail`, {
      Type: "Create",
      Data: data,
    });
  },
  updateQuickEmails: (data) => {
    return instance.post(`${API_URL}/api/QuickEmail`, {
      Type: "Update",
      Data: data,
    });
  },
  deleteQuickEmails: (id) => {
    return instance.post(`${API_URL}/api/QuickEmail`, {
      Type: "Delete",
      Data: { Id: id },
    });
  },
  getQuickNotes: () => {
    return instance.post(`${API_URL}/api/QuickNote`, { Type: "GetQuickNotes" });
  },
  saveQuickNotes: (data) => {
    return instance.post(`${API_URL}/api/QuickNote`, {
      Type: "Create",
      Data: data,
    });
  },
  updateQuickNotes: (data) => {
    return instance.post(`${API_URL}/api/QuickNote`, {
      Type: "Update",
      Data: data,
    });
  },
  deleteQuickNotes: (id) => {
    return instance.post(`${API_URL}/api/QuickNote`, {
      Type: "Delete",
      Data: { Id: id },
    });
  },
  getCsrTaskActions: () => {
    return instance.post(`${API_URL}/api/CsrTaskAction`, { Type: "getall" });
  },
  saveCsrTaskActions: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskAction`, {
      Type: "insert",
      Data: data,
    });
  },
  updateCsrTaskActions: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskAction`, {
      Type: "update",
      Data: data,
    });
  },
  getCsrTaskStatus: () => {
    return instance.post(`${API_URL}/api/CsrTaskStatus`, { Type: "getall" });
  },
  getCsrTaskTypes: () => {
    return instance.post(`${API_URL}/api/CsrTaskType`, { Type: "getall" });
  },
  saveCsrTaskType: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskType`, {
      Type: "addnew",
      Data: data,
    });
  },
  updateCsrTaskType: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskType`, {
      Type: "update",
      Data: data,
    });
  },

  checkRouteNumber: (payload) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "routingnumbervalidation",
      Data: payload,
    });
  },

  getOfficeNames: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getofficenames",
    });
  },

  getSalesAgents: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getsalesagents",
    });
  },
  getNegotiators: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getnegotiators",
    });
  },

  getCSRs: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcsrs",
    });
  },

  getAllActiveEmployees: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getallusers",
    });
  },

  getTaskTypesNames: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcsrtasktypes",
    });
  },

  getTaskActionNames: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcsrtaskactions",
    });
  },

  sendEmailMg: (data) => {
    return instance.post(`${API_URL}/api/EmailSender`, data);
  },

  initializeDashboard: () => {
    return instance.post(`${API_URL}/api/AgentDashboard`, {
      Type: "init",
    });
  },
  getCsrTaskTypesSimplified: () => {
    return instance.post(`${API_URL}/api/CsrTaskType`, {
      Type: "gettasktypes",
    });
  },

  getInboundCallSettings() {
    return instance.post(`${API_URL}/api/OfficePhoneSetting`, {
      Type: "getall",
    });
  },
  updateInboundCallSettings: (data) => {
    return instance.post(`${API_URL}/api/OfficePhoneSetting`, {
      Type: "update",
      Data: data,
    });
  },

  insertInboundCallSettings: (data) => {
    return instance.post(`${API_URL}/api/OfficePhoneSetting`, {
      Type: "create",
      Data: data,
    });
  },

  getCompanyCallSettings() {
    return instance.post(`${API_URL}/api/CallSettings`, {
      Type: "getinboundsettings",
    });
  },
  updateCompanyCallSettings: (data) => {
    return instance.post(`${API_URL}/api/CallSettings`, {
      Type: "updateinboundsettings",
      Data: data,
    });
  },

  getDidassignTypes: () => {
    return instance.post(`${API_URL}/api/CallSettings`, {
      Type: "getdidassigntypes",
    });
  },
  updateOutboundCallMethod: (id) => {
    return instance.post(`${API_URL}/api/CallSettings`, {
      Type: "updatedidassigntype",
      Data: { Id: id },
    });
  },
  updateInboundRules: (data) => {
    return instance.post(`${API_URL}/api/CallSettings`, {
      Type: "updateinboundrules",
      Data: data,
    });
  },
  getInboundRules: (data) => {
    return instance.post(`${API_URL}/api/CallSettings`, {
      Type: "getinboundrules",
      Data: data,
    });
  },

  getCallerId: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetCallerIdV2",
      Data: data,
    });
  },

  getDidAssignableOffices: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "DidAssignableOffices",
    });
  },
  updateOfficeDid: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "UpdateOfficeDid",
      Data: data,
    });
  },
  updateCompanyDid: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "UpdateCompanyDid",
      Data: data,
    });
  },

  createTagManagement(data) {
    return instance.post(`${API_URL}/api/CustomerTagType`, {
      Type: "create",
      Data: data,
    });
  },
  updateTagManagement: (data) => {
    return instance.post(`${API_URL}/api/CustomerTagType`, {
      Type: "update",
      Data: data,
    });
  },
  deleteTagManagement: (data) => {
    return instance.post(`${API_URL}/api/CustomerTagType`, {
      Type: "delete",
      Data: data,
    });
  },
  getAllTagManagement: () => {
    return instance.post(`${API_URL}/api/CustomerTagType`, {
      Type: "getall",
    });
  },
  getDashboard: (data) => {
    return instance.post(`${API_URL}/api/Dashboard`, {
      Type: "nfdashboard",
      Data: data,
    });
  },
  getCSRDashboardReport: (data) => {
    return instance.post(`${API_URL}/api/CSRDashboardReport`, data);
  },
  updateVmBoxEnabled: (data) => {
    return instance.post(`${API_URL}/api/User`, {
      Type: "updateUserVmBoxEnabled",
      Data: data,
    });
  },
  suggestCreditorByPattern: (data) => {
    return instance.post(`${API_URL}/api/CreditorAverageSettlementPercentage`, {
      Type: "GetNameList",
      Data: data,
    });
  },

  getAllCreditorAndAliases: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getallcreditorandaliases",
    });
  },

  getForCustomerStatusManagement: (data) => {
    return instance.post(`${API_URL}/api/DispositionManagement`, {
      Type: "getforcustomerstatusmanagement",
      Data: { StatusId: data },
    });
  },

  getSmsBrands: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetCompanySmsBrands",
      Data: data,
    });
  },
  getCustomerCancelationReason: (data) => {
    return instance.post(`${API_URL}/api/CustomerCancelationReason`, {
      Type: "getall",
      Data: { IsActive: data },
    });
  },

  addCustomerCancelationReason: (data) => {
    return instance.post(`${API_URL}/api/CustomerCancelationReason`, {
      Type: "add",
      Data: data,
    });
  },

  updateCustomerCancelationReason: (data) => {
    return instance.post(`${API_URL}/api/CustomerCancelationReason`, {
      Type: "update",
      Data: data,
    });
  },

  getAuditMatrixConfigs: (data) => {
    return instance.post(`${API_URL}/api/AuditMatrixConfigurations`, {
      Type: "getAuditMatrixConfigs",
      Data: data,
    });
  },
  saveAuditMatrixConfigs: (data) => {
    return instance.post(`${API_URL}/api/AuditMatrixConfigurations`, {
      Type: "saveAuditMatrixConfigs",
      Data: data,
    });
  },
  changePassword: (data) => {
    return instance.post(`${API_URL}/api/ChangePassword`, data);
  },

  mfaVerificationSend: (data) => {
    return instance.post(`${API_URL}/api/MfaVerification`, {
      Type: "send",
      Data: data,
    });
  },
  mfaVerificationVerify: (data) => {
    return instance.post(`${API_URL}/api/MfaVerification`, {
      Type: "verify",
      Data: data,
    });
  },

  getCPAAgentReport: (data) => {
    return instance.post(`${API_URL}/api/NmsCpaAgentReport`, data);
  },
  getCPAOfficeReport: (data) => {
    return instance.post(`${API_URL}/api/NmsCpaOfficeReport`, data);
  },
  getCPAStateReport: (data) => {
    return instance.post(`${API_URL}/api/NmsCpaStateReport`, data);
  },
  getCPAPubReport: (data) => {
    return instance.post(`${API_URL}/api/NmsCpaPubReport`, data);
  },

  getAutoDial: (data) => {
    return instance.post(`${API_URL}/api/AutoDial`, {
      Type: "getAutoDial",
      Data: data,
    });
  },
  setAutoDial: (data) => {
    return instance.post(`${API_URL}/api/AutoDial`, {
      Type: "setAutoDial",
      Data: data,
    });
  },
};
