import types from "./types";
import api from "@/services/client/customerApi";
import to from "@/helpers/to";
import downloadApi from "@/services/fileServices/downloadService";

export default {
  state: {
    clientCreditors: [],
    customerDocuments: [],
  },
  actions: {

    GET_CUSTOMER_OPTIN_STATUS: async ({ state }, payload) => {
      let err, result;
      [err, result] = await to(api.getCustomerOptinStatus(payload));
      return [err, result];
    },
    PUSH_TO_TCI: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.pushToTCI(payload));
      return [err, result];
    },


    UPDATE_DRAFT_AMOUNT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(
          api.updateGlobalInfoarmationDraftAmount(payload)
      );
      return [err, result];
    },

    GET_CLIENT: async ({ commit }, CustomerId) => {
      let result;
      result = await to(api.getCustomer(CustomerId));

      return result;
    },

    GET_CLIENTLOGS: async ({ commit }, customerId) => {
      let err, result;
      [err, result] = await to(api.getCustomerLogs(customerId));

      if (result) {
        commit(types.SET_CLIENTLOGS, result.Data);
      }
    },

    DELETEEMAIL: async ({ commit }, payload) => {
      let data;
      if (payload.CoCustomerId > 0 && payload.CustomerId > 0) {
        data = {
          Type: "deleteCoCustomerEmail",
          Data: {
            EmailId: payload.Id,
            CoCustomerId: payload.CoCustomerId,
          },
        };
      } else if (payload.CustomerId > 0) {
        data = {
          Type: "deleteEmail",
          Data: {
            EmailId: payload.Id,
            CustomerId: payload.CustomerId,
          },
        };
      }

      let result = await to(api.deleteEmail(data));
      return result;
    },
    DELETEPHONE: async ({ commit }, payload) => {
      let data;

      if (payload.CoCustomerId > 0 && payload.CustomerId > 0) {
        data = {
          Type: "deleteCoCustomerPhone",
          Data: {
            PhoneId: payload.Id,
            CoCustomerId: payload.CoCustomerId,
          },
        };
      } else if (payload.CustomerId > 0) {
        data = {
          Type: "deletePhone",
          Data: {
            PhoneId: payload.Id,
            CustomerId: payload.CustomerId,
          },
        };
      }
      let result = await to(api.deletePhone(data));
      return result;
    },

    GETASSIGNEDAGENTS: async ({ commit, state }, agentId) => {
      let err, result;

      [err, result] = await to(api.getAssignedAgents(agentId));
      if (result) {
        return result;
      }
    },
    UPDATE_CLIENT: async ({ commit }, payload) => {
      let result = await to(api.updateCustomer(payload));
      return result;
    },
    INSERT_NEW_CLIENT: async ({ commit }, payload) => {
      return await to(api.insertCustomer(payload));
    },
    GET_CALL_LOGS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCallLogs(payload));

      if (result) {
        return result;
      }
    },
    GET_CAMPAIGNS: async ({ commit }, Id) => {
      let err, result;

      [err, result] = await to(api.getCampaignsWithDisabled(Id));

      if (result) {
        return result;
      }
    },

    RESET_STATES: async ({ commit }) => {
      commit(types.SET_RESET_STATES);
    },
    GET_CUSTOMERDOCUMENTS: async ({ commit, state }) => {
      if (state.customerDocuments.length > 0) return;

      let err, result;

      [err, result] = await to(api.getCustomerDocumentTemplates());
      if (result) {
        commit("SET_CUSTOMERDOCUMENTS", result.Data);
      }
    },
    GENERATE_CUSTOMER_TEMPLATE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(downloadApi.generateCustomerTemplate(payload));
      return [err, result];
    },
    GET_CUSTOMERDOCUMENTLIST: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCustomerDocumentList(payload));

      return [err, result];
    },
    REFRESH_CUSTOMERDOCUMENTLIST: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCustomerDocumentList(payload));
      if (result) {
        commit("SET_CUSTOMERDOCUMENTLIST", result.Data);
      }
    },

    DOWNLOAD_CUSTOMER_DOCUMENT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(downloadApi.downloadCustomerDocument(payload));
      return [err, result];
    },
    DELETE_CUSTOMER_DOCUMENT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.deleteCustomerDocument(payload));
      return [err, result];
    },
    RENAME_CUSTOMER_DOCUMENT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.renameCustomerDocument(payload));
      return [err, result];
    },
    UPLOAD_EXTRA_DOCUMENT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.uploadExtraDocuments(payload));
      return [err, result];
    },

    VALIDATE_ACCOUNT_NUMBER: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.accountNumberValidation(payload));
      return [err, result];
    },


    CREATE_CLIENT_INFO_CHANGES: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.gcsTabClinetInfoCreate(payload));
      return [err, result];
    },
    CREATE_DOCUMENT_REVIEW_CHANGES: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.gcsTabDocReviewCreate(payload));
      return [err, result];
    },

  },
  mutations: {

    SETFAV: (state, payload) => {
      state.favorites = payload;
    },
    SET_CLIENT_CREDITORS: (state, payload) => {
      state.clientCreditors = payload;
    },
    SET_RESET_STATES: (state) => {
      state.clientCreditors = [];
      state.customerDocuments = [];

    },
    SET_CUSTOMERDOCUMENTS: (state, payload) => {
      state.customerDocuments = payload;
    },

  },
};