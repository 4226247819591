<template>
  <div style="position: relative; z-index: 401">
    <div
      style="width: 100%; height: 100%; position: absolute"
      v-show="isScriptPopupOpen"
    >
      <div :style="scriptStyle" class="scriptPopupMoveable">
        <ScriptTab
          v-if="isScriptPopupOpen && scriptPopupList.length > 0"
          :filteredScripts="scriptPopupList"
          :selectedScript="scriptPopupSelected"
          :isPopup="true"
          @popupOpen="isScriptPopupOpen = $event"
        />
      </div>

      <Moveable v-bind="moveable" @drag="handleDrag" />
    </div>

    <div
      class="navbar-custom"
      :style="isMobile || !isLeftMenuOpen ? 'left:0px; ' : 'left:240px; '"
      style="margin-left: 2px; z-index: 999"
    >
      <div
        v-if="!isLeftMenuOpen"
        class="logoWrapper"
        style="
          max-width: 240px;
          float: left;
          background-color: white;
          border: none !important;
        "
      >
        <img :src="companyLogo" class="logoImage" />
        <div class="companyNameWrapper">
          <div class="companyName">{{ maxNchars(CompanyName, 40) }}</div>
          <div class="userName">
            {{ maxNchars(firstName + " " + lastName, 30) }}
          </div>
        </div>
      </div>

      <div
        v-if="!isMobile"
        style="margin-left: -20px; cursor: pointer !important"
        class="menuIcon"
        @click="expandCollapseLeftMenu()"
      >
        <i v-if="!isLeftMenuOpen" class="ri-menu-fill"></i>
        <i v-if="isLeftMenuOpen" class="ri-arrow-drop-left-line"></i>

        <i
          v-if="isLeftMenuOpen"
          class="ri-menu-fill"
          style="margin-left: -10px"
        ></i>
      </div>

      <div
        class="menuIcon"
        v-if="isMobile"
        @click="() => (showMenu = !showMenu)"
      >
        <i class="ri-menu-fill"></i>
      </div>

      <ul class="list-unstyled topnav-menu float-right mb-0">
        <li v-if="showPhone && userLoggedIn && !isMobile">
          <div
            @contextmenu.prevent="rightclick()"
            @click="
              () => {
                showPhonePanel = !showPhonePanel;
              }
            "
            style="width: auto; background-color: #3bafda"
            :class="
              activeCallStatus && !showPhonePanel
                ? 'navbarBtn waves-effect btn-link btn pulse'
                : 'navbarBtn waves-effect btn-link btn'
            "
          >
            <div class="navbarBtnIcon mr-1">
              <i
                style="line-height: 0px; color: white"
                class="fe-phone noti-icon"
              ></i>
            </div>

            <div
              id="expandCollapsePhoneButton"
              class="navbarBtnContent text-white"
            >
              {{ showPhonePanel ? "Collapse Dial Panel" : "Expand Dial Panel" }}
            </div>
          </div>
        </li>
        <li
          v-if="!isMobile"
          class="dropdown notification-list"
          v-click-outside="hideNotificationPanel"
        >
          <div class="navbarBtnWrapper">
            <div
              @contextmenu.prevent="rightclick()"
              @click="toggleNotifications()"
              class="navbarBtn btn btn-link"
            >
              <div class="navbarBtnIcon">
                <i class="fe-bell noti-icon"></i>
              </div>
              <div class="navbarBtnContent text-white">
                <span
                  class="badge rounded-circle noti-icon-badge"
                  :class="
                    sortedNotifications.length > 0
                      ? 'badge-danger '
                      : 'badge-gray '
                  "
                  >{{ sortedNotifications.length }}</span
                >
              </div>
            </div>
            <div
              ref="notificationPanel"
              class="dropdown-menu dropdown-menu-right dropdown-lg show"
              style="
                width: 400px !important;
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-269px, 70px, 0px);
              "
              x-placement="bottom-end"
              v-show="isNotifyPanelOpen"
            >
              <div class="dropdown-item noti-title">
                <h5 class="m-0">Notifications</h5>
              </div>
              <div class="slimScrollDiv" v-if="sortedNotifications.length > 0">
                <perfect-scrollbar
                  :style="'height: ' + notificationScrollBarHeight + 'px'"
                >
                  <template
                    :key="index"
                    v-for="(item, index) in sortedNotifications"
                  >
                    <NotificationItem
                      :index="index"
                      :value="item"
                      :canCallCustomer="showPhone"
                    ></NotificationItem>
                  </template>
                </perfect-scrollbar>
              </div>

              <div
                v-if="sortedNotifications.length == 0"
                class="text-center noNotificationPanel"
              >
                No Pending Notifications
              </div>
              <!-- All-->
              <a
                @contextmenu.prevent="rightclick()"
                @click="showCalendar()"
                class="dropdown-item text-center text-primary notify-item notify-all"
                style="cursor: pointer"
              >
                View all notifications
                <i class="fi-arrow-right"></i>
              </a>
            </div>
          </div>
        </li>
        <li
          v-if="!isMobile && showPhone"
          class="dropdown notification-list"
          v-click-outside="hideVoiceMailNotifications"
        >
          <div class="navbarBtnWrapper">
            <div
              @click="toggleVoiceMailNotifications()"
              @contextmenu.prevent="rightclick()"
              data-toggle="dropdown"
              class="navbarBtn btn btn-link"
            >
              <div class="navbarBtnIcon">
                <i class="fe-voicemail noti-icon"></i>
              </div>
              <div class="navbarBtnContent text-white">
                <span
                  class="badge noti-icon-badge"
                  :class="
                    voiceMailNotifications.length > 0
                      ? 'badge-danger '
                      : 'badge-gray '
                  "
                  >{{ voiceMailNotifications.length }}</span
                >
              </div>
            </div>
            <div
              class="dropdown-menu dropdown-menu-right dropdown-lg show"
              style="
                width: 400px !important;
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-269px, 70px, 0px);
              "
              x-placement="bottom-end"
              v-show="isVoiceMailPanelOpen"
            >
              <!-- item-->
              <div class="dropdown-item noti-title">
                <h5 class="m-0">Voicemail Notifications</h5>
              </div>
              <div
                class="slimScrollDiv"
                v-if="voiceMailNotifications.length > 0"
              >
                <perfect-scrollbar
                  :style="'height: ' + notificationScrollBarHeight + 'px'"
                >
                  <template
                    :key="index"
                    v-for="(item, index) in voiceMailNotifications"
                  >
                    <NotificationItem
                      :index="index"
                      :value="item"
                      :canCallCustomer="showPhone"
                    ></NotificationItem>
                  </template>
                </perfect-scrollbar>
              </div>
              <div
                v-if="voiceMailNotifications.length == 0"
                class="text-center noNotificationPanel"
              >
                No Pending Notifications
              </div>
              <!-- All-->
              <a
                @contextmenu.prevent="rightclick()"
                @click="showCalendar()"
                class="dropdown-item text-center text-primary notify-item notify-all"
                style="cursor: pointer"
              >
                View all notifications
                <i class="fi-arrow-right"></i>
              </a>
            </div>
          </div>
        </li>

        <li
          v-if="!isMobile"
          class="dropdown notification-list"
          v-click-outside="hideSmsNotificationPanel"
        >
          <div class="navbarBtnWrapper">
            <div
              @contextmenu.prevent="rightclick()"
              @click="toggleSmsNotifications()"
              class="navbarBtn btn btn-link"
            >
              <div class="navbarBtnIcon">
                <i class="far fa-comment noti-icon"></i>
              </div>
              <div class="navbarBtnContent text-white">
                <span
                  class="badge rounded-circle noti-icon-badge"
                  :class="
                    smsNotifications.length > 0
                      ? 'badge-danger '
                      : 'badge-gray '
                  "
                  >{{ smsNotifications.length }}</span
                >
              </div>
            </div>
            <div
              ref="smsPanel"
              class="dropdown-menu dropdown-menu-right dropdown-lg show"
              style="
                width: 400px !important;
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-269px, 70px, 0px);
              "
              x-placement="bottom-end"
              v-show="isSmsPanelOpen"
            >
              <!-- item-->
              <div class="dropdown-item noti-title">
                <h5 class="m-0">Sms Notifications</h5>
              </div>
              <div class="slimScrollDiv" v-if="smsNotifications.length > 0">
                <perfect-scrollbar
                  :style="'height: ' + notificationScrollBarHeight + 'px'"
                >
                  <template
                    :key="index"
                    v-for="(item, index) in smsNotifications"
                  >
                    <NotificationItem
                      :index="index"
                      :value="item"
                      :canCallCustomer="showPhone"
                    ></NotificationItem>
                  </template>
                </perfect-scrollbar>
              </div>
              <div
                v-if="smsNotifications.length == 0"
                class="text-center noNotificationPanel"
              >
                No Pending Notifications
              </div>
              <a
                @contextmenu.prevent="rightclick()"
                @click="showCalendar()"
                class="dropdown-item text-center text-primary notify-item notify-all"
                style="cursor: pointer"
              >
                View all notifications
                <i class="fi-arrow-right"></i>
              </a>
            </div>
          </div>
        </li>
        <li
          v-if="pendingDispoCustomerId > 0 && checkAuth(1865)"
          class="dropdown notification-list"
        >
          <div
            class="navbarBtnWrapper text-center"
            @click="
              handleViewCustomer({
                customerId: pendingDispoCustomerId,
                customerName: 'Pending Dsiposition',
              })
            "
          >
            <div title="Pending Disposition" class="navbarBtn btn btn-link">
              <div
                class="navbarBtnIcon"
                style="float: none; display: inline-block"
              >
                <i class="ri-alert-fill noti-icon text-danger blink"></i>
              </div>
            </div>
          </div>
        </li>

        <!-- <li>
          <div class="navbarBtnWrapper">
            <div
              @click="this.$Emitter.emit('SendDataSyncMessage');"
              style="width: 200px; background-color: green; color: white"
              class="navbarBtn btn btn-link btn-primary"
            >
              Send Bus Message
            </div>
          </div>
        </li> -->
        <!-- <li>
          <div class="navbarBtnWrapper">
            <div
              @click="() => { this.$Emitter.emit('SendLeadDistro'); }"
        
              class="btn btn-info"
            >
              Lead Distro Message
            </div>
          </div>
        </li> -->

        <li v-if="!isMobile">
          <div
            class="navbarBtnWrapper"
            style="position: relative"
            v-click-outside="hidePauseOptionMenu"
          >
            <div
              @contextmenu.prevent="rightclick()"
              @click="setOnlineStatus()"
              style="width: 90px"
              class="navbarBtn btn btn-link"
              :class="isUserOnline ? 'onlineButton' : 'awayButton'"
            >
              <div class="navbarBtnIcon">
                <i
                  v-if="isUserOnline"
                  class="fas fa-check statusIcon"
                  style="color: #fff"
                ></i>
                <i
                  v-if="!isUserOnline"
                  class="fas fa-clock statusIcon"
                  style="color: #fff"
                ></i>
              </div>
              <div class="navbarBtnContent text-white ml-1">
                {{ isUserOnline ? "Online" : "Away" }}
              </div>
            </div>
            <div
              @contextmenu.prevent="rightclick()"
              class="dropdown-menu dropdown-menu-right dropdown-sm show"
              style="
                width: 150px !important;
                min-width: 60px !important;
                padding: 2px;
                background-color: #e9e9e9;
                cursor: pointer;
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 15px;
                transform: translate3d(-269px, 70px, 0px);
                text-align: center;
              "
              x-placement="bottom-end"
              v-show="isPauseOptionsOpen"
            >
              <perfect-scrollbar
                style="max-height: 150px; padding: 5px 10px 5px 5px"
              >
                <span
                  v-for="item in sortedPauseReasonTypes"
                  :key="item.Id"
                  class="pauseOptionItem"
                  v-on:click.prevent.stop="setUserAway(item.Id)"
                  >{{ item.Name }}</span
                >
              </perfect-scrollbar>
            </div>
          </div>
        </li>
        <li class="dropdown notification-list">
          <div class="navbarBtnWrapper">
            <div
              class="navbarBtn btn btn-link"
              @click="toggleUserMenu()"
              @contextmenu.prevent="rightclick()"
              v-click-outside="hideUserMenu"
            >
              <img
                :src="this.photoUrl"
                alt="user-image"
                @error="imageLoadOnError()"
                class="rounded-circle"
                style="width: 22px; height: 22px; float: left"
              />

              <div class="navbarBtnContent ml-1">
                {{ maxNchars(screenName, 15) }}
              </div>
              <div class="navbarBtnIcon ml-1">
                <i class="ri-arrow-down-s-fill"></i>
              </div>
            </div>
            <div
              class="dropdown-menu dropdown-menu-right dropdown-sm show"
              style="
                width: 180px !important;
                min-width: 60px !important;
                padding: 10px;
                background-color: #ffffff;
                border-color: #e9e9e9;
                cursor: pointer;
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-269px, 70px, 0px);
                text-align: center;
              "
              x-placement="bottom-end"
              v-show="isUserMenuOpen"
            >
              <button
                class="col-md-12 mb-1 btn btn-light"
                style="width: 100%; display: block"
                @click="openChangePasswordPopUp"
              >
                Change Password
              </button>
              <button
                class="col-md-12 btn btn-light"
                style="width: 100%; display: block"
                @click="handleLogout"
              >
                Logout
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="left-side-menu" v-if="showMenu && isLeftMenuOpen">
      <div :style="isMobile ? 'width:100%; top:72px;  ' : 'width:240px; '">
        <div v-if="!isMobile" class="logoWrapper">
          <img :src="companyLogo" class="logoImage" />
          <div class="companyNameWrapper">
            <div class="companyName">{{ maxNchars(CompanyName, 40) }}</div>
            <div class="userName">
              {{ maxNchars(firstName + " " + lastName, 30) }}
            </div>
          </div>
        </div>

        <div class="slimscroll-menu">
          <perfect-scrollbar
            style="width: 240px"
            :style="'height:' + navbarScrollHeight + 'px'"
          >
            <div
              id="sidebar-menu"
              :class="isMobile ? 'mobile-sidebar-menu' : ''"
            >
              <ul class="metismenu" id="side-menu">
                <li class="menu-title">
                  NAVIGATION
                  <span style="float: right">VERSION {{ version }}</span>
                </li>
                <li>
                  <router-link
                    v-slot="{}"
                    to="/dashboard"
                    class="waves-effect"
                    href="javascript:void(0)"
                  >
                    <span
                      class="navigationItemWrapper"
                      @click="
                        () => {
                          handleOtherNav();
                          anotherComponent('Dashboard');
                        }
                      "
                    >
                      <i class="ri-home-fill navigationItemIcon"></i>
                      <span class="navigationItemText">Dashboard</span>
                    </span>
                  </router-link>
                </li>

                <li :class="[isCustomerActive && 'router-link-active']">
                  <a
                    @contextmenu.prevent="rightclick()"
                    class="waves-effect navigationItemWrapper"
                    @click="
                      () => {
                        handleCustomers();
                      }
                    "
                  >
                    <i class="ri-user-fill navigationItemIcon"></i>
                    <span class="navigationItemText">Customers</span>
                  </a>
                </li>
                <li
                  :class="isFavActive ? 'mm-active' : ''"
                  style="color: #6c757d"
                >
                  <a
                    @contextmenu.prevent="rightclick()"
                    class="waves-effect navigationItemWrapper"
                    aria-expanded="true"
                    @click="
                      () => {
                        isFavActive = !isFavActive;
                      }
                    "
                  >
                    <div class="navCategoryColor">
                      <i class="ri-star-fill navigationItemIcon"></i>
                      <span class="navigationItemText">Favorites</span>
                      <span style="margin-top: -3px" class="menu-arrow"></span>
                    </div>
                  </a>

                  <ul
                    aria-expanded="true"
                    :class="
                      isFavActive
                        ? 'nav-second-level mm-collapse mm-show'
                        : 'nav-second-level mm-collapse'
                    "
                  >
                    <li
                      v-for="item in favorites"
                      :key="item.CustomerId"
                      style="height: 28px; cursor: pointer"
                    >
                      <a
                        :class="
                          item.CustomerId == activeCustomerId
                            ? 'router-link-active'
                            : ''
                        "
                        @contextmenu.prevent="rightclick()"
                        @click="
                          handleOutsideCustomers(
                            item.CustomerId,
                            item.FirstName + ' ' + item.LastName,
                            'favorites'
                          )
                        "
                        class="navigationItemWrapper"
                      >
                        <div class="navCategoryColor">
                          <i class="ri-user-fill navigationItemIcon"></i>
                          <span class="navigationItemText">
                            {{
                              maxNchars(
                                item.FirstName + " " + item.LastName,
                                19
                              )
                            }}
                          </span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
                <li :class="isHistoryActive ? 'mm-active' : ''">
                  <a
                    class="waves-effect navigationItemWrapper"
                    aria-expanded="true"
                    @click="
                      () => {
                        isHistoryActive = !isHistoryActive;
                      }
                    "
                  >
                    <div class="navCategoryColor">
                      <i class="ri-time-fill navigationItemIcon"></i>
                      <span class="navigationItemText">History</span>
                      <span style="margin-top: -3px" class="menu-arrow"></span>
                    </div>
                  </a>
                  <ul
                    aria-expanded="true"
                    :class="
                      isHistoryActive
                        ? 'nav-second-level mm-collapse mm-show'
                        : 'nav-second-level mm-collapse'
                    "
                  >
                    <li
                      v-for="item in revertedHistory"
                      :key="item.id"
                      style="height: 28px"
                    >
                      <a
                        @contextmenu.prevent="rightclick()"
                        href="javascript:void(0)"
                        @click="
                          handleOutsideCustomers(item.Id, item.Name, 'history')
                        "
                        class="navigationItemWrapper"
                        :class="
                          item.CustomerId == activeCustomerIdHistory
                            ? 'router-link-active'
                            : ''
                        "
                      >
                        <i class="ri-user-fill navigationItemIcon"></i>
                        <span class="navigationItemText">{{
                          maxNchars(item.Name, 19)
                        }}</span>
                      </a>
                    </li>
                  </ul>
                </li>

                <li :class="isTasksActive ? 'mm-active' : ''" v-showTasksModule>
                  <a
                    v-if="!isMobile"
                    @contextmenu.prevent="rightclick()"
                    class="waves-effect navigationItemWrapper"
                    aria-expanded="true"
                    @click="
                      () => {
                        isTasksActive = !isTasksActive;
                      }
                    "
                  >
                    <div class="navCategoryColor">
                      <i class="fas fa-tasks navigationItemIcon"></i>
                      <span class="navigationItemText">Tasks</span>

                      <span style="margin-top: -3px" class="menu-arrow"></span>
                    </div>
                  </a>

                  <ul
                    v-if="!isMobile"
                    aria-expanded="true"
                    :class="
                      isTasksActive
                        ? 'nav-second-level mm-collapse mm-show'
                        : 'nav-second-level mm-collapse'
                    "
                  >
                    <router-link
                      v-slot="{ navigate }"
                      to="/csr-tasks"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'10022'">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('CSRTasks');
                                navigate;
                              }
                            "
                          >
                            <i class="ri-align-left navigationItemIcon"></i>
                            <span class="navigationItemText">Csr Tasks</span>
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <!-- <li v-showIfAuthorized="'200010'"> -->
                    <li v-showIfAuthorized="'200010'">
                      <router-link
                        v-slot="{ navigate }"
                        to="/csr-tickets"
                        class="waves-effect"
                        href="javascript:void(0)"
                      >
                        <span
                          class="navigationItemWrapper"
                          @click="
                            () => {
                              handleOtherNav();
                              anotherComponent('CsrTickets');
                              navigate;
                            }
                          "
                        >
                          <div class="navCategoryColor">
                            <i class="ri-coupon-3-fill navigationItemIcon"></i>
                            <span class="navigationItemText">Tickets</span>
                          </div>
                        </span>
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li>
                  <router-link
                    v-slot="{ navigate }"
                    to="/vm-recorder-management"
                    class="waves-effect"
                    href="javascript:void(0)"
                  >
                    <span
                      class="navigationItemWrapper"
                      @click="
                        () => {
                          handleOtherNav();
                          anotherComponent('VMRecorderManagement');
                          navigate;
                        }
                      "
                    >
                      <div class="navCategoryColor">
                        <i class="fas fa-microphone navigationItemIcon"></i>
                        <span class="navigationItemText">VM Recorder</span>
                      </div>
                    </span>
                  </router-link>
                </li>
                <li :class="isReportsActive ? 'mm-active' : ''" v-showReports>
                  <a
                    @contextmenu.prevent="rightclick()"
                    class="waves-effect navigationItemWrapper"
                    aria-expanded="true"
                    @click="
                      () => {
                        isReportsActive = !isReportsActive;
                      }
                    "
                  >
                    <div class="navCategoryColor">
                      <i class="mdi mdi-file-search navigationItemIcon"></i>
                      <span class="navigationItemText">Reports</span>

                      <span style="margin-top: -3px" class="menu-arrow"></span>
                    </div>
                  </a>

                  <ul
                    aria-expanded="true"
                    :class="
                      isReportsActive
                        ? 'nav-second-level mm-collapse mm-show'
                        : 'nav-second-level mm-collapse'
                    "
                  >
                    <div class="col-md-12">
                      <input
                        autocomplete="off"
                        type="text"
                        class="filterReportInput"
                        placeholder="Search"
                        v-model="reportsFilter"
                      />
                      <button class="fas fa-search searchIcon"></button>
                    </div>
                    <router-link
                      v-slot="{ navigate }"
                      :to="item.Path"
                      href="javascript:void(0)"
                      :key="item.Name"
                      v-for="item in filteredReports"
                    >
                      <li v-showIfAuthorized="item.AuthId.toString()">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent(item.Name);
                                navigate;
                              }
                            "
                          >
                            <i class="bx bxs-report navigationItemIcon"></i>
                            <span class="navigationReportsText">{{
                              item.Label
                            }}</span>
                          </span>
                        </a>
                      </li>
                    </router-link>
                  </ul>
                </li>

                <li
                  style="
                    width: 100%;
                    float: left;
                    margin-top: 10px;
                    border-top: 1px solid #e6e6e6;
                  "
                  class="menu-title"
                  v-showAdminText
                >
                  ADMIN ACTIONS
                </li>
                <li
                  :class="isCompanySettingsActive ? 'mm-active' : ''"
                  v-showCompanySettings
                >
                  <a
                    @contextmenu.prevent="rightclick()"
                    class="waves-effect navigationItemWrapper"
                    aria-expanded="true"
                    @click="
                      () => {
                        isCompanySettingsActive = !isCompanySettingsActive;
                      }
                    "
                  >
                    <div class="navCategoryColor">
                      <i class="ri-home-gear-fill navigationItemIcon"></i>
                      <span class="navigationItemText">Company Settings</span>

                      <span style="margin-top: -3px" class="menu-arrow"></span>
                    </div>
                  </a>

                  <ul
                    aria-expanded="true"
                    :class="
                      isCompanySettingsActive
                        ? 'nav-second-level mm-collapse mm-show'
                        : 'nav-second-level mm-collapse'
                    "
                  >
                    <!-- <router-link
                      v-slot="{ navigate }"
                      to="/call-settings-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'3901'">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('CallSettingsManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="mdi mdi-phone-settings navigationItemIcon"
                            ></i>
                            <span class="navigationItemText"
                              >Call Settings</span
                            >
                          </span>
                        </a>
                      </li>
                    </router-link> -->

                    <router-link
                      v-slot="{ navigate }"
                      to="/api-link-generator-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'677991'">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('ApiLinkGeneratorManagement');
                                navigate;
                              }
                            "
                          >
                            <i class="ri-links-line navigationItemIcon"></i>
                            <span class="navigationItemText"
                              >Api Link Generator</span
                            >
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <router-link
                      v-slot="{ navigate }"
                      to="/campaign-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'2774'">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('CampaignManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-price-tag-3-fill navigationItemIcon"
                            ></i>
                            <span class="navigationItemText">Campaigns</span>
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <router-link
                      v-slot="{ navigate }"
                      to="/lead-distro-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'8361'">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('LeadDistributionManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-traffic-light-line navigationItemIcon"
                            ></i>
                            <span class="navigationItemText"
                              >Lead Distribution</span
                            >
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <router-link
                      v-slot="{ navigate }"
                      to="/office-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'1453'">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('OfficeManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-building-4-fill navigationItemIcon"
                            ></i>
                            <span class="navigationItemText">Offices</span>
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <router-link
                      v-slot="{ navigate }"
                      to="/ui-settings-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'921085'">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('UISettingsManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-file-settings-fill navigationItemIcon"
                            ></i>
                            <span class="navigationItemText">UI Settings</span>
                          </span>
                        </a>
                      </li>
                    </router-link>
                  </ul>
                </li>
                <li
                  :class="isTaskManagementActive ? 'mm-active' : ''"
                  v-if="checkAuth(8913) || checkAuth(8914)"
                >
                  <a
                    @contextmenu.prevent="rightclick()"
                    class="waves-effect navigationItemWrapper"
                    aria-expanded="true"
                    @click="
                      () => {
                        isTaskManagementActive = !isTaskManagementActive;
                      }
                    "
                  >
                    <div class="navCategoryColor">
                      <i class="fas fa-tasks navigationItemIcon"></i>
                      <span class="navigationItemText">CSR Tasks</span>
                      <span style="margin-top: -3px" class="menu-arrow"></span>
                    </div>
                  </a>
                  <ul
                    aria-expanded="true"
                    :class="
                      isTaskManagementActive
                        ? 'nav-second-level mm-collapse mm-show'
                        : 'nav-second-level mm-collapse'
                    "
                  >
                    <router-link
                      v-slot="{ navigate }"
                      to="/csr-task-action-management"
                      href="javascript:void(0)"
                    >
                      <li v-if="checkAuth(8913) || checkAuth(8914)">
                        <a
                          class="waves-effect navigationItemWrapper"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('CSRTaskActionManagement');
                                navigate;
                              }
                            "
                          >
                            <i class="ri-task-fill navigationItemIcon"></i>
                            <span class="navigationItemText">Task Actions</span>
                          </span>
                        </a>
                      </li>
                    </router-link>
                    <router-link
                      v-slot="{ navigate }"
                      to="/csr-task-type-management"
                      href="javascript:void(0)"
                    >
                      <li v-if="checkAuth(8913) || checkAuth(8914)">
                        <a
                          class="waves-effect navigationItemWrapper"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('CSRTaskTypeManagement');
                                navigate;
                              }
                            "
                          >
                            <i class="bx bx-task navigationItemIcon"></i>
                            <span class="navigationItemText">Task Types</span>
                          </span>
                        </a>
                      </li>
                    </router-link>
                  </ul>
                </li>
                <li
                  :class="isClientSettingsActive ? 'mm-active' : ''"
                  v-showClientSettings
                >
                  <a
                    @contextmenu.prevent="rightclick()"
                    class="waves-effect navigationItemWrapper"
                    aria-expanded="true"
                    @click="
                      () => {
                        isClientSettingsActive = !isClientSettingsActive;
                      }
                    "
                  >
                    <div class="navCategoryColor">
                      <i class="fas fa-user-cog navigationItemIcon"></i>
                      <span class="navigationItemText">Customer Settings</span>
                      <span style="margin-top: -3px" class="menu-arrow"></span>
                    </div>
                  </a>
                  <ul
                    aria-expanded="true"
                    :class="
                      isClientSettingsActive
                        ? 'nav-second-level mm-collapse mm-show'
                        : 'nav-second-level mm-collapse'
                    "
                  >
                    <router-link
                      v-slot="{ navigate }"
                      to="/customer-cancelation-reasons-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'55840'">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent(
                                  'CustomerCancelationReasonsManagement'
                                );
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-mail-close-fill navigationItemIcon"
                            ></i>
                            <span class="navigationItemText"
                              >Cancelation Reasons</span
                            >
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <router-link
                      v-slot="{ navigate }"
                      to="/disposition-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'1096'">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('DispositionManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-sound-module-fill navigationItemIcon"
                            ></i>
                            <span class="navigationItemText">Dispositions</span>
                          </span>
                        </a>
                      </li>
                    </router-link>
                    <router-link
                      v-slot="{ navigate }"
                      to="/drip-email-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'1588'">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('DripEmailManagement');
                                navigate;
                              }
                            "
                          >
                            <i class="ri-mail-send-fill navigationItemIcon"></i>
                            <span class="navigationItemText">Drip Email</span>
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <router-link
                      v-slot="{ navigate }"
                      to="/quick-email-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'5146'">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('QuickEmailManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-mail-settings-fill navigationItemIcon"
                            ></i>
                            <span class="navigationItemText">Quick Emails</span>
                          </span>
                        </a>
                      </li>
                    </router-link>
                    <router-link
                      v-slot="{ navigate }"
                      to="/quick-note-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'3432'">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('QuickNoteManagement');
                                navigate;
                              }
                            "
                          >
                            <i class="ri-file-4-fill navigationItemIcon"></i>
                            <span class="navigationItemText">Quick Notes</span>
                          </span>
                        </a>
                      </li>
                    </router-link>
                    <router-link
                      v-slot="{ navigate }"
                      to="/quick-sms-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'1580'">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('QuickSmsManagement');
                                navigate;
                              }
                            "
                          >
                            <i class="ri-chat-1-fill navigationItemIcon"></i>
                            <span class="navigationItemText">Quick Sms</span>
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <router-link
                      v-slot="{ navigate }"
                      to="/status-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'952'">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('StatusManagement');
                                navigate;
                              }
                            "
                          >
                            <i class="mdi mdi-sync navigationItemIcon"></i>
                            <span class="navigationItemText">Statuses</span>
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <router-link
                      v-slot="{ navigate }"
                      to="/tag-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'64326'">
                        <a
                          class="navigationItemWrapper waves-effect"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('TagManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-price-tag-3-fill navigationItemIcon"
                            ></i>
                            <span class="navigationItemText">Tags</span>
                          </span>
                        </a>
                      </li>
                    </router-link>
                  </ul>
                </li>
                <li
                  :class="isEmployeeSettingsActive ? 'mm-active' : ''"
                  v-showEmployeeSettings
                >
                  <a
                    @contextmenu.prevent="rightclick()"
                    class="waves-effect navigationItemWrapper"
                    aria-expanded="true"
                    @click="
                      () => {
                        isEmployeeSettingsActive = !isEmployeeSettingsActive;
                      }
                    "
                  >
                    <div class="navCategoryColor">
                      <i class="fas fa-users-cog navigationItemIcon"></i>
                      <span class="navigationItemText">Employee Settings</span>
                      <span style="margin-top: -3px" class="menu-arrow"></span>
                    </div>
                  </a>
                  <ul
                    aria-expanded="true"
                    :class="
                      isEmployeeSettingsActive
                        ? 'nav-second-level mm-collapse mm-show'
                        : 'nav-second-level mm-collapse'
                    "
                  >
                    <router-link
                      v-slot="{ navigate }"
                      to="/employee-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'9542'">
                        <a
                          class="waves-effect navigationItemWrapper"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('EmployeeManagement');
                                navigate;
                              }
                            "
                          >
                            <i class="ri-user-2-fill navigationItemIcon"></i>
                            <span class="navigationItemText">Employees</span>
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <router-link
                      v-slot="{ navigate }"
                      to="/pause-reason-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'1662'">
                        <a
                          class="waves-effect navigationItemWrapper"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('PauseReasonManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="far fa-pause-circle navigationItemIcon"
                            ></i>
                            <span class="navigationItemText"
                              >Pause Reasons</span
                            >
                          </span>
                        </a>
                      </li>
                    </router-link>
                    <router-link
                      v-slot="{ navigate }"
                      to="/roles-management"
                      href="javascript:void(0)"
                    >
                      <li v-show="checkAuth(6580) || checkAuth(6581)">
                        <a
                          class="waves-effect navigationItemWrapper"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('RolesManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="mdi mdi-shield-lock-outline navigationItemIcon"
                            ></i>
                            <span class="navigationItemText">Roles</span>
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <router-link
                      v-slot="{ navigate }"
                      to="/script-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'1496'">
                        <a
                          class="waves-effect navigationItemWrapper"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('ScriptManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-file-paper-fill navigationItemIcon"
                            ></i>
                            <span class="navigationItemText">Scripts</span>
                          </span>
                        </a>
                      </li>
                    </router-link>
                    <router-link
                      v-slot="{ navigate }"
                      to="/vm-drop-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'3841'">
                        <a
                          class="waves-effect navigationItemWrapper"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('VMDropManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-record-mail-line navigationItemIcon"
                            ></i>
                            <span class="navigationItemText">VM Templates</span>
                          </span>
                        </a>
                      </li>
                    </router-link>
                  </ul>
                </li>
                <li
                  :class="isPhoneSettingsActive ? 'mm-active' : ''"
                  v-showPhoneSettings
                >
                  <a
                    @contextmenu.prevent="rightclick()"
                    class="waves-effect navigationItemWrapper"
                    aria-expanded="true"
                    @click="
                      () => {
                        isPhoneSettingsActive = !isPhoneSettingsActive;
                      }
                    "
                  >
                    <div class="navCategoryColor">
                      <i class="fas fa-phone-alt navigationItemIcon"></i>
                      <span class="navigationItemText">Phone Settings</span>
                      <span style="margin-top: -3px" class="menu-arrow"></span>
                    </div>
                  </a>
                  <ul
                    aria-expanded="true"
                    :class="
                      isPhoneSettingsActive
                        ? 'nav-second-level mm-collapse mm-show'
                        : 'nav-second-level mm-collapse'
                    "
                  >
                    <router-link
                      v-slot="{ navigate }"
                      to="/blocked-number-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'54984'">
                        <a
                          class="waves-effect navigationItemWrapper"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('BlockedNumberManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-settings-5-fill navigationItemIcon"
                            ></i>
                            <span class="navigationItemText"
                              >Blocked Numbers</span
                            >
                          </span>
                        </a>
                      </li>
                    </router-link>
                    <router-link
                      v-slot="{ navigate }"
                      to="/did-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'240'">
                        <a
                          class="waves-effect navigationItemWrapper"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('DidManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-settings-5-fill navigationItemIcon"
                            ></i>
                            <span class="navigationItemText">DIDs</span>
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <router-link
                      v-slot="{ navigate }"
                      to="/did-purchase-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'273'">
                        <a
                          class="waves-effect navigationItemWrapper"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('DidPurchaseManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-settings-5-fill navigationItemIcon"
                            ></i>
                            <span class="navigationItemText">DID Purchase</span>
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <router-link
                      v-slot="{ navigate }"
                      to="/inbound-call-rules-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'3922'">
                        <a
                          class="waves-effect navigationItemWrapper"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent('InboundCallRulesManagement');
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-settings-5-fill navigationItemIcon"
                            ></i>
                            <span class="navigationItemText"
                              >Inbound Call Rules</span
                            >
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <router-link
                      v-slot="{ navigate }"
                      to="/inbound-call-settings-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'3908'">
                        <a
                          class="waves-effect navigationItemWrapper"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent(
                                  'InboundCallSettingsManagement'
                                );
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-settings-5-fill navigationItemIcon"
                            ></i>
                            <span class="navigationItemText"
                              >Inbound Call Settings</span
                            >
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <router-link
                      v-slot="{ navigate }"
                      to="/outbound-call-settings-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'3917'">
                        <a
                          class="waves-effect navigationItemWrapper"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent(
                                  'OutboundCallSettingsManagement'
                                );
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-settings-5-fill navigationItemIcon"
                            ></i>
                            <span class="navigationItemText"
                              >Outbound Call Settings</span
                            >
                          </span>
                        </a>
                      </li>
                    </router-link>

                    <router-link
                      v-slot="{ navigate }"
                      to="/sms-brands-management"
                      href="javascript:void(0)"
                    >
                      <li v-showIfAuthorized="'3923'">
                        <a
                          class="waves-effect navigationItemWrapper"
                          @contextmenu.prevent="rightclick()"
                        >
                          <span
                            class="navigationItemWrapper buttonHover"
                            @click="
                              () => {
                                handleOtherNav();
                                anotherComponent(
                                  'OutboundCallSettingsManagement'
                                );
                                navigate;
                              }
                            "
                          >
                            <i
                              class="ri-settings-5-fill navigationItemIcon"
                            ></i>
                            <span class="navigationItemText">SMS Brands</span>
                          </span>
                        </a>
                      </li>
                    </router-link>
                  </ul>
                </li>
              </ul>
            </div>
          </perfect-scrollbar>

          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <div class="backgroundLock" v-if="isCalendarOpen">
      <div class="reminderCalendar" v-if="isCalendarOpen">
        <div class="calendarExit" @click="closeCalendar()">
          <i class="fas fa-window-close"></i>
        </div>
        <ReminderCalendar
          @getCalendarClick="getCalendarClick"
        ></ReminderCalendar>
      </div>
    </div>
    <div
      class="backgroundLock"
      style="text-align: center"
      v-if="isSmsHistoryOpen"
    >
      <div
        style="display: inline-block"
        class="smsHistoryPanel col-xl-5 col-lg-6 col-md-6 col-sm-12 noPadding mt-3"
        v-if="isSmsHistoryOpen"
      >
        <div class="closeSmsHistory" @click="closeSmsHistory()">Close</div>
        <SmsHistoryPanel
          ref="smsHistory"
          :phoneNumber="smsHistoryPhoneNumber"
          :phoneList="smsHistoryPhoneList"
          :customerId="smsHistoryCustomerId"
          :languageId="smsHistoryLanguageId"
        ></SmsHistoryPanel>
      </div>
    </div>
    <div class="row">
      <NotificationPopupWrapper
        v-if="popupNotifications.length > 0"
        :hasPhoneSystem="showPhone"
      ></NotificationPopupWrapper>
    </div>
    <div v-if="!isMobile" v-show="showPhonePanel && showPhone">
      <PlivoInterface
        ref="plivo"
        @updateShowPhone="showPhone = $event"
        @updateShowPhonePanel="showPhonePanel = $event"
        @updatePlivoLoggedIn="userLoggedIn = $event"
        @callStatusChanged="handleCallStatusChange"
      />
    </div>

    <div class="backgroundLock" v-if="isReminderGridOpen">
      <div
        style="position: relative; margin: 3% auto"
        class="offset-lg-4 col-xl-5 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <div
          v-if="!autoCloseReminders"
          class="closeButton mr-2"
          @click="closeReminderGrid()"
        >
          <i class="fas fa-window-close"></i>
        </div>
        <ReminderGrid
          :customerId="reminderCustomerId"
          :autoClose="autoCloseReminders"
          :customerPrimaryPhone="customerPrimaryPhone"
          @closeReminderPanel="closeReminderGrid()"
        ></ReminderGrid>
      </div>
    </div>
    <div v-if="dispositionCustomerId > 0" class="backgroundLock">
      <div
        style="position: relative; margin: 3% auto"
        class="offset-lg-4 col-xl-4 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <div class="reminderClose" @click="closeDispositionPopup()">
          <i class="fas fa-window-close"></i>
        </div>
        <DispositionPanel
          :customerId="dispositionCustomerId"
          :statusId="dispositionStatusId"
          :fromEdit="0"
        ></DispositionPanel>
      </div>
    </div>
    <div
      class="backgroundLock"
      v-if="isHotLeadPopupOpen"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div class="hotLeadWrapper">
        <HotLeadPopup
          :customerId="hotLeadCustomerId"
          @closeHotLeadPopup="closeHotLeadPopup"
          @handleHotLeadAccept="handleHotLeadAccept"
        ></HotLeadPopup>
      </div>
    </div>
    <div
      class="backgroundLock"
      v-if="isBrCallPopupOpen"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div class="hotLeadWrapper">
        <BrCallPopup
          :customerId="brCallCustomerId"
          :customerName="brCallCustomerName"
          @closeBrCallPopup="closeBrCallPopup"
          @handleViewCustomer="handleViewCustomer"
        ></BrCallPopup>
      </div>
    </div>
    <div
      class="backgroundLock"
      v-if="isCreditorContactFormOpen"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div
        style="position: relative; margin: 3% auto"
        class="offset-lg-4 col-xl-4 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <CreditorContactForm
          @closeCreditorContactForm="closeCreditorContactForm"
        ></CreditorContactForm>
      </div>
    </div>

    <div v-if="noteCustomerId > 0 && checkAuth(3816)" class="backgroundLock">
      <div
        style="
          position: relative;
          margin: 2% auto;
          padding-top: 30px;
          background-color: white;
          max-height: 800px;
          overflow-y: auto;
        "
        class="offset-xl-3 col-xl-9 col-lg-12 col-md-12 col-sm-12 notePanel"
      >
        <div class="reminderClose" @click="closeNotePanel()">
          <i class="fas fa-window-close"></i>
        </div>

        <NotePanel
          :customerId="noteCustomerId"
          :creditorId="noteCreditorId"
          :isPopup="true"
          :csrTaskId="noteCsrTaskId"
          :agentId="noteAgentId"
          :agentName="noteAgentName"
        ></NotePanel>
      </div>
    </div>
    <div v-if="csrTaskItemForPopup && checkAuth(10027)" class="backgroundLock">
      <ClientTaskPopUp
        :task="csrTaskItemForPopup"
        @closeTaskPopup="closeTaskPopup"
        @updateTask="updateCsrTask"
      ></ClientTaskPopUp>
    </div>
    <div class="row">
      <div
        class="backgroundLock"
        v-if="isPortalUserInterfaceOpen && portalUserInterfaceData"
      >
        <div
          style="position: relative; margin: 3% auto"
          class="offset-xl-3 col-xl-6 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-xs-12"
        >
          <PortalUserInterface
            :customer="portalUserInterfaceData.Customer"
            :customerId="portalUserInterfaceData.CustomerId"
            :customerPrimaryEmail="portalUserInterfaceData.CustomerEmail"
            :quickEmailData="portalUserInterfaceData.QuickEmail"
            :authTypeList="portalUserInterfaceData.AuthList"
            :isPortalMessage="portalUserInterfaceData.IsPortalMessage"
            :isPortalAuthMessage="portalUserInterfaceData.IsPortalAuthMessage"
            :attorneyName="portalUserInterfaceData.AttorneyName"
            @closePortalUserInterface="closePortalUserInterface"
          ></PortalUserInterface>
        </div>
      </div>
    </div>
    <div
      class="backgroundLock"
      v-if="isChangePasswordPopUp"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div
        style="position: relative; margin: 10% auto"
        class="col-xl-3 col-lg-4 col-md-5 col-sm-12"
      >
        <ChangePasswordPopUp
          @closeChangePasswordPopUp="closeChangePasswordPopUp"
          :closeButtonExpiration="closeButtonExpiration"
        >
        </ChangePasswordPopUp>
      </div>
    </div>
  </div>
</template>

<script>
import globalTypes from "@/store/types";
import plivoTypes from "@/components/PlivoParts/types";
import notificationTypes from "@/views/agent/Notification/types";
import { mapState } from "vuex";
import _orderby from "lodash";
import to from "@/helpers/to";
import api from "@/services/client/customerApi";
import NotificationItem from "@/views/agent/Notification/NotificationItem";
import ReminderCalendar from "@/views/agent/Notification/ReminderCalendar";
import NotificationPopupWrapper from "@/views/agent/Notification/NotificationPopupWrapper";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
import PlivoInterface from "@/components/PlivoInterface";
import SmsHistoryPanel from "@/views/agent/SMS/SmsHistory";
import ReminderGrid from "@/views/agent/Notification/ReminderGrid";
import HotLeadPopup from "@/components/HotLeadPopup";
import BrCallPopup from "@/components/BrCallPopup";
import DispositionPanel from "@/views/agent/Disposition/DispositionPanel";
import CreditorContactForm from "@/components/CreditorContactForm";
import NotePanel from "@/views/agent/EditClient/ClientNotes/ClientNotes";
import ClientTaskPopUp from "@/views/agent/EditClient/ClientTasks/ClientTaskPopUp";
import PortalUserInterface from "@/components/PortalUserInterface";
import Moveable from "vue3-moveable";
import ScriptTab from "@/views/agent/Scripts/Scripts";
import ChangePasswordPopUp from "@/components/ChangePasswordPopUp";
import {
  clearInterval,
  clearTimeout,
  setInterval,
  setTimeout,
} from "worker-timers";
let plivoCheckTimer = null;
const Crm_Version = process.env.VUE_APP_CRM_VERSION;
let inactivityTimerVar = null;
export default {
  name: "Navigation",

  components: {
    ChangePasswordPopUp,
    NotificationItem,
    ReminderCalendar,
    NotificationPopupWrapper,
    PlivoInterface,
    SmsHistoryPanel,
    ReminderGrid,
    HotLeadPopup,
    DispositionPanel,
    CreditorContactForm,
    NotePanel,
    ClientTaskPopUp,
    PortalUserInterface,
    Moveable,
    ScriptTab,
    BrCallPopup,
  },
  emits: ["authenticated", "openCloseMenu"],
  data() {
    return {
      pauseReasonTypeId: null,
      pendingDispoCustomerId: 0,
      isAutoDialLeadDistro: false,
      activeCallStatus: false,
      closeButtonExpiration: false,
      isChangePasswordPopUp: false,
      blockClose: false,
      isTouchDevice: false,
      reportList: [
        {
          Path: "/call-logs-reports",
          AuthId: 8113,
          Name: "CallLogsReport",
          Label: "Call Logs Report",
        },

        {
          Path: "/csr-realtime-tasks-report",
          AuthId: 7261,
          Name: "CSRRealtimeTasksReport",
          Label: "CSR Realtime Tasks Report",
        },
        {
          Path: "/csr-task-performance-report",
          AuthId: 8263,
          Name: "CSRTaskPerformanceReport",
          Label: "CSR Task Performance Report",
        },
        {
          Path: "/csr-task-report",
          AuthId: 3715,
          Name: "CSRTaskReport",
          Label: "CSR Task Report",
        },
        {
          Path: "/customer-status-reports",
          AuthId: 5366,
          Name: "CustomerStatusReport",
          Label: "Customer Status Report",
        },
        {
          Path: "/customer-tag-report",
          AuthId: 61346,
          Name: "CustomerTagReport",
          Label: "Customer Tag Report",
        },
        {
          Path: "/db-sales-compensation-report",
          AuthId: 81547,
          Name: "DebtBlueSalesCompensationReport",
          Label: "DB Sales Compensation Report",
        },

        {
          Path: "/inbound-statistics",
          AuthId: 5578,
          Name: "InboundStatisticsReport",
          Label: "Inbound Statistics Report",
        },
        {
          Path: "/incoming-vm-report",
          AuthId: 9677,
          Name: "IncomingVmReport",
          Label: "Incoming VM Report",
        },
        {
          Path: "/lead-assign-report",
          AuthId: 8162,
          Name: "LeadAssignReport",
          Label: "Lead Assign Report",
        },
        {
          Path: "/live-calls-reports",
          AuthId: 3821,
          Name: "LiveCallsReport",
          Label: "Live Calls Report",
        },
        {
          Path: "/missed-call-reports",
          AuthId: 6180,
          Name: "MissedCallReport",
          Label: "Missed Call Report",
        },

        {
          Path: "/pending-disposition-report",
          AuthId: 4721,
          Name: "PendingDispositionReport",
          Label: "Pending Disposition Report",
        },
        {
          Path: "/reminders-reports",
          AuthId: 6582,
          Name: "RemindersReport",
          Label: "Reminders Report",
        },
        {
          Path: "/sms-history-reports",
          AuthId: 7567,
          Name: "SmsHistoryReport",
          Label: "SMS History Report",
        },

        {
          Path: "/agent-phone-status-report",
          AuthId: 97696,
          Name: "AgentPhoneStatusReport",
          Label: "Agent Phone Status Report",
        },
        {
          Path: "/agent-phone-report",
          AuthId: 55417,
          Name: "AgentPhoneReport",
          Label: "Agent Phone Report",
        },

        {
          Path: "/csr-task-summary-report",
          AuthId: 8234,
          Name: "CsrTaskSummaryReport",
          Label: "Csr Task Summary Report",
        },
        {
          Path: "/customer-disposition-settings-report",
          AuthId: 5377,
          Name: "CustomerDispositionSettingsReport",
          Label: "Customer Disposition Settings Report",
        },
        {
          Path: "/csr-task-contact-schedule-report",
          AuthId: 55836,
          Name: "CSRTaskContactScheduleReport",
          Label: "CSR Task Contact Trigger Schedule Report",
        },

        {
          Path: "/csr-task-distro-queue-report",
          AuthId: 3716,
          Name: "CSRTaskDistroQueueReport",
          Label: "CSR Task Distro Queue Report",
        },
        {
          Path: "/call-metrics-report",
          AuthId: 48214,
          Name: "CallMetricsReport",
          Label: "Call Metrics Report",
        },
        {
          Path: "/cpa-agent-report",
          AuthId: 65837,
          Name: "CPAAgentReport",
          Label: "CPA Agent Report",
        },
        {
          Path: "/cpa-office-report",
          AuthId: 65838,
          Name: "CPAOfficeReport",
          Label: "CPA Office Report",
        },
        {
          Path: "/cpa-state-report",
          AuthId: 65839,
          Name: "CPAStateReport",
          Label: "CPA State Report",
        },
        {
          Path: "/cpa-pub-report",
          AuthId: 65840,
          Name: "CPAPubReport",
          Label: "CPA Pub Report",
        },
        {
          Path: "/user-time-log-report",
          AuthId: 55850,
          Name: "UserTimeLogReport",
          Label: "User Time Log Report",
        },
      ],
      reportsFilter: "",
      showMenu: false,
      isMobile: false,
      firstName: "",
      lastName: "",
      photoUrl: "",
      screenName: "",
      defaultPhotoUrl: "",
      isFavActive: false,
      isHistoryActive: false,
      isTaskManagementActive: false,
      isClientSettingsActive: false,
      isEmployeeSettingsActive: false,
      isTasksActive: false,
      isCompanySettingsActive: false,
      isPhoneSettingsActive: false,
      isReportsActive: false,
      isCustomerActive: false,
      //Notification variables
      notifyTimer: null,
      isNotifyPanelOpen: false,
      isCalendarOpen: false,
      notificationScrollBarHeight: 450,
      newNotifications: [],
      showPhone: false,
      showPhonePanel: false,
      isSmsPanelOpen: false,
      isSmsHistoryOpen: false,
      smsHistoryPhoneNumber: "",
      smsHistoryPhoneList: [],
      smsPanelRefresh: 0,
      smsHistoryCustomerId: 0,
      reminderCustomerId: 0,
      smsHistoryLanguageId: null,
      isReminderGridOpen: false,
      customerPrimaryPhone: "",
      autoCloseReminders: false,
      dispositionCustomerId: 0,
      dispositionStatusId: 0,
      isVoiceMailPanelOpen: false,
      navbarScrollHeight: 500,
      isHotLeadPopupOpen: false,
      hotLeadCustomerId: 0,
      officeId: 0,
      tabGuid: 0,
      lastHotLeadShowTime: new Date(
        new Date().setSeconds(new Date().getSeconds() - 120)
      ),
      isUserMenuOpen: false,
      userLoggedIn: false,
      CompanyName: "",
      companyLogo: "",
      hotLeadTimer: {},
      leadTier: 0,
      tierTimeOut: 0,
      myUserId: 0,
      isPauseOptionsOpen: false,
      userPhoneSettings: null,
      version: "",
      isConnectionLost: false,
      lastConnectionTime: 0,
      isCreditorContactFormOpen: false,
      activeCustomerId: 0,
      activeCustomerIdHistory: 0,
      noteCustomerId: 0,
      noteCreditorId: 0,
      noteCsrTaskId: 0,
      noteAgentId: 0,
      noteAgentName: "",
      csrTaskItemForPopup: null,
      isPortalUserInterfaceOpen: false,
      portalUserInterfaceData: null,
      customerPrimaryEmail: "",
      isScriptPopupOpen: false,
      scriptPopupList: [],
      scriptPopupSelected: null,
      scriptCustomerId: 0,
      scriptStyle: "",
      moveable: {
        target: [".scriptPopupMoveable"],
        draggable: true,
        scalable: false,
        rotatable: false,
        pinchable: false,
        origin: false,
      },

      creditReportStyle: "",
      moveableCreditReport: {
        target: [".creditReportPopupMoveable"],
        draggable: true,
        scalable: false,
        rotatable: false,
        pinchable: false,
        origin: false,
      },

      isLeftMenuOpen: true,
      notificationTimerInterval: 15000,
      loginStatusInterval: 1500,
      userTierSettings: [],
      isBrCallPopupOpen: false,
      brCallCustomerId: 0,
      brCallCustomerName: "",
      forceLeavePage: false,
    };
  },
  computed: mapState({
    notificationList: (state) => state.globals.pendingNotifications,
    popupNotifications: (state) => state.notifications.popupNotifications,
    historyList: (state) => state.globals.historyList,
    favorites: (state) => state.globals.favorites,
    isUserOnline: (state) => state.globals.isUserOnline,
    userListeningCall: (state) => state.globals.userListeningCall,
    pauseReasonTypes: (state) => state.globals.pauseReasonTypes,
    userLastCallTime: (state) => state.globals.userLastCallTime,

    sortedPauseReasonTypes() {
      return _orderby.orderBy(this.pauseReasonTypes, "Name", "asc");
    },
    sortedNotifications() {
      return _orderby.orderBy(
        this.notificationList.filter(
          (x) =>
            x.NotificationType.Id != 6 &&
            x.NotificationType.Id != 5 &&
            x.NotificationType.Id != 2 &&
            !(x.NotificationType.Id == 1 && this.checkAuth(8004))
        ),
        "NotificationType.Priority",
        "asc"
      );
    },
    smsNotifications() {
      return _orderby.orderBy(
        this.notificationList.filter((x) => x.NotificationType.Id == 6),
        "NotificationType.Priority",
        "asc"
      );
    },
    voiceMailNotifications() {
      return _orderby.orderBy(
        this.notificationList.filter(
          (x) => x.NotificationType.Id == 5 || x.NotificationType.Id == 2
        ),
        "NotificationType.Priority",
        "asc"
      );
    },
    revertedHistory() {
      return deepCopy(this.historyList).reverse();
    },

    filteredReports() {
      return _orderby.orderBy(
        this.reportList.filter(
          (item) =>
            item.Label.toLowerCase().indexOf(this.reportsFilter.toLowerCase()) >
            -1
        ),
        "Name",
        "asc"
      );
    },
  }),
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("beforeunload", this.leavePage);
    this.$Emitter.off("viewCustomer");
    this.$Emitter.off("openSms");
    this.$Emitter.off("openReminder");
    this.$Emitter.off("openDisposition");
    this.$Emitter.off("acceptLeadFromCampaignCall");
    this.$Emitter.off("viewNotePanel");
    this.$Emitter.off("openCsrTaskPopup");
    this.$Emitter.off("hotLeadReceived");
    this.$Emitter.off("hotLeadCancel");
    this.$Emitter.off("openScriptPopup");
    this.$Emitter.off("showCustomerGrid");
    this.$Emitter.off("updateCustomerDispoLogs");
    this.$Emitter.off("updateReminderLogs");
    this.$Emitter.off("refreshCashFlow");
    this.$Emitter.off("resizeDashboard");
    this.$Emitter.off("updateTicketDashboard");
    this.$Emitter.off("updateCustomerProfileTags");
    this.$Emitter.off("brCallReceived");
  },
  async mounted() {
    let userPhone = sessionStorage.getItem("userPhoneSettings");
    if (userPhone != null && JSON.parse(userPhone).endPointId != null) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(async () => {})
        .catch(async () => {
          this.$swal
            .fire({
              title: "Warning",
              text: "Please set your microphone permission and reload page.",
              type: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            })
            .then(async (dialog) => {
              if (dialog.value) {
                this.handleLogout();
              }
            });
        });
    }

    if (process.env.VUE_APP_IS_DEVELOPMENT == "false") {
      window.addEventListener("beforeunload", this.leavePage);
      this.$Emitter.on("forceLeavePage", () => {
        this.forceLeavePage = true;
        window.removeEventListener("beforeunload", this.leavePage);
      });
    }

    this.isMobile = this.isMobileDevice();
    this.version = Crm_Version;
    this.companyLogo =
      this.$logo + "?v=" + (Math.floor(Math.random() * 1000) + 1).toString();
    this.tabGuid = new Date().getTime();
    this.showPhone = false;
    this.showPhonePanel = false;
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.myUserId = userInfo.userId;
    this.isAutoDialLeadDistro = userInfo.IsAutoDial;
    this.photoUrl =
      userInfo.photoUrl + "?rand" + Math.floor(Math.random() * 1000) + 1;
    this.defaultPhotoUrl = userInfo.defaultPhotoUrl;
    this.firstName = userInfo.firstName;
    this.lastName = userInfo.lastName;
    this.officeId = userInfo.officeId;
    this.CompanyName = userInfo.companyName;
    this.screenName = userInfo.screenName;
    this.leadTier = userInfo.leadTier;
    this.tierTimeOut = userInfo.tierTimeOut;
    this.lastConnectionTime = null;
    this.userPhoneSettings = JSON.parse(
      sessionStorage.getItem("userPhoneSettings")
    );
    this.isTouchDevice = false;
    if ("ontouchstart" in document.documentElement) {
      this.isTouchDevice = true;
    }

    if (
      !this.isMobile &&
      this.userPhoneSettings &&
      this.userPhoneSettings.endPointId
    ) {
      if (plivoCheckTimer == null) {
        plivoCheckTimer = setInterval(async () => {
          if (document.getElementById("expandCollapsePhoneButton") == null) {
            this.$swal
              .fire({
                title: "Warning",
                text: "Cannot initialize the Phone system. Please log out then log back in",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ee324d",
                confirmButtonText: "Logout",
                allowOutsideClick: false,
              })
              .then(async (dialog) => {
                if (dialog.value) {
                  this.$emit("authenticated", false);
                }
              });
          }
          clearInterval(plivoCheckTimer);
          plivoCheckTimer = null;
        }, 20000);
      } else {
        clearInterval(plivoCheckTimer);
        plivoCheckTimer = null;
      }
      this.showPhone = true;
      if (this.checkAuth(1865)) {
        await this.checkPendingDispositions();
      }
    } else {
      this.showPhone = false;
      let reasonTypes = this.pauseReasonTypes.filter((x) => x.Id > 2);
      if (reasonTypes.length > 0) {
        reasonTypes = _orderby.orderBy(reasonTypes, "Id", "asc");
      }
      this.$store.dispatch(globalTypes.GET_ONLINE_STATUS, {
        IsOnline: false,
        PauseReasonId: reasonTypes.length > 0 ? reasonTypes[0].Id : 1,
      });
    }

    if (userInfo && userInfo.notificationTimerInterval) {
      this.notificationTimerInterval = userInfo.notificationTimerInterval;
    }

    if (userInfo && userInfo.tierSettings) {
      this.userTierSettings = userInfo.tierSettings;
    }

    this.$Emitter.on("viewCustomer", (data) => {
      if (data) {
        this.handleOutsideCustomers(data.customerId, data.customerName);
      }
    });

    this.$Emitter.on("viewNotePanel", (data) => {
      if (data) {
        if (data.CustomerId && data.CustomerId > 0) {
          this.noteCustomerId = data.CustomerId;
        }

        if (data.CreditorId && data.CreditorId > 0) {
          this.noteCreditorId = data.CreditorId;
        }

        if (data.CsrTaskId > 0) {
          this.noteCsrTaskId = data.CsrTaskId;
        }

        if (data.AgentId > 0) {
          this.noteAgentId = data.AgentId;
          this.noteAgentName = data.AgentName;
        }
      }
    });

    this.notifyTimer = setInterval(() => {
      this.getNewNotifications();
    }, this.notificationTimerInterval);

    this.loginStatusTimer = setInterval(() => {
      this.updateUserLoginStatus();
    }, this.loginStatusInterval);

    await Promise.all([
      this.$store.dispatch(globalTypes.GET_NOTIFICATIONS, {
        RemoveAutoCallReminders: this.checkAuth(8004),
      }),
      this.$store.dispatch(globalTypes.GET_USER_ACTIVITY_HISTORY),
      this.$store.dispatch(globalTypes.GET_PAUSE_REASON_TYPES, null),
      this.$store.dispatch(globalTypes.GET_FAV, null),
    ]);

    this.checkInactivity();

    if (this.pauseReasonTypes && this.pauseReasonTypes.length > 0) {
      let orderedList = _orderby.orderBy(this.pauseReasonTypes, "Id", "asc");
      this.pauseReasonTypeId = orderedList[0].Id;
    }

    this.$Emitter.on("openSms", (data) => {
      if (data) {
        this.isSmsHistoryOpen = true;
        this.smsHistoryPhoneList = data.PhoneList;
        this.smsHistoryPhoneNumber = data.Phones;
        this.smsHistoryCustomerId = data.CustomerId;
        this.smsHistoryLanguageId = data.LanguageId;
      }
    });
    this.$Emitter.on("openReminder", (data) => {
      if (data) {
        this.reminderCustomerId = data.CustomerId;
        this.autoCloseReminders = data.IsAutoClose;
        this.isReminderGridOpen = true;
        this.customerPrimaryPhone = data.CustomerPrimaryPhone;
      }
    });
    this.$Emitter.on("openDisposition", (data) => {
      if (data) {
        this.dispositionCustomerId = data.CustomerId;
        this.dispositionStatusId = data.StatusId;
      }
    });

    this.$Emitter.on("acceptLeadFromCampaignCall", (data) => {
      if (data) {
        this.AcceptLeadFromCampaignCall(data);
      }
    });

    if (this.$refs.plivo) {
      await this.sleep(1000);
      this.$refs.plivo.loginPlivo();
    }

    this.$Emitter.on("closeActiveCustomer", (data) => {
      if (this.activeCustomerId == data.CustomerId) {
        this.activeCustomerId = 0;
        this.activeCustomerIdHistory = 0;
      }
    });

    this.$Emitter.on("openCsrTaskPopup", (data) => {
      if (data != null && data.Id > 0) {
        this.csrTaskItemForPopup = data;
      }
    });

    this.$Emitter.on("openPortalUserInterface", (data) => {
      if (data != null) {
        this.portalUserInterfaceData = data;
        this.isPortalUserInterfaceOpen = true;
      }
    });

    this.$Emitter.on("hotLeadReceived", async (data) => {
      // this.$store.dispatch(globalTypes.SEND_HOT_LEAD_LOG, {
      //   Message: "2. Emitter: hotLeadReceived",
      // });

      if (data != null) {
        let isAvailable =
          this.isAutoDialLeadDistro == true
            ? await this.isAvailableforAutoDialLead(data.Message)
            : this.isAvailableforHotLead(data.Message);

        if (isAvailable) {
          let tierTimeout = null;
          let userTier = null;
          if (this.userTierSettings && this.userTierSettings.length > 0) {
            let indx = this.userTierSettings.findIndex(
              (x) => x.CampaignId == data.Customers[0].CampaignId
            );
            if (indx > -1) {
              tierTimeout = parseInt(this.userTierSettings[indx].TierTimeout);
              userTier = parseInt(this.userTierSettings[indx].TierNumber);
            }
          }

          let timeout = 7000;
          if (tierTimeout && userTier) {
            timeout = tierTimeout * (userTier - 1) * 1000;
          }

          this.hotLeadTimer[data.Customers[0].CustomerId] = setTimeout(
            async () => {
              if (this.isAutoDialLeadDistro == true) {
                if (this.$refs.plivo.isCallAvailableforAutoDialLead()) {
                  await this.handleHotLeadAccept();
                }
              } else {
                this.openHotLeadPopup(data.Customers[0].CustomerId);
              }
            },
            timeout
          );
        }
      }
    });

    this.$Emitter.on("hotLeadCancel", (data) => {
      if (data != null) {
        if (
          this.isHotLeadPopupOpen &&
          data.CustomerId == this.hotLeadCustomerId
        ) {
          this.closeHotLeadPopup();
        }
        try {
          if (this.hotLeadTimer[data.CustomerId]) {
            clearTimeout(this.hotLeadTimer[data.CustomerId]);
            delete this.hotLeadTimer[data.CustomerId];
          }
        } catch (err) {
          //console.log(err);
        }
      }
    });

    this.$Emitter.on("openScriptPopup", async (data) => {
      if (data) {
        this.isScriptPopupOpen = !this.isScriptPopupOpen;
        this.scriptPopupList = data.ScriptList;
        this.scriptPopupSelected = data.SelectedScript;
        this.scriptStyle = this.isScriptPopupOpen ? "" : "display:none;";
        this.scriptCustomerId = data.CustomerId;
      }
    });

    this.$Emitter.on("showCustomerGrid", () => {
      this.handleCustomers(true);
    });

    this.$Emitter.on("brCallReceived", async (data) => {
      if (data != null) {
        this.brCallCustomerId = data.CustomerId;
        this.brCallCustomerName = data.CustomerName;
        this.isBrCallPopupOpen = true;
      }
    });

    let passwordExpirationDate = userInfo.passwordExpirationDate;
    let currentDate = new Date();
    let fifteenDays = 15 * 24 * 60 * 60 * 1000;

    if (
      passwordExpirationDate != null &&
      new Date(passwordExpirationDate) <= currentDate
    ) {
      this.closeButtonExpiration = true;
      this.isChangePasswordPopUp = true;
    } else if (
      passwordExpirationDate != null &&
      new Date(passwordExpirationDate) - currentDate <= fifteenDays
    ) {
      await this.$swal({
        title:
          "Your password expires on " +
          this.formatShortDate(passwordExpirationDate),
        text: "Please set up a new password.",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Remind me Later",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Change Password",
        allowOutsideClick: false,
      }).then(async (dialog) => {
        if (dialog.value) {
          this.isChangePasswordPopUp = true;
        }
      });
    }
    this.$Emitter.on("SetPendingDispo", (customerId) => {
      if (customerId > 0) {
        this.pendingDispoCustomerId = customerId;
      }
    });
    this.$Emitter.on("CheckPendingDispo", async () => {
      await this.sleep(1000);
      await this.checkPendingDispositions();
    });

    // this.$Emitter.on("sendServiceBusLogs", (message) => {
    //   this.$store.dispatch(globalTypes.SEND_HOT_LEAD_LOG, { Message: message });
    // });
  },
  methods: {
    async checkPendingDispositions() {
      if (this.checkAuth(1865)) {
        let err, result;
        [err, result] = await this.$store.dispatch(
          globalTypes.GET_PENDING_DISPO
        );
        if (result) {
          if (result.Data && result.Data.CustomerId) {
            this.pendingDispoCustomerId = result.Data.CustomerId;
          } else {
            this.pendingDispoCustomerId = 0;
          }
        }
      }
    },
    handleCallStatusChange(isCallActive) {
      this.activeCallStatus = isCallActive;
    },
    leavePage(event) {
      if (!this.forceLeavePage) {
        event.preventDefault();
        let currentTime = new Date().getTime();
        let form = this;
        setTimeout(() => {
          if (
            !form.isMobile &&
            form.userPhoneSettings &&
            form.userPhoneSettings.endPointId &&
            form.$refs.plivo
          ) {
            if (parseFloat(new Date().getTime() - currentTime) > 10000) {
              form.$refs.plivo.loginPlivo(true);
            }
          }
        }, 5000);
      } else {
        this.handleSoftLogout();
        window.location.href = "/#/login";
      }
    },
    handleViewCustomer(data) {
      if (data) {
        this.handleOutsideCustomers(data.customerId, data.customerName);
      }
      this.isBrCallPopupOpen = false;
    },
    closeBrCallPopup() {
      this.isBrCallPopupOpen = false;
    },
    async openChangePasswordPopUp() {
      this.isChangePasswordPopUp = true;
      let currentExpirationDate = JSON.parse(
        sessionStorage.getItem("userInfo")
      ).passwordExpirationDate;

      let currentDate = new Date();
      if (
        currentExpirationDate != null &&
        new Date(currentExpirationDate) <= currentDate
      ) {
        this.closeButtonExpiration = true;
      } else {
        this.closeButtonExpiration = false;
      }
    },
    closeChangePasswordPopUp() {
      this.isChangePasswordPopUp = false;
    },
    checkInactivity() {
      window.onload = this.resetInactivityTimer;
      window.onmousemove = this.resetInactivityTimer;
      window.onmousedown = this.resetInactivityTimer;
      window.ontouchstart = this.resetInactivityTimer;
      window.ontouchmove = this.resetInactivityTimer;
      window.onclick = this.resetInactivityTimer;
      window.onkeydown = this.resetInactivityTimer;
      window.addEventListener("scroll", this.resetInactivityTimer, true);
      this.resetInactivityTimer();
    },
    resetInactivityTimer() {
      if (inactivityTimerVar != null) {
        clearTimeout(inactivityTimerVar);
      }

      inactivityTimerVar = setTimeout(this.timedOutLogout, 5400000); //set for 90 minutes?
    },
    async timedOutLogout() {
      //if active call or hot lead show -> resetTimer()

      let listeningCallRecord = false;
      let callLogPlayer = document.getElementById("callLogPlayer");
      if (typeof callLogPlayer != "undefined" && callLogPlayer) {
        listeningCallRecord = !callLogPlayer.paused;
      }

      let listeningCallReport = false;
      let callLogReportPlayer = document.getElementById("callReportPlayer");
      if (typeof callLogReportPlayer != "undefined" && callLogReportPlayer) {
        listeningCallReport = !callLogReportPlayer.paused;
      }
      if (
        (this.$refs.plivo && this.$refs.plivo.isUserOnCall()) ||
        this.isHotLeadPopupOpen == true ||
        listeningCallRecord == true ||
        listeningCallReport == true ||
        this.userListeningCall == true
      ) {
        this.resetInactivityTimer();
      } else {
        clearTimeout(inactivityTimerVar);
        inactivityTimerVar = null;
        await this.$store.dispatch(globalTypes.GET_ONLINE_STATUS, {
          IsOnline: false,
          PauseReasonId: 2,
        });
        this.forceLeavePage = true;
        location.reload();
      }
    },
    expandCollapseLeftMenu() {
      this.isLeftMenuOpen = !this.isLeftMenuOpen;
      this.$emit("openCloseMenu", this.isLeftMenuOpen);
      this.$Emitter.emit("resizeDashboard", this.isLeftMenuOpen);
    },

    handleDrag({ target, transform }) {
      if (this.isTouchDevice) {
        return;
      }
      try {
        //matrix(1, 0, 0, 1, 0, 0) translate(-308px, -701px)
        if (target && transform.includes("translate")) {
          let index = transform.indexOf("translate(");
          let str = transform.slice(index, transform.length);
          str = str.replace("translate(", "");
          str = str.replace(")", "");

          let maxHeight = window.innerHeight;
          let customerPanel = document.getElementById(
            "customer-" + this.scriptCustomerId
          );
          if (typeof customerPanel != "undefined" && customerPanel != null) {
            maxHeight = customerPanel.clientHeight;
          }

          if (str.length > 0) {
            let positions = str.split(",");
            if (positions.length > 0) {
              let posX = parseInt(positions[0].replace("px", ""));
              let posY = parseInt(positions[1].replace("px", ""));
              let winWidth = window.innerWidth;
              let controlWidth = target.offsetWidth;
              let controlHeight = target.offsetHeight;
              let minX = -controlWidth + 50;
              let minY = -controlHeight + 50;
              if (
                posX > minX &&
                posY > minY &&
                posX < parseInt(winWidth - controlWidth) &&
                posY < parseInt(maxHeight)
              ) {
                target.style.transform = transform;
              }
            }
          }
        }
      } catch {
        //todo:log errors
      }
    },
    closePortalUserInterface() {
      this.isPortalUserInterfaceOpen = false;
    },
    closeTaskPopup() {
      this.csrTaskItemForPopup = null;
    },
    updateCsrTask() {
      this.$Emitter.emit("updateCsrTask");
    },
    closeNotePanel() {
      this.noteCustomerId = 0;
      this.noteCreditorId = 0;
      this.noteCsrTaskId = 0;
      this.noteAgentId = 0;
      this.noteAgentName = "";
    },
    OpenCreditorContactForm() {
      this.isCreditorContactFormOpen = true;
    },
    closeCreditorContactForm() {
      this.isCreditorContactFormOpen = false;
    },

    setUserAway(reasonTypeId) {
      this.pauseReasonTypeId = reasonTypeId;
      this.$store.dispatch(globalTypes.GET_ONLINE_STATUS, {
        IsOnline: false,
        PauseReasonId: reasonTypeId,
      });
      this.isPauseOptionsOpen = false;
    },
    setOnlineStatus() {
      if (this.isUserOnline) {
        this.isPauseOptionsOpen = true;
      } else {
        this.$store.dispatch(globalTypes.GET_ONLINE_STATUS, {
          IsOnline: true,
          PauseReasonId: 1,
        });
        this.pauseReasonTypeId = 0;

        if (this.$refs.plivo) {
          this.$refs.plivo.checkPendingIncomingCalls();
        }
      }
    },
    async isAvailableforHotLead(msgObj) {
      if (
        this.isHotLeadPopupOpen ||
        !this.isUserOnline ||
        this.isBrCallPopupOpen
      ) {
        return false;
      }

      if (!this.checkAuth(55835)) {
        if (!this.showPhone || !this.userLoggedIn) {
          return false;
        }
      }

      // this.$store.dispatch(globalTypes.SEND_HOT_LEAD_LOG, {
      //   Message:
      //     "3. isAvailableforHotLead: IsHotLeadPopupOpen: " +
      //     this.isHotLeadPopupOpen +
      //     " IsUserOnline: " +
      //     this.isUserOnline,
      // });

      // this.$store.dispatch(globalTypes.SEND_HOT_LEAD_LOG, {
      //   Message:
      //     "4. isAvailableforHotLead: showPhone: " +
      //     this.showPhone +
      //     " IsUserLoggedIn: " +
      //     this.userLoggedIn,
      // });
      if (
        !msgObj ||
        !msgObj.Offices ||
        msgObj.Offices.length == 0 ||
        !msgObj.Offices.includes(this.officeId)
      ) {
        return false;
      }
      // this.$store.dispatch(globalTypes.SEND_HOT_LEAD_LOG, {
      //   Message:
      //     "5. isAvailableforHotLead: OfficeMatched: " +
      //     msgObj.Offices.includes(this.officeId),
      // });
      // this.$store.dispatch(globalTypes.SEND_HOT_LEAD_LOG, {
      //   Message:
      //     "6. isAvailableforHotLead: Is User On Call: " + this.isUserOnCall,
      // });
      let secs = Math.abs(
        (new Date().getTime() - this.lastHotLeadShowTime.getTime()) / 1000
      );

      if (secs <= 10) return false;
      // this.$store.dispatch(globalTypes.SEND_HOT_LEAD_LOG, {
      //   Message:
      //     "7. isAvailableforHotLead: LastHotLeadShowTime: " +
      //     this.lastHotLeadShowTime.toLocaleString() +
      //     " secs: " +
      //     secs,
      // });

      if (!this.checkAuth(55835)) {
        let hasAutoCallReminders = false;
        if (this.checkAuth(8004)) {
          hasAutoCallReminders =
            this.popupNotifications.filter((x) => x.NotificationType.Id == 1)
              .length > 0 ||
            this.notificationList.filter((x) => x.NotificationType.Id == 1)
              .length > 0;
        }

        if (hasAutoCallReminders == true) return;
      }

      this.lastHotLeadShowTime = new Date();
      return true;
    },
    async isAvailableforAutoDialLead(msgObj) {
      if (
        !this.isUserOnline ||
        !this.showPhone ||
        !this.userLoggedIn ||
        !this.$refs.plivo.isCallAvailableforAutoDialLead()
      ) {
        return false;
      }
      if (
        !msgObj ||
        !msgObj.Offices ||
        msgObj.Offices.length == 0 ||
        !msgObj.Offices.includes(this.officeId)
      ) {
        return false;
      }

      let secs = Math.abs(
        (new Date().getTime() - this.lastHotLeadShowTime.getTime()) / 1000
      );

      if (secs <= 10) return false;

      // if (!this.checkAuth(55835)) {
      //   let hasAutoCallReminders = false;
      //   if (this.checkAuth(8004)) {
      //     hasAutoCallReminders =
      //       this.popupNotifications.filter((x) => x.NotificationType.Id == 1)
      //         .length > 0 ||
      //       this.notificationList.filter((x) => x.NotificationType.Id == 1)
      //         .length > 0;
      //   }

      //   if (hasAutoCallReminders == true) return;
      // }

      this.lastHotLeadShowTime = new Date();
      return true;
    },

    clearIntervals() {
      try {
        clearInterval(this.notifyTimer);
        this.notifyTimer = null;
        clearInterval(this.loginStatusTimer);
        this.loginStatusTimer = null;
        for (let i in this.hotLeadTimer) {
          try {
            clearTimeout(this.hotLeadTimer[i]);
          } catch (err) {
            //console.log("HotLead Timer Error: " + JSON.stringify(err));
          }
        }
      } catch (err) {
        //console.log("HotLead Timer Error: " + JSON.stringify(err));
      }
    },
    closeReminderGrid() {
      this.isReminderGridOpen = false;
      this.reminderCustomerId = 0;
    },
    async handleHotLeadAccept(customerId) {
      this.closeHotLeadPopup();

      /*
      if (!this.checkAuth(55835) && this.$refs.plivo) {
        this.$refs.plivo.hangUp();
      }
        */

      this.$Emitter.emit("SendLeadDistro", {
        Event: "Cancel",
        CustomerId: customerId,
      });

      let res = await this.$store.dispatch(globalTypes.ACCEPT_HOT_LEAD, {
        CustomerId: customerId,
      });
      if (res && res.Data) {
        if (res.Data.CustomerId && res.Data.CustomerId > 0) {
          this.handleOutsideCustomers(res.Data.CustomerId, "Hot Lead");
          if (
            (!(
              this.checkAuth(55835) && this.isAvailableforAutoDialLead == false
            ) ||
              this.isAvailableforAutoDialLead == true) &&
            res.Data.PhoneNumber &&
            res.Data.PhoneNumber.trim().length > 0
          ) {
            // call number
            if (this.showPhone && this.$refs.plivo) {
              this.$refs.plivo.callDid(
                res.Data.PhoneNumber,
                customerId,
                false,
                "",
                true
              );
            }
          }
        }
      }
    },
    async AcceptLeadFromCampaignCall(data) {
      let res = await this.$store.dispatch(globalTypes.ACCEPT_HOT_LEAD, {
        CustomerId: data.Id,
        CampaignId: data.CampaignId,
        PhoneNumber: data.PhoneNumber,
      });
      if (res && res.Data) {
        if (res.Data.CustomerId && res.Data.CustomerId > 0) {
          await this.$store.dispatch(
            globalTypes.INSERT_ASSIGNED_LEAD_DATA,
            data
          );
          this.handleOutsideCustomers(res.Data.CustomerId, data.Name);
          this.$Emitter.emit("refreshCustomerGrid");
          if (this.$refs.plivo) {
            this.$refs.plivo.updateActiveCall(res.Data.CustomerId, data.Name);
          }
        }
      }
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    rightclick() {},
    maxNchars(val, n) {
      let rtn = val;
      if (val.length > n) rtn = val.slice(0, n - 3) + "...";
      return rtn;
    },

    handleOtherNav() {
      this.activeCustomerId = 0;
      this.activeCustomerIdHistory = 0;
      this.isCustomerActive = false;
      this.$parent.resetLink();
      if (this.isMobile) {
        this.showMenu = false;
      }
    },
    handleCustomers(openNewCustomer = false) {
      this.isCustomerActive = true;
      this.anotherComponent("Customers");
      this.$Emitter.emit("openGrid", { HandleNew: openNewCustomer });
      this.$router.push("customers");
      this.$parent.customersLink();
      if (this.isMobile) {
        this.showMenu = false;
      }
    },
    handleOutsideCustomers(customerId, customerName, from) {
      this.isCustomerActive = true;

      if (from == "history") {
        this.activeCustomerId = 0;
        this.activeCustomerIdHistory = customerId;
      } else if (from == "favorites") {
        this.activeCustomerId = customerId;
        this.activeCustomerIdHistory = 0;
      }

      this.anotherComponent("Customer");
      this.$router.push("customers");
      if (this.isMobile) {
        this.showMenu = false;
      }
      this.$parent.customersOutsideLink(customerId, customerName);
    },

    async handleLogout() {
      if (this.showPhone && this.$refs.plivo) {
        this.showPhone = false;
        this.$Emitter.emit("stopLiveCallTimer");
        clearInterval(this.$refs.plivo.pendingCallTimer);
        this.$refs.plivo.logOut();
      }

      await this.$store.dispatch(globalTypes.GET_ONLINE_STATUS, {
        IsOnline: false,
        PauseReasonId: 2,
      });
      sessionStorage.clear();
      this.$store.dispatch(globalTypes.MODIFY_NOTIFICATIONS, []); // reset notification state
      this.$store.dispatch(globalTypes.GET_HISTORY, { Id: 0, Name: "reset" });
      this.forceLeavePage = true;
      this.$emit("authenticated", false);
      this.clearIntervals();
    },
    handleSoftLogout() {
      // function sleep(delay) {
      //   const start = new Date().getTime();
      //   while (new Date().getTime() < start + delay);
      // }
      // sleep(3000);

      if (this.showPhone && this.$refs.plivo) {
        this.showPhone = false;
        clearInterval(this.$refs.plivo.pendingCallTimer);
        this.$refs.plivo.logOut();
      }
      this.$Emitter.emit("stopLiveCallTimer");
      this.clearIntervals();
    },
    handleResize() {
      if (window.innerWidth > 767) {
        this.showMenu = true;
      } else {
        this.showMenu = false;
      }

      let windowHeight = window.innerHeight;

      if (windowHeight - 200 < this.notificationScrollBarHeight) {
        this.notificationScrollBarHeight = windowHeight - 200;
      } else {
        this.notificationScrollBarHeight = 450;
      }

      this.getNavbarHeight();
    },
    toggleNotifications() {
      this.isNotifyPanelOpen = !this.isNotifyPanelOpen;
    },
    async toggleSmsNotifications() {
      this.isSmsPanelOpen = !this.isSmsPanelOpen;
    },
    toggleVoiceMailNotifications() {
      this.isVoiceMailPanelOpen = !this.isVoiceMailPanelOpen;
    },
    imageLoadOnError() {
      event.target.src = this.defaultPhotoUrl;
    },
    hideNotificationPanel() {
      if (this.isNotifyPanelOpen == true) this.isNotifyPanelOpen = false;
    },
    hideSmsNotificationPanel() {
      if (this.isSmsPanelOpen == true) this.isSmsPanelOpen = false;
    },
    hideVoiceMailNotifications() {
      if (this.isVoiceMailPanelOpen == true) this.isVoiceMailPanelOpen = false;
    },
    showCalendar() {
      this.isNotifyPanelOpen = false;
      this.isCalendarOpen = true;
    },
    closeCalendar() {
      this.isCalendarOpen = false;
    },
    getCalendarClick: function (eventItem) {
      this.calendarNotification = eventItem;
    },
    openSmsHistory() {
      this.isSmsHistoryOpen = true;
    },
    callBackSmsHistory() {
      this.blockClose == true;
    },
    closeSmsHistory() {
      if (this.blockClose == false) {
        this.isSmsHistoryOpen = false;
      }
    },

    async updateUserLoginStatus() {
      let loginStatusId = 0;
      if (this.userLoggedIn && this.showPhone && this.$refs.plivo) {
        loginStatusId = this.isUserOnline ? 1 : 2;
        if (this.$refs.plivo.isUserOnCall() == true) {
          loginStatusId = 3;
        } else if (
          this.isUserOnline &&
          this.$refs.plivo.hasIncomingCall() == true
        ) {
          loginStatusId = 4;
        }

        if (loginStatusId == 1) {
          loginStatusId = this.pendingDispoCustomerId > 0 ? 5 : loginStatusId;
        }

        let err, result;
        [err, result] = await this.$store.dispatch(
          globalTypes.UPDATE_USER_LOGIN_STATUS,
          {
            UserLoginStatusId: loginStatusId,
          }
        );
      }
    },
    async getNewNotifications() {
      let expireUTC = sessionStorage.getItem("expireUTC");
      if (expireUTC) {
        let expireLocalDate = new Date(expireUTC);
        let dtNow = new Date();
        if (dtNow > expireLocalDate) {
          this.handleLogout();
        }
      }

      let loginStatusId = 0;
      if (this.userLoggedIn && this.showPhone && this.$refs.plivo) {
        loginStatusId = this.isUserOnline ? 1 : 2;
        if (this.$refs.plivo.isUserOnCall() == true) {
          loginStatusId = 3;
        } else if (
          this.isUserOnline &&
          this.$refs.plivo.hasIncomingCall() == true
        ) {
          loginStatusId = 4;
        }

        if (loginStatusId == 1) {
          loginStatusId = this.pendingDispoCustomerId > 0 ? 5 : loginStatusId;
        }
      }

      let err, result;
      [err, result] = await to(
        api.checkNewNotifications({
          UserLoginStatusId: loginStatusId,
          PauseReasonId: loginStatusId == 2 ? this.pauseReasonTypeId : null,
        })
      );
      if (result) {
        this.newNotifications = result.Data;
        if (this.newNotifications && this.newNotifications.length > 0) {
          this.newNotifications.forEach((item) => {
            if (
              this.notificationList.filter((x) => x.Id === item.Id).length == 0
            )
              if (!(item.NotificationType.Id == 1 && this.checkAuth(8004))) {
                this.notificationList.push(item);
              }
          });

          this.$store
            .dispatch(globalTypes.MODIFY_NOTIFICATIONS, this.notificationList)
            .catch((err) => {});

          if (this.newNotifications.length > 0) {
            this.newNotifications.forEach((item) => {
              if (!this.popupNotifications.map((x) => x.Id).includes(item.Id)) {
                if (item.NotificationType.Id == 1 && this.checkAuth(8004)) {
                  item.IsAutoCallReminder = true;
                  if (
                    this.userLoggedIn &&
                    this.showPhone &&
                    this.isUserOnline &&
                    this.$refs.plivo &&
                    this.$refs.plivo.isUserOnCall() == false
                  ) {
                    this.popupNotifications.push(item);
                  }
                } else {
                  this.popupNotifications.push(item);
                }
              }
            });
            this.$store
              .dispatch(
                notificationTypes.MODIFY_POPUPNOTIFICATIONS,
                this.popupNotifications
              )
              .catch((err) => {});

            if (this.isSmsHistoryOpen) {
              let smsNotifications = this.newNotifications.filter(
                (x) => x.NotificationType.Id == 6
              );

              if (smsNotifications.length > 0) {
                let phones = smsNotifications.map((x) => x.NotificationPayload);

                if (
                  phones.length > 0 &&
                  phones.includes(this.smsHistoryPhoneNumber)
                ) {
                  this.$Emitter.emit("refreshSmsHistory");
                }
              }
            }
          }
        }
      }

      if (this.checkAuth(8004) && this.popupNotifications.length == 0) {
        if (
          this.userLoggedIn &&
          this.showPhone &&
          this.isUserOnline &&
          this.$refs.plivo &&
          this.$refs.plivo.isUserOnCall() == false
        ) {
          await this.$store.dispatch(globalTypes.GET_NOTIFICATIONS, {
            RemoveAutoCallReminders: this.checkAuth(8004),
          });
          let indx = this.notificationList.findIndex(
            (x) => x.NotificationType.Id == 1
          );
          if (
            indx > -1 &&
            this.popupNotifications.filter(
              (x) => x.Id == this.notificationList[indx].Id
            ).length == 0
          ) {
            this.popupNotifications.push(this.notificationList[indx]);
            this.$store.dispatch(
              notificationTypes.MODIFY_POPUPNOTIFICATIONS,
              this.popupNotifications
            );
          }
        }
      }

      if (
        !this.isMobile &&
        this.$refs.plivo &&
        this.$refs.plivo.isCallAvailableforNewLead()
      ) {
        let expirePeerServerToken = this.userPhoneSettings.iceServers.ExpiresOn;
        if (expirePeerServerToken) {
          let expireServerLocalDate = new Date(expirePeerServerToken);
          let dtNow = new Date();
          if (dtNow > expireServerLocalDate) {
            this.handleLogout();
          }
        }
      }

      let backgroundLocks = document.querySelectorAll(".backgroundLock");
      for (let item of backgroundLocks) {
        if (item.childNodes.length == 0) {
          item.style.display = "none";
        }
      }
    },

    getNavbarHeight() {
      this.navbarScrollHeight = window.innerHeight - 80;
    },
    openHotLeadPopup(CustomerId) {
      clearTimeout(this.hotLeadTimer[CustomerId]);
      this.hotLeadTimer[CustomerId] = null;

      if (
        !(
          !this.isUserOnline ||
          this.isReminderGridOpen ||
          this.isSmsHistoryOpen ||
          this.isCalendarOpen ||
          this.isPortalUserInterfaceOpen ||
          this.isCreditorContactFormOpen ||
          this.csrTaskItemForPopup ||
          this.noteCustomerId > 0 ||
          this.dispositionCustomerId > 0 ||
          this.isMobile
        )
      ) {
        // this.$store.dispatch(globalTypes.SEND_HOT_LEAD_LOG, {
        //   Message: "8. openHotLeadPopup: Conditions passed",
        // });

        if (
          this.checkAuth(55835) ||
          (this.$refs.plivo && this.$refs.plivo.isCallAvailableforNewLead())
        ) {
          // this.$store.dispatch(globalTypes.SEND_HOT_LEAD_LOG, {
          //   Message: "9. isCallAvailableforNewLead: Yes ",
          // });
          let audio = new Audio(
            "https://zcrmstorage.blob.core.windows.net/public-sounds/hotlead.wav"
          );
          audio.play();
          // this.$store.dispatch(globalTypes.SEND_HOT_LEAD_LOG, {
          //   Message: "10. Play sound",
          // });
          this.hotLeadCustomerId = CustomerId;
          this.isHotLeadPopupOpen = true;
          // this.$store.dispatch(globalTypes.SEND_HOT_LEAD_LOG, {
          //   Message: "11. Hot lead popup opened",
          // });
        }
      } else {
        // let logmsg =
        //   "isReminderGridOpen: " +
        //   this.isReminderGridOpen +
        //   "### isSmsHistoryOpen: " +
        //   this.isSmsHistoryOpen +
        //   "### isCalendarOpen: " +
        //   this.isCalendarOpen +
        //   "### isPortalUserInterfaceOpen: " +
        //   this.isPortalUserInterfaceOpen +
        //   "### isCreditorContactFormOpen: " +
        //   this.isCreditorContactFormOpen +
        //   "### csrTaskItemForPopup: " +
        //   this.csrTaskItemForPopup +
        //   "### noteCustomerId: " +
        //   this.noteCustomerId +
        //   "### dispositionCustomerId: " +
        //   this.dispositionCustomerId +
        //   "### isMobile: " +
        //   this.isMobile;
        // this.$store.dispatch(globalTypes.SEND_HOT_LEAD_LOG,{ Message: "8. isCallAvailableforNewLead:No Details: "+ logmsg});
      }
    },
    closeHotLeadPopup() {
      this.isHotLeadPopupOpen = false;
      // this.$store.dispatch(globalTypes.SEND_HOT_LEAD_LOG, {
      //     Message: "HOT LEAD CANCELLED BY BUTTON CLICK",
      //   });
    },
    closeDispositionPopup() {
      this.dispositionCustomerId = 0;
      this.dispositionStatusId = 0;
    },
    toggleUserMenu() {
      this.isUserMenuOpen = !this.isUserMenuOpen;
    },

    hideUserMenu() {
      if (this.isUserMenuOpen == true) this.isUserMenuOpen = false;
    },
    hidePauseOptionMenu() {
      if (this.isPauseOptionsOpen == true) this.isPauseOptionsOpen = false;
    },
    anotherComponent(componentName) {
      if (componentName != "LiveCallReport") {
        this.$Emitter.emit("stopLiveCallTimer");
      }
      if (componentName != "Customers") {
        this.$Emitter.emit("stopCustomerGridTimer");
      }
    },
  },
  mixins: [formatMixin, utilitiesMixin],
};
</script>
<style scoped>
.animate_collapseAnimation {
  animation-name: collapseAnimation;
  animation-duration: 1s;
}
.animate_expandAnimation {
  animation-name: expandAnimation;
  animation-duration: 1s;
}

@keyframes collapseAnimation {
  from {
    margin-left: 0;
  }
  to {
    margin-left: -250px;
  }
}
@keyframes expandAnimation {
  from {
    margin-left: -250px;
  }
  to {
    margin-left: 0;
  }
}
.searchIcon {
  position: absolute;
  left: 176px;
  top: 2px;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 0.3rem;
  height: 25px;
  color: #6c757d;
  cursor: default;
}
.filterReportInput {
  display: block;
  padding: 0.2rem 0.5rem;
  font-size: 14px;
  margin-left: 9px;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.navigationItemWrapper {
  width: 100%;
}
.navigationItemIcon {
  width: 8%;
  float: left;
}
.navigationItemText {
  width: 80%;
  float: left;
  margin-top: -1px;
}
.navigationReportsText {
  width: 90%;
  float: left;
  margin-top: -1px;
}
.valignTop {
  margin-top: -6px;
}

.left-side-menu {
  position: fixed;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.mobile-sidebar-menu {
  margin-top: 72px;
}
.reminderClose {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.backgroundLock {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
}
.reminderCalendar {
  width: 50%;
  float: left;
  margin: 10px 25%;
  position: relative;
  padding: 0 10px 0 10px;
  z-index: 1000;
  background: #efefef;
  border: 3px solid #555;
  box-shadow: 0 2px 5px #000;
}
.smsHistoryPanel {
  z-index: 1000;
  border-radius: 10px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
}
.closeSmsHistory {
  width: 60px;
  float: right;
  display: block;
  margin: 10px 12px 0 0;
  padding: 8px 0;
  cursor: pointer;
  border-radius: 8px;
  border: solid 1px #3bafda;
  background-color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #3bafda;
}
.calendarExit {
  width: 50px;
  position: absolute;
  top: 10px;
  right: 15px;
  display: block;
  text-align: right;
  font-size: 32px;
  line-height: 24px;
  cursor: pointer;
  z-index: 1000;
}
.calendarExit:hover {
  color: #ff3860;
}
.hotLeadWrapper {
  margin: auto;
  position: relative;
  box-sizing: border-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  width: 28em;
  max-width: 100%;
  padding: 1.25em;
  border: none;
  border-radius: 0.3125em;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
}
.noNotificationPanel {
  background-color: #f5f5f5;
  padding: 40px 0;
}
.statusIcon {
  font-size: 16px;
  line-height: 0px !important;
  display: block;
}
.onlineButton {
  background-color: #58a56b !important;
  color: #fff;
}
.awayButton {
  background-color: #f7b84b !important;
  color: #fff;
}

.buttonHover {
  color: #6a6c71;
}

.buttonHover:hover {
  color: #3bafda !important;
  transition-duration: 0.3s;
}
.navCategoryColor {
  color: #6a6c71;
}
.navCategoryColor:hover {
  color: #3bafda !important;
  transition-duration: 0.3s;
}
.scriptPopupMoveable {
  position: absolute;
  width: 480px;
  height: auto;
  margin: 0 auto;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 0 5px 2px #b7b3b3;
  background-color: #ffffff;
  cursor: pointer;
  z-index: 2000;
}
.creditReportPopupMoveable {
  position: absolute;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 0 5px 2px #b7b3b3;
  background-color: #ffffff;
  cursor: pointer;
  z-index: 1050;
}
.moveable-control-box {
  display: none !important;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 255, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 255, 0, 0);
  }
}
.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
}
.blink {
  animation: blink-animation 3s steps(5, start) infinite;
  -webkit-animation: blink-animation 3s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>
