<template>
  <div class="row">
    <div
      class="col-xl-4 col-lg-4 col-md-4 col-sm-12 text-center"
      style="margin: 10px auto"
    >
      <div class="card card-body">
        <i
          class="ri-question-line text-warning"
          style="font-size: 96px; font-weight: normal; line-height: 80px"
        ></i>
        <br />
        <h4 class="card-title">Are you sure to unsubscribe</h4>
        <h4 class="card-title">{{ Email }}?</h4>
        <p>
          <button
            v-if="CompanyId && CompanyId > 0 && Email != '' && !isUnsubscribed"
            type="button"
            @click="unsubscribe()"
            id="unsubscribeBtn"
            :disabled="processing"
            class="animated-btn btn btn-primary btn-bordered-primary waves-effect"
          >
            Yes, Unsubscribe
            <i v-if="processing" class="fas fa-spinner mr-1"></i>
          </button>
        </p>

        <div v-if="isUnsubscribed" class="text-success">
          Unsubscribed successfully.
        </div>
        <div v-if="errorMessage.length > 0" class="text-danger">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import utilitiesMixin from "@/mixins/utilitiesMixin";
import axios from "axios";
export default {
  name: "Unsubscribe",
  data() {
    return {
      Data: "",
      Email: "",
      CompanyId: 0,
      processing: false,
      isUnsubscribed: false,
      errorMessage: "",
    };
  },
  mounted() {
    if (this.$route.query.req) {
      this.Data = this.$route.query.req;
      try {
        let data = atob(this.Data);

        let arr = data.split(",");
        if (arr.length > 0) {
          this.CompanyId = parseInt(arr[0]);
        }
        if (arr.length > 1) {
          this.Email = arr[1];
        }
      } catch (err) {
        console.log("Unsubscribe.vue: " + JSON.stringify(err));
      }
    }
  },
  methods: {
    async unsubscribe() {
      this.processing = true;
      if (this.CompanyId > 0 && this.Email != "") {
        let err, result;
        await axios
          .post(`${process.env.VUE_APP_API}/api/Unsubscribe/`, {
            Data: this.Data,
          })
          .then(() => {
            this.isUnsubscribed = true;
          })
          .catch((err) => {
            this.errorMessage = "An Error Occured!";
            if (err) {
              if (err.response.data.Errors)
                this.errorMessage = err.response.data.Errors.join(",");
            }
          });
      }

      this.processing = false;
    },
  },
  mixins: [utilitiesMixin],
};
</script>
